import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    Grid,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { AppButton } from "../AppButton";
import { AppTypography } from "../AppTypography";
import { PaymentMethod } from "./PaymentMethodRadio";
import { AddToCart } from "JS/Models/Media";
import { AppCircularProgress } from "../Progress/AppCircularProgress";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        warpperDialog: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.grey[600],
        },
    }),
);

export interface TransactionConfirmDialogProps
    extends InternalStandardProps<DialogProps> {
    onClose: () => void;
    onBuy: () => void;
    giftPurchasedEnabled: boolean;
    onBuyGift?: () => void;
    method: PaymentMethod;
    requiredCredits: string;
    totalPrice: string;
    cart?: AddToCart;
    buying?: boolean;
}

export const TransactionConfirmDialog = (
    props: TransactionConfirmDialogProps,
) => {
    const classes = useStyles(props);

    const {
        className,
        onClose,
        requiredCredits,
        totalPrice,
        onBuy,
        onBuyGift = () => {},
        giftPurchasedEnabled,
        method,
        buying,
        cart,
        ...rest
    } = props;

    return (
        <Dialog
            role="dialog"
            aria-modal="true"
            maxWidth={"sm"}
            fullWidth
            className={clsx(className, classes.root)}
            {...rest}
        >
            <Box className={classes.warpperDialog}>
                <DialogContent>
                    {method === PaymentMethod.CARD && (
                        <Grid container>
                            <Grid item xs={6}>
                                <AppTypography
                                    variant="body1"
                                    paragraph={false}
                                >
                                    Subtotal:
                                </AppTypography>
                                <AppTypography
                                    variant="body1"
                                    paragraph={false}
                                >
                                    Tax:
                                </AppTypography>
                                <AppTypography
                                    variant="body1"
                                    paragraph={false}
                                >
                                    Shipping Fee:
                                </AppTypography>
                                <AppTypography
                                    variant="body1"
                                    paragraph={false}
                                >
                                    Processing Fee:
                                </AppTypography>
                                <AppTypography
                                    marginTop={"8px"}
                                    fontWeight={"bold"}
                                    variant="body1"
                                >
                                    Total:
                                </AppTypography>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <AppTypography variant="body1">
                                    ${cart?.subtotal?.toFixed(2)}
                                </AppTypography>
                                <AppTypography variant="body1">
                                    ${cart?.tax?.toFixed(2)}
                                </AppTypography>
                                <AppTypography variant="body1">
                                    ${cart?.shippingFee?.toFixed(2)}
                                </AppTypography>
                                <AppTypography variant="body1">
                                    ${cart?.processingFee?.toFixed(2)}
                                </AppTypography>
                                <AppTypography
                                    marginTop={"8px"}
                                    fontWeight={"bold"}
                                    variant="body1"
                                >
                                    ${cart?.total?.toFixed(2)}
                                </AppTypography>
                            </Grid>
                        </Grid>
                    )}

                    {method === PaymentMethod.MY_CREDITS && (
                        <Grid container>
                            <Grid item xs={12}>
                                <AppTypography
                                    variant="h6"
                                    className={classes.grey600}
                                >
                                    {`Are you sure you want to Redeem?`}
                                </AppTypography>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <AppButton
                        disabled={buying}
                        buttonVariant="grey-text"
                        onClick={giftPurchasedEnabled ? onBuyGift : onBuy}
                    >
                        {buying && (
                            <AppCircularProgress
                                aria-label="processing"
                                style={{
                                    height: "20px",
                                    width: "20px",
                                }}
                            />
                        )}
                        {!buying &&
                            (method === PaymentMethod.MY_CREDITS
                                ? "Redeem"
                                : "Buy")}
                    </AppButton>
                    <AppButton buttonVariant="grey-text" onClick={onClose}>
                        Cancel
                    </AppButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
