import { config } from "JS/Config";
import { GiftsGiven, GiftsAcquiredDate, User } from "JS/Models";
import { GiftRemainingQuantity, Gifts, MembersGroup } from "JS/Models/Media";
import { AppAlternativeResponse, AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";
import { loggingFirebaseService } from "../Firebase/LoggingService";

export class GiftService extends BaseService {
    async getPurchasableGiftsByMembers(category) {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);
        formData.append(category, "1");

        return (
            await this.doXHR<AppResponse<{ gifts: Gifts[] }>>({
                url: this.routes.server.api.gifts.getPurchasableGiftsByMembers(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async getReceivedMemberMediaGifts(isVideo: boolean) {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);
        if (isVideo) formData.append("is_video", "1");

        return (
            await this.doXHR<AppAlternativeResponse<{ gifts: Gifts[] }>>({
                url: this.routes.server.api.gifts.getReceivedMemberMediaGifts(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async getReceivedProspectMediaGifts(isVideo: boolean) {
        let giftType = "GiftProsMP3";
        if (isVideo) giftType = "GiftProsVideo";

        const memberId = config?.user?.memberId;

        return (
            await this.doXHR<AppResponse<{ gifts: Gifts[] }>>({
                url: this.routes.server.api.gifts.getReceivedProspectMediaGifts(
                    memberId,
                    giftType,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async getMediaGiftsGiven(isVideo: boolean) {
        const formData = new FormData();
        const memberId = config?.user?.memberId;

        formData.append("member_id", memberId);
        formData.append("access_token", `${config?.accessToken}`);
        if (isVideo) formData.append("is_video", "1");

        return (
            await this.doXHR<AppAlternativeResponse<{ gifts: GiftsGiven[] }>>({
                url: this.routes.server.api.gifts.getMediaGiftsGiven(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async getSentMediaGifts(isVideo: boolean) {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);
        if (isVideo) formData.append("is_video", "1");

        return (
            await this.doXHR<AppAlternativeResponse<{ gifts: Gifts[] }>>({
                url: this.routes.server.api.gifts.getSentMediaGifts(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async giftRemainingQuantity(skuId) {
        const formData = new FormData();
        let input = JSON.stringify({
            member_id: +config?.user?.memberId,
            sku_ids: [`${skuId}`],
        });

        formData.append("access_token", `${config?.accessToken}`);
        formData.append("input", input);

        return (
            await this.doXHR<AppAlternativeResponse<GiftRemainingQuantity[]>>({
                url: this.routes.server.api.gifts.giftRemainingQuantity(),
                method: "POST",
                data: formData,
                headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                },
            })
        )?.data;
    }

    async giftProsRemainingQuantity(skuId) {
        const formData = new FormData();
        let input = JSON.stringify({
            member_id: +config?.user?.memberId,
            sku_ids: [`${skuId}`],
        });

        formData.append("input", input);

        return (
            await this.doXHR<AppAlternativeResponse<GiftRemainingQuantity[]>>({
                url: this.routes.server.api.gifts.giftProsRemainingQuantity(),
                method: "POST",
                data: formData,
                headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                },
            })
        )?.data;
    }

    async allMembersOfGroup() {
        const formData = new FormData();
        formData.append("access_token", `${config?.accessToken}`);
        formData.append("member_id", `${config?.user?.memberId}`);
        formData.append("group_name", `My LOS`);

        return (
            await this.doXHR<AppResponse<MembersGroup>>({
                url: this.routes.server.api.gifts.allMembersOfGroup(),
                method: "POST",
                data: formData,
                headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                },
            })
        ).data;
    }

    async sendGift(skuId: string[], selectedMembers: string[]) {
        const formData = new FormData();
        let input = JSON.stringify({
            receivers: selectedMembers,
            gift_skus: skuId,
        });

        formData.append("access_token", `${config?.accessToken}`);
        formData.append("input", input);

        return (
            await this.doXHR<AppResponse<[]>>({
                url: this.routes.server.api.gifts.sendGift(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async getPurchasableGiftsAcquiredDates(
        contentType: string,
        pageOffset: number,
        pageSize: number,
        sincePaymentDate: string,
    ) {
        const memberId = config?.user?.memberId;
        return (
            await this.doXHR<
                AppResponse<{
                    gifts: GiftsAcquiredDate[];
                    pageNo: number;
                    totalPages: number;
                }>
            >({
                url: this.routes.server.api.gifts.getPurchasableGiftsAcquiredDates(
                    memberId,
                    contentType,
                    pageOffset,
                    pageSize,
                    sincePaymentDate,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async syncDYS(username: string, password: string) {
        const formData = new FormData();
        formData.append("email", username);
        formData.append("password", password);
        formData.append("access_token", config?.accessToken);

        return (
            await this.doXHR<AppResponse<string>>({
                url: this.routes.server.api.gifts.syncDYS(
                    config?.user?.memberId,
                ),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    getUserName() {
        const formData = new FormData();
        formData.append("access_token", config?.accessToken);
        formData.append("member_id", config?.user?.memberId);
        formData.append("source", "React-Media");
        loggingFirebaseService.logRealtimeAnalysis(
            "UsernameParams",
            {
                accessToken: config?.accessToken,
                memberId: config?.user?.memberId,
            },
            "UsernameFetch",
        );

        return this.doXHR<AppResponse<User>>({
            url: this.routes.server.api.gifts.getUserName(),
            method: "POST",
            data: formData,
        });
    }
}
