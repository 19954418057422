import { useEffect, useState, useCallback } from "react";
import { config } from "JS/Config";
import { PushNotificationsService } from "JS/Services/PushNotifications";
import { DeleteTokenData, RegisterTokenData, UpdateTokenData } from "JS/Models";
import { AppResponse } from "JS/Types";
import { useAuth } from "../Context/AuthenticationProvider";
import {
    deleteDeviceTokenApi,
    getFirebaseToken,
    getLocalFcmToken,
    getLocalRefreshedOnInitialized,
    getWebcastUUID,
    logReason,
    saveToken,
    setLocalRefreshedOnInitialized,
} from "JS/Helpers/PushNotifications";
import { useGlobalMedia } from "./Media";
import { isAndroid, isIOS } from "react-device-detect";

export const useRequestPermission = () => {
    const { isAuthenticated } = useAuth();
    const { notiPermissionDialogCount, decrementNotiPermissionDialogCount } =
        useGlobalMedia();

    const { initializeNotifications, updateTokenIfNew } =
        usePermissionHelpers();

    const allowsNotification = !!window.Notification;

    const [show, setShow] = useState(
        allowsNotification &&
            Notification.permission === "default" &&
            notiPermissionDialogCount > 0,
    );

    useEffect(() => {
        if (isAuthenticated) {
            const currentToken = getLocalFcmToken();
            if (!!currentToken) {
                updateTokenIfNew(currentToken);
            } else if (
                allowsNotification &&
                Notification.permission === "granted"
            )
                initializeNotifications();
        }
    }, [isAuthenticated]);

    const yesClick = async () => {
        decrementNotiPermissionDialogCount();
        setShow(false);
        const permission = await Notification.requestPermission();
        if (permission === "granted") initializeNotifications();
    };

    const noClick = () => {
        decrementNotiPermissionDialogCount();
        setShow(false);
    };

    return {
        show,
        yesClick,
        noClick,
    };
};

export const usePermissionIfDenied = () => {
    const { initializeNotifications, updateTokenIfNew } =
        usePermissionHelpers();

    const onGettingPermission = () => {
        const currentToken = getLocalFcmToken();
        if (!!currentToken) {
            updateTokenIfNew(currentToken);
        } else {
            initializeNotifications();
        }
    };

    return {
        onGettingPermission,
    };
};

const usePermissionHelpers = () => {
    const { registerDeviceToken } = useRegisterDeviceToken();
    const { updateDeviceToken } = useUpdateDeviceToken();

    const initializeNotifications = () => {
        getFirebaseToken()
            .then(async (token) => {
                const resp = await registerDeviceToken(token);
                if (resp.status) {
                    saveToken(token);
                }
            })
            .catch((reason) => {
                const isRefreshed = getLocalRefreshedOnInitialized();
                logReason(reason);

                //these android and ios errors are from browser if app tries to gets token before registering the service worker
                const isErrOnAndroid =
                    isAndroid &&
                    reason.name === "AbortError" &&
                    reason.code === 20;
                const isErrOnIos =
                    isIOS &&
                    reason.name === "InvalidStateError" &&
                    reason.code === 11;
                if (!isRefreshed && (isErrOnAndroid || isErrOnIos)) {
                    setLocalRefreshedOnInitialized(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            });
    };

    const updateTokenIfNew = (currentToken: string) => {
        getFirebaseToken()
            .then(async (token) => {
                if (currentToken !== token) {
                    const updateRes = await updateDeviceToken(
                        currentToken,
                        token,
                    );
                    if (updateRes.status) {
                        saveToken(token);
                    }
                }
            })
            .catch((reason) => logReason(reason));
    };

    return {
        initializeNotifications,
        updateTokenIfNew,
    };
};

//api calls
export const service = new PushNotificationsService();

const useRegisterDeviceToken = () => {
    const [loading, setLoading] = useState(false);

    const registerDeviceToken = useCallback(
        (token: RegisterTokenData["token"]) => {
            setLoading(true);

            return service
                .registerDeviceToken(
                    config.user.memberId,
                    token,
                    getWebcastUUID(),
                )
                .then((resp) => resp?.response)
                .catch((err) => {
                    return {
                        data: {},
                        status: err.toJSON().status,
                        message: err.message,
                    } as AppResponse<null>["response"];
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        loading,
        registerDeviceToken,
    };
};

const useUpdateDeviceToken = () => {
    const [loading, setLoading] = useState(false);

    const updateDeviceToken = useCallback(
        async (
            oldToken: UpdateTokenData["oldToken"],
            newToken: UpdateTokenData["newToken"],
        ) => {
            setLoading(true);
            try {
                const updateRes = await service.updateDeviceToken(
                    config.user.memberId,
                    oldToken,
                    newToken,
                    getWebcastUUID(),
                );

                return updateRes?.response;
            } catch (err) {
                return {
                    data: {},
                    status: err.toJSON().status,
                    message: err.message,
                } as AppResponse<null>["response"];
            } finally {
                setLoading(false);
            }
        },
        [],
    );

    return {
        loading,
        updateDeviceToken,
    };
};

export const useDeleteDeviceToken = () => {
    const [loading, setLoading] = useState(false);

    const deleteDeviceToken = useCallback((token: DeleteTokenData["token"]) => {
        setLoading(true);

        return deleteDeviceTokenApi(config.user.memberId, token)
            .then((res) => {
                const resp = res.response;
                return resp;
            })
            .catch((err) => {
                return {
                    data: {},
                    status: err.toJSON().status,
                    message: err.message,
                } as AppResponse<null>["response"];
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return {
        loading,
        deleteDeviceToken,
    };
};
