import { createStyles, makeStyles } from "@mui/styles";
import { Box, Theme } from "@mui/material";
import { Layout } from "../Layout";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { useState } from "react";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { AppButton } from "JS/React/Components/AppButton";
import { useFeedback } from "JS/React/Hooks/Feedback";
import { AppEmptyAlertDialog } from "JS/React/Components/AppEmptyAlertDialog";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { AppOutlineTextFeild } from "JS/React/Components/TextFields/AppOutlineTextFeild";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        button: {
            // marginTop: theme.spacing(3),
            height: "30px",
            width: "100px",
            margin: "auto",
        },
        listItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        ListTitle: {
            fontWeight: 400,
            width: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        ListIconsWrapper: {
            width: "5%",
        },
        list: {
            flexGrow: 1,
        },
        forwardIcon: {},
    }),
);

export const Feedback = () => {
    const { homeStack } = useGlobalNavStack();
    const classes = useStyles();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const [field, setField] = useState("");
    const { feed, loading: feedbackLoader } = useFeedback();
    const [isEmpty, setIsEmpty] = useState(false);

    const handleToggleConfirmDialog = () => {
        setIsEmpty(false);
    };

    const handleFeedback = () => {
        if (field === "") {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
            feed(field);
        }
    };
    return (
        <Layout className={classes.list}>
            <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                <AppHeader
                    title={"Feedback"}
                    canGoBack
                    searchable={false}
                    onBackClick={() => {
                        if (!homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.more().index());
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                <AppOutlineTextFeild
                    multiline={true}
                    rows={10}
                    variant="outlined"
                    autoFocus={true}
                    placeholder="Please provide your feedback here."
                    style={{
                        paddingBottom: "20px",
                    }}
                    onChange={(e) => setField(e.target.value)}
                ></AppOutlineTextFeild>

                <AppButton
                    onClick={handleFeedback}
                    className={classes.button}
                    disabled={feedbackLoader}
                    buttonVariant="primary-filled"
                >
                    Send
                </AppButton>
                {feedbackLoader && (
                    <AppBackdropProgress
                        open={feedbackLoader}
                        backdropText={"Sending feedback."}
                    />
                )}

                {isEmpty && (
                    <AppEmptyAlertDialog
                        open={isEmpty}
                        onClose={handleToggleConfirmDialog}
                        popupMainText="Feedback is required."
                        cancelButtonText="OK"
                        showConfirmFirst={false}
                    />
                )}
            </Box>
        </Layout>
    );
};
