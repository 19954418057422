import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    InternalStandardProps,
    LinearProgress,
    Theme,
} from "@mui/material";
import { AppTypography } from "./AppTypography";
import { AppCircularProgress } from "./Progress/AppCircularProgress";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        progressRoot: { background: theme.palette.grey[300] },
        progress: {
            backgroundColor: theme.palette.grey[500],
        },
        completionText: {
            color: theme.palette.grey[600],
            textAlign: "center",
            fontWeight: 500,
            marginBottom: theme.spacing(0.5),
        },
        completionTextHeight: {
            height: "25px",
        },
    }),
);

export interface AppCompletionProgressProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    title: string;
    loading?: boolean;
    value?: number;
    lowerTitle?: string;
    isDownloadingQueue?: boolean;
}

export const AppCompletionProgress = (props: AppCompletionProgressProps) => {
    const classes = useStyles(props);
    const {
        className,
        title,
        lowerTitle,
        isDownloadingQueue,
        loading,
        value,
        ...rest
    } = props;

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            {!loading && (
                <>
                    <AppTypography
                        className={clsx(
                            classes.completionText,
                            isDownloadingQueue && classes.completionTextHeight,
                        )}
                        variant="body1">
                        {title}
                    </AppTypography>
                    <LinearProgress
                        className={classes.progressRoot}
                        classes={{
                            bar: classes.progress,
                        }}
                        variant="determinate"
                        value={value}
                    />
                    <AppTypography
                        className={clsx(
                            classes.completionText,
                            isDownloadingQueue && classes.completionTextHeight,
                        )}
                        variant="body1">
                        {lowerTitle}
                    </AppTypography>
                </>
            )}
            {loading && (
                <Box display={"flex"} justifyContent="center">
                    <AppCircularProgress loaderSize={"small"} />
                </Box>
            )}
        </div>
    );
};
