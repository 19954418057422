import { useCallback, useEffect, useState } from "react";
import * as audioIndexDb from "JS/Database/Audio";
import { AudioDexie, getDexieConnectionRefresh } from "JS/Database/Dexie";
import { config } from "JS/Config";
import { IndexableTypeArray, IndexableTypePart } from "dexie";

export const useAudioIndexFromDb = (skip: boolean = false) => {
    const [loading, setLoading] = useState(false);
    const [memberAudios, setMemberAudios] = useState<AudioDexie[]>([]);

    const refetch = useCallback(async () => {
        setLoading(true);
        try {
            const audios = await audioIndexDb.getAudiosIndex();
            const memberAud = audios.filter((d) =>
                d.id.startsWith(config.user.memberId),
            );
            setMemberAudios(memberAud);
            return audios;
        } catch (err) {
            return null;
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!skip) refetch();
    }, [skip]);

    return {
        memberAudios,
        loading,
        refetch,
    };
};

export const useMemberAudiosFromDb = (skip = false) => {
    const [loading, setLoading] = useState(false);
    const [memberAudios, setMemberAudios] = useState<AudioDexie[]>([]);

    const refetch = useCallback(async () => {
        setLoading(true);
        try {
            const toRetAudios: AudioDexie[] = [];
            const bundleCollection = await audioIndexDb.getAudioBundles();
            let downloadedBundleNids: string[] = [];
            let availableBundleNids: string[] = [];
            let bundleMap = {};

            await bundleCollection.keys().then((res) => {
                if (res && res.length) {
                    let downloadedBundlePartsNids: IndexableTypeArray = res;
                    downloadedBundlePartsNids.forEach(
                        (nid: IndexableTypePart) => {
                            const splittednid = nid?.toString().split("-");
                            if (splittednid[0] && splittednid[1]) {
                                let nid = `${splittednid[0]}-${splittednid[1]}`;
                                if (bundleMap[nid]) {
                                    bundleMap[nid] = bundleMap[nid] + 1;
                                } else {
                                    bundleMap[nid] = 1;
                                }
                                downloadedBundleNids.push(
                                    `${splittednid[0]}-${splittednid[1]}`,
                                );
                            }
                        },
                    );

                    downloadedBundleNids = Array.from(
                        new Set(downloadedBundleNids),
                    );
                    downloadedBundleNids.forEach((nid: string) => {
                        const availableNid = downloadedBundlePartsNids.find(
                            (pnid) => pnid.toString().startsWith(nid),
                        );
                        availableBundleNids.push(availableNid.toString());
                    });
                }
            });
            await getDexieConnectionRefresh().audios.each((audio) => {
                if (audio?.isBundle) {
                    if (availableBundleNids.includes(audio?.id)) {
                        const splittedNid = audio.id?.toString().split("-");
                        if (splittedNid && splittedNid.length > 1) {
                            const bundleNid = `${splittedNid[0]}-${splittedNid[1]}`;
                            if (
                                bundleMap[bundleNid] &&
                                audio?.no_of_files === bundleMap[bundleNid]
                            ) {
                                toRetAudios.push(audio);
                            }
                        }
                    }
                } else {
                    toRetAudios.push(audio);
                }
            });
            const memberAud = toRetAudios.filter((d) =>
                d.id.startsWith(config.user.memberId),
            );
            setMemberAudios(memberAud);
            return memberAud;
        } catch (err) {
            return null;
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!skip) refetch();
    }, [skip]);

    return {
        loading,
        memberAudios,
        refetch,
    };
};

export const useBulkUpdateAudiosDB = () => {
    const bulkUpdateAudios = useCallback(async (audios: AudioDexie[]) => {
        try {
            const id = await audioIndexDb.bulkUpdateAudios(audios);
            return id;
        } catch (err) {
            return null;
        }
    }, []);

    return {
        bulkUpdateAudios,
    };
};
