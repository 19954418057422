import AWS from "aws-sdk";
import { HomeService } from "../Home";

export class MixedContentService extends HomeService {
    async getMixedContentJsonFile(
        filePath: string,
    ): Promise<AWS.S3.GetObjectOutput> {
        return await this.getLandingPageJsonFile(filePath);
    }
}
