import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Button,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaymentMethod } from "./PaymentMethodRadio";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "80px",
            marginBottom: theme.spacing(8),
        },
        buttonWrapper: {
            display: "flex",
            margin: theme.spacing(1),
        },
        btnQuantity: {
            minWidth: "60px",
            maxWidth: "40px",
            margin: theme.spacing(1),
            borderColor: theme.palette.grey[800],
            color: `${theme.palette.grey[800]} !important`,
            fontSize: theme.spacing(3),
            maxHeight: "60px",
            height: theme.spacing(12),
            "&:hover": {
                borderColor: theme.palette.grey[800],
            },
            "&:active": {
                borderColor: theme.palette.grey[800],
            },
        },
        btnQuantityText: {
            minWidth: "60px",
            maxWidth: "60px",
            margin: theme.spacing(1),
            borderColor: theme.palette.grey[800],
            color: `${theme.palette.grey[800]} !important`,
            fontSize: theme.spacing(3),
            backgroundColor:
                theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
            maxHeight: "60px",
            height: theme.spacing(12),
        },
    }),
);

export interface GiftQuantityProps extends InternalStandardProps<GridProps> {
    activeGiftItem: {
        title: string;
        giftPurchasedEnabled: boolean;
        quantity: number;
    };
    method: PaymentMethod;
    credits: number;
    onQuantityChange?: (
        event: React.MouseEvent<HTMLButtonElement>,
        action: string,
    ) => void;
    isInsufficeintCredits: boolean;
}

export const GiftQuantity = (props: GiftQuantityProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles(props);
    const {
        className,
        activeGiftItem,
        method,
        credits,
        onQuantityChange,
        isInsufficeintCredits,
        ...rest
    } = props;

    return (
        <Grid container {...rest} className={clsx(className, classes.root)}>
            <Grid item={true} xs={12} md={12} className={classes.buttonWrapper}>
                <Button
                    variant="outlined"
                    className={clsx(classes.btnQuantityText)}
                    disabled
                    size="large"
                >
                    {activeGiftItem.quantity}
                </Button>

                <Button
                    variant="outlined"
                    className={clsx(classes.btnQuantity)}
                    onClick={(e) => {
                        if (isInsufficeintCredits) {
                            enqueueSnackbar("Insufficient credits", {
                                variant: "warning",
                            });
                        } else {
                            onQuantityChange(e, "INCREMENT");
                        }
                    }}
                    size="large"
                >
                    <FontAwesomeIcon icon={["fas", "plus"]} />
                </Button>

                <Button
                    variant="outlined"
                    className={clsx(classes.btnQuantity)}
                    onClick={(e) => onQuantityChange(e, "DECREMENT")}
                    size="large"
                >
                    <FontAwesomeIcon icon={["fas", "minus"]} />
                </Button>
            </Grid>
        </Grid>
    );
};
