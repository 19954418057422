import { InternalStandardProps } from "@mui/material";
import {
    ReceivedGiftIcon,
    PurchaseGiftIcon,
    SendGiftIcon,
    GiftGivenIcon,
    BuyIcon,
    BuyIconDark,
    AlertIcon,
    GiftIcon,
} from "Icons";
import { useThemeModeContext } from "JS/React/Context/ThemeModeProvider";

export enum Icons {
    purchaseGiftIcon = "purchaseGiftIcon",
    receivedGiftIcon = "receivedGiftIcon",
    sendGiftIcon = "sendGiftIcon",
    giftGivenIcon = "giftGivenIcon",
    buyIcon = "buyIcon",
    alertIcon = "alertIcon",
    giftIcon = "giftIcon",
}

export interface IconProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    icon: Icons;
    iconProps?: any;
    iconStyles?: any;
}

export const Icon = (props: IconProps) => {
    const { mode: themeMode } = useThemeModeContext();
    const { icon, iconProps, iconStyles } = props;

    const renderIcon = () => {
        switch (icon) {
            case Icons.receivedGiftIcon:
                return <ReceivedGiftIcon style={iconStyles} {...iconProps} />;
            case Icons.purchaseGiftIcon:
                return <PurchaseGiftIcon style={iconStyles} {...iconProps} />;
            case Icons.sendGiftIcon:
                return <SendGiftIcon style={iconStyles} {...iconProps} />;
            case Icons.giftGivenIcon:
                return <GiftGivenIcon style={iconStyles} {...iconProps} />;
            case Icons.buyIcon:
                return themeMode === "light" ? (
                    <BuyIcon style={iconStyles} {...iconProps} />
                ) : (
                    <BuyIconDark style={iconStyles} {...iconProps} />
                );
            case Icons.alertIcon:
                return <AlertIcon style={iconStyles} {...iconProps} />;
            case Icons.giftIcon:
                return <GiftIcon {...iconProps} />;
            default:
                return null;
        }
    };

    return renderIcon();
};
