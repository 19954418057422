import { getSizeOfContent } from "JS/Helpers";
import { useEffect, useState } from "react";

export const useFileSize = (key?: string, bucket?: string) => {
    const [size, setSize] = useState(0);
    const [loading, setLoading] = useState(false);

    async function getSize(key: string, bucket: string) {
        setLoading(true);
        getSizeOfContent(key, bucket)
            .then((val) => {
                setSize(val);
            })
            .catch((err) => {
                setSize(0);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (!!key && !!bucket) getSize(key, bucket);
    }, [key, bucket]);

    return {
        size,
        loading,
    };
};

export const useMultipleFileSizes = (
    params?: {
        key: string;
        bucket: string;
    }[],
) => {
    const [sizes, setSizes] = useState<
        {
            key: string;
            bucket: string;
            size: number;
        }[]
    >([]);

    const [loading, setLoading] = useState(false);

    async function getSize(
        params: {
            key: string;
            bucket: string;
        }[],
    ) {
        const promises: Promise<{
            key: string;
            bucket: string;
            size: number;
        }>[] = params.map((p) => {
            return getSizeOfContent(p.key, p.bucket)
                .then((val) => {
                    return {
                        key: p.key,
                        bucket: p.bucket,
                        size: val,
                    };
                })
                .catch((err) => {
                    return {
                        key: p.key,
                        bucket: p.bucket,
                        size: 0,
                    };
                });
        });

        setLoading(true);

        return Promise.all(promises)
            .then((allSizes) => {
                setSizes(allSizes);
                return allSizes;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (params) getSize(params);
    }, [params]);

    return {
        sizes,
        loading,
    };
};
