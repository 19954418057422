import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { RecommendationsDialog } from "./RecommendationsDialog";
import {
    useFilteredRecommendations,
    useGlobalRecommendations,
    useRecommendations,
} from "JS/React/Hooks/Recommendations";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import {
    AudioContent,
    EventActions,
    EventNames,
    Recommendations as IRecommendations,
} from "JS/Models";
import { getPublicUrl } from "JS/Helpers";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { keyBy } from "lodash-es";
import { messaging } from "JS/Helpers/UserMessaging";
import { config } from "JS/Config";
import {
    resetRedirectionState,
    setConsentFormStats,
    setLoadingState,
} from "JS/Redux/ConsentForm";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { useGetConsentStatusFromCMLM } from "JS/React/Hooks/ConsentForm";
import { RedirectionDialog } from "JS/React/Components/RedirectionDialog/RedirectionDialog";
import { getConsentStatusById } from "JS/Database/ConsentForm";
import { openSSOLink } from "JS/Helpers/HomePageHelper";
import { setIsRedirectedToAmmwayConsentForm } from "JS/Helpers/LocalStorageHelpers";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";

export interface RecommendationsProps extends InternalStandardProps<BoxProps> {}
export type RecommendationQParams = {
    auto: boolean;
    push: boolean;
};

export const Recommendations = (props: RecommendationsProps) => {
    const classes = useStyles(props);

    const { className, ...rest } = props;
    const { localAuto, push } = useQueryParams();
    const dispatch = useAppDispatch();

    const {
        recommendations,
        recommendedAudios,
        loading,
        onPurchaseAudio,
        loadingMessage,
        showRecommendations,
        audioError,
    } = useAudioRecommendations(push);

    const { thumbnails } = useThumbnails(recommendedAudios);

    const { loading: consentStatusLoading } = useGetConsentStatusFromCMLM();

    const { showRedirectionDialog, loadingConsent } = useAppSelector(
        (state) => state.consentForm,
    );
    const { logFirebaseEvent } = useFirebaseLogger();

    const handleClose = () => {
        getConsentStatusById(config.user.memberId)
            .then((consentData) => {
                if (consentData?.redirectToAmwayConsentForm) {
                    openSSOLink(consentData.ssoRedirectionPlace, true);
                    setIsRedirectedToAmmwayConsentForm("true");

                    dispatch(
                        setConsentFormStats({
                            showRedirectionDialog: false,
                            loadingConsent: true,
                        }),
                    );

                    setTimeout(
                        () =>
                            dispatch(
                                setLoadingState({ loadingConsent: false }),
                            ),
                        3000,
                    );

                    logFirebaseEvent(EventNames.CONSENT_FORM_V2_REDIRECT, {
                        action: EventActions.CONSENT_FORM_V2_REDIRECT,
                    });
                }
            })
            .catch(() => dispatch(resetRedirectionState()));
    };

    return (
        <Box className={clsx(className, classes.root)} {...rest}>
            {loading && (
                <AppBackdropProgress
                    open={loading}
                    backdropText={loadingMessage}
                />
            )}
            {consentStatusLoading && (
                <AppBackdropProgress
                    open={consentStatusLoading}
                    backdropText={loadingMessage}
                />
            )}
            {loadingConsent && (
                <AppBackdropProgress
                    open={loadingConsent}
                    backdropText={messaging?.loader?.loadingSession}
                />
            )}
            {showRedirectionDialog && (
                <RedirectionDialog
                    open={showRedirectionDialog}
                    onClose={handleClose}
                    dialogMessage={messaging.consentForm.ssoRedirection}
                />
            )}
            {showRecommendations && !audioError && (
                <RecommendationsDialog
                    recommendedAudios={recommendedAudios}
                    recommendations={recommendations}
                    thumbnails={thumbnails}
                    isAuto={localAuto}
                    push={push}
                    onPurchaseAudio={onPurchaseAudio}
                />
            )}
        </Box>
    );
};

const useAudioRecommendations = (push: boolean) => {
    const history = useHistory();

    const { audios, setGlobalAudios, isAudioUpdateRequired } =
        useGlobalAudios();
    const { recommendationState } = useGlobalRecommendations();

    const {
        loading: audiosLoading,
        loaded: audiosLoaded,
        errorMsg,
    } = useAudios(!!audios && audios.length > 0, isAudioUpdateRequired);
    const { recommendations, loading: recommendationsLoading } =
        useRecommendations(
            recommendationState?.recommendations != null && !push,
        );
    const { filteredRecommendedAudios, filteredRecommendations } =
        useFilteredRecommendations(recommendations, audios);

    const { sortedRecAudios } = useRecSorting(
        filteredRecommendedAudios,
        filteredRecommendations,
    );

    const onPurchaseAudio = (nid: string) => {
        const toSaveAudios: AudioContent[] = audios.map((d) => {
            if (d.nid === nid) {
                return {
                    ...d,
                    is_purchased: true,
                    publish_free: "1",
                    content_price: "0.0",
                };
            } else {
                return d;
            }
        });
        setGlobalAudios(toSaveAudios);
    };

    useEffect(() => {
        if (errorMsg) {
            history.goBack();
        }
    }, [errorMsg]);

    return {
        showRecommendations:
            !!recommendations &&
            (!!audios?.length || audiosLoaded) &&
            !recommendationsLoading,
        audioError: errorMsg,
        recommendedAudios: sortedRecAudios,
        recommendations: filteredRecommendations,
        loading: audiosLoading || recommendationsLoading,
        loadingMessage: audiosLoading
            ? messaging.loader.audios
            : messaging.loader.recommendations,
        onPurchaseAudio,
    };
};

const useRecSorting = (
    recAudios: AudioContent[],
    recommendations: IRecommendations[],
) => {
    const sortedRecAudios = useMemo(() => {
        const recMap = recommendations
            ? keyBy(recommendations, (x) => `${x.nid}`)
            : {};

        return [...recAudios]?.sort(
            (a, b) => recMap[a.nid]?.rank - recMap[b.nid]?.rank,
        );
    }, [recAudios, recommendations]);

    return {
        sortedRecAudios,
    };
};

const useQueryParams = () => {
    const location = useLocation();
    const history = useHistory();

    const queryParams: RecommendationQParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { auto = "false", push = "false" } = parsedQueryString;

        return {
            auto: auto == "true",
            push: push == "true",
        };
    }, [location?.search]);

    const setQueryParams = (params: Partial<RecommendationQParams>) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify({ ...queryParams, ...params }),
        });
    };

    const [localIsAuto] = useState(queryParams.auto);

    useEffect(() => {
        setQueryParams({ auto: false });
    }, []);

    return {
        localAuto: localIsAuto,
        push: queryParams.push,
    };
};

const useThumbnails = (recommendations: AudioContent[]) => {
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    const createImageUrl = async (audio: AudioContent) => {
        const url = await getPublicUrl(
            audio.image_url_prefix,
            audio.image_url_postfix,
            audio.image_file_name,
        );

        return {
            id: audio.nid,
            url: url,
        };
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        recommendations.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    useEffect(() => {
        if (recommendations) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [recommendations]);

    return {
        thumbnails,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
        },
    }),
);
