import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import FallbackWaveform from "Images/Content/FallbackWaveform.png";
import moment from "moment";

const PlaylistContentListItem = (props: PlaylistContentListItemProps) => {
    const classes = useStyles(props);
    const {
        className,
        imageUrl,
        title,
        sortType,
        acquiredDate,
        played,
        releaseDate,
        skuId,
        description,
        handleToogleCheckBox,
        isCheckedItem,
        nid,
        ...rest
    } = props;

    const showDate = () => {
        if (sortType) {
            if (sortType === "acquired_date") {
                if (acquiredDate && acquiredDate !== "0")
                    return `${formatDate(+acquiredDate)}`;
                else return "";
            }
            if (sortType === "played") {
                if (played && played !== "0")
                    return `${formatDateTime(+played)}`;
                else return "";
            } else if (releaseDate && releaseDate !== "0")
                return `${formatDate(+releaseDate)}`;
            else return "";
        } else if (releaseDate && releaseDate !== "0")
            return `${formatDate(+releaseDate)}`;
        else return "";
    };

    const formatDate = (date: number) => {
        return moment.unix(date).format("ll");
    };

    const formatDateTime = (date: number) => {
        return `${moment.unix(date).format("ll")} @ ${moment
            .unix(date)
            .format("hh:mma")}`;
    };

    return (
        <Grid container className={clsx(className, classes.root)} {...rest}>
            <Card
                onClick={(e) => handleToogleCheckBox(nid, !isCheckedItem)}
                elevation={0}
                className={clsx(classes.contentCard)}
            >
                <Box className={clsx(classes.imageContainer)}>
                    <img
                        src={imageUrl || FallbackWaveform}
                        style={{ width: "60px", height: "auto" }}
                        alt="thumbnail"
                    />
                </Box>
                <CardContent className={clsx(classes.cardContent)}>
                    <Typography
                        className={clsx(
                            classes.title,
                            classes.grey600,
                            classes.singleLineDescription,
                        )}
                    >
                        {title || ""}
                    </Typography>
                    <Typography className={classes.skuId}>
                        {skuId || ""}
                    </Typography>
                    <Typography className={classes.skuId}>
                        {showDate()}
                    </Typography>
                    <Typography
                        className={clsx(classes.clampText, classes.grey600)}
                        fontSize={"12px"}
                    >
                        {description || ""}
                    </Typography>
                </CardContent>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    height={"100%"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    className={clsx(classes.actions)}
                >
                    <Checkbox
                        onClick={(e) =>
                            handleToogleCheckBox(nid, !isCheckedItem)
                        }
                        checked={isCheckedItem}
                        icon={
                            <RadioButtonUnchecked
                                className={clsx(classes.checkedIcon)}
                            />
                        }
                        checkedIcon={
                            <CheckCircleOutline
                                className={clsx(classes.checkedIcon)}
                            />
                        }
                    />
                </Box>
            </Card>
        </Grid>
    );
};

export default PlaylistContentListItem;

interface PlaylistContentListItemProps
    extends InternalStandardProps<GridProps> {
    imageUrl: string;
    title: string;
    sortType?: string;
    releaseDate?: string;
    acquiredDate?: string;
    played?: string;
    skuId: string;
    nid: string;
    description: string;
    handleToogleCheckBox: (nid: string, value: boolean) => void;
    isCheckedItem: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: `${theme.palette.background.default} !important`,
        },
        contentCard: {
            backgroundColor: `${theme.palette.background.default} !important`,
            display: "flex",
            alignItems: "center",
            cursor: "pointer !important",
            marginBottom: "15px",
            paddingLeft: "10px",
            paddingRight: "20px",
            position: "relative",
            "&:last-child": {
                marginBottom: "20px",
            },
            width: "100%",
        },
        imageContainer: {
            width: "80px",
            display: "flex",
            justifyContent: "center",
            position: "relative",
        },
        cardContent: {
            width: "75%",
        },
        [theme.breakpoints.down("md")]: {
            cardContent: {
                width: "70%",
            },
        },
        [theme.breakpoints.down("sm")]: {
            cardContent: {
                width: "70%",
            },
        },
        skuId: {
            fontSize: "14px",
            color: theme.palette.grey[500],
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        title: {
            fontSize: "18px",
            marginBottom: "5px",
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        singleLineDescription: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        clampText: {
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
        },
        actions: {
            width: "10%",
        },
        checkedIcon: {
            color: theme.palette.grey[700],
        },
    }),
);
