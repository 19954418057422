import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Card,
    CardContent,
    GridProps,
    IconButton,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FallbackWaveform from "Images/Content/FallbackWaveform.png";
import { CourseStep } from "JS/Models";
import {
    DownloadStatusIcon,
    StepListDownloadType,
} from "JS/React/Components/Icon/DownloadStatusIcon";

export const StepsListItem = (props: StepsListItemProps) => {
    const classes = useStyles(props);
    const {
        isAllowClick,
        isLocked,
        isDisabled,
        stepThumbnail,
        isQuiz,
        step,
        isCompleted,
        isResume,
        downloadStatus,
        onListItemClick,
    } = props;

    const { handlePlayIconClick } = useHandlers(props);

    return (
        <Card
            onClick={() => {
                if (isAllowClick && !isLocked && !isDisabled) {
                    onListItemClick();
                }
            }}
            elevation={0}
            className={clsx(
                classes.root,
                classes.flexVerticalCentered,
                classes.contentCard,
                isLocked && classes.lockedItem,
                isDisabled && classes.disabled,
            )}
        >
            <Box
                sx={{
                    width: "80px",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                {isLocked && (
                    <FontAwesomeIcon
                        style={{ fontSize: "22px" }}
                        icon={["fas", "lock"]}
                        className={clsx(classes.imgLock, classes.grey600)}
                    />
                )}
                <img
                    src={stepThumbnail || FallbackWaveform}
                    style={{ width: "60px", height: "auto" }}
                    alt="thumbnail"
                />
            </Box>
            <CardContent className={clsx(classes.cardContent)}>
                <Typography className={clsx(classes.title, classes.grey600)}>
                    {!isLocked && isCompleted && (
                        <FontAwesomeIcon
                            className={clsx(classes.completedIcon)}
                            icon={["fas", "circle-check"]}
                        />
                    )}
                    &nbsp;&nbsp;
                    {step.title || ""}
                </Typography>
                <Typography className={classes.skuId}>
                    {step.sku_id || ""} |{" "}
                    {step.content_item_type_name.toUpperCase()}
                </Typography>
                <Typography
                    className={clsx(classes.clampText, classes.grey600)}
                    fontSize={"12px"}
                >
                    {step.description || ""}
                </Typography>
            </CardContent>
            <Box
                display={"flex"}
                alignItems={"center"}
                height={"100%"}
                flexDirection={"column"}
                className={clsx(classes.actions)}
            >
                <>
                    {isLocked ? (
                        <FontAwesomeIcon
                            style={{ fontSize: "22px" }}
                            icon={["fas", "lock"]}
                            className={classes.grey600}
                        />
                    ) : (
                        <>
                            {!isQuiz && (
                                <>
                                    {step.content_item_type_name !== "PDF" && (
                                        <IconButton
                                            onClick={handlePlayIconClick}
                                            className={classes.iconButton}
                                        >
                                            <FontAwesomeIcon
                                                style={{
                                                    fontSize: "22px",
                                                }}
                                                className={clsx(
                                                    classes.grey600,
                                                    isResume &&
                                                        classes.playResume,
                                                )}
                                                icon={["fas", "play"]}
                                            />
                                        </IconButton>
                                    )}
                                    <DownloadStatusIcon
                                        downloadStatus={downloadStatus}
                                    />
                                </>
                            )}
                        </>
                    )}
                </>
            </Box>
        </Card>
    );
};

const useHandlers = (props: StepsListItemProps) => {
    const { onPlayIconClick } = props;

    const handlePlayIconClick = (e) => {
        if (onPlayIconClick) {
            e.stopPropagation();
            onPlayIconClick();
        }
    };
    return { handlePlayIconClick };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: `${theme.palette.background.default}`,
        },
        flexVerticalCentered: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer !important",
        },
        contentCard: {
            marginBottom: "15px",
            paddingLeft: "10px",
            paddingRight: "20px",
            position: "relative",
            "&:last-child": {
                marginBottom: "20px",
            },
        },
        actions: {
            position: "absolute",
            top: "16px",
            right: "20px",
        },
        clampText: {
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            wordBreak: "break-all",
            overflow: "hidden",
        },
        cardContent: {
            width: "75%",
            paddingRight: "20px",
        },
        title: {
            fontSize: "18px",
            marginBottom: "5px",
        },
        skuId: { fontSize: "14px", color: theme.palette.grey[500] },
        grey600: {
            color: theme.palette.grey[600],
        },
        lockedItem: {
            opacity: "80%",
        },
        imgLock: {
            position: "absolute",
        },
        disabled: {
            opacity: 0.6,
        },
        completedIcon: { color: theme.palette.primary.main },
        playResume: {
            color: theme.palette.primary.main,
        },
        iconButton: {
            padding: 0,
        },
    }),
);

export interface StepsListItemProps extends InternalStandardProps<GridProps> {
    step: CourseStep;
    stepThumbnail: string;
    isAllowClick?: boolean;
    isLocked?: boolean;
    isDisabled?: boolean;
    isQuiz?: boolean;
    isCompleted?: boolean;
    isResume?: boolean;
    downloadStatus?: StepListDownloadType;
    onListItemClick?: () => void;
    onPlayIconClick?: () => void;
}
