import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { InternalStandardProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Layout } from "../Layout";
import { useHistory } from "react-router-dom";
import {
    useGlobalGifts,
    useReceivedMemberVideoGifts,
    useReceivedProspectVideoGifts,
} from "JS/React/Hooks/Gifts";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRouting } from "JS/React/Hooks/Routes";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { useGlobalVideos, useVideos } from "JS/React/Hooks/Video";
import { uniqBy } from "lodash-es";
import { messaging } from "JS/Helpers/UserMessaging";
import { FavoritesTypes } from ".";
import { EventActions, EventCategories, EventNames, FirebaseEventAction, VideoContent } from "JS/Models";
import { ListingComponent } from "../Audios/ListingComponent";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
}));

export interface FavoritesVideosListingProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > { }

export const FavoritesVideosListing = (props: FavoritesVideosListingProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const {
        homeStack,
        favoritesVideosStack,
        resetFavoritesVideosStack,
        setGlobalFavoritesVideosStack,
    } = useGlobalNavStack();
    const [searchQuery, setSearchQuery] = useState<string>(
        favoritesVideosStack?.searchQuery || "",
    );
    const [headerTitle, setHeaderTitle] = useState("Favorite Videos");
    const history = useHistory();

    const { videos, isVideoUpdateRequired, resumeUserVideos } = useGlobalVideos();
    const { loading: videosLoading } = useVideos(!!videos && videos.length > 0, isVideoUpdateRequired);

    const { linkProvider } = useRouting();

    const { receivedMemberVideoGifts, receivedProspectVideoGifts } =
        useGlobalGifts();

    const { loading: receivedMemberVideoGiftsLoading } =
        useReceivedMemberVideoGifts(
            !!receivedMemberVideoGifts && receivedMemberVideoGifts.length > 0,
        );

    const { loading: receivedProspectVideoGiftsLoading } =
        useReceivedProspectVideoGifts(
            !!receivedProspectVideoGifts &&
            receivedProspectVideoGifts.length > 0,
        );

    const { favoritesVideos } = useGlobalFavorites();
    const { loading: favLoading } = useGetFavorites(
        !!favoritesVideos && favoritesVideos.length > 0,
    );

    const filteredVideos = useMemo(() => {
        return uniqBy(
            [
                ...videos,
                ...receivedMemberVideoGifts,
                ...receivedProspectVideoGifts,
            ],
            (x) => x.nid,
        );
    }, [videos, receivedMemberVideoGifts, receivedProspectVideoGifts]);

    const filterFavoritesVideos = useMemo(() => {
        return filteredVideos.filter((audio) =>
            favoritesVideos.some((favAud) => favAud.nid === audio.nid),
        );
    }, [filteredVideos, favoritesVideos]);

    const searchResults = useMemo(() => {
        return filterFavoritesVideos?.filter(
            (d) =>
                d?.title
                    ?.toLocaleLowerCase()
                    ?.includes(searchQuery?.toLocaleLowerCase().trim()) ||
                d?.description
                    ?.toLocaleLowerCase()
                    ?.includes(searchQuery?.toLocaleLowerCase().trim()),
        );
    }, [filterFavoritesVideos, searchQuery]);

    useEffect(() => {
        setGlobalFavoritesVideosStack({
            searchQuery: searchQuery,
        });
    }, [searchResults]);

    const isVideosContentLoaded =
        !videosLoading &&
        !receivedMemberVideoGiftsLoading &&
        !receivedProspectVideoGiftsLoading;

    useEffect(() => {
        window.scrollTo(0, 500);
        window.scrollTo(0, 0);
    }, []);

    const isResumeable = (filteredVideo: VideoContent) => {
        if (
            resumeUserVideos &&
            resumeUserVideos.filter((x) => x.nid === filteredVideo.nid).length >
            0
        ) {
            const resumeData = resumeUserVideos.find(
                (x) => x.nid === filteredVideo.nid,
            );
            return !!resumeData;
        } else {
            return false;
        }
    };
    const { handleListItemClick } = useHandlers(searchQuery, FavoritesTypes.FAVVIDEOS);
    return (
        <Layout>
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title={headerTitle || "Videos"}
                    canGoBack
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Videos by title/description"}
                    onBackClick={() => {
                        setSearchQuery("");
                        resetFavoritesVideosStack();
                        if (!homeStack?.isHomeTrack) {
                            history.push(
                                linkProvider.react.favorites().index(),
                            );
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                {isVideosContentLoaded && !favLoading ? (
                    <ListingComponent
                        listContent={searchResults as VideoContent[]}
                        searchQuery={searchQuery}
                        categoryId={FavoritesTypes.FAVVIDEOS}
                        showSortHeader={true}
                        setGlobalStack={setGlobalFavoritesVideosStack}
                        globalStack={favoritesVideosStack}
                        isResumeable={isResumeable}
                        favoritesContent={favoritesVideos}
                        handleListItemClick={handleListItemClick}
                        controlType="videoFavorite"
                    />
                ) : (
                    <AppBackdropProgress
                        open={true}
                        backdropText={
                            favLoading
                                ? messaging.loader.favorites
                                : messaging.loader.audios
                        }
                    />
                )}
            </div>
        </Layout>
    );
};
const useHandlers = (searchQuery: string, categoryId: string) => {
    const { logFirebaseEvent } = useFirebaseLogger();
    const { linkProvider } = useRouting();
    const history = useHistory();
    const handleListItemClick = (
        filteredVideo: VideoContent,
        isAutoPlay: string,
        scrollIndex: number
    ) => {
        if (searchQuery) {
            const action: FirebaseEventAction = {
                action: EventActions.SEARCH,
                category: filteredVideo.isReceived
                    ? EventCategories.GIFT_VIDEOS
                    : EventCategories.VIDEOS,
                skuId: filteredVideo.sku_id,
                nId: filteredVideo.nid,
                contentTitle: filteredVideo.title,
                searchQuery: searchQuery,
            };

            logFirebaseEvent(
                filteredVideo.isReceived
                    ? EventNames.GIFT_VIDEO_SEARCH_OPENED
                    : EventNames.VIDEO_SEARCH_VIDEO_OPENED,
                action,
            );
        }
        history.push(
            linkProvider.react
                .favorites()
                .favoriteVideosDetail(
                    filteredVideo.nid,
                    categoryId ? categoryId : filteredVideo.content_category_id,
                    "true",
                    isAutoPlay,
                    {
                        scrollTo: scrollIndex ? +scrollIndex : 0
                    }
                ),
        );
    };

    return { handleListItemClick };
};
