import { Box } from "@mui/material";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppTypography } from "JS/React/Components/AppTypography";

export const RequireAuth = (props: RequireAuthProps) => {
    return (
        <Box
            height={"100vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            textAlign={"center"}
        >
            <AppTypography textColor="black" variant="h6">
                {messaging.requireAuth}
            </AppTypography>
        </Box>
    );
};

export interface RequireAuthProps {}
