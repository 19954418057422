import { config } from "JS/Config";
import { CreatePlaylistResponse, Playlist, PlaylistAudio } from "JS/Models";
import { AppAlternativeResponse, AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";

export class PlaylistService extends BaseService {
    async getPlaylists() {
        return (
            await this.doXHR<AppResponse<Playlist[]>>({
                url: this.routes.server.api.playlist.getPlaylists(),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async createPlaylist(title: string) {
        const data = new FormData();
        data.append("access_token", config?.accessToken);
        data.append("playlist_name", title);
        return (
            await this.doXHR<AppResponse<CreatePlaylistResponse>>({
                url: this.routes.server.api.playlist.create(),
                method: "POST",
                data: data,
            })
        ).data;
    }

    async createPlaylistWithContents(title: string, contentIds: string[]) {
        const data = new FormData();
        data.append("access_token", config?.accessToken);
        data.append("playlist_name", title);
        data.append("contentIds", JSON.stringify(contentIds));

        return (
            await this.doXHR<AppAlternativeResponse<any[]>>({
                url: this.routes.server.api.playlist.createPlaylistWithContents(),
                method: "POST",
                data: data,
            })
        ).data;
    }

    async deletePlaylist(id: string) {
        const data = new FormData();
        data.append("access_token", config?.accessToken);
        data.append("playlist_id", id);
        return (
            await this.doXHR<AppResponse<string[]>>({
                url: this.routes.server.api.playlist.delete(),
                method: "POST",
                data: data,
            })
        ).data;
    }

    async renamePlaylist(id: string, newTitle: string) {
        const data = new FormData();
        data.append("access_token", config?.accessToken);
        data.append("playlist_id", id);
        data.append("playlist_name", newTitle);
        return (
            await this.doXHR<AppResponse<string[]>>({
                url: this.routes.server.api.playlist.rename(),
                method: "POST",
                data: data,
            })
        ).data;
    }

    async sortPlaylists(ids: string) {
        const data = new FormData();
        data.append("access_token", config?.accessToken);
        data.append("playlistIds", ids);
        return (
            await this.doXHR<AppResponse<string[]>>({
                url: this.routes.server.api.playlist.orderPlaylists(
                    config?.user?.memberId,
                ),
                method: "POST",
                data: data,
            })
        ).data;
    }

    async getPlaylistAudios(id: string) {
        const data = new FormData();
        data.append("access_token", config?.accessToken);
        data.append("playlist_id", id);
        return (
            await this.doXHR<AppResponse<PlaylistAudio[]>>({
                url: this.routes.server.api.playlist.getAudios(),
                method: "POST",
                data: data,
            })
        ).data;
    }

    async removeAudioFromPlaylist(id: string, audioNid: string) {
        const data = new FormData();
        data.append("access_token", config?.accessToken);
        data.append("playlist_id", id);
        data.append("content_id", audioNid);
        return (
            await this.doXHR<AppResponse<string[]>>({
                url: this.routes.server.api.playlist.removeAudio(),
                method: "POST",
                data: data,
            })
        ).data;
    }

    async addAudioToPlaylist(id: string, audioNid: string) {
        const data = new FormData();
        data.append("access_token", config?.accessToken);
        data.append("playlist_id", id);
        data.append("content_id", audioNid);
        return (
            await this.doXHR<AppResponse<string[]>>({
                url: this.routes.server.api.playlist.addAudio(),
                method: "POST",
                data: data,
            })
        ).data;
    }

    async sortAudiosFromPlaylist(id: string, skus: string) {
        const data = new FormData();
        data.append("access_token", config?.accessToken);
        data.append("playlist_id", id);
        data.append("sku_ids", skus);
        return (
            await this.doXHR<AppResponse<string[]>>({
                url: this.routes.server.api.playlist.sort(),
                method: "POST",
                data: data,
            })
        ).data;
    }
}
