import { config } from "JS/Config";
import { S3Helper } from "JS/Helpers";
import { MediaEssentials } from "JS/Models/MediaEssentials";
import { BaseService } from "../BaseService";

export class MediaEssentialsService extends BaseService {
    async getS3MediaEssentials(): Promise<MediaEssentials> {
        const s3Sdk = new S3Helper().sdk;
        return new Promise((res, rej) => {
            const params = {
                Bucket: config.s3.mediaEssentials.bucketName,
                Key: config.s3.mediaEssentials.key,
                ResponseCacheControl: "no-cache",
            };
            s3Sdk.getObject(params, (err, data) => {
                if (err) {
                    rej(err);
                } else {
                    const parsed = JSON.parse(data?.Body?.toString());
                    res(parsed);
                }
            });
        });
    }
}
