import { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";

import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { MixedContentService } from "JS/Services/MixedContent/Service";
import { messaging } from "JS/Helpers/UserMessaging";
import { setMixedContentJson } from "JS/Redux/MixedContent";

export const useMixedContent = (filePath: string) => {
    const service = new MixedContentService();
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(async () => {
        setLoading(true);
        await service
            .getMixedContentJsonFile(filePath)
            .then((response) => {
                if (!!response)
                    dispatch(
                        setMixedContentJson({
                            mixedContentJson: JSON.parse(
                                response?.Body?.toString(),
                            ),
                        }),
                    );
            })
            .catch(() => {
                enqueueSnackbar(messaging.common.error, {
                    variant: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        refetch();
    }, []);

    return {
        refetch,
        loading,
        mixedContentJson: useAppSelector(
            (state) => state.mixedContent.mixedContentJson,
        ),
    };
};
