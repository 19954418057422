import { captureSentryError } from "JS/Helpers/SentryHelper";
import { FycStatus } from "JS/Models/FycStatus";
import { FycStatusService } from "JS/Services/FycStatus/Service";
import { useCallback, useEffect, useState } from "react";
import { useGlobalMediaEssentials } from "../MediaEssentials";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { setFycStatusInfo } from "JS/Redux/Home";

export const service = new FycStatusService();

export const useFycStatusDetail = () => {
    const [loading, setLoading] = useState(false);
    const [fycStatus, setFycStatus] = useState<FycStatus>(null);
    const { mediaEssentials } = useGlobalMediaEssentials();
    const dispatch = useAppDispatch();
    const fycStatusInfo = useAppSelector((state) => state.home.fycStatusInfo);
    const refetch = useCallback(() => {
        setLoading(true);
        service
            .getFycStatus(
                mediaEssentials?.allow_fyc_status_request,
                fycStatusInfo,
            )
            .then((res) => {
                const responseData = res?.response?.data;
                if (res?.response?.status && responseData) {
                    setFycStatus(responseData);
                    dispatch(
                        setFycStatusInfo({
                            fycStatusInfo: responseData,
                        }),
                    );
                } else {
                    captureSentryError(
                        new Error(
                            "Received undefined response data from fyc status API",
                        ),
                        {
                            location: "fycStatusApi",
                        },
                    );
                }
            })
            .catch((error) => {
                captureSentryError(error, {
                    location: "fycStatusApi",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        refetch();
    }, [refetch]);

    return {
        refetch,
        fycStatus,
        loading,
    };
};
