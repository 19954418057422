import React from "react";
import clsx from "clsx";
import { InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import Input, { InputProps } from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { AppButton } from "./AppButton";
import "./style.css";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        inputFeild: {
            minHeight: "50px",
            color: `${theme.palette.common.black}`,
            "&::before": {
                borderBottomColor: `${theme.palette.grey[600]} !important`,
            },
            "&::after": {
                borderBottomColor: `${theme.colors.textField.focus} !important`,
            },
            "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus":
                {
                    transition:
                        "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
                    transitionDelay: "background-color 5000s, color 5000s",
                },
        },
        searchIconWarpper: {
            color: `${theme.palette.grey[600]}`,
        },
        searchWrapper: {
            padding: theme.spacing(1, 0),
        },
        cancelBtn: {
            height: "35px",
            marginLeft: theme.spacing(2),
            visibility: "hidden",
        },
        btnVisibility: {
            visibility: "visible",
        },
    }),
);

export interface AppSearchBarProps extends InternalStandardProps<InputProps> {
    onCancel?: () => void;
    elementId?: string;
}

export const AppSearchBar = (props: AppSearchBarProps) => {
    const classes = useStyles(props);
    const { className, value, elementId, onCancel, ...rest } = props;
    const searchId = elementId ? elementId : "standard-adornment-password";

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            const input = document.getElementById(searchId);
            input?.blur();
        }
    };

    const customInputProps: any = {
        enterKeyHint: "Search",
    };

    return (
        <div className={clsx(className, classes.root)}>
            <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <Input
                    {...rest}
                    id={searchId}
                    type="search"
                    value={value}
                    onKeyPress={handleKeyDown}
                    inputProps={customInputProps}
                    className={clsx(classes.inputFeild)}
                    startAdornment={
                        <InputAdornment
                            position="start"
                            className={clsx(classes.searchIconWarpper)}
                        >
                            <SearchIcon />
                        </InputAdornment>
                    }
                    endAdornment={
                        <div className={classes.searchWrapper}>
                            {(value as string)?.length > 0 && (
                                <AppButton
                                    onClick={onCancel}
                                    className={clsx(classes.cancelBtn, {
                                        [classes.btnVisibility]:
                                            (value as string)?.length > 0,
                                    })}
                                    rounded
                                    buttonVariant="grey-outlined"
                                >
                                    Cancel
                                </AppButton>
                            )}
                        </div>
                    }
                />
            </FormControl>
        </div>
    );
};
