import {
    Divider,
    DividerProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderColor: theme.palette.grey[300],
        },
    }),
);

export interface AppDividerProps extends InternalStandardProps<DividerProps> {}

export const AppDivider = (props: AppDividerProps) => {
    const classes = useStyles(props);
    const { className, variant = "fullWidth", ...rest } = props;

    return (
        <Divider
            {...rest}
            variant={variant}
            className={clsx(className, classes.root)}
        />
    );
};
