import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    IconButton,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { CourseLogFirebaseEventSettings } from "./CourseStep";
import {
    CourseDetail,
    EventActions,
    EventCategories,
    EventNames,
} from "JS/Models";
import { useGlobalMedia } from "JS/React/Hooks/Media";
import { AppDocument } from "JS/React/Components/AppDocument";
import { RenderPrintProps, printPlugin } from "@react-pdf-viewer/print";
import { isIOS } from "react-device-detect";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        containers: {
            padding: theme.spacing(1),
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        documentGrid: {
            overflow: "hidden",
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
    }),
);

export interface PdfStepProps extends InternalStandardProps<GridProps> {
    mediaUrl: string;
    course: CourseDetail;
    onStepCompleted?: () => Promise<void>;
    onStepOpen: () => Promise<void>;
    onClose: () => void;
    handleLogFirebaseEvents: (
        type: "course" | "step" | "question",
        settings: CourseLogFirebaseEventSettings,
    ) => void;
}

export const PdfStep = (props: PdfStepProps) => {
    const classes = useStyles(props);
    const {
        className,
        mediaUrl,
        course,
        onStepCompleted,
        handleLogFirebaseEvents,
        onStepOpen,
        onClose,
        ...rest
    } = props;

    const pdfWrapperRef = useRef(null);
    const { setIsZoomAllowed } = useGlobalMedia();
    const printPluginInstance = printPlugin();
    const { Print } = printPluginInstance;

    const onDocumentLoadSuccess = async () => {
        if (course.content_item_type_name === "Course") {
            handleLogFirebaseEvents("step", {
                action: EventActions.STARTED,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_STARTED,
            });
            handleLogFirebaseEvents("step", {
                action: EventActions.DOCUMENT_VIEWED,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_DOCUMENT_VIEWED,
            });
        }
        if (course.content_item_type_name === "GiftCourse") {
            handleLogFirebaseEvents("step", {
                action: EventActions.STARTED,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_STARTED,
            });
            handleLogFirebaseEvents("step", {
                action: EventActions.DOCUMENT_VIEWED,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_DOCUMENT_VIEWED,
            });
        }
        await onStepOpen();
        if (onStepCompleted) {
            await onStepCompleted();
        }
    };

    useEffect(() => {
        setIsZoomAllowed(true);

        return () => {
            setIsZoomAllowed(false);
        };
    }, []);

    return (
        <Grid
            {...rest}
            container
            className={clsx(classes.containers, className)}
        >
            <Grid item xs={12} display="flex" justifyContent="space-between">
                {!isIOS && (
                    <Print>
                        {(props: RenderPrintProps) => (
                            <IconButton onClick={props.onClick}>
                                <FontAwesomeIcon
                                    icon={["fas", "print"]}
                                    className={classes.icon}
                                />
                            </IconButton>
                        )}
                    </Print>
                )}
                {isIOS && <Box />}
                <IconButton onClick={onClose}>
                    <FontAwesomeIcon
                        style={{
                            fontSize: "30px",
                        }}
                        className={classes.grey600}
                        icon={["fas", "xmark"]}
                    />
                </IconButton>
            </Grid>
            <Grid
                item
                xs={12}
                ref={pdfWrapperRef}
                className={classes.documentGrid}
            >
                {mediaUrl && (
                    <AppDocument
                        fileUrl={mediaUrl}
                        printPlugin={printPluginInstance}
                        onDocumentOpen={onDocumentLoadSuccess}
                        loader={
                            <Box display="flex" justifyContent="center">
                                <AppBackdropProgress
                                    open
                                    backdropText={messaging.loader.document}
                                />
                            </Box>
                        }
                    />
                )}
            </Grid>
        </Grid>
    );
};
