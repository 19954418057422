import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    BoxProps,
    IconButton,
    InternalStandardProps,
    List,
    ListItem,
    Switch,
    Theme,
} from "@mui/material";
import { Layout } from "../Layout";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { AppTypography } from "JS/React/Components/AppTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppDivider } from "JS/React/Components/AppDivider";
import { useThemeModeContext } from "JS/React/Context/ThemeModeProvider";
import { useDashboardPushStatus } from "JS/React/Hooks/Firebase/GiftDashboard";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import { AppSwitch } from "JS/React/Components/AppSwitch";
import { getUserName, isBetaEnvironment, isStageEnvironment } from "JS/Helpers";
import { config } from "JS/Config";

export const Appsettings = (props: AppInfoProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const { homeStack } = useGlobalNavStack();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const themeMode = useThemeModeContext();
    const { setPushStatus, loading, status, error, statusLoading } =
        useDashboardPushStatus();
    const pushSwitchChange = () => setPushStatus(!status);

    return (
        <Layout>
            <Box
                {...rest}
                className={clsx(className, classes.root)}
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
            >
                <AppHeader
                    title={"Settings"}
                    canGoBack
                    searchable={false}
                    onBackClick={() => {
                        if (!homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.more().index());
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                {(isBetaEnvironment() || isStageEnvironment()) && <List className={classes.list} >
                    <AppTypography
                        className={clsx(classes.grey600, classes.listHeader)}
                        variant="h6"
                    >
                        User Information
                    </AppTypography>
                    <AppDivider />
                    <ListItem className={classes.listItem}>
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            {`${getUserName()} (${config.user.memberId})`}
                        </AppTypography>

                    </ListItem>
                    <AppDivider />
                </List>}
                <List className={classes.list}>
                    <AppTypography
                        className={clsx(classes.grey600, classes.listHeader)}
                        variant="h6"
                    >
                        Dark Mode Settings
                    </AppTypography>
                    <AppDivider />
                    <ListItem
                        className={clsx(
                            classes.listItem,
                            themeMode.mode === "light" &&
                            !themeMode.defaultSystem &&
                            classes.listItemSelected,
                        )}
                        onClick={() =>
                            themeMode.toggleColorMode("light", false)
                        }
                    >
                        <IconButton
                            sx={{ mr: 1 }}
                            color="inherit"
                            style={{ display: "block" }}
                        >
                            {themeMode.mode === "light" &&
                                !themeMode.defaultSystem ? (
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: "30px",
                                    }}
                                    className={classes.grey600}
                                    icon={["fas", "sun"]}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: "30px",
                                    }}
                                    className={classes.grey600}
                                    icon={["far", "sun"]}
                                />
                            )}
                        </IconButton>
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Light
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        className={clsx(
                            classes.listItem,
                            themeMode.mode === "dark" &&
                            !themeMode.defaultSystem &&
                            classes.listItemSelected,
                        )}
                        onClick={() => themeMode.toggleColorMode("dark", false)}
                    >
                        <IconButton
                            sx={{ mr: 1 }}
                            color="inherit"
                            style={{ display: "block" }}
                        >
                            {themeMode.mode === "dark" &&
                                !themeMode.defaultSystem ? (
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: "30px",
                                    }}
                                    className={classes.grey600}
                                    icon={["fas", "moon"]}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: "30px",
                                    }}
                                    className={classes.grey600}
                                    icon={["far", "moon"]}
                                />
                            )}
                        </IconButton>
                        <AppTypography
                            className={clsx(classes.grey600, classes.listText)}
                            variant="h6"
                        >
                            Dark
                        </AppTypography>
                    </ListItem>
                    <AppDivider />
                    <ListItem
                        className={clsx(
                            classes.listItem,
                            themeMode.defaultSystem && classes.listItemSelected,
                        )}
                        onClick={themeMode.toogleSystemMode}
                    >
                        <IconButton sx={{ mr: 1 }} color="inherit">
                            {themeMode.defaultSystem ? (
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: "30px",
                                    }}
                                    className={classes.grey600}
                                    icon={["fas", "tablet"]}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: "30px",
                                    }}
                                    className={classes.grey600}
                                    icon={["fas", "tablet-alt"]}
                                />
                            )}
                        </IconButton>
                        <Box>
                            <AppTypography
                                className={clsx(
                                    classes.grey600,
                                    classes.listText,
                                )}
                                variant="h6"
                            >
                                Use System Settings
                            </AppTypography>
                            <AppTypography
                                className={clsx(
                                    classes.grey600,
                                    classes.listParagraphText,
                                )}
                                variant="body1"
                            >
                                We'll adjust your appearance based on your
                                device's system settings.
                            </AppTypography>
                        </Box>
                    </ListItem>
                    <AppDivider />
                    <AppTypography
                        className={clsx(
                            classes.grey600,
                            classes.listHeader,
                            classes.topMargin,
                        )}
                        variant="h6"
                    >
                        Gift Dashboard
                    </AppTypography>
                    <AppDivider />
                    <ListItem
                        className={clsx(classes.notificationSettingsItem)}
                    >
                        {loading ? (
                            <AppCircularProgress />
                        ) : (
                            <>
                                <AppTypography
                                    className={clsx(
                                        classes.listText,
                                        classes.grey600,
                                    )}
                                >
                                    {error
                                        ? `${messaging.common.error}`
                                        : "Notification Settings"}
                                </AppTypography>
                                {!error &&
                                    (statusLoading ? (
                                        <AppCircularProgress />
                                    ) : (
                                        <AppSwitch
                                            checked={status}
                                            onSwitchChange={pushSwitchChange}
                                        />
                                    ))}
                            </>
                        )}
                    </ListItem>
                    <AppDivider />
                </List>
            </Box>
        </Layout>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: `${theme.palette.grey[600]} !important`,
        },
        listItem: {
            fontWeight: 600,
            display: "flex",
            justifyContent: "flex-start",
            backgroundColor: `${theme.palette.background} !important`,
        },
        notificationSettingsItem: {
            fontWeight: 600,
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: `${theme.palette.background} !important`,
        },
        topMargin: {
            marginTop: "10px",
        },
        themeContentWrapper: {
            display: "grid",
            textAlign: "center",
        },
        listHeader: {
            fontWeight: 800,
        },
        listText: { fontWeight: 600 },
        listParagraphText: { fontWeight: 400 },
        list: {
            flexGrow: 1,
        },
        listIcon: {
            fontWeight: 400,
            fontSize: "24px",
            marginRight: "5%",
            width: "10%",
        },
        listItemSelected: {
            backgroundColor:
                theme.palette.mode === "light"
                    ? `${theme.palette.grey[200]} !important`
                    : `${theme.palette.grey[900]} !important`,
        },
    }),
);

export interface AppInfoProps extends InternalStandardProps<BoxProps> { }
