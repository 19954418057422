import { defaultTo } from "lodash-es";

export interface ErrorInfo {
    message: string;
    code: string;
}

export interface FieldState {
    identifier: string;
    errors: ErrorInfo[];
    children: ValidityState;
}

export type ValidityState = FieldState[];

export function getInitialValidityState(
    state: ValidityState,
    identifiers: Readonly<string | string[]>,
) {
    state = defaultTo(state, [] as ValidityState);
    if (typeof identifiers === "string") {
        state = getInitialValidityState(state, [identifiers]);
    } else {
        identifiers.forEach((f) => {
            if (!state.find((a) => a.identifier === f)) {
                state = [
                    ...state,
                    {
                        children: [],
                        errors: [],
                        identifier: f,
                    },
                ];
            }
        });
    }
    return state;
}

export function getFieldValidityState(
    state: ValidityState,
    identifier: string,
) {
    return getInitialValidityState(state, identifier).find(
        (a) => a.identifier === identifier,
    );
}

export function getFirstErrorInfo(state: ValidityState, identifier: string) {
    return getFieldValidityState(state, identifier).errors[0];
}

export function checkStateValidity(state: ValidityState): boolean {
    state = defaultTo<ValidityState>(state, [] as ValidityState);
    return state.reduce((pre, f) => {
        return pre && f.errors.length === 0 && checkStateValidity(f.children);
    }, true);
}

export function addErrorInValidity(
    validityState: ValidityState,
    identifier: string,
    error: ErrorInfo,
) {
    validityState = getInitialValidityState(validityState, identifier);
    return validityState.map((a) => {
        if (a.identifier === identifier && error) {
            return {
                ...a,
                errors: [...a.errors, error],
            };
        }
        return a;
    });
}
export function replaceError(
    validityState: ValidityState,
    identifier: string,
    error: ErrorInfo,
) {
    validityState = getInitialValidityState(validityState, identifier);
    return validityState.map((a) => {
        if (a.identifier === identifier) {
            return {
                ...a,
                errors: error ? [error] : [],
            };
        }
        return a;
    });
}
export function replaceFieldState(
    validityState: ValidityState,
    identifier: string,
    fieldState: FieldState,
) {
    const old = validityState.find((a) => a.identifier === identifier);
    if (old !== undefined && old !== null) {
        if (fieldState === null) {
            //delete
            validityState = validityState.filter(
                (a) => a.identifier !== identifier,
            );
        }
        //update
        else {
            validityState = validityState.map((a) => {
                if (a.identifier === identifier) {
                    return fieldState;
                }
                return a;
            });
        }
    } else {
        //add
        validityState = [...validityState, fieldState];
    }
    return validityState;
}

export function mergeValidity(dest: ValidityState, withState: ValidityState) {
    let state = getInitialValidityState(dest, []);
    withState.forEach((field) => {
        field.errors.forEach((error) => {
            state = addErrorInValidity(state, field.identifier, error);
        });
        const destField = getFieldValidityState(state, field.identifier);
        state = replaceFieldState(state, field.identifier, {
            ...destField,
            children: mergeValidity(destField.children, field.children),
        });
    });
    return state;
}
