import { FirebaseApp, initializeApp } from "firebase/app";
import { isSupported } from "firebase/analytics";

import {
    Auth,
    getAuth,
    inMemoryPersistence,
    setPersistence,
    signInWithCustomToken,
} from "firebase/auth";
import {
    Database,
    getDatabase,
    ref,
    get,
    set,
    update,
} from "firebase/database";
import { config } from "JS/Config";
import moment from "moment";
import { getWebcastUUID } from "JS/Helpers/PushNotifications";

class WebcastFirebaseService {
    private app: FirebaseApp;
    private db: Database;
    private auth: Auth;
    private static APP_NAME = "ReactMedia";

    constructor() {
        isSupported().then((supported) => {
            if (supported) {
                this.app = initializeApp(config.firebase.webcast, "RMAS");
                if (this.app) {
                    this.db = getDatabase(this.app);
                    this.auth = getAuth(this.app);
                }
            }
        });
    }

    async signInWithToken() {
        await signInWithCustomToken(this.auth, config.firebase.webcast.token);
        await setPersistence(this.auth, inMemoryPersistence);
    }

    async writeOpenDate(webcastTitle: String) {
        const baseUrl = this.baseUrl(webcastTitle);
        const url = `${baseUrl}/first_open_date_time`;
        if (this.db) {
            let dbRef = ref(this.db, url);

            const snap = await get(dbRef);

            const data = snap.val();

            if (data === null) {
                dbRef = ref(this.db, baseUrl);
                const time = moment().valueOf();
                set(dbRef, {
                    first_open_timestamp: time,
                    first_open_date_time: this.format(time),
                    device_model: navigator.userAgent,
                });
            }
        }
    }

    async writeStartDate(webcastTitle: string) {
        const baseUrl = this.baseUrl(webcastTitle);
        const url = `${baseUrl}/start_date_time`;

        if (this.db) {
            let dbRef = ref(this.db, url);
            const snap = await get(dbRef);

            const data = snap.val();

            if (data === null) {
                dbRef = ref(this.db, baseUrl);
                const time = moment().valueOf();
                update(dbRef, {
                    start_timestamp: time,
                    start_date_time: this.format(time),
                });
            }
        }
    }

    updateWatchTime(webcastTitle: string, watchDate: number) {
        const baseUrl = this.baseUrl(webcastTitle);
        const watchUrl = `${baseUrl}/last_watch_timestamp`;

        if (this.db) {
            let dbRef = ref(this.db, watchUrl);

            dbRef = ref(this.db, baseUrl);
            update(dbRef, {
                last_watch_timestamp: watchDate,
                last_watch_date_time: this.format(watchDate),
            });
        }
    }

    async updateDuration(webcastTitle: string) {
        const baseUrl = this.baseUrl(webcastTitle);
        const watchUrl = `${baseUrl}/total_time_spent`;
        if (this.db) {
            let dbRef = ref(this.db, watchUrl);

            const snap = await get(dbRef);
            const data = snap.val();
            const updateTime =
                data === null ? DURATION_TIME : data + DURATION_TIME;

            dbRef = ref(this.db, baseUrl);
            update(dbRef, {
                total_time_spent: updateTime,
            });
        }
    }

    private baseUrl(webcastTitle: String) {
        return `${webcastTitle}/${config.user.memberId}/${
            WebcastFirebaseService.APP_NAME
        }-${getWebcastUUID()}`;
    }

    private format(time: number) {
        return moment(time).utcOffset(-5).format("MMMM DD, yyyy, hh:mm a zZ");
    }
}

export const webcastFirebaseService = new WebcastFirebaseService();
const DURATION_TIME = 20;
export const DURATION_MILLIS = DURATION_TIME * 1000;
