import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    CircularProgress,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    useCourseDetailWithMedia,
    useReceivedCourses,
} from "JS/React/Hooks/Course/Course";
import { Layout } from "../../Layout";
import { useEffect, useMemo, useState } from "react";
import { getPublicUrl } from "JS/Helpers/S3Helper";
import { useWriteStepStats } from "JS/React/Hooks/Firebase/Database";
import { FBFunctionStepStats, StepActions } from "JS/Models/Firebase/Courses";
import qs from "qs";
import { useRouting } from "JS/React/Hooks/Routes";
import { QuizStep } from "./QuizStep";
import { MediaStep } from "./MediaStep";
import { PdfStep } from "./PdfStep";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import {
    CourseDetail,
    CourseStep as ICourseStep,
    EventActions,
    EventCategories,
    EventNames,
} from "JS/Models";
import { useScrollToTop, useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { useStepFromDb } from "JS/React/Hooks/Database/Course";
import { config } from "JS/Config";
import {
    createUrlFromBlob,
    createCourseIDBId,
    createStepObjectForFB,
    createCourseIDForStats,
} from "JS/Helpers";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import { SentGiftType, toSentGift } from "JS/Models/Firebase/GiftDashboard";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useDownloadedCourses } from "JS/React/Hooks/DownloadCourses";

export const CourseStep = (props: CourseStepProps) => {
    const classes = useStyles(props);

    const history = useHistory();
    const location = useLocation();
    const { linkProvider } = useRouting();
    const { firebaseCourseStats } = useDownloadedCourses();
    const { detailSkuId, stepSkuId, isAutoPlay, scrollTo }: any = useParams();

    const [currentPlayingState, setCurrentPlayingState] = useState(true);

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const {
            displayMode = "",
            segment = "",
            giftTab,
            isMixedContent = false,
            scrollTo = 0,
        } = parsedQueryString;

        return {
            displayMode: displayMode ? displayMode.toString() : null,
            segment: segment ? segment.toString() : null,
            giftTab: giftTab ? giftTab.toString() : null,
            isMixedContent: !!isMixedContent ? true : false,
            scrollTo: scrollTo ? +scrollTo : 0,
        };
    }, [location?.search]);

    const displayMode = queryParams.displayMode === "true";
    const isGiftTab = queryParams.giftTab === "true";

    const { detail, loading: detailLoading } =
        useCourseDetailWithMedia(detailSkuId);

    const step = detail?.steps.find((d) => d.sku_id === stepSkuId);

    const getStepDetail = () => {
        return (
            firebaseCourseStats[createCourseIDForStats(detailSkuId)]?.steps?.[
                stepSkuId
            ] || null
        );
    };

    useScrollToTop();
    const { homeStack } = useGlobalNavStack();

    const { stepStats, writeFBStepStats } = useWriteStepStats();

    const { logFirebaseEvent } = useFirebaseLogger();
    const { imgUrl, mediaUrl, setMediaUrl, setImgUrl } = useVerifiedUrls(
        detail,
        step,
    );
    const nextStep = detail?.steps?.find(
        (d) => +d?.position === +step?.position + 1,
    );
    const prevStep = detail?.steps?.find(
        (d) => +d?.position === +step?.position - 1,
    );

    const { receivedCourses } = useReceivedCourses(true);

    const receivedCourse = useMemo(() => {
        return receivedCourses?.find((c) => c.sku_id === detailSkuId);
    }, [receivedCourses, detailSkuId]);

    const { logGiftOpen, logGiftCompleted } = useGiftDashboard(
        toSentGift(receivedCourse),
        SentGiftType.COURSE,
        receivedCourse?.sender?.member_id,
        false,
    );

    const isGift = detail?.content_item_type_name === "GiftCourse";

    const onStepOpen = async () => {
        if (step) {
            handleLogFirebaseEvents("step", {
                action: EventActions.OPEN,
                category: isGift
                    ? EventCategories.GIFT_COURSE_STEP
                    : EventCategories.COURSE_STEP,
                eventNames: isGift
                    ? EventNames.GIFT_COURSE_STEP_OPENED
                    : EventNames.COURSE_STEP_OPENED,
            });
            if (isGift)
                logGiftOpen({ stepSku: stepSkuId, stepName: step.title });

            const toSendData: Partial<FBFunctionStepStats> =
                createStepObjectForFB(step);
            if (step.content_item_type_name === "quiz") {
                toSendData.total_questions = step.quiz.length;
            }
            await updateStepStats(toSendData, StepActions.Open);
        }
    };

    const updateStepStats = async (
        stats: Partial<FBFunctionStepStats>,
        action: StepActions,
        refetch?: boolean,
    ) => {
        await writeFBStepStats(detailSkuId, stepSkuId, action, stats);
    };

    const handleLogFirebaseEvents = (
        type: "course" | "step" | "question",
        settings: CourseLogFirebaseEventSettings,
    ) => {
        if (type === "course") {
            logFirebaseEvent(settings.eventNames, {
                action: settings.action,
                contentTitle: detail.title,
                nId: detail.nid,
                skuId: detail.sku_id,
                category: settings.category,
            });
        }
        if (type === "step") {
            logFirebaseEvent(settings.eventNames, {
                action: settings.action,
                contentTitle: step.title,
                nId: detail.nid,
                skuId: detail.sku_id,
                stepNId: step.nid,
                stepSkuId: step.sku_id,
                position: +step.position,
                category: settings.category,
            });
        }
        if (type === "question") {
            logFirebaseEvent(settings.eventNames, {
                action: settings.action,
                contentTitle: step.title,
                nId: detail.nid,
                skuId: detail.sku_id,
                stepNId: step.nid,
                stepSkuId: step.sku_id,
                position: +step.position,
                category: settings.category,
                questionId: settings.questionId,
                questionPosition: settings.questionPos,
            });
        }
    };

    const loading = detailLoading;

    ///stats related functions

    const onStepCompleted = async () => {
        handleLogFirebaseEvents("step", {
            action: EventActions.COMPLETED,
            category: isGift
                ? EventCategories.GIFT_COURSE_STEP
                : EventCategories.COURSE_STEP,
            eventNames: isGift
                ? EventNames.GIFT_COURSE_STEP_COMPLETED
                : EventNames.COURSE_STEP_COMPLETED,
        });
        if (isGift)
            logGiftCompleted({ stepSku: stepSkuId, stepName: step.title });

        await updateStepStats(
            createStepObjectForFB(step),
            StepActions.Complete,
        );
        if (step.content_item_type_name === "quiz") {
            if (detail.content_item_type_name === "Course") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.QUIZ_COMPLETED,
                    category: EventCategories.COURSE_STEP,
                    eventNames: EventNames.COURSE_STEP_QUIZ_COMPLETED,
                });
            }
            if (detail.content_item_type_name === "GiftCourse") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.QUIZ_COMPLETED,
                    category: EventCategories.GIFT_COURSE_STEP,
                    eventNames: EventNames.GIFT_COURSE_STEP_QUIZ_COMPLETED,
                });
            }
        } else {
            onStepFinished();
        }
    };

    const onStepFinished = () => {
        if (displayMode && nextStep && step?.content_item_type_name !== "PDF") {
            forwardStep();
        } else {
            if (step?.content_item_type_name === "quiz") {
                goBackToIndex();
            }
        }
    };

    const forwardStep = () => {
        if (nextStep) {
            setCurrentPlayingState(true);
            setImgUrl(null);
            setMediaUrl(null);
            handleRedirectToStep(
                detail.sku_id,
                nextStep.sku_id,
                isAutoPlay,
                displayMode,
                queryParams?.segment,
            );
        }
    };

    const backwardStep = () => {
        if (prevStep) {
            setCurrentPlayingState(true);
            setImgUrl(null);
            setMediaUrl(null);
            handleRedirectToStep(
                detail.sku_id,
                prevStep.sku_id,
                isAutoPlay,
                displayMode,
                queryParams?.segment,
            );
        }
    };

    const goBackToIndex = () => {
        if (detail?.content_item_type_name === "GiftCourse") {
            handleRedirectToCourseDetail(
                detail?.nid,
                "true",
                queryParams?.segment,
            );
        } else {
            if (!queryParams?.isMixedContent) {
                history.push(
                    linkProvider.react.courses().detail(detail?.nid, {
                        received: "false",
                        segment: queryParams?.segment,
                        scrollTo: queryParams?.scrollTo,
                    }),
                );
            } else {
                history.push(
                    linkProvider.react
                        .mixedContent()
                        .courseDetail(
                            detail?.nid,
                            "false",
                            queryParams?.segment,
                            {
                                isMixedContent: true,
                                scrollTo: queryParams?.scrollTo?.toString(),
                            },
                        ),
                );
            }
        }
    };

    const handleRedirectToStep = (
        detailSku: string,
        stepSku: string,
        isAutoPlay: string,
        displayMode: boolean,
        segment: string,
    ) => {
        if (isGiftTab) {
            history.push(
                linkProvider.react
                    .gifts()
                    .courseMediaStepDetail(
                        detailSku,
                        stepSku,
                        isAutoPlay,
                        displayMode,
                        segment,
                        "true",
                    ),
            );
        } else {
            history.push(
                linkProvider.react
                    .courses()
                    .mediaStepDetail(detailSku, stepSku, isAutoPlay, {
                        displayMode,
                        contentType: segment,
                    }),
            );
        }
    };

    const handleRedirectToCourseDetail = (
        detailNid: string,
        received: string,
        contentType: string,
    ) => {
        if (isGiftTab) {
            history.push(
                linkProvider.react.gifts().courseDetail(detailNid, {
                    received,
                    giftTab: "true",
                    contentType,
                }),
            );
        } else {
            if (!queryParams?.isMixedContent) {
                history.push(
                    linkProvider.react
                        .courses()
                        .detail(detailNid, { received, segment: contentType }),
                );
            } else {
                history.push(
                    linkProvider.react
                        .mixedContent()
                        .courseDetail(detailNid, received, contentType, {
                            isMixedContent: true,
                            scrollTo: queryParams?.scrollTo?.toString(),
                        }),
                );
            }
        }
    };

    return (
        <Layout
            className={clsx(
                step?.content_item_type_name === "quiz" &&
                    classes.disabledLayoutRoot,
            )}
            disableLayout={step?.content_item_type_name === "quiz"}
        >
            <AppHeader
                title={step?.title}
                canGoBack
                searchable={false}
                onBackClick={() => {
                    goBackToIndex();
                }}
            />
            {displayMode && step?.content_item_type_name !== "quiz" && (
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"space-between"}
                        >
                            {!!prevStep ? (
                                <Box
                                    display={"flex"}
                                    alignItems="center"
                                    className={classes.forwardBackItem}
                                    onClick={backwardStep}
                                >
                                    <FontAwesomeIcon
                                        className={clsx(
                                            classes.fwdBackIcon,
                                            classes.backIcon,
                                        )}
                                        icon={["fas", "chevron-left"]}
                                    />
                                    <Typography
                                        className={classes.fwdBackTypo}
                                        fontWeight={"bold"}
                                        variant="h5"
                                    >
                                        Back
                                    </Typography>
                                </Box>
                            ) : (
                                <div></div>
                            )}
                            {!!nextStep &&
                            stepStats?.analytics?.completed_count &&
                            stepStats?.analytics?.completed_count > 0 ? (
                                <Box
                                    display={"flex"}
                                    alignItems="center"
                                    className={classes.forwardBackItem}
                                    onClick={forwardStep}
                                >
                                    <Typography
                                        className={classes.fwdBackTypo}
                                        fontWeight={"bold"}
                                        variant="h5"
                                    >
                                        Next
                                    </Typography>
                                    <FontAwesomeIcon
                                        className={clsx(
                                            classes.fwdBackIcon,
                                            classes.fwdIcon,
                                        )}
                                        icon={["fas", "chevron-right"]}
                                    />
                                </Box>
                            ) : (
                                <div></div>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            )}

            {step && detail && !loading && (
                <>
                    {(step.content_item_type_name === "Video" ||
                        step.content_item_type_name === "MP3") && (
                        <MediaStep
                            isAutoPlay={isAutoPlay === "true"}
                            key={step?.sku_id}
                            step={step}
                            mediaUrl={mediaUrl}
                            imgUrl={imgUrl}
                            updateStepStats={updateStepStats}
                            courseSku={detailSkuId}
                            stepSku={stepSkuId}
                            detail={detail}
                            stepDetail={getStepDetail()}
                            onStepCompleted={onStepCompleted}
                            onStepOpen={onStepOpen}
                            handleLogFirebaseEvents={handleLogFirebaseEvents}
                            setCurrentPlayingState={setCurrentPlayingState}
                            currentPlayingState={currentPlayingState}
                            receivedCourse={receivedCourse}
                        />
                    )}

                    {step.content_item_type_name === "PDF" && (
                        <PdfStep
                            course={detail}
                            mediaUrl={mediaUrl}
                            onStepOpen={onStepOpen}
                            onClose={goBackToIndex}
                            handleLogFirebaseEvents={handleLogFirebaseEvents}
                        />
                    )}
                    {step.content_item_type_name === "quiz" && (
                        <QuizStep
                            key={step?.sku_id}
                            course={detail}
                            courseSku={detailSkuId}
                            goBackToIndex={goBackToIndex}
                            updateStepStats={updateStepStats}
                            step={step}
                            stepDetail={getStepDetail()}
                            onQuizStepCompleted={onStepCompleted}
                            onQuizStepFinished={onStepFinished}
                            onStepOpen={onStepOpen}
                            handleLogFirebaseEvents={handleLogFirebaseEvents}
                        />
                    )}
                </>
            )}
            {loading && (
                <Grid
                    className={classes.loaderWrapper}
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent={"center"}
                >
                    <CircularProgress className={classes.spinner} />
                </Grid>
            )}
        </Layout>
    );
};

const useVerifiedUrls = (detail: CourseDetail, step: ICourseStep) => {
    const [imgUrl, setImgUrl] = useState<string>(null);
    const [mediaUrl, setMediaUrl] = useState<string>(null);

    const { verifyAwsKeys } = useVerifyAwsKeys();
    const { refetch: getStepFromDB } = useStepFromDb();

    useEffect(() => {
        (async () => {
            if (step) {
                const downloadedId = createCourseIDBId(
                    config.user.memberId,
                    detail.nid,
                    step.nid,
                );
                const downloadedStep = await getStepFromDB(downloadedId);

                await verifyAwsKeys().then(async () => {
                    const imgUrl = await getPublicUrl(
                        step.media_data[0].image.image_url_prefix,
                        step.media_data[0].image.image_url_postfix,
                        step.media_data[0].image.image_file_name,
                    );
                    setImgUrl(imgUrl);

                    if (downloadedStep) {
                        const url = createUrlFromBlob(downloadedStep.blob);
                        setMediaUrl(url);
                    } else {
                        const mediaUrl = await getPublicUrl(
                            step.media_data[0].media.media_url_prefix,
                            step.media_data[0].media.media_url_postfix,
                            step.media_data[0].media.media_file_name,
                        );
                        setMediaUrl(mediaUrl);
                    }
                });
            }
        })();
    }, [step]);

    return {
        imgUrl,
        mediaUrl,
        setMediaUrl,
        setImgUrl,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },
        spinner: {
            color: theme.palette.primary.main,
        },

        loaderWrapper: {
            minHeight: `calc(100vh - ${theme.footer.height})`,
        },

        forwardBackItem: {
            padding: theme.spacing(2),
            color: theme.palette.grey.A100,
            cursor: "pointer",
        },
        fwdBackTypo: {
            fontSize: "18px",
        },
        fwdBackIcon: {
            fontSize: "18px",
        },
        backIcon: {
            marginRight: theme.spacing(2),
        },
        fwdIcon: {
            marginLeft: theme.spacing(2),
        },

        disabledLayoutRoot: {
            height: "100vh",
            display: "flex",
            flexDirection: "column",
        },
    }),
);

export type CourseLogFirebaseEventSettings = {
    eventNames: EventNames;
    action: EventActions;
    category: EventCategories;
    questionId?: number;
    questionPos?: number;
};

export interface CourseStepProps extends InternalStandardProps<GridProps> {}
