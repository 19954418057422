import AWS from "aws-sdk";
import { Event, Ticket } from "JS/Models/Events";
import { config } from "JS/Config";
import { GetObjectRequest } from "aws-sdk/clients/s3";
import { BaseService } from "../BaseService";
import { AppAlternativeResponse, AppResponse } from "JS/Types";
import { EventsHelper } from "./EventsHelper";

export enum TicketError {
    NoEvents,
    NoneRegistered,
}

export interface ImageResponse {
    AcceptRanges: string;
    Body: Uint8Array;
    ContentLength: number;
    ContentType: string;
    ETag: string;
    LastModified: Date;
}

export class TicketsService extends BaseService {
    private helper = new EventsHelper();

    async getEvents(): Promise<Event[]> {
        return new Promise((eventRes, eventRej) => {
            this.helper.initConfig();
            const s3 = this.helper.getS3();
            let validEvents: Event[] = [];
            let eventsWithTickets: Event[] = [];

            s3.listObjectsV2(this.allEventParams(), (err, data) => {
                if (err) {
                    eventRej(TicketError.NoEvents);
                } else {
                    Promise.all(
                        data.Contents.map(
                            (content) =>
                                new Promise<Event>((singleEventRes, _) => {
                                    s3.getObject(
                                        this.getEventFileParams(content.Key),
                                        (err, data) => {
                                            if (err) singleEventRes(null);
                                            const event: Event = JSON.parse(
                                                data.Body.toString(),
                                            );
                                            if (this.shouldShowEvent(event)) {
                                                let folder =
                                                    this.getFolderNameFromKey(
                                                        content.Key,
                                                    );
                                                validEvents.push(event);
                                                Promise.all([
                                                    this.helper.primaryTicketPromise(
                                                        folder,
                                                    ),
                                                    this.helper.secondaryTicketPromise(
                                                        folder,
                                                    ),
                                                ]).then((values) => {
                                                    if (
                                                        values[0] !== null ||
                                                        values[1] !== null
                                                    ) {
                                                        const ticket: Ticket[] =
                                                            [];
                                                        values &&
                                                            values.length > 0 &&
                                                            values.map(
                                                                (item) =>
                                                                    item !==
                                                                        null &&
                                                                    ticket.push(
                                                                        JSON.parse(
                                                                            item?.Body.toString(),
                                                                        ),
                                                                    ),
                                                            );

                                                        eventsWithTickets.push({
                                                            ...event,
                                                            Tickets: ticket,
                                                        });
                                                        singleEventRes(event);
                                                    } else singleEventRes(null);
                                                });
                                            } else singleEventRes(null);
                                        },
                                    );
                                }),
                        ),
                    ).then((_) => {
                        if (
                            eventsWithTickets.length === 0 &&
                            validEvents.length > 0
                        )
                            eventRej(TicketError.NoneRegistered);
                        else eventRes(eventsWithTickets);
                    });
                }
            });
        });
    }

    async getS3PostFileUrl(fileName: string, isPrimaryUser: number) {
        return (
            await this.doXHR<AppResponse<string>>({
                url: this.routes.server.api.events.getS3PostFileUrl(
                    `${fileName}`,
                    isPrimaryUser,
                ),
                method: "GET",
                headers: {
                    Authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async setProfilePhotoInS3(file, url) {
        return await this.doXHR<AppResponse<null>>({
            url: url,
            method: "PUT",
            data: file,
        });
    }

    async getBarCode(userId: string) {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);
        formData.append("member_id", `${userId}`);

        return (
            await this.doXHR<AppAlternativeResponse<string>>({
                url: this.routes.server.api.events.getBarCode(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async getPdfBlob(url: string) {
        return (
            await this.doXHR<Blob>({
                url: url,
                method: "GET",
                responseType: "blob",
            })
        ).data;
    }

    async getProfImageByS3(userId: string) {
        return new Promise((reslove, reject) => {
            this.helper.initConfig();
            const s3 = this.helper.getS3();
            let key = `${this.helper.imagePath}${userId}.jpg`;
            s3.getObject(
                this.getEventFileParams(key),
                (err, res: ImageResponse) => {
                    if (err) {
                        reject(false);
                    } else {
                        if (res?.ContentLength > 0) {
                            reslove(true);
                        } else {
                            reslove(false);
                        }
                    }
                },
            );
        });
    }

    private allEventParams(): AWS.S3.ListObjectsV2Request {
        return {
            Bucket: this.helper.bucket,
            Prefix: `${this.helper.folder}/tix/`,
            Delimiter: "/",
        };
    }

    private getFolderNameFromKey(key: string): string {
        if (key.includes(".")) return key.substring(0, key.lastIndexOf("."));
        else return key;
    }

    private getEventFileParams(key: string): GetObjectRequest {
        return {
            Bucket: config?.s3?.events?.bucketName,
            Key: key,
            ResponseCacheControl: "no-cache",
        };
    }

    private shouldShowEvent(event: Event): boolean {
        const eventDate = new Date(event.EventEndDateAndTime);
        const sevenDays = 7 * 24 * 60 * 60 * 1000;
        return Date.now() - eventDate.getTime() < sevenDays;
    }
}
