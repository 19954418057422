import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Button,
    ButtonProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: "none",
            background: theme.palette.primary.main,
            color: theme.colors.white,
        },
        primaryFilled: {
            background: theme.palette.primary.main,
            color: theme.colors.white,
            "&:hover": {
                background: theme.palette.primary.dark,
                color: theme.colors.white,
            },
            "&:active": {
                background: theme.palette.primary.main,
                color: theme.colors.white,
            },
            "&:disabled": {
                background: theme.palette.secondary.main,
            },
        },
        greyOutlined: {
            background: "transparent",
            boxShadow: "none",
            color: theme.palette.grey.A100,
            border: `2px solid ${theme.palette.grey.A100}`,
            "&:hover": {
                background: "transparent",
                boxShadow: "none",
            },
            "&:disabled": {
                background: "transparent",
                color: theme.palette.grey[300],
            },
        },
        disabledOutlined: {
            background: "transparent",
            boxShadow: "none",
            color: theme.palette.grey[300],
            border: `2px solid ${theme.palette.grey[300]}`,

            "&:hover": {
                background: "transparent",
                boxShadow: "none",
            },
            "&:disabled": {
                background: "transparent",
                color: theme.palette.grey[300],
            },
        },
        greyText: {
            background: "transparent",
            boxShadow: "none",
            color: theme.palette.grey.A100,
            "&:hover": {
                background: "transparent",
                boxShadow: "none",
            },
            "&:disabled": {
                background: "transparent",
                color: theme.palette.grey[300],
            },
        },
        rounded: {
            borderRadius: theme.spacing(3),
            padding: theme.spacing(1, 3),
        },
    }),
);

export interface AppButtonProps extends InternalStandardProps<ButtonProps> {
    buttonVariant?:
        | "primary-filled"
        | "grey-outlined"
        | "grey-text"
        | "disabled-outlined";
    rounded?: boolean;
}

export const AppButton = (props: AppButtonProps) => {
    const classes = useStyles(props);
    const {
        className,
        buttonVariant,
        variant = "contained",
        rounded,

        ...rest
    } = props;

    return (
        <Button
            {...rest}
            variant={variant}
            className={clsx(className, classes.root, {
                [classes.primaryFilled]: buttonVariant === "primary-filled",
                [classes.greyOutlined]: buttonVariant === "grey-outlined",
                [classes.disabledOutlined]:
                    buttonVariant === "disabled-outlined",
                [classes.greyText]: buttonVariant === "grey-text",
                [classes.rounded]: rounded === true,
            })}
        />
    );
};
