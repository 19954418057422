import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    BoxProps,
    InternalStandardProps,
    List,
    ListItem,
    Theme,
} from "@mui/material";
import { Layout } from "../Layout";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { Fragment } from "react";
import { AppHeader } from "JS/React/Components/AppHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { AppDivider } from "JS/React/Components/AppDivider";
import { config } from "JS/Config";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        listItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        ListTitle: {
            fontWeight: 400,
            width: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        ListIconsWrapper: {
            width: "5%",
        },
        list: {
            flexGrow: 1,
        },
        forwardIcon: {
            color: `${theme.palette.common.black}`,
        },
    }),
);

export enum AppInfoTypes {
    TERMS_AND_CONDTIONS = "Terms & Conditions of Use",
    PRIVACY_POLICY = "Privacy Policy",
    LICENSE_AGREEMENT = "End User License Agreement",
    EVENT_REGISTRATION_DISCLOURSE = "Event Registration Disclosure",
}

export enum AppInfoEndpoints {
    TERMS_AND_CONDTIONS = "/downloads/media_term_and_condition",
    PRIVACY_POLICY = "/downloads/ltd_privacy_policy",
    LICENSE_AGREEMENT = "/downloads/media_user_licence",
    REGISTRATION_DISCLOURSE = "/downloads/event_registration_disclosure",
}

export interface AppInfoProps extends InternalStandardProps<BoxProps> {}

export const AppInfo = (props: AppInfoProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const { homeStack } = useGlobalNavStack();
    const history = useHistory();
    const { linkProvider } = useRouting();
    useSeamlessUpdate();

    const handleNavigate = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        key: string,
    ) => {
        const baseUrl = config.feedbackBaseApiUrl;
        switch (key) {
            case AppInfoTypes.TERMS_AND_CONDTIONS:
                window.open(
                    baseUrl + AppInfoEndpoints.TERMS_AND_CONDTIONS,
                    "_self",
                );

                break;
            case AppInfoTypes.PRIVACY_POLICY:
                window.open(baseUrl + AppInfoEndpoints.PRIVACY_POLICY, "_self");
                break;
            case AppInfoTypes.LICENSE_AGREEMENT:
                window.open(
                    baseUrl + AppInfoEndpoints.LICENSE_AGREEMENT,
                    "_self",
                );
                break;
            case AppInfoTypes.EVENT_REGISTRATION_DISCLOURSE:
                window.open(
                    baseUrl + AppInfoEndpoints.REGISTRATION_DISCLOURSE,
                    "_self",
                );
                break;
            default:
                break;
        }
    };

    return (
        <Layout>
            <Box
                {...rest}
                className={clsx(className, classes.root)}
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
            >
                <AppHeader
                    title={"App Info"}
                    canGoBack
                    searchable={false}
                    onBackClick={() => {
                        if (!homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.more().index());
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                <List className={classes.list}>
                    {Object.keys(AppInfoTypes).map((key, idx) => (
                        <Fragment key={`${idx}-${key}`}>
                            <ListItem
                                className={classes.listItem}
                                button
                                onClick={(e) =>
                                    handleNavigate(e, AppInfoTypes[key])
                                }
                            >
                                <AppTypography
                                    className={clsx(
                                        classes.grey600,
                                        classes.ListTitle,
                                    )}
                                    variant="h6"
                                >
                                    {AppInfoTypes[key]}
                                </AppTypography>
                                <Box className={classes.ListIconsWrapper}>
                                    <FontAwesomeIcon
                                        className={classes.forwardIcon}
                                        icon={["fas", "chevron-right"]}
                                    />
                                </Box>
                            </ListItem>
                            <AppDivider />
                        </Fragment>
                    ))}
                </List>
            </Box>
        </Layout>
    );
};
