import AWS from "aws-sdk";
import { config } from "JS/Config";
import { GetObjectRequest } from "aws-sdk/clients/s3";

export class LandingPageHelper {
    public bucket = config.s3.landingPage.bucketName;

    public getS3(): AWS.S3 {
        return new AWS.S3();
    }

    public initConfig() {
        AWS.config.update({
            credentials: {
                accessKeyId: config.s3.accessKey,
                secretAccessKey: config.s3.secretAccessKey,
            },
            region: config.s3.region,
        });
    }

    public getLandingPageJson(
        filePath: string,
    ): Promise<AWS.S3.GetObjectOutput> {
        return new Promise((res, _) => {
            this.getS3().getObject(
                this.jsonFileParams(filePath),
                (err, data) => {
                    if (err) res(null);
                    res(data);
                },
            );
        });
    }

    public jsonFileParams(filePath: string): GetObjectRequest {
        return {
            Bucket: this.bucket,
            Key: filePath,
            ResponseCacheControl: 'no-cache',
        };
    }
}
