import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { AppTypography } from "JS/React/Components/AppTypography";
import { AppButton } from "JS/React/Components/AppButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: QuizFooterProps) => ({
            background:
                theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
            padding: theme.spacing(2, 4),
            minHeight: props.increaseHeight ? "250px" : "150px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }),
        btn: {
            background:
                theme.palette.mode === "light"
                    ? theme.colors.white
                    : theme.palette.grey[900],
            color: theme.palette.grey[600],
        },
        subTitle: {
            margin: theme.spacing(2, 0),
        },
        subTitleClamp: {
            display: "-webkit-box",
            "-webkit-line-clamp": 3,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    }),
);

export interface QuizFooterProps extends InternalStandardProps<BoxProps> {
    title?: string;
    subTitle?: string;
    btnText?: string;
    disableBtn?: boolean;
    onBtnClick?: () => void;
    increaseHeight?: boolean;
}

export const QuizFooter = (props: QuizFooterProps) => {
    const classes = useStyles(props);
    const {
        className,
        title,
        subTitle,
        btnText,
        disableBtn,
        onBtnClick = () => {},
        increaseHeight,
        ...rest
    } = props;

    return (
        <Box {...rest} className={clsx(className, classes.root)}>
            {title && (
                <AppTypography
                    fontWeight={"bold"}
                    variant="h5"
                    textColor="grey-a100"
                >
                    {title}
                </AppTypography>
            )}

            {subTitle && (
                <AppTypography
                    className={clsx(
                        classes.subTitle,
                        increaseHeight && classes.subTitleClamp,
                    )}
                    variant="h6"
                    textColor="grey-a100"
                >
                    {subTitle}
                </AppTypography>
            )}

            {btnText && (
                <Box textAlign="center">
                    <AppButton
                        disabled={disableBtn}
                        onClick={onBtnClick}
                        className={classes.btn}
                        buttonVariant="grey-outlined"
                        rounded
                    >
                        {btnText}
                    </AppButton>
                </Box>
            )}
        </Box>
    );
};
