import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    IconButton,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { Videocam as VideocamIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppHeader } from "JS/React/Components/AppHeader";
import { AppButton } from "JS/React/Components/AppButton";
import { AppCompletionProgress } from "JS/React/Components/AppCompletionProgress";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";
import { AppTypography } from "JS/React/Components/AppTypography";
import { calculatePercentage, createCourseIDForStats } from "JS/Helpers";
import {
    useDeleteCourse,
    useDownloadedCourses,
    useGetLatestCourseStats,
    useWriteOfflineCourseStats,
} from "JS/React/Hooks/DownloadCourses";
import { useRouting } from "JS/React/Hooks/Routes";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useScrollToTop } from "JS/React/Hooks/Media";
import { useReceivedCourses } from "JS/React/Hooks/Course/Course";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import {
    CourseStep,
    DownloadedCourseDetail,
    EventActions,
    EventCategories,
    EventNames,
} from "JS/Models";
import { SentGiftType, toSentGift } from "JS/Models/Firebase/GiftDashboard";
import {
    AskConfirmationDialog,
    AskConfirmationDialogRenderProps,
} from "JS/React/Components/AskConfirmationDialog";
import { messaging } from "JS/Helpers/UserMessaging";
import { StepsListItem } from "../../Courses/StepsListItem";
import qs from "qs";
export const OfflineCourseDetail = (props: OfflineCourseDetailProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const [showDesc, setShowDesc] = useState(false);

    const {
        activeCourse,
        firebaseCourseStats,
        offlineCourseStats,
        progressVal,
        completedSteps,
    } = useActiveCourse();

    const {
        sortedSteps,
        goBack,
        handleStartResume,
        handleStepItemClick,
        handleDeleteIconClick,
    } = useHandlers(activeCourse, completedSteps);
    const { scrollTo } = useQueryParams();
    const { deleteCompleteCourse } = useDeleteCourse();
    useScrollToTop();
    const confirmationPopupBtnText = "Delete";
    const confirmationPopupMainText = messaging.confirmation.courseDelete;

    const handleDeleteClick = (detail: DownloadedCourseDetail) => {
        deleteCompleteCourse(detail.nid)
            .then(() => {
                goBack(+scrollTo);
            })
            .catch((ex) => { });
    };

    return (
        <>
            <AppHeader
                title={activeCourse?.title}
                canGoBack
                searchable={false}
                onBackClick={() => goBack(+scrollTo)}
            />
            <Grid container className={clsx(classes.root, className)} {...rest}>
                {activeCourse && (
                    <>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                className={classes.greyA100}
                                fontWeight={"bold"}
                                variant="h6"
                            >
                                {activeCourse.title}
                            </Typography>
                        </Grid>
                        <Grid item className={classes.grey500} xs={12}>
                            <Typography variant="body1">
                                {activeCourse.sku_id}
                            </Typography>
                            <Box display="flex">
                                <Typography
                                    className={classes.release}
                                    variant="body1"
                                >
                                    Released:
                                </Typography>
                                <Typography variant="body1">
                                    {moment
                                        .unix(+activeCourse.releaseDate)
                                        .format("LL")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.playerContainer}>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                                className={classes.dummyImageBox}
                            >
                                <span className={classes.graphicWrapper}>
                                    <VideocamIcon
                                        className={classes.graphicIcon}
                                        sx={{
                                            fontSize: "60px",
                                        }}
                                    />
                                </span>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <AppCompletionProgress
                                loading={!firebaseCourseStats}
                                title={`Course Completion ${progressVal <= 100
                                    ? progressVal.toFixed(0)
                                    : 100
                                    }%`}
                                value={progressVal <= 100 ? progressVal : 100}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box
                                className={classes.iconsWrapper}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <div>
                                    <AskConfirmationDialog
                                        popupMainText={
                                            confirmationPopupMainText
                                        }
                                        confirmText={confirmationPopupBtnText}
                                        cancelButtonText={"Cancel"}
                                        onConfirm={() =>
                                            handleDeleteClick(activeCourse)
                                        }
                                    >
                                        {(renderProps) => {
                                            return (
                                                <IconButton
                                                    onClick={() =>
                                                        handleDeleteIconClick(
                                                            renderProps,
                                                        )
                                                    }
                                                >
                                                    <AppDownloadingIcons iconType="trash" />
                                                </IconButton>
                                            );
                                        }}
                                    </AskConfirmationDialog>
                                </div>
                                <>
                                    <div>
                                        {progressVal >= 100 ? (
                                            <Box display="flex">
                                                <FontAwesomeIcon
                                                    style={{
                                                        fontSize: "18px",
                                                        marginRight: "8px",
                                                    }}
                                                    className={classes.grey600}
                                                    icon={[
                                                        "fas",
                                                        "circle-check",
                                                    ]}
                                                />
                                                <AppTypography
                                                    className={
                                                        classes.completionText
                                                    }
                                                    variant="body1"
                                                >
                                                    Completed
                                                </AppTypography>
                                            </Box>
                                        ) : (
                                            <>
                                                {!!activeCourse?.displayMode && (
                                                    <AppButton
                                                        rounded
                                                        buttonVariant="grey-outlined"
                                                        onClick={
                                                            handleStartResume
                                                        }
                                                    >
                                                        {progressVal <= 0
                                                            ? "Start"
                                                            : "Resume"}
                                                    </AppButton>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </>

                                <Box display={"flex"}>
                                    <IconButton
                                        onClick={() => setShowDesc(!showDesc)}
                                    >
                                        <FontAwesomeIcon
                                            style={{
                                                fontSize: "22px",
                                            }}
                                            className={classes.grey600}
                                            icon={["fas", "circle-info"]}
                                        />
                                    </IconButton>
                                    &nbsp;
                                </Box>
                            </Box>
                        </Grid>
                        {!showDesc &&
                            firebaseCourseStats &&
                            sortedSteps.map((d, idx) => (
                                <Grid item xs={12} key={idx}>
                                    <StepsListItem
                                        isResume={
                                            offlineCourseStats?.steps &&
                                            offlineCourseStats?.steps[d.sku_id]
                                                ?.analytics?.last_played > 0
                                        }
                                        isDisabled={
                                            activeCourse.isSequenceOn &&
                                            idx > completedSteps
                                        }
                                        isQuiz={
                                            d.content_item_type_name === "quiz"
                                        }
                                        isCompleted={
                                            offlineCourseStats?.steps &&
                                            offlineCourseStats?.steps[d.sku_id]
                                                ?.analytics?.completed_count > 0
                                        }
                                        stepThumbnail={""}
                                        step={d}
                                        isLocked={false}
                                        isAllowClick
                                        onListItemClick={() =>
                                            handleStepItemClick(d, "false")
                                        }
                                        onPlayIconClick={() =>
                                            handleStepItemClick(d, "false")
                                        }
                                    />
                                </Grid>
                            ))}

                        {showDesc && (
                            <Grid item xs={12}>
                                <AppTypography
                                    className={classes.grey600}
                                    variant="body1"
                                >
                                    {activeCourse.description}
                                </AppTypography>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};

const useActiveCourse = () => {
    const params: any = useParams();
    const { id: contentId } = params;

    const { userDownloadedCourses, firebaseCourseStats } =
        useDownloadedCourses();

    const activeCourse = useMemo(() => {
        return userDownloadedCourses?.find((c) => c.nid === contentId);
    }, [userDownloadedCourses, contentId]);

    const memeberCourseSkuId = createCourseIDForStats(activeCourse?.sku_id);

    const offlineCourseStats =
        firebaseCourseStats && firebaseCourseStats[memeberCourseSkuId];

    const progressVal = calculatePercentage(
        offlineCourseStats?.analytics?.completed_steps,
        offlineCourseStats?.analytics?.total_steps,
    );

    const completedSteps = offlineCourseStats?.analytics
        ? offlineCourseStats.analytics.completed_steps
        : 0;

    return {
        activeCourse,
        offlineCourseStats,
        firebaseCourseStats,
        progressVal,
        completedSteps,
    };
};

const useHandlers = (
    activeCourse: DownloadedCourseDetail,
    completedSteps: number,
) => {
    const { writeOfflineFBCourseStats } = useWriteOfflineCourseStats();
    const { getLatestCourseStats } = useGetLatestCourseStats();
    const { sortedSteps, nextStep } = useVariables(
        activeCourse,
        completedSteps,
    );
    const { logFirebaseEvent } = useFirebaseLogger();

    const history = useHistory();
    const { linkProvider } = useRouting();

    const { receivedCourses } = useReceivedCourses(true);

    const activeReceivedCourse = useMemo(() => {
        return receivedCourses?.find((c) => c.nid === activeCourse?.nid);
    }, [receivedCourses, activeCourse]);

    const { logGiftOpen } = useGiftDashboard(
        toSentGift(activeReceivedCourse),
        SentGiftType.COURSE,
        activeReceivedCourse?.sender?.member_id,
        false,
        null,
        true,
    );

    const onCourseOpen = async () => {
        const latestCourseStats = getLatestCourseStats(activeCourse?.sku_id);

        if (latestCourseStats) {
            writeOfflineFBCourseStats(activeCourse?.sku_id, {
                ...latestCourseStats,
                analytics: {
                    ...latestCourseStats?.analytics,
                    last_opened: moment().valueOf(),
                    opened_count: latestCourseStats.analytics.opened_count + 1,
                },
            });
        }

        if (activeCourse) {
            logFirebaseEvent(EventNames.COURSE_OPENED, {
                action: EventActions.OPEN,
                contentTitle: activeCourse.title,
                nId: activeCourse.nid,
                skuId: activeCourse.sku_id,
                category: EventCategories.COURSE,
            });
        }
    };
    const goBack = (scrollTo?: number) => {
        history.push(linkProvider.react.offline().courses().index({ scrollTo: scrollTo ?? 0 }));
    };

    const handleStartResume = () => {
        if (nextStep) {
            history.push(
                linkProvider.react
                    .offline()
                    .courses()
                    .mediaStepDetail(
                        activeCourse.sku_id,
                        nextStep.sku_id,
                        "true",
                        activeCourse?.displayMode,
                    ),
            );
        }
    };

    const handleStepItemClick = (d: CourseStep, isAutoPlay: string) =>
        history.push(
            linkProvider.react
                .offline()
                .courses()
                .mediaStepDetail(
                    activeCourse.sku_id,
                    d.sku_id,
                    isAutoPlay,
                    activeCourse?.displayMode,
                ),
        );

    useEffect(() => {
        if (activeCourse) {
            onCourseOpen();
        }
    }, [activeCourse]);

    useEffect(() => {
        if (activeReceivedCourse) {
            logGiftOpen();
        }
    }, [activeReceivedCourse]);

    useEffect(() => {
        if (!activeCourse) {
            goBack();
        }
    }, [activeCourse]);

    const handleDeleteIconClick = (
        renderProps: AskConfirmationDialogRenderProps,
    ) => {
        renderProps.askConfirmation();
    };

    return {
        sortedSteps,
        handleStartResume,
        goBack,
        handleStepItemClick,
        handleDeleteIconClick,
    };
};

const useVariables = (
    activeCourse: DownloadedCourseDetail,
    completedSteps: number,
) => {
    const sortedSteps = activeCourse
        ? activeCourse?.steps
            .slice()
            .sort((a, b) => +a.position - +b.position)
            .filter((x) => x?.isDownloaded)
        : [];

    const nextStep = sortedSteps?.find((d) => +d.position === completedSteps);
    return {
        sortedSteps,
        nextStep,
    };
};
const useQueryParams = () => {
    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const {
            scrollTo = "0"
        } = parsedQueryString;

        return {
            scrollTo: scrollTo
        };
    }, [location?.search]);

    return queryParams;
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },

        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            background: theme.palette.grey[900],
            height: "100%",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        release: {
            marginRight: theme.spacing(1),
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        iconsWrapper: {
            margin: theme.spacing(1.5, 0),
        },
        giftIconButtonDisabled: {
            opacity: "50%",
            cursor: "not-allowed !important",
        },
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        completionText: {
            color: theme.palette.grey[600],
            textAlign: "center",
            fontWeight: 500,
        },
    }),
);

export interface OfflineCourseDetailProps
    extends InternalStandardProps<GridProps> { }
