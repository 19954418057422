import { config } from "JS/Config";
import { UserTeamInfo } from "JS/Models";

export const isSubscribed = (
    includedSubscriptions: string[],
    teamInfo: UserTeamInfo,
) =>
    includedSubscriptions === undefined ||
    teamInfo?.activeSubscriptions === undefined ||
    teamInfo?.activeSubscriptions?.some((sub) =>
        includedSubscriptions
            ?.map((s) => s.toLowerCase())
            ?.includes(sub.subscriptionName.toLowerCase()),
    );

export const openExternalLink = (
    url: string,
    isConsentForm: boolean = false,
) => {
    if (url)
        window.open(
            url,
            isConsentForm ? "_self" : "_blank",
            "noopener,noreferrer",
        );
};

export const openSSOLink = (place: string, isConsentForm: boolean) => {
    if (place) {
        const url = `${config.baseApiUrl}/sso/redirect/${config.accessToken}/${place}/LTD`;
        openExternalLink(url, isConsentForm);
    }
};
