import { useState, useCallback, useEffect } from "react";
import { DocumentDetails } from "JS/Models";
import { DocumentsService } from "JS/Services/Documents";
import { config } from "JS/Config";
import { getSignedPublicUrl } from "JS/Helpers";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    setGlobalDocumentsFavorites,
    setGlobalDocuments,
} from "JS/Redux/Documents";
import { useSnackbar } from "notistack";
import { useVerifyAwsKeys } from "./Media";
import { messaging } from "JS/Helpers/UserMessaging";

export const service = new DocumentsService();

export const useDocumentsDetails = () => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [docDetails, setDocDetails] = useState<DocumentDetails[]>(null);
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const showError = () => {
        enqueueSnackbar(messaging.common.error, {
            variant: "error",
        });
    };

    const refetch = useCallback(() => {
        setLoading(true);
        verifyAwsKeys()
            .then(() => {
                service
                    .getDocumentDetails()
                    .then((res) => {
                        const sorted = res.sort((a, b) =>
                            a.Description.localeCompare(b.Description),
                        );
                        setDocDetails(sorted);
                        dispatch(setGlobalDocuments(sorted));
                    })
                    .catch(() => showError())
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch(() => {
                showError();
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        refetch();
    }, [refetch]);

    return {
        refetch,
        docDetails,
        loading,
    };
};

export const useDocumentsFavoritesDetails = () => {
    const { documentUserFavorites } = useGlobalDocuments();
    const [loading, setLoading] = useState(false);
    const [docDetails, setDocDetails] = useState<DocumentDetails[]>(null);

    const refetch = useCallback(() => {
        setLoading(true);
        service
            .getDocumentDetails()
            .then((res) => {
                let filteredFavoritesDoc = res?.filter((x) =>
                    documentUserFavorites?.some((z) => z.Name === x.Name),
                );
                const sortedDocuments = filteredFavoritesDoc?.sort((a, b) =>
                    a.Description.localeCompare(b.Description),
                );
                setDocDetails(sortedDocuments);
            })
            .catch((err) => {
                console.log({ err });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        refetch();
    }, [refetch]);

    return {
        refetch,
        docDetails,
        loading,
    };
};

export const useDocumentPdf = (pdfName: string) => {
    const [loading, setLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState<string>(null);

    const refetch = useCallback((name: string) => {
        setLoading(true);
        const docsConfig = config.s3.documents;
        getSignedPublicUrl(`${docsConfig.baseUrl}/${name}`)
            .then((url) => setPdfUrl(url))
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (pdfName) {
            refetch(pdfName);
        }
    }, [refetch, pdfName]);

    return {
        refetch,
        pdfUrl,
        loading,
    };
};

export const useGlobalDocuments = () => {
    const dispatch = useAppDispatch();
    const favDocuments = useAppSelector(
        (state) => state.documents,
    )?.documentFavorites;
    const documentFavorites = favDocuments ? favDocuments : {};
    const documentUserFavorites =
        documentFavorites && documentFavorites[`${config?.user?.memberId}`]
            ? documentFavorites[`${config?.user?.memberId}`]
            : [];

    const downloadedContentNids = useAppSelector(
        (state) => state?.download?.downloadedContentNids,
    );

    const allDocuments = useAppSelector(
        (state) => state?.documents?.documentsList,
    );

    return {
        documentFavorites,
        documentUserFavorites,
        markDocumentsFavorites: (documentsFavorite: DocumentDetails) => {
            const currentUserFavorites: DocumentDetails[] = documentFavorites[
                config.user.memberId
            ]
                ? documentFavorites[config.user.memberId]
                : [];
            const newMap = { ...documentFavorites };
            const newDocFavorites = [...currentUserFavorites]?.filter(
                (c) => c.Name !== documentsFavorite.Name,
            );
            newDocFavorites.push(documentsFavorite);
            newMap[config.user.memberId] = newDocFavorites;

            dispatch(setGlobalDocumentsFavorites(newMap));
        },
        unMarkDocumentsFavorites: (documentsFavorite: DocumentDetails) => {
            const currentUserFavorites: DocumentDetails[] = documentFavorites[
                config.user.memberId
            ]
                ? documentFavorites[config.user.memberId]
                : [];
            const newMap = { ...documentFavorites };
            const userSpecificDocs = [...currentUserFavorites];
            userSpecificDocs.findIndex(
                (x) => x.Name === documentsFavorite?.Name,
            ) !== -1 &&
                userSpecificDocs.splice(
                    userSpecificDocs.findIndex(
                        (x) => x.Name === documentsFavorite?.Name,
                    ),
                    1,
                );
            newMap[config.user.memberId] = userSpecificDocs;

            dispatch(setGlobalDocumentsFavorites(newMap));
        },
        downloadedContentNids,
        allDocuments,
    };
};
