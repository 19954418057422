import { CourseDexie } from "JS/Database/Dexie";
import { useCallback, useEffect, useState } from "react";
import {
    bulkDeleteStepsByIds,
    bulkGetSteps,
    getStepById,
} from "JS/Database/Course";
import { messaging } from "JS/Helpers/UserMessaging";
import { useSnackbar } from "notistack";

export const useStepFromDb = (id?: string) => {
    const [loading, setLoading] = useState(false);
    const [downloadedContent, setDownloadedContent] = useState<CourseDexie>();

    const refetch = useCallback(async (id: string) => {
        setLoading(true);
        try {
            const course = await getStepById(id);
            setDownloadedContent(course);
            return course;
        } catch (err) {
            return null;
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (id) {
            refetch(id);
        }
    }, [id]);

    return {
        downloadedContent,
        loading,
        refetch,
    };
};

export const useBulkStepsFromDb = () => {
    const refetch = useCallback(async (ids: string[]) => {
        try {
            return await bulkGetSteps(ids);
        } catch (err) {
            return null;
        }
    }, []);

    return {
        refetch,
    };
};

export const useBulkDeleteSteps = () => {
    const [deleting, setDeleting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const deleteSteps = useCallback(async (ids: string[]) => {
        setDeleting(true);
        try {
            const delIds = await bulkDeleteStepsByIds(ids);
            enqueueSnackbar(messaging.download.deleteSuccess, {
                variant: "success",
            });
            return delIds;
        } catch (err) {
            enqueueSnackbar(messaging.download.deleteError, {
                variant: "error",
            });
            return null;
        } finally {
            setDeleting(false);
        }
    }, []);

    return { deleting, deleteSteps };
};
