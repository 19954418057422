import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { AppTypography } from "JS/React/Components/AppTypography";
import {
    CourseDetail,
    CourseStep,
    EventActions,
    EventCategories,
    EventNames,
    ReceivedCourse,
} from "JS/Models";
import clsx from "clsx";
import { Videocam as VideocamIcon } from "@mui/icons-material";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import {
    FBFunctionStepStats,
    FBStepStats,
    StepActions,
} from "JS/Models/Firebase/Courses";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CourseLogFirebaseEventSettings } from "./CourseStep";
import { messaging } from "JS/Helpers/UserMessaging";
import { useSnackbar } from "notistack";
import {
    useContentPlayingHandlers,
    usePlayerHandlers,
} from "JS/React/Hooks/MediaPlayer";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import { useMiniPlayer } from "JS/React/Hooks/MiniPlayer";
import { useMiniPlayerOpen } from "JS/React/Hooks/Course/MiniPlayerOpen";
import { PLAYER_PROGRESS_INTERVAL } from "JS/Helpers/Contants";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import {
    ActionDetails,
    SentGiftType,
    getPlayerPlayedTimeToLog,
    toSentGift,
} from "JS/Models/Firebase/GiftDashboard";
import { createStepObjectForFB } from "JS/Helpers";
import { useGlobalMediaEssentials } from "JS/React/Hooks/MediaEssentials";

export const MediaStep = (props: MediaStepProps) => {
    const mediaRef = useRef<any>(null);
    const playerRef = useRef<any>();
    const { handleChangeSeek } = usePlayerHandlers(playerRef);
    const classes = useStyles(props);
    const {
        className,
        step,
        mediaUrl,
        courseSku,
        stepSku,
        detail,
        imgUrl,
        isAutoPlay,
        currentPlayingState,
        receivedCourse,
        updateStepStats,
        onStepCompleted,
        onStepOpen,
        handleLogFirebaseEvents,
        setCurrentPlayingState,
        stepDetail,
        ...rest
    } = props;

    const { enqueueSnackbar } = useSnackbar();
    const [isReady, setIsReady] = useState(false);
    const [isReadyToPlay, setIsReadyToPlay] = useState(false);
    const { handlePlayerPause, handlePlayerPlay } = useContentPlayingHandlers(
        setCurrentPlayingState,
    );
    const { isBlob, isMediaPlaying } = useVariables(props);

    const { mediaEssentials } = useGlobalMediaEssentials();

    const getDashboardActionDetails = (): ActionDetails => {
        return {
            duration: getPlayerPlayedTimeToLog(playerRef).duration,
            stepSku: stepSku,
            stepName: step.title,
        };
    };

    useGiftDashboard(
        toSentGift(receivedCourse),
        SentGiftType.COURSE,
        receivedCourse?.sender?.member_id,
        !!receivedCourse && currentPlayingState,
        getDashboardActionDetails,
    );
    const onMediaPlayerReady = useCallback(async () => {
        setIsReadyToPlay(true);
        if (!isReady) {
            if (stepDetail) {
                playerRef?.current?.seekTo(
                    stepDetail.analytics?.last_played
                        ? stepDetail.analytics.last_played
                        : 0,
                    "seconds",
                );
                const d = playerRef?.current?.getDuration();
                const durationToLog = !!d && !isNaN(d) ? +d.toFixed(0) : 0;
                updateStepStats(
                    createStepObjectForFB(step, durationToLog),
                    StepActions.PlayerReady,
                );
            }
            setIsReady(true);
        }
    }, [isReady]);

    const onFirstPlay = async () => {
        mediaRef.current = true;
        if (stepDetail) {
            const d = playerRef?.current?.getDuration();
            const durationToLog = !!d && !isNaN(d) ? +d.toFixed(0) : 0;
            updateStepStats(
                createStepObjectForFB(step, durationToLog),
                StepActions.Play,
            );
        }

        if (detail.content_item_type_name === "Course") {
            handleLogFirebaseEvents("step", {
                action: EventActions.STARTED,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_STARTED,
            });
            handleLogFirebaseEvents("step", {
                action: isBlob ? EventActions.PLAY : EventActions.LIVE_STREAM,
                category: EventCategories.COURSE_STEP,
                eventNames: isBlob
                    ? EventNames.COURSE_STEP_PLAYED
                    : EventNames.COURSE_STEP_LIVESTREAM,
            });
        }

        if (detail.content_item_type_name === "GiftCourse") {
            handleLogFirebaseEvents("step", {
                action: EventActions.STARTED,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_STARTED,
            });
            handleLogFirebaseEvents("step", {
                action: isBlob ? EventActions.PLAY : EventActions.LIVE_STREAM,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: isBlob
                    ? EventNames.GIFT_COURSE_STEP_PLAYED
                    : EventNames.GIFT_COURSE_STEP_LIVESTREAM,
            });
        }
    };

    const onPlayerPause = () => {
        handlePlayerPause();
        if (
            detail.content_item_type_name === "Course" &&
            playerRef?.current.getCurrentTime() !==
                playerRef?.current.getDuration()
        ) {
            handleLogFirebaseEvents("step", {
                action: EventActions.PAUSED,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_PAUSED,
            });
        }

        if (
            detail.content_item_type_name === "GiftCourse" &&
            playerRef?.current.getCurrentTime() !==
                playerRef?.current.getDuration()
        ) {
            handleLogFirebaseEvents("step", {
                action: EventActions.PAUSED,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_PAUSED,
            });
        }
    };

    const onPlayerResume = () => {
        handlePlayerPlay();
        if (!mediaRef.current) {
            if (detail.content_item_type_name === "Course") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.RESUMED,
                    category: EventCategories.COURSE_STEP,
                    eventNames: EventNames.COURSE_STEP_RESUMED,
                });
            }
            if (detail.content_item_type_name === "GiftCourse") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.RESUMED,
                    category: EventCategories.GIFT_COURSE_STEP,
                    eventNames: EventNames.GIFT_COURSE_STEP_RESUMED,
                });
            }
        } else {
            mediaRef.current = false;
        }
    };

    const handleDownloadClick = (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
        enqueueSnackbar(messaging.common.featureComingSoon, {
            variant: "info",
        });
    };

    const onPlayerProgress = async () => {
        const t = playerRef?.current?.getCurrentTime();
        const playerTime = !!t && !isNaN(t) ? +t.toFixed(0) : 0;
        if (stepDetail && currentPlayingState) {
            updateStepStats(
                createStepObjectForFB(step, null, playerTime),
                StepActions.Progress,
            );
        }
    };

    useEffect(() => {
        onStepOpen();
    }, []);

    useEffect(() => {
        setIsReady(false);
        setIsReadyToPlay(false);
    }, [stepSku]);

    useMiniPlayerOpen();

    return (
        <Grid
            {...rest}
            container
            className={clsx(className, classes.containers)}
        >
            <Grid item xs={12} display="flex" justifyContent="space-between">
                <AppTypography
                    className={classes.greyA100}
                    fontWeight={"bold"}
                    variant="h6"
                >
                    {step.title}
                </AppTypography>
            </Grid>
            <Grid item className={classes.grey500} xs={12} display="flex">
                <AppTypography variant="body1">{step.sku_id}</AppTypography>
            </Grid>
            <Grid item xs={12} className={classes.playerContainer}>
                {!mediaUrl && (
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                        className={classes.dummyImageBox}
                    >
                        <span className={classes.graphicWrapper}>
                            <VideocamIcon
                                className={classes.graphicIcon}
                                sx={{
                                    fontSize: "60px",
                                }}
                            />
                        </span>
                    </Box>
                )}

                {mediaUrl && (
                    <MediaPlayer
                        title={step.title}
                        playerRef={playerRef}
                        isAudio={step.content_item_type_name === "MP3"}
                        mediaURL={mediaUrl}
                        imageURL={imgUrl}
                        playing={isMediaPlaying}
                        progressInterval={
                            mediaEssentials?.player_progress_interval_courses |
                            PLAYER_PROGRESS_INTERVAL
                        }
                        onStart={onFirstPlay}
                        onPause={onPlayerPause}
                        onPlay={onPlayerResume}
                        onEnded={onStepCompleted}
                        onReady={onMediaPlayerReady}
                        onProgress={onPlayerProgress}
                        loading={!isReadyToPlay}
                        onUpdateExpiredUrl={() => {
                            setIsReady(false);
                            setIsReadyToPlay(true);
                        }}
                        onError={() => {
                            setIsReadyToPlay(true);
                        }}
                    />
                )}
            </Grid>

            <Grid item xs={12}>
                <Grid container className={classes.iconsWrapper}>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <PlayerActionBtns handleChangeSeek={handleChangeSeek} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <AppTypography className={classes.grey600} variant="body1">
                    {step.description}
                </AppTypography>
            </Grid>
        </Grid>
    );
};

const useVariables = (props: MediaStepProps) => {
    const { mediaUrl, step, isAutoPlay, currentPlayingState } = props;

    const { miniPlayer } = useMiniPlayer();

    const isBlob = useMemo(() => {
        if (mediaUrl?.search("blob:") === 0) {
            return true;
        }

        return false;
    }, [mediaUrl]);

    const isMediaPlaying = useMemo(() => {
        if (step?.content_item_type_name === "MP3") {
            return isAutoPlay && !miniPlayer.url;
        } else {
            return isAutoPlay && currentPlayingState && !miniPlayer.url;
        }
    }, [
        step?.content_item_type_name,
        isAutoPlay,
        miniPlayer,
        currentPlayingState,
    ]);
    return {
        isBlob,
        isMediaPlaying,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        containers: {
            padding: theme.spacing(1),
        },
        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            background: theme.palette.grey[900],
            height: "100%",
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        iconsWrapper: {
            margin: theme.spacing(1.5, 0),
        },
        controlIcons: {
            minWidth: "40px",
            display: "flex",
        },
        playerIcon: {
            height: "35px",
        },
    }),
);

export interface MediaStepProps extends InternalStandardProps<GridProps> {
    step: CourseStep;
    mediaUrl: string;
    imgUrl: string;
    updateStepStats: (
        stats: Partial<FBFunctionStepStats>,
        action: StepActions,
    ) => Promise<void>;
    courseSku: string;
    stepSku: string;
    detail: CourseDetail;
    stepDetail: Partial<FBStepStats>;
    isAutoPlay: boolean;
    receivedCourse: ReceivedCourse;
    onStepCompleted: () => Promise<void>;
    onStepOpen: () => Promise<void>;
    handleLogFirebaseEvents: (
        type: "course" | "step" | "question",
        settings: CourseLogFirebaseEventSettings,
    ) => void;
    currentPlayingState?: boolean;
    setCurrentPlayingState?: React.Dispatch<React.SetStateAction<boolean>>;
}
