import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GiftNavStack, IHomeNavStack, INavStack } from "JS/Models/navStack";

export enum GiftCategories {
    PURCHASABLE_BY_MEMBERS = "purchase-gifts-for-members",
    PURCHASABLE_BY_PROSPECTS = "purchase-gifts-for-prospects",
    SEND_MEMBER = "send-member-gifts",
    RECEIVED_AS_MEMBER = "received-gifts-as-member",
    RECEIVED_AS_PROSPECT = "received-gifts-as-prospect",
    GIVEN = "gifts-given",
    GIVEN_CONTENT = "gifts-given",
}

export interface NavStackState {
    home?: IHomeNavStack;
    videos?: INavStack;
    audios?: INavStack;
    courses?: INavStack;
    events?: INavStack;
    webcasts?: INavStack;
    gifts?: GiftNavStack;
    more?: INavStack;
    favorites?: {
        favoritesAudios?: INavStack;
        favoritesVideos?: INavStack;
    };
}

const initialStackState: INavStack = {
    lastVisited: "",
    searchQuery: "",
    sorting: [
        {
            col: "title",
            dir: "asc",
            position: 0,
        },
    ],
};

const initialStackMoreState: INavStack = {
    lastVisited: "",
};
const initialStackHomeState: IHomeNavStack = {
    isHomeTrack: false,
    searchQuery: "",
};

const initialGiftStackState = {
    [GiftCategories.PURCHASABLE_BY_MEMBERS]: initialStackState,
    [GiftCategories.PURCHASABLE_BY_PROSPECTS]: initialStackState,
    [GiftCategories.RECEIVED_AS_MEMBER]: initialStackState,
    [GiftCategories.RECEIVED_AS_PROSPECT]: initialStackState,
    [GiftCategories.SEND_MEMBER]: initialStackState,
    [GiftCategories.GIVEN]: {
        ...initialStackState,
        ...{
            sorting: [
                {
                    col: "first_name",
                    dir: "asc",
                    position: 0,
                },
            ],
        },
    } as INavStack,
};

const initialFavoritesStackState = {
    favoritesAudios: initialStackState,
    favoritesVideos: initialStackState,
};

const initialState: NavStackState = {
    home: initialStackHomeState,
    videos: initialStackState,
    audios: initialStackState,
    courses: initialStackState,
    gifts: initialGiftStackState,
    more: initialStackMoreState,
    favorites: initialFavoritesStackState,
};

export const navStackSlice = createSlice({
    name: "navStackState",
    initialState,
    reducers: {
        resetHomeNavStack: (state) => {
            state = {
                ...state,
                home: initialStackState,
            };
            return state;
        },
        setGlobalVideosNavStack: (state, data: PayloadAction<INavStack>) => {
            state = { ...state, videos: { ...state.videos, ...data.payload } };
            return state;
        },
        resetVideosNavStack: (state) => {
            state = {
                ...state,
                videos: initialStackState,
            };
            return state;
        },
        setGlobalAudiosNavStack: (state, data: PayloadAction<INavStack>) => {
            state = { ...state, audios: { ...state.audios, ...data.payload } };
            return state;
        },
        resetAudiosNavStack: (state) => {
            state = {
                ...state,
                audios: initialStackState,
            };
            return state;
        },
        setGlobalCoursesNavStack: (state, data: PayloadAction<INavStack>) => {
            state = {
                ...state,
                courses: { ...state.courses, ...data.payload },
            };
            return state;
        },
        setGlobalFavoritesAudiosNavStack: (
            state,
            data: PayloadAction<INavStack>,
        ) => {
            state = {
                ...state,
                favorites: {
                    ...state?.favorites,
                    favoritesAudios: {
                        ...state?.favorites?.favoritesAudios,
                        ...data.payload,
                    },
                },
            };
            return state;
        },
        setGlobalFavoritesVideosNavStack: (
            state,
            data: PayloadAction<INavStack>,
        ) => {
            state = {
                ...state,
                favorites: {
                    ...state?.favorites,
                    favoritesVideos: {
                        ...state?.favorites?.favoritesVideos,
                        ...data.payload,
                    },
                },
            };
            return state;
        },
        resetCoursesNavStack: (state) => {
            state = {
                ...state,
                courses: initialStackState,
            };
            return state;
        },
        resetFavoritesNavStack: (state) => {
            state = {
                ...state,
                favorites: initialFavoritesStackState,
            };
            return state;
        },
        resetFavoritesAudiosNavStack: (state) => {
            state = {
                ...state,
                favorites: {
                    ...state?.favorites,
                    favoritesAudios: initialStackState,
                },
            };
            return state;
        },
        resetFavoritesVideosNavStack: (state) => {
            state = {
                ...state,
                favorites: {
                    ...state?.favorites,
                    favoritesVideos: initialStackState,
                },
            };
            return state;
        },
        setGlobalGiftsCategoryStack: (
            state,
            data: PayloadAction<{
                payload: Omit<INavStack, "lastVisited">;
                categoryId: string;
            }>,
        ) => {
            const tempStates = {
                [data.payload.categoryId]: {
                    ...state.gifts[`${data.payload.categoryId}`],
                    ...data.payload.payload,
                },
            };
            state = { ...state, gifts: { ...state.gifts, ...tempStates } };
            return state;
        },
        resetGiftsCategoryStack: (
            state,
            data: PayloadAction<{
                categoryId: string;
            }>,
        ) => {
            let tempStates = {};
            if (data.payload.categoryId === GiftCategories.GIVEN) {
                tempStates = {
                    [data.payload.categoryId]: {
                        ...initialStackState,
                        ...{
                            sorting: [
                                {
                                    col: "first_name",
                                    dir: "asc",
                                    position: 0,
                                },
                            ],
                        },
                    },
                };
            } else {
                tempStates = {
                    [data.payload.categoryId]: initialStackState,
                };
            }
            state = { ...state, gifts: { ...state.gifts, ...tempStates } };
            return state;
        },
        setGlobalMoreNavStack: (state, data: PayloadAction<INavStack>) => {
            state = { ...state, more: { ...state.more, ...data.payload } };
            return state;
        },
        setGlobalHomeNavStack: (state, data: PayloadAction<IHomeNavStack>) => {
            state = { ...state, home: { ...state.home, ...data.payload } };
            return state;
        },
        setGlobalEventsNavStack: (state, data: PayloadAction<INavStack>) => {
            state = { ...state, events: { ...state.events, ...data.payload } };
            return state;
        },
        setGlobalWebCastsNavStack: (state, data: PayloadAction<INavStack>) => {
            state = {
                ...state,
                webcasts: { ...state.webcasts, ...data.payload },
            };
            return state;
        },
        resetNavStack: () => {
            return initialState;
        },
    },
});
export const {
    setGlobalVideosNavStack,
    setGlobalAudiosNavStack,
    setGlobalCoursesNavStack,
    setGlobalFavoritesAudiosNavStack,
    setGlobalFavoritesVideosNavStack,
    setGlobalGiftsCategoryStack,
    setGlobalMoreNavStack,
    setGlobalHomeNavStack,
    setGlobalEventsNavStack,
    setGlobalWebCastsNavStack,
    resetHomeNavStack,
    resetNavStack,
    resetVideosNavStack,
    resetAudiosNavStack,
    resetCoursesNavStack,
    resetGiftsCategoryStack,
    resetFavoritesNavStack,
    resetFavoritesVideosNavStack,
    resetFavoritesAudiosNavStack,
} = navStackSlice.actions;
