import { IHomeNavStack, INavStack } from "JS/Models/navStack";
import {
    setGlobalFavoritesVideosNavStack,
    resetAudiosNavStack,
    resetCoursesNavStack,
    resetGiftsCategoryStack,
    resetVideosNavStack,
    setGlobalAudiosNavStack,
    setGlobalCoursesNavStack,
    setGlobalEventsNavStack,
    setGlobalGiftsCategoryStack,
    setGlobalHomeNavStack,
    setGlobalMoreNavStack,
    setGlobalVideosNavStack,
    setGlobalWebCastsNavStack,
    resetFavoritesNavStack,
    resetFavoritesAudiosNavStack,
    resetFavoritesVideosNavStack,
    setGlobalFavoritesAudiosNavStack,
} from "JS/Redux/NavStack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";

export const useGlobalNavStack = () => {
    const dispatch = useAppDispatch();

    return {
        homeStack: useAppSelector((state) => state.navStack)?.home,
        videosStack: useAppSelector((state) => state.navStack)?.videos,
        audiosStack: useAppSelector((state) => state.navStack)?.audios,
        coursesStack: useAppSelector((state) => state.navStack)?.courses,
        giftsStack: useAppSelector((state) => state.navStack)?.gifts,
        moreStack: useAppSelector((state) => state.navStack)?.more,
        eventsStack: useAppSelector((state) => state.navStack)?.events,
        webcastsStack: useAppSelector((state) => state.navStack)?.webcasts,
        favoritesAudiosStack: useAppSelector((state) => state.navStack)
            ?.favorites?.favoritesAudios,
        favoritesVideosStack: useAppSelector((state) => state.navStack)
            ?.favorites?.favoritesVideos,

        resetGlobalHomeStack: () => {
            dispatch(resetAudiosNavStack());
        },
        setGlobalVideosStack: (payload: INavStack) => {
            dispatch(setGlobalVideosNavStack(payload));
        },
        resetGlobalVideosStack: () => {
            dispatch(resetVideosNavStack());
        },
        setGlobalAudiosStack: (payload: INavStack) => {
            dispatch(setGlobalAudiosNavStack(payload));
        },
        resetGlobalAudiosStack: () => {
            dispatch(resetAudiosNavStack());
        },
        setGlobalCoursesStack: (payload: INavStack) => {
            dispatch(setGlobalCoursesNavStack(payload));
        },
        resetGlobalCoursesStack: () => {
            dispatch(resetCoursesNavStack());
        },
        setGlobalGiftsCategoryStack: (
            payload: Omit<INavStack, "lastVisited">,
            categoryId: string,
        ) => {
            dispatch(setGlobalGiftsCategoryStack({ payload, categoryId }));
        },
        resetGlobalGiftsCategoryStack: (categoryId: string) => {
            dispatch(resetGiftsCategoryStack({ categoryId }));
        },
        setGlobalMoreStack: (payload: INavStack) => {
            dispatch(setGlobalMoreNavStack(payload));
        },
        setGlobalHomeStack: (payload: IHomeNavStack) => {
            dispatch(setGlobalHomeNavStack(payload));
        },
        setGlobalEventStack: (payload: INavStack) => {
            dispatch(setGlobalEventsNavStack(payload));
        },
        setGlobalWebCastsStack: (payload: INavStack) => {
            dispatch(setGlobalWebCastsNavStack(payload));
        },
        setGlobalFavoritesAudiosStack: (payload: INavStack) => {
            dispatch(setGlobalFavoritesAudiosNavStack(payload));
        },
        setGlobalFavoritesVideosStack: (payload: INavStack) => {
            dispatch(setGlobalFavoritesVideosNavStack(payload));
        },
        resetFavoritesStack: () => {
            dispatch(resetFavoritesNavStack());
        },
        resetFavoritesAudiosStack: () => {
            dispatch(resetFavoritesAudiosNavStack());
        },
        resetFavoritesVideosStack: () => {
            dispatch(resetFavoritesVideosNavStack());
        },
    };
};
