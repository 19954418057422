import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Dialog,
    DialogContent,
    DialogProps,
    Grid,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { AppButton } from "../AppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        content: {
            display: "flex",
            minHeight: "250px",
            justifyContent: "flex-start",
        },
        camWrapper: {
            display: "flex",
            justifyContent: "center",
            marginBottom: `${theme.spacing(2)}`,
        },
        camStyling: { overflow: "hidden" },
        camRotateIcon: {
            height: "40px",
            cursor: "pointer",
        },
        capturedImage: {
            objectFit: "contain",
        },
    }),
);

export interface WebCamImageDailogProps
    extends InternalStandardProps<DialogProps> {
    onClose: () => void;
    setCapturedImage: (capturedImage: any) => void;
    capturedImage: any;
    onUploadCaptureImage: () => void;
}

export const WebCamImageDailog = (props: WebCamImageDailogProps) => {
    const classes = useStyles(props);

    const {
        className,
        capturedImage,
        setCapturedImage,
        onUploadCaptureImage,
        onClose,
        ...rest
    } = props;

    const [videoConstraints, setVideoConstraints] = useState<{
        facingMode: string | { exact: string };
    }>({
        facingMode: "user",
    });

    const webcamRef = useRef(null);
    const capturePhoto = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setCapturedImage(imageSrc);
    }, [webcamRef]);

    const handleToggleCam = () => {
        if (videoConstraints?.facingMode === "user") {
            setVideoConstraints({
                ...videoConstraints,
                facingMode: { exact: "environment" },
            });
        } else {
            setVideoConstraints({
                ...videoConstraints,
                facingMode: "user",
            });
        }
    };

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            className={clsx(className, classes.root)}
            {...rest}>
            <DialogContent>
                <Grid container className={classes.content}>
                    {!capturedImage ? (
                        <>
                            <Grid
                                item
                                xs={12}
                                className={clsx(
                                    classes.camWrapper,
                                    classes.camStyling,
                                )}>
                                <Webcam
                                    ref={webcamRef}
                                    audio={false}
                                    screenshotFormat="image/jpeg"
                                    width={"100%"}
                                    videoConstraints={videoConstraints}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.camWrapper}>
                                <AppButton
                                    buttonVariant="grey-text"
                                    onClick={handleToggleCam}>
                                    <FontAwesomeIcon
                                        className={classes.camRotateIcon}
                                        icon={["fas", "camera-rotate"]}
                                    />
                                </AppButton>
                                <AppButton
                                    buttonVariant="grey-outlined"
                                    rounded
                                    onClick={capturePhoto}>
                                    Capture
                                </AppButton>
                                &nbsp;&nbsp;
                                <AppButton
                                    buttonVariant="grey-outlined"
                                    rounded
                                    onClick={onClose}>
                                    Cancel
                                </AppButton>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                xs={12}
                                className={clsx(
                                    classes.camWrapper,
                                    classes.camStyling,
                                )}>
                                <img
                                    className={classes.capturedImage}
                                    src={capturedImage}
                                    alt="Not-Found"
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.camWrapper}>
                                <AppButton
                                    buttonVariant="grey-outlined"
                                    rounded
                                    onClick={() => setCapturedImage(null)}>
                                    Retake
                                </AppButton>
                                &nbsp;&nbsp;
                                <AppButton
                                    buttonVariant="grey-outlined"
                                    rounded
                                    onClick={onUploadCaptureImage}>
                                    Ok
                                </AppButton>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
