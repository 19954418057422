import { Layout } from "../Layout";
import { Box, Grid, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { validateDYSLogin } from "JS/Services/User";
import { checkStateValidity, getFieldValidityState } from "JS/Types";
import { AppStandardTextField } from "JS/React/Components/TextFields/AppStandardTextField";
import { AppButton } from "JS/React/Components/AppButton";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import {
    useReceivedProspectAudioGifts,
    useSyncDYS,
} from "JS/React/Hooks/Gifts";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { ILogin, AllTabs } from "JS/Models";
import { GiftCategories } from ".";
import { AppTypography } from "JS/React/Components/AppTypography";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import cloudicon from "Images/ic_cloud_sync.png";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        syncIcon: {
            height: "125px",
            width: "125px",
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        root: {
            height: `calc(100vh - 1.1 * ${theme.footer.height})`,
        },
    }),
);

export interface SyncDYSProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const SyncDYS = (props: SyncDYSProps) => {
    const classes = useStyles(props);
    const [user, setUser] = useState<ILogin>({
        userName: "",
        password: "",
    });
    const { signingIn, success, error, signIn } = useSyncDYS();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const [enabledValidations, setEnableValidations] = useState(false);
    const localValidityState = useMemo(() => {
        if (enabledValidations) return validateDYSLogin(user);
        else return [];
    }, [user, enabledValidations]);
    const isLoginValid = useMemo(
        () => checkStateValidity(validateDYSLogin(user)),
        [user],
    );
    const handleLogin = async (e) => {
        e.preventDefault();

        if (!enabledValidations) setEnableValidations(true);

        if (isLoginValid) signIn(user.userName, user.password);
    };
    const onPressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") handleLogin(e);
    };

    useEffect(() => {
        if (error)
            enqueueSnackbar("No such prospect exists.", {
                variant: "error",
            });
    }, [error]);

    const openListing = () => {
        history.replace(
            linkProvider.react
                .gifts()
                .listing(GiftCategories.RECEIVED_AS_PROSPECT, AllTabs.AUDIOS),
        );
    };

    useEffect(() => {
        if (success) openListing();
    }, [success]);

    const { loading, isSynced } = useReceivedProspectAudioGifts(false);

    useEffect(() => {
        if (isSynced && isSynced.synced) openListing();
    }, [isSynced]);

    return (
        <Layout>
            <AppHeader
                title={"Connect Accounts"}
                canGoBack={true}
                searchable={false}
                showTabs={false}
                onBackClick={() =>
                    history.replace(linkProvider.react.gifts().index())
                }
            />
            <Grid
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"space-evenly"}
                p={1}
                className={clsx(classes, classes.root)}
            >
                <img
                    className={clsx(classes, classes.syncIcon)}
                    alt={"Cloud Icon"}
                    src={cloudicon}
                />
                <AppTypography style={{ color: "#0D456D" }}>
                    To access gifts you received as a prospect using DYS app,
                    please submit your DYS login credentials below.
                </AppTypography>
                <Box>
                    <AppStandardTextField
                        fullWidth
                        label="Username"
                        className={classes.marginBottom}
                        value={user.userName}
                        onKeyDown={onPressEnter}
                        onChange={(e) =>
                            setUser({
                                ...user,
                                userName: e.target.value,
                            })
                        }
                        errorInfo={getFieldValidityState(
                            localValidityState,
                            "userName",
                        )}
                    />
                    <AppStandardTextField
                        className={classes.marginBottom}
                        fullWidth
                        label="Password"
                        type="password"
                        value={user.password}
                        onKeyDown={onPressEnter}
                        onChange={(e) =>
                            setUser({
                                ...user,
                                password: e.target.value,
                            })
                        }
                        errorInfo={getFieldValidityState(
                            localValidityState,
                            "password",
                        )}
                    />
                </Box>
                <AppButton
                    disabled={
                        !checkStateValidity(localValidityState) || signingIn
                    }
                    onClick={handleLogin}
                    style={{ width: "125px" }}
                    buttonVariant="primary-filled"
                >
                    {signingIn ? (
                        <AppCircularProgress
                            data-testid="sigin-in-circular-progress"
                            size={25}
                        />
                    ) : (
                        "Sync"
                    )}
                </AppButton>
            </Grid>
            {loading && <AppBackdropProgress open backdropText={"Loading"} />}
        </Layout>
    );
};
