import { useEffect } from "react";
import { useLocation } from "react-router";
import { createStyles, makeStyles } from "@mui/styles";
import { InternalStandardProps, Theme } from "@mui/material";
import { useEssentailsVisibilityChange } from "../Hooks/MediaEssentials";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
    }),
);

export interface ScrollToTopProps extends InternalStandardProps<{}> {
    children: React.ReactNode;
}

const ScrollToTop = (props: ScrollToTopProps) => {
    const location = useLocation();
    useEssentailsVisibilityChange();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>;
};

export default ScrollToTop;
