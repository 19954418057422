import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { InternalStandardProps, Theme, Box, BoxProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppTypography } from "./AppTypography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: "center",
            color: `${theme.palette.grey[400]}`,
        },
        folderIcon: {
            height: "80px",
            color: `${theme.palette.grey[400]}`,
        },
    }),
);

export interface AppNoDataFoundProps extends InternalStandardProps<BoxProps> {
    notFoundText: string;
}

export const AppNoDataFound = (props: AppNoDataFoundProps) => {
    const classes = useStyles(props);
    const { className, notFoundText } = props;
    return (
        <Box className={clsx(className, classes.root)}>
            <FontAwesomeIcon
                className={classes.folderIcon}
                icon={["far", "folder-closed"]}
            />
            <AppTypography variant="body1">{notFoundText}</AppTypography>
        </Box>
    );
};
