import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {
    Grid,
    InputAdornment,
    InternalStandardProps,
    Theme,
} from "@mui/material";

import { createStyles, makeStyles } from "@mui/styles";
import { AppStandardTextField } from "JS/React/Components/TextFields/AppStandardTextField";
import { AppButton } from "JS/React/Components/AppButton";
import { validateLogin } from "JS/Services/User";
import { ILogin } from "JS/Models";
import { checkStateValidity, getFieldValidityState } from "JS/Types";
import { useAuth } from "JS/React/Context/AuthenticationProvider";
import loginLogo from "Images/login_logo.png";
import loginLogodark from "Images/login_logo_dark.png";

import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import RestoreIcon from "@mui/icons-material/Restore";
import { useHistory, useLocation } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import qs from "qs";
import { useUserHistory } from "JS/React/Hooks/UserHistory";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { useThemeModeContext } from "JS/React/Context/ThemeModeProvider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        loginContainer: {
            display: "flex",
            flexDirection: "column",
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        button: {
            marginTop: theme.spacing(3),
        },
        logo: {
            marginBottom: theme.spacing(10),
            width: "70%",
            alignSelf: "center",
        },
        restoreIcon: {
            color: theme.palette.grey[600],
        },
    }),
);

export interface LoginProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const Login = (props: LoginProps) => {
    const passRef = useRef<any | null>();
    const classes = useStyles(props);
    const {
        loginStatus,
        setLoginStatus,
        signIn,
        loading: signingIn,
        sessionLoading,
    } = useAuth();

    const { linkProvider } = useRouting();
    const history = useHistory();
    const [enabledValidations, setEnableValidations] = useState(false);
    const { user, setUser } = useVariables();
    const themeModeContext = useThemeModeContext();

    const localValidityState = useMemo(() => {
        if (enabledValidations) {
            return validateLogin(user);
        } else {
            return [];
        }
    }, [user, enabledValidations]);

    const isLoginValid = useMemo(
        () => checkStateValidity(validateLogin(user)),
        [user],
    );
    useEffect(() => {
        if (loginStatus == false) {
            setUser({
                ...user,
                password: "",
            });

            passRef.current.focus();
            setLoginStatus(true);
        }
    }, [loginStatus]);
    const handleLogin = async (e) => {
        e.preventDefault();

        if (!enabledValidations) {
            setEnableValidations(true);
        }

        if (isLoginValid) {
            signIn(user);
        }
    };

    const onPressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            handleLogin(e);
        }
    };
    const userHistoryClick = () => {
        history.push(linkProvider.react.userHistory());
    };
    if (sessionLoading) {
        return (
            <AppBackdropProgress
                open={sessionLoading}
                backdropText={messaging?.loader?.loadingSession}
            />
        );
    }

    return (
        <div className={classes.root}>
            <Grid container justifyContent={"center"}>
                <Grid item xl={2} lg={3} sm={5} xs={11}>
                    <div className={classes.loginContainer}>
                        <img
                            src={
                                themeModeContext.mode === "light"
                                    ? loginLogo
                                    : loginLogodark
                            }
                            alt="login"
                            className={clsx(classes.logo)}
                        />
                        <AppStandardTextField
                            fullWidth
                            id="standard-basic"
                            label="Username"
                            inputProps={{
                                pattern: "[0-9]*",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={classes.restoreIcon}
                                        onClick={userHistoryClick}
                                    >
                                        <RestoreIcon color="inherit" />
                                    </InputAdornment>
                                ),
                            }}
                            className={classes.marginBottom}
                            value={user.userName}
                            onKeyDown={onPressEnter}
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    userName: e.target.value,
                                })
                            }
                            errorInfo={getFieldValidityState(
                                localValidityState,
                                "userName",
                            )}
                        />

                        <AppStandardTextField
                            inputRef={passRef}
                            className={classes.marginBottom}
                            fullWidth
                            label="Password"
                            type="password"
                            value={user.password}
                            onKeyDown={onPressEnter}
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    password: e.target.value,
                                })
                            }
                            errorInfo={getFieldValidityState(
                                localValidityState,
                                "password",
                            )}
                        />
                        <AppButton
                            disabled={
                                !checkStateValidity(localValidityState) ||
                                signingIn
                            }
                            onClick={handleLogin}
                            className={classes.button}
                            buttonVariant="primary-filled"
                        >
                            {signingIn ? (
                                <AppCircularProgress
                                    data-testid="sigin-in-circular-progress"
                                    size={25}
                                />
                            ) : (
                                "Login"
                            )}
                        </AppButton>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const useVariables = () => {
    const [user, setUser] = useState<ILogin>({
        userName: "",
        password: "",
    });
    const location = useLocation();
    const { history: userHistory } = useUserHistory();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const { history_index } = parsedQueryString;

        return {
            history_index,
        };
    }, [location?.search]);

    useEffect(() => {
        if (queryParams && queryParams.history_index) {
            const selectedId =
                userHistory[+queryParams.history_index].member_id;
            setUser({
                userName: selectedId,
                password: "",
            });
        }
    }, [queryParams]);

    return {
        user,
        setUser,
    };
};
