import { VideoDexie, getDexieConnectionRefresh } from "./Dexie";

export const deleteVideoById = async (id: string) => {
    await getDexieConnectionRefresh().videos.delete(id);
};

export const bulkDeleteVideosByIds = async (ids: string[]) => {
    await getDexieConnectionRefresh().videos.bulkDelete(ids);
};

export const getVideoById = async (id: string) => {
    return await getDexieConnectionRefresh().videos.get(id);
};

export const getVideosIndex = async () => {
    return await getDexieConnectionRefresh().videos.toArray();
};

export const bulkUpdateVideos = async (videos: VideoDexie[]) => {
    return await getDexieConnectionRefresh().videos.bulkPut(videos);
};
