import { createSlice } from "@reduxjs/toolkit";

export interface PushNotificationsState {
    notiPermissionDialogCount?: number;
}

const initialState: PushNotificationsState = {
    notiPermissionDialogCount: 1,
};

export const pushNotificationsStateSlice = createSlice({
    name: "pushNotificationsState",
    initialState,
    reducers: {
        decrementNotiPermissionDialogCount: (state) => {
            state.notiPermissionDialogCount =
                state.notiPermissionDialogCount - 1;
            return state;
        },

        resetHomeState: () => {
            return initialState;
        },
    },
});
export const { decrementNotiPermissionDialogCount } =
    pushNotificationsStateSlice.actions;
