import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { formatDuration } from "JS/Helpers";
import { AppTypography } from "JS/React/Components/AppTypography";
import { RefreshButton } from "JS/React/Components/MediaPlayer/RefreshButton";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import { useEffect, useState } from "react";
import { OnProgressProps } from "react-player/base";

export const MiniPlayerOverlay = (props: MiniPlayerOverlayProps) => {
    const classes = useStyles(props)();
    const { getProgressString } = useHandlers(props);

    const {
        playing,
        playerProgress,
        playerDuration,
        disableNavBtns,
        title,
        showButtons,
        isError,
        loading,
        onClosePlayer,
        onExpandClick,
        onForwardClick,
        onBackwardClick,
        onPlayPauseClick,
    } = props;

    const [showOverlay, setShowOverlay] = useState(true);

    useEffect(() => {
        const onVisibilityChange = () => {
            setShowOverlay(true);
        };
        document.addEventListener("visibilitychange", onVisibilityChange);

        return () =>
            document.removeEventListener(
                "visibilitychange",
                onVisibilityChange,
            );
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (showOverlay) setShowOverlay(false);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [showOverlay]);

    const enableOverlay = showOverlay && showButtons;

    return (
        <div
            onTouchStart={(e) => {
                setTimeout(() => {
                    setShowOverlay(!showOverlay);
                }, 500);
            }}
            onMouseLeave={() => {
                setShowOverlay(false);
            }}
            className={clsx(classes.root, enableOverlay && classes.visible)}
        >
            <>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    {!isError && (
                        <IconButton
                            className={
                                !enableOverlay && classes.noPointerEvents
                            }
                            onClick={onExpandClick}
                        >
                            <FontAwesomeIcon
                                className={clsx(classes.layoutIcon)}
                                id="expand"
                                icon={["fas", "up-right-from-square"]}
                            />
                        </IconButton>
                    )}
                    <Box
                        component="div"
                        overflow="hidden"
                        whiteSpace="pre-line"
                        textOverflow="ellipsis"
                        color="white"
                        className={clsx(classes.title)}
                    >
                        {title}
                    </Box>

                    <Box id="closemax" display={"flex"} gap={2}>
                        <IconButton
                            className={clsx(
                                !enableOverlay && classes.noPointerEvents,
                            )}
                            onClick={onClosePlayer}
                            onTouchStart={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <FontAwesomeIcon
                                className={clsx(classes.actionIcons)}
                                icon={["fas", "xmark"]}
                            />
                        </IconButton>
                    </Box>
                </Box>
                {isError && (
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        textAlign={"center"}
                    >
                        <Box
                            className={clsx(
                                !enableOverlay && classes.noPointerEvents,
                            )}
                        >
                            <RefreshButton isMini />
                        </Box>
                        <AppTypography
                            className={classes.progressText}
                            variant="body1"
                        >
                            Something went wrong. Please try again
                        </AppTypography>
                    </Box>
                )}
                {!isError && !loading && (
                    <Box display={"flex"} justifyContent={"space-evenly"}>
                        <IconButton
                            id="previous"
                            disabled={disableNavBtns || !enableOverlay}
                            onTouchStart={(e) => {
                                e.stopPropagation();
                                onBackwardClick();
                            }}
                            className={clsx(
                                (!onBackwardClick || disableNavBtns) &&
                                    classes.hideIcon,
                            )}
                        >
                            <FontAwesomeIcon
                                className={clsx(classes.layoutIcon)}
                                icon={["fas", "backward-step"]}
                            />
                        </IconButton>
                        <IconButton
                            id="playpause"
                            disabled={!enableOverlay}
                            onTouchStart={(e) => {
                                e.stopPropagation();
                                onPlayPauseClick();
                            }}
                        >
                            <FontAwesomeIcon
                                className={classes.layoutIcon}
                                icon={["fas", playing ? "pause" : "play"]}
                            />
                        </IconButton>
                        <IconButton
                            id="next"
                            disabled={disableNavBtns || !enableOverlay}
                            onTouchStart={(e) => {
                                e.stopPropagation();
                                onForwardClick();
                            }}
                            className={clsx(
                                (!onForwardClick || disableNavBtns) &&
                                    classes.hideIcon,
                            )}
                        >
                            <FontAwesomeIcon
                                className={clsx(classes.layoutIcon)}
                                icon={["fas", "forward-step"]}
                            />
                        </IconButton>
                    </Box>
                )}
                {!isError && loading && (
                    <Box display={"flex"} justifyContent={"space-evenly"}>
                        <AppCircularProgress />
                    </Box>
                )}
                <Box>
                    {playerProgress && playerDuration && !isError && (
                        <AppTypography
                            variant="body1"
                            className={classes.progressText}
                        >
                            {getProgressString()}
                        </AppTypography>
                    )}
                </Box>
            </>
        </div>
    );
};

const useHandlers = (props: MiniPlayerOverlayProps) => {
    const { playerDuration, playerProgress } = props;

    const getProgressString = () => {
        if (playerDuration && playerProgress) {
            return `${formatDuration(playerProgress.playedSeconds)} /
                ${formatDuration(playerDuration)}`;
        }

        return "0 / 0";
    };

    return { getProgressString };
};

const useStyles = (props) =>
    makeStyles((theme: Theme) =>
        createStyles({
            root: {
                position: "absolute",
                width: "100%",
                height: "100%",
                padding: theme.spacing(1),
                background: "rgba(0,0,0,0.5)",
                opacity: 0,
                zIndex: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                transition: "all 0.5s ease",
            },

            layoutIcon: {
                fontSize: "1.1rem",
                cursor: "pointer",
                zIndex: 100,
                color: theme.colors.white,
            },

            actionIcons: {
                fontSize: "1.4rem",
                cursor: "pointer",
                zIndex: 100,
                color: theme.colors.white,
            },

            progressText: {
                color: theme.colors.white,
                fontSize: "0.8rem",
            },

            hideIcon: {
                visibility: "hidden",
            },

            visible: {
                opacity: 1,
            },

            noPointerEvents: {
                pointerEvents: "none",
            },

            title: {
                marginLeft: theme.spacing(1),
                WebkitLineClamp: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                maxWidth: "75%",
            },
        }),
    );

export interface MiniPlayerOverlayProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    isError: boolean;
    playerProgress: OnProgressProps;
    playerDuration: number;
    playing: boolean;
    disableNavBtns: boolean;
    title?: string;
    showButtons?: boolean;
    onClosePlayer: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onExpandClick: () => void;
    onPlayPauseClick: () => void;
    loading?: boolean;
    onForwardClick?: () => void;
    onBackwardClick?: () => void;
}
