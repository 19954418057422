import { doc, setDoc, getDoc } from "firebase/firestore";
import { FirestoreService } from "../Firebase/FirestoreService";
import { ConsentFormApiResponse, ConsentStatus } from "JS/Models/ConsentForm";
import { config } from "JS/Config";
import { AppResponse } from "JS/Types";

export class ConsentStatusService extends FirestoreService {
    private memberId: string;

    constructor(memberId: string) {
        super();
        this.memberId = memberId;
    }

    private getDoc() {
        return doc(this.store, "ConsentForm", this.memberId);
    }

    public postConsentToFirestore() {
        if (this.isStoreActive()) {
            return setDoc(this.getDoc(), {
                consentStatus: true,
            });
        }
    }

    public getConsentFromFirestore(): Promise<ConsentStatus> {
        return new Promise((res, rej) => {
            if (this.isStoreActive()) {
                getDoc(this.getDoc())
                    .then((doc) => {
                        if (doc.exists)
                            res(doc.data() as ConsentStatus | undefined);
                        else
                            rej(
                                new Error(
                                    "ConsentStatus is not found while fetching from firestore",
                                ),
                            );
                    })
                    .catch((err) => rej(err));
            } else rej(new Error("Store is not initialized"));
        });
    }

    public postConsentToCMLM() {
        const params = new FormData();
        params.append("userID", this.memberId);
        params.append("action", "Accept");
        params.append("consentType", "LtdShareDataWithAmway");
        return this.doXHR({
            url: this.routes.server.api.consentForm.postConsent(),
            method: "POST",
            headers: {
                authorization: `Bearer ${config?.accessToken}`,
            },
            data: params,
        });
    }

    public getConsentFromCMLM() {
        return this.doXHR<AppResponse<ConsentFormApiResponse>>({
            url: this.routes.server.api.consentForm.getConsent(this.memberId),
            method: "GET",
            headers: {
                authorization: `Bearer ${config?.accessToken}`,
            },
        });
    }
}
