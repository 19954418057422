import React, { useEffect } from "react";
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";
import { useRouting } from "JS/React//Hooks/Routes";
import { DownloadsOffline } from "JS/React//Scenes/DownloadsOffline/DownloadsOffline";
import { ContentDetailOffline } from "JS/React/Scenes/DownloadsOffline/ContentDetailOffline";
import { BundleDetailOffline } from "JS/React/Scenes/DownloadsOffline/BundleDetailOffline";
import { BundlePlayerOffline } from "./Scenes/DownloadsOffline/BundlePlayerOffline";
import { Box, Typography } from "@mui/material";
import { isDeviceAllowed } from "JS/Helpers";
import { AttachedSkuOffline } from "./Scenes/DownloadsOffline/AttachSkuOffline";
import { DownloadMenu } from "./Scenes/DownloadsOffline/DownloadMenu";
import { PlaylistListing } from "./Scenes/DownloadsOffline/Playlist";
import { PlaylistDetail } from "./Scenes/DownloadsOffline/Playlist/PlaylistDetail";
import { PlaylistPlayer } from "./Scenes/DownloadsOffline/Playlist/PlaylistPlayer";
import { OfflineCourses } from "./Scenes/DownloadsOffline/Courses";
import { OfflineCourseDetail } from "./Scenes/DownloadsOffline/Courses/OfflineCourseDetail";
import { OfflineDocuments } from "./Scenes/DownloadsOffline/Documents";
import { OfflineDocumentDetail } from "./Scenes/DownloadsOffline/Documents/OfflineDocumentDetail";
import { OfflineCourseStep } from "./Scenes/DownloadsOffline/Courses/Steps/OfflineCourseStep";
import { useGlobalMedia } from "./Hooks/Media";
import { Helmet } from "react-helmet";
import { useMiniPlayer } from "./Hooks/MiniPlayer";
import { config } from "JS/Config";
import { RequireAuth } from "./Scenes/DownloadsOffline/RequireAuth";

export const RootOffline = () => {
    const { routeBuilder } = useRouting();
    const routeProvider = routeBuilder();
    const deviceAllowed = isDeviceAllowed();
    const { isZoomAllowed } = useGlobalMedia();
    const { resetGlobalMiniPlayer } = useMiniPlayer();

    useEffect(() => {
        setTimeout(() => resetGlobalMiniPlayer(), 1000);

        return () => resetGlobalMiniPlayer();
    }, []);

    const isLoggedIn = !!config.user.memberId;

    return (
        <React.Fragment>
            {!isZoomAllowed ? (
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"
                    />
                </Helmet>
            ) : (
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta
                        name="viewport"
                        content="width=device-width, user-scalable=1, viewport-fit=cover"
                    />
                </Helmet>
            )}
            {!isLoggedIn && <RequireAuth />}
            {isLoggedIn && (
                <Router>
                    {deviceAllowed ? (
                        <>
                            <Switch>
                                <Route
                                    exact={true}
                                    path={routeProvider.react.offline().index()}
                                    component={DownloadMenu}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .listing()}
                                    component={DownloadsOffline}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .playlist()
                                        .index()}
                                    component={PlaylistListing}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .playlist()
                                        .detail()}
                                    component={PlaylistDetail}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .playlist()
                                        .play()}
                                    component={PlaylistPlayer}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .documents()
                                        .index()}
                                    component={OfflineDocuments}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .documents()
                                        .detail()}
                                    component={OfflineDocumentDetail}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .courses()
                                        .index()}
                                    component={OfflineCourses}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .courses()
                                        .detail()}
                                    component={OfflineCourseDetail}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .courses()
                                        .mediaStepDetail()}
                                    component={OfflineCourseStep}
                                />
                                <Route
                                    exact={true}
                                    path={routeProvider.react
                                        .offline()
                                        .downloaddetail()}
                                    component={ContentDetailOffline}
                                />
                                <Route
                                    path={routeProvider.react
                                        .offline()
                                        .bundle()
                                        .audios()}
                                    component={BundleDetailOffline}
                                />
                                <Route
                                    path={routeProvider.react
                                        .offline()
                                        .bundle()
                                        .play()}
                                    component={BundlePlayerOffline}
                                />
                                <Route
                                    path={routeProvider.react
                                        .offline()
                                        .attachedSku()}
                                    component={AttachedSkuOffline}
                                />
                                <Route
                                    render={() => (
                                        <Redirect
                                            to={routeProvider.react
                                                .offline()
                                                .index()}
                                        />
                                    )}
                                />
                            </Switch>
                        </>
                    ) : (
                        <Switch>
                            <Route
                                path={routeProvider.react.root()}
                                render={() => (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography variant="h5">
                                            Not Available
                                        </Typography>
                                    </Box>
                                )}
                            />
                        </Switch>
                    )}
                </Router>
            )}
        </React.Fragment>
    );
};
