import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { AppTypography } from "JS/React/Components/AppTypography";
import {
    CourseDetail,
    CourseStep,
    EventActions,
    EventCategories,
    EventNames,
    ReceivedCourse,
} from "JS/Models";
import clsx from "clsx";
import { Videocam as VideocamIcon } from "@mui/icons-material";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { FBStepStats } from "JS/Models/Firebase/Courses";
import { useCallback, useEffect, useRef, useState } from "react";
import { CourseLogFirebaseEventSettings } from "./OfflineCourseStep";
import moment from "moment";
import {
    useContentPlayingHandlers,
    usePlayerHandlers,
} from "JS/React/Hooks/MediaPlayer";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import { PLAYER_PROGRESS_INTERVAL } from "JS/Helpers/Contants";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import {
    ActionDetails,
    SentGiftType,
    getPlayerPlayedTimeToLog,
    toSentGift,
} from "JS/Models/Firebase/GiftDashboard";

export const OfflineMediaStep = (props: OfflineMediaStepProps) => {
    const mediaRef = useRef<any>(null);
    const [isReady, setIsReady] = useState(false);
    const [isReadyToPlay, setIsReadyToPlay] = useState(false);

    const playerRef = useRef<any>();
    const { handleChangeSeek } = usePlayerHandlers(playerRef);

    const classes = useStyles(props);
    const {
        className,
        step,
        mediaUrl,
        courseSku,
        stepSku,
        detail,
        imgUrl,
        currentPlayingState,
        isAutoPlay,
        latestStepStats,
        receivedCourse,
        updateStepStats,
        onStepCompleted,
        onStepOpen,
        handleLogFirebaseEvents,
        setCurrentPlayingState,
        ...rest
    } = props;
    const { handlePlayerPause, handlePlayerPlay } = useContentPlayingHandlers(
        setCurrentPlayingState,
    );
    const { isMediaPlaying } = useVariables(props);

    const getDashboardActionDetails = (): ActionDetails => {
        return {
            duration: getPlayerPlayedTimeToLog(playerRef).duration,
            stepSku: stepSku,
            stepName: step.title,
        };
    };

    useGiftDashboard(
        toSentGift(receivedCourse),
        SentGiftType.COURSE,
        receivedCourse?.sender?.member_id,
        !!receivedCourse && isMediaPlaying,
        getDashboardActionDetails,
        true,
    );

    const onMediaPlayerReady = useCallback(async () => {
        setIsReadyToPlay(true);
        if (!isReady) {
            if (latestStepStats) {
                playerRef?.current?.seekTo(
                    latestStepStats.analytics?.last_played
                        ? latestStepStats.analytics.last_played
                        : 0,
                    "seconds",
                );
                const d = playerRef?.current?.getDuration();
                const durationToLog = !!d && !isNaN(d) ? +d.toFixed(0) : 0;
                updateStepStats({
                    ...latestStepStats,
                    analytics: {
                        ...latestStepStats.analytics,
                        duration: durationToLog,
                    },
                });
            }
            setIsReady(true);
        }
    }, [isReady]);

    useEffect(() => {
        setIsReady(false);
        setIsReadyToPlay(false);
    }, [stepSku]);

    const onFirstPlay = async () => {
        mediaRef.current = true;
        const progress =
            latestStepStats.analytics?.progress_state === 0
                ? 1
                : latestStepStats.analytics?.progress_state;

        if (latestStepStats) {
            const d = playerRef?.current?.getDuration();
            const durationToLog = !!d && !isNaN(d) ? +d.toFixed(0) : 0;
            updateStepStats({
                ...latestStepStats,
                analytics: {
                    ...latestStepStats.analytics,
                    played_count: latestStepStats.analytics?.played_count + 1,
                    last_start_date: moment().valueOf(),
                    progress_state: progress,
                    duration: durationToLog,
                },
            });
        }

        if (detail.content_item_type_name === "Course") {
            handleLogFirebaseEvents("step", {
                action: EventActions.STARTED,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_STARTED,
            });
            handleLogFirebaseEvents("step", {
                action: EventActions.PLAY,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_PLAYED,
            });
        }

        if (detail.content_item_type_name === "GiftCourse") {
            handleLogFirebaseEvents("step", {
                action: EventActions.STARTED,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_STARTED,
            });
            handleLogFirebaseEvents("step", {
                action: EventActions.PLAY,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_PLAYED,
            });
        }
    };

    const onPlayerPause = () => {
        handlePlayerPause();
        if (
            detail.content_item_type_name === "Course" &&
            playerRef?.current.getCurrentTime() !==
                playerRef?.current.getDuration()
        ) {
            handleLogFirebaseEvents("step", {
                action: EventActions.PAUSED,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_PAUSED,
            });
        }

        if (
            detail.content_item_type_name === "GiftCourse" &&
            playerRef?.current.getCurrentTime() !==
                playerRef?.current.getDuration()
        ) {
            handleLogFirebaseEvents("step", {
                action: EventActions.PAUSED,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_PAUSED,
            });
        }
    };

    const onPlayerResume = () => {
        handlePlayerPlay();
        if (!mediaRef.current) {
            if (detail.content_item_type_name === "Course") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.RESUMED,
                    category: EventCategories.COURSE_STEP,
                    eventNames: EventNames.COURSE_STEP_RESUMED,
                });
            }
            if (detail.content_item_type_name === "GiftCourse") {
                handleLogFirebaseEvents("step", {
                    action: EventActions.RESUMED,
                    category: EventCategories.GIFT_COURSE_STEP,
                    eventNames: EventNames.GIFT_COURSE_STEP_RESUMED,
                });
            }
        } else {
            mediaRef.current = false;
        }
    };

    const onPlayerProgress = async () => {
        const t = playerRef?.current?.getCurrentTime();
        const playerTime = !!t && !isNaN(t) ? +t.toFixed(0) : 0;

        if (latestStepStats) {
            updateStepStats({
                ...latestStepStats,
                analytics: {
                    ...latestStepStats.analytics,
                    last_played: playerTime,
                    maximum_played: playerTime,
                },
            });
        }
    };

    useEffect(() => {
        onStepOpen();
    }, []);

    return (
        <Grid
            {...rest}
            container
            className={clsx(className, classes.containers)}
        >
            <Grid item xs={12} display="flex" justifyContent="space-between">
                <AppTypography
                    className={classes.greyA100}
                    fontWeight={"bold"}
                    variant="h6"
                >
                    {step.title}
                </AppTypography>
            </Grid>
            <Grid item className={classes.grey500} xs={12} display="flex">
                <AppTypography variant="body1">{step.sku_id}</AppTypography>
            </Grid>
            <Grid item xs={12} className={classes.playerContainer}>
                {!mediaUrl && (
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                        className={classes.dummyImageBox}
                    >
                        <span className={classes.graphicWrapper}>
                            <VideocamIcon
                                className={classes.graphicIcon}
                                sx={{
                                    fontSize: "60px",
                                }}
                            />
                        </span>
                    </Box>
                )}

                {mediaUrl && (
                    <MediaPlayer
                        title={step.title}
                        playerRef={playerRef}
                        isAudio={step.content_item_type_name === "MP3"}
                        mediaURL={mediaUrl}
                        imageURL={imgUrl}
                        playing={isMediaPlaying}
                        progressInterval={PLAYER_PROGRESS_INTERVAL}
                        onStart={onFirstPlay}
                        onPause={onPlayerPause}
                        onPlay={onPlayerResume}
                        onEnded={onStepCompleted}
                        onReady={onMediaPlayerReady}
                        onProgress={onPlayerProgress}
                        loading={!isReadyToPlay}
                        onUpdateExpiredUrl={() => {
                            setIsReady(false);
                            setIsReadyToPlay(true);
                        }}
                        onError={() => {
                            setIsReadyToPlay(true);
                        }}
                    />
                )}
            </Grid>

            <Grid item xs={12}>
                <Grid container className={classes.iconsWrapper}>
                    <Grid
                        item
                        xs={12}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <PlayerActionBtns handleChangeSeek={handleChangeSeek} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <AppTypography className={classes.grey600} variant="body1">
                    {step.description}
                </AppTypography>
            </Grid>
        </Grid>
    );
};

const useVariables = (props: OfflineMediaStepProps) => {
    const { step, isAutoPlay, currentPlayingState } = props;

    const isMediaPlaying =
        step.content_item_type_name === "MP3"
            ? isAutoPlay
            : isAutoPlay && currentPlayingState;

    return {
        isMediaPlaying,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        containers: {
            padding: theme.spacing(1),
        },
        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            background: theme.palette.grey[900],
            height: "100%",
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        iconsWrapper: {
            margin: theme.spacing(1.5, 0),
        },

        controlIcons: {
            minWidth: "40px",
            display: "flex",
        },
        playerIcon: {
            height: "35px",
        },
    }),
);

export interface OfflineMediaStepProps
    extends InternalStandardProps<GridProps> {
    step: CourseStep;
    mediaUrl: string;
    imgUrl: string;
    updateStepStats: (stats: FBStepStats) => void;
    latestStepStats: FBStepStats;
    courseSku: string;
    stepSku: string;
    detail: CourseDetail;
    isAutoPlay: boolean;
    onStepCompleted: () => Promise<void>;
    onStepOpen: () => Promise<void>;
    handleLogFirebaseEvents: (
        type: "course" | "step" | "question",
        settings: CourseLogFirebaseEventSettings,
    ) => void;
    currentPlayingState?: boolean;
    setCurrentPlayingState?: React.Dispatch<React.SetStateAction<boolean>>;
    receivedCourse: ReceivedCourse;
}
