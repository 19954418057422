import { appConstants } from "JS/Helpers/Contants";
import {
    addToDownloadQueue,
    getDownloadQueueLength,
} from "JS/Helpers/ContentDownloadHelper";
import { AddToDownloadOptions } from "JS/Models/Common";
import { DocumentDexie, getDexieConnectionRefresh } from "JS/Database/Dexie";
import { messaging } from "JS/Helpers/UserMessaging";
import { useSnackbar } from "notistack";
import { setDownloadedContentNids } from "JS/Redux/download";
import { useAppDispatch } from "JS/Redux/Store";
import { config } from "JS/Config";
import { useCallback, useEffect, useState } from "react";
import { getDocumentsBymemberId } from "JS/Database/Document";

export const useDownloadDocument = () => {
    const download = (pdf, description) => {
        const downloadingQueueLimit =
            appConstants.downloadingQueue.downloadingQueueLimit;
        const queueLength = getDownloadQueueLength();

        if (queueLength < downloadingQueueLimit) {
            const params: AddToDownloadOptions = {
                name: description,
                nid: pdf,
                type: "document",
                description,
            };
            addToDownloadQueue(params);
            return pdf;
        } else {
            return null;
        }
    };

    return { download };
};

export const useDeleteDocument = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const deleteDocument = async (
        pdf,
        downloadedContentNids: string[],
        deleteSuccess: () => void,
    ) => {
        getDexieConnectionRefresh()
            .documents.delete(`${config.user.memberId}-${pdf}`)
            .then((_) => {
                deleteSuccess();
                enqueueSnackbar(messaging.download.deleteSuccess, {
                    variant: "success",
                });

                if (downloadedContentNids && downloadedContentNids.length) {
                    const downloadedNids = [...downloadedContentNids];
                    const updatedDownloadedNids = downloadedNids?.filter(
                        (downloadedNid) =>
                            downloadedNid !== `${config.user.memberId}-${pdf}`,
                    );
                    dispatch(
                        setDownloadedContentNids({
                            downloadedContentNids: updatedDownloadedNids,
                        }),
                    );
                }
            })
            .catch((err) => {
                enqueueSnackbar(messaging.download.deleteError, {
                    variant: "error",
                });
            });
    };

    return {
        deleteDocument,
    };
};

export const useDownloadedDocumentsList = () => {
    const [downloadedDocuments, setDownloadedDocuments] = useState<
        DocumentDexie[]
    >([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetch = async () => {
        setIsLoading(true);
        try {
            const toRetDocs: DocumentDexie[] = [];

            await getDexieConnectionRefresh()?.documents.each((doc) =>
                toRetDocs.push(doc),
            );

            setDownloadedDocuments(
                toRetDocs
                    .filter((d) => d.id.startsWith(config.user.memberId))
                    .sort((a, b) => a.description.localeCompare(b.description)),
            );
        } catch (error) {
            console.log("Error while fetching downloaded documents.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return {
        downloadedDocuments,
        isLoading,
    };
};

export const useDocumentDownloadedURL = (
    pdf,
    success?: () => void,
    failure?: () => void,
) => {
    const [downloadedURL, setDownloadedURL] = useState<string>();

    const refetch = async (): Promise<string> => {
        return getDexieConnectionRefresh()
            .documents.get(`${config.user.memberId}-${pdf}`)
            .then((res) => {
                if (res) {
                    !!success && success();
                    const url = URL.createObjectURL(res.blob);
                    setDownloadedURL(url);
                    return url;
                } else {
                    !!failure && failure();
                    return null;
                }
            })
            .catch((_) => {
                !!failure && failure();
                return null;
            });
    };

    useEffect(() => {
        refetch();
    }, []);

    return {
        downloadedURL,
        refetch,
    };
};

export const useUserSavedDocIds = () => {
    const [userDownloadedDocIds, setuserDownloadedDocIds] = useState([]);

    const fetch = useCallback(() => {
        return getDocumentsBymemberId(config?.user?.memberId)
            .then((res) => {
                if (res?.length) {
                    setuserDownloadedDocIds(res.map((x) => x.id));
                } else {
                    setuserDownloadedDocIds([]);
                }
            })
            .catch(() => {
                setuserDownloadedDocIds([]);
            });
    }, []);

    useEffect(() => {
        fetch();
    }, []);

    return {
        fetch,
        userDownloadedDocIds,
    };
};
