import { useState, useCallback, useEffect } from "react";
import { AppResponse } from "JS/Types";
import { useSnackbar } from "notistack";
import { AddToCart, ConfirmContentPurchase } from "JS/Models/Media";

import {
    EventNames,
    EventActions,
    EventCategories,
    FirebaseEventAction,
} from "JS/Models";
import { useFirebaseLogger } from "./Firebase";
import { PurchaseService } from "JS/Services/Purchase";

const service = new PurchaseService();

export default service;

export const useAvailableCredits = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [availableCredits, setAvailableCredits] = useState<number>(null);

    const { enqueueSnackbar } = useSnackbar();

    const getAvailableAudioCredits = useCallback(() => {
        setLoading(true);
        setAvailableCredits(null);

        return service
            .getAvailableAudioCredits()
            .then((val) => {
                const response = val?.response;
                if (response?.status) {
                    setAvailableCredits(response.data);
                } else {
                    enqueueSnackbar(response.message, { variant: "error" });
                }
                return val;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, { variant: "error" });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            getAvailableAudioCredits();
        }
    }, [skip]);

    return {
        getAvailableAudioCredits,
        availableCredits,
        loading,
    };
};

export const useRedeemByCredits = () => {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { logFirebaseEvent } = useFirebaseLogger();

    const redeemByCredits = useCallback(
        (
            skuId: string,
            firebaseEvent: Partial<FirebaseEventAction>,
            onFailed?: (msg: string) => void,
        ) => {
            setLoading(true);

            let eventName: EventNames,
                successEventName: EventNames,
                failedEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                action: EventActions.REDEEM,
                category: firebaseEvent?.category,
                contentTitle: firebaseEvent?.contentTitle,
                nId: firebaseEvent?.nId,
                skuId: skuId,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.AUDIOS) {
                    eventName = EventNames.AUDIO_REDEEM_REQUESTED;
                    successEventName = EventNames.AUDIO_REDEEMED;
                    failedEventName = EventNames.AUDIO_REDEEM_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.VIDEOS) {
                    eventName = EventNames.VIDEO_REDEEM_REQUESTED;
                    successEventName = EventNames.VIDEO_REDEEMED;
                    failedEventName = EventNames.VIDEO_REDEEM_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.COURSE) {
                    eventName = EventNames.COURSE_REDEEM_REQUESTED;
                    successEventName = EventNames.COURSE_REDEEMED;
                    failedEventName = EventNames.COURSE_REDEEM_FAILED;
                }
                logFirebaseEvent(eventName, {
                    ...fireEvent,
                    action: EventActions.REDEEM_REQUEST,
                });
            }

            return service
                .redeemAudioCredits(skuId)
                .then((val) => {
                    if (!val.response?.status) {
                        !!onFailed && onFailed(val.response.message);
                        enqueueSnackbar(val.response.message, {
                            variant: "error",
                        });

                        if (firebaseEvent) {
                            logFirebaseEvent(failedEventName, {
                                ...fireEvent,
                                action: EventActions.REDEEM_FAILED,
                            });
                        }
                    } else {
                        if (firebaseEvent) {
                            logFirebaseEvent(successEventName, {
                                ...fireEvent,
                                action: EventActions.REDEEM,
                            });
                        }
                        enqueueSnackbar(val.response.message, {
                            variant: "success",
                        });
                    }
                    return val;
                })
                .catch((err) => {
                    onFailed(err.message);
                    enqueueSnackbar(err.message, { variant: "error" });
                    if (firebaseEvent) {
                        logFirebaseEvent(failedEventName, {
                            ...fireEvent,
                            action: EventActions.REDEEM_FAILED,
                        });
                    }
                    return null as AppResponse<null>;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        redeemByCredits,
        loading,
    };
};

export const usePurchaseByCard = () => {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { logFirebaseEvent } = useFirebaseLogger();

    const purchaseByCard = useCallback(
        (
            cartId: string,
            quantity: number,
            skuId: string,
            firebaseEvent: Partial<FirebaseEventAction>,
            onFailed?: (msg: string) => void,
        ) => {
            setLoading(true);

            let purchaseRequestedEventName: EventNames,
                purchasedEventName: EventNames,
                purchaseFailedEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                action: EventActions.PURCHASE,
                category: firebaseEvent?.category,
                contentTitle: firebaseEvent?.contentTitle,
                nId: firebaseEvent?.nId,
                skuId: skuId,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.AUDIOS) {
                    purchaseRequestedEventName =
                        EventNames.AUDIO_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.AUDIO_PURCHASED;
                    purchaseFailedEventName = EventNames.AUDIO_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.VIDEOS) {
                    purchaseRequestedEventName =
                        EventNames.VIDEO_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.VIDEO_PURCHASED;
                    purchaseFailedEventName = EventNames.VIDEO_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.COURSE) {
                    purchaseRequestedEventName =
                        EventNames.COURSE_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.COURSE_PURCHASED;
                    purchaseFailedEventName = EventNames.COURSE_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_AUDIOS) {
                    purchaseRequestedEventName =
                        EventNames.GIFT_AUDIO_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.GIFT_AUDIO_PURCHASED;
                    purchaseFailedEventName =
                        EventNames.GIFT_AUDIO_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_VIDEOS) {
                    purchaseRequestedEventName =
                        EventNames.GIFT_VIDEO_PURCHASED_REQUESTED;
                    purchasedEventName = EventNames.GIFT_VIDEO_PURCHASED;
                    purchaseFailedEventName =
                        EventNames.GIFT_VIDEO_PURCHASE_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_COURSE) {
                    purchaseRequestedEventName =
                        EventNames.GIFT_COURSE_PURCHASE_REQUESTED;
                    purchasedEventName = EventNames.GIFT_COURSE_PURCHASED;
                    purchaseFailedEventName =
                        EventNames.GIFT_COURSE_PURCHASE_FAILED;
                }

                logFirebaseEvent(purchaseRequestedEventName, {
                    ...fireEvent,
                    action: EventActions.PURCHASE_REQUEST,
                });
            }

            return service
                .confirmContentPurchase(cartId, quantity, skuId)
                .then((val) => {
                    if (!val?.response?.status) {
                        onFailed(val?.response?.message);
                        enqueueSnackbar(val?.response?.message, {
                            variant: "error",
                        });
                        logFirebaseEvent(purchaseFailedEventName, {
                            ...fireEvent,
                            action: EventActions.PURCHASE_FAILED,
                        });
                    } else {
                        enqueueSnackbar(val?.response?.message, {
                            variant: "success",
                        });
                        logFirebaseEvent(purchasedEventName, {
                            ...fireEvent,
                            action: EventActions.PURCHASE,
                        });
                    }
                    return val;
                })
                .catch((err) => {
                    onFailed(err.message);
                    enqueueSnackbar(err.message, { variant: "error" });
                    logFirebaseEvent(purchaseFailedEventName, {
                        ...fireEvent,
                        action: EventActions.PURCHASE_FAILED,
                    });
                    return null as AppResponse<ConfirmContentPurchase>;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        purchaseByCard,
        loading,
    };
};

export const useAddContentToCart = () => {
    const [loading, setLoading] = useState(false);
    const [cart, setCart] = useState<AddToCart>(null);

    const { enqueueSnackbar } = useSnackbar();

    const addToCart = useCallback((skuId, quantity) => {
        setLoading(true);

        return service
            .purchaseContentRequest(skuId, quantity)
            .then((val) => {
                if (val.response?.status) {
                    setCart(val.response.data);
                } else {
                    enqueueSnackbar(val.response?.message, {
                        variant: "error",
                    });
                }
                return val;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, { variant: "error" });
                return null as AppResponse<AddToCart>;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return {
        cart,
        addToCart,
        loading,
    };
};

export const useRedeemGiftByCredits = () => {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { logFirebaseEvent } = useFirebaseLogger();

    const redeemGiftByCredits = useCallback(
        (
            skuId: string,
            quantity: number,
            firebaseEvent?: Partial<FirebaseEventAction>,
        ) => {
            setLoading(true);

            let eventName: EventNames,
                successEventName: EventNames,
                failedEventName: EventNames;

            const fireEvent: FirebaseEventAction = {
                action: EventActions.REDEEM,
                category: firebaseEvent?.category,
                contentTitle: firebaseEvent?.contentTitle,
                nId: firebaseEvent?.nId,
                skuId: skuId,
            };

            if (firebaseEvent) {
                if (firebaseEvent?.category === EventCategories.GIFT_AUDIOS) {
                    eventName = EventNames.GIFT_AUDIO_REDEEM_REQUESTED;
                    successEventName = EventNames.GIFT_AUDIO_REDEEMED;
                    failedEventName = EventNames.GIFT_AUDIO_REDEEM_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_VIDEOS) {
                    eventName = EventNames.GIFT_VIDEO_REDEEM_REQUESTED;
                    successEventName = EventNames.GIFT_VIDEO_REDEEMED;
                    failedEventName = EventNames.GIFT_VIDEO_REDEEM_FAILED;
                }

                if (firebaseEvent?.category === EventCategories.GIFT_COURSE) {
                    eventName = EventNames.GIFT_COURSE_REDEEM_REQUESTED;
                    successEventName = EventNames.GIFT_COURSE_REDEEMED;
                    failedEventName = EventNames.GIFT_COURSE_REDEEM_FAILED;
                }
                logFirebaseEvent(eventName, {
                    ...fireEvent,
                    action: EventActions.REDEEM_REQUEST,
                });
            }

            return service
                .redeemGiftByCredits(skuId, quantity)
                .then((val) => {
                    if (!val.response?.status) {
                        enqueueSnackbar(val.response.message, {
                            variant: "error",
                        });
                        logFirebaseEvent(failedEventName, {
                            ...fireEvent,
                            action: EventActions.REDEEM_FAILED,
                        });
                    } else {
                        enqueueSnackbar(val.response.message, {
                            variant: "success",
                        });
                        logFirebaseEvent(successEventName, {
                            ...fireEvent,
                            action: EventActions.REDEEM,
                        });
                    }
                    return val;
                })
                .catch((err) => {
                    logFirebaseEvent(failedEventName, {
                        ...fireEvent,
                        action: EventActions.REDEEM_FAILED,
                    });

                    enqueueSnackbar(err.message, { variant: "error" });
                    return null as AppResponse<[]>;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        redeemGiftByCredits,
        loading,
    };
};
