import { AppFallBackComponent } from "JS/React/Components/AppFallBackComponent";
import { routesForContext } from "JS/Routing";
import React, { ErrorInfo } from "react";
import { captureSentryError } from "JS/Helpers/SentryHelper";

interface IErrorBoundary {
    hasError: boolean;
}
interface ErrorBoundaryProps {
    children?: React.ReactNode;
}

const logError = (error: any, errorInfo: ErrorInfo) => {
    const errorToLog = new Error(error);

    errorToLog.name = `${error.name}-Fallback`;
    errorToLog.message = error.message;
    errorToLog.stack = error.stack;

    const tags = {
        location: "fallback",
    };

    captureSentryError(errorToLog, tags);
};

export default class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    IErrorBoundary
> {
    protected routes = routesForContext()();
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        logError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <AppFallBackComponent
                    title="Oops!"
                    desc="Something went wrong."
                    routeText="Go To Homepage"
                    route={this.routes.react.home()}
                />
            );
        }

        return this.props.children;
    }
}
