import {
    ActionDetails,
    DashboardActivity,
    SentGift,
    SentGiftAction,
    SentGiftToLog,
    SentGiftType,
} from "JS/Models/Firebase/GiftDashboard";
import { GiftDashboardService } from "JS/Services/Firebase/GiftDashboardService";
import { useEffect, useState } from "react";
import { useGlobalGiftDashboard } from "../GiftDashboard";
import { Gifts } from "JS/Models";
import { useGetLatestUsername } from "../Users";
import { Unsubscribe } from "@firebase/firestore";
import { useFirebaseAuth } from "./Auth";
import moment from "moment";
import { config } from "JS/Config";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";
import {
    useReceivedMemberAudioGifts,
    useReceivedMemberVideoGifts,
} from "../Gifts";
import { EventsHelper } from "JS/Services/Events/EventsHelper";
import { loggingFirebaseService } from "JS/Services/Firebase/LoggingService";
import { useReceivedCourses } from "../Course/Course";

export const useGiftDashboard = (
    gift: SentGift,
    type: SentGiftType,
    memberId: Gifts["sender"]["member_id"],
    isPlaying: boolean,
    getPlayerActionDetails?: () => ActionDetails,
    isOffline?: boolean,
) => {
    const service = new GiftDashboardService();
    const { giftDashboard, setGlobalGiftDashboard } = useGlobalGiftDashboard();
    const { getLatestUsername } = useGetLatestUsername();
    const { isGift: isAudioGift, getGiftDate: getAudioGiftDate } =
        useReceivedMemberAudioGifts(true);
    const { isGift: isVideoGift, getGiftDate: getVideoGiftDate } =
        useReceivedMemberVideoGifts(true);
    const { getGiftDate: getCourseGiftDate } = useReceivedCourses(true);

    const sendPush = (action: SentGiftAction) => {
        const getTime = (date: string) =>
            !!date ? new Date(date).getTime() : new Date().getTime();

        let giftDate: number;
        const giftData = giftToLog();
        if (isAudioGift(giftData.nid))
            giftDate = getTime(getAudioGiftDate(giftData.nid));
        else if (isVideoGift(giftData.nid))
            giftDate = getTime(getVideoGiftDate(giftData.nid));
        else giftDate = getTime(getCourseGiftDate(giftData.nid));
        if (moment.now() - giftDate <= 60 * EventsHelper.ONE_DAY)
            service.sendDashboardPush(
                giftData,
                action,
                memberId,
                getPlayerActionDetails && getPlayerActionDetails(),
            );
    };

    useEffect(() => {
        if (
            !config.user.firstName ||
            config.user.firstName === "undefined" ||
            !config.user.lastName ||
            config.user.lastName === "undefined"
        ) {
            loggingFirebaseService.logRealtimeAnalysis(
                "UsernameNotFound",
                null,
                "UsernameFetch",
            );
            getLatestUsername();
        }
    }, []);

    const giftToLog = () => {
        return {
            ...gift,
            receiverFirstName: config.user.firstName || "",
            receiverLastName: config.user.lastName || "",
            createdAt: moment.now(),
        } as SentGiftToLog;
    };

    const logGiftStream = () => {
        if (isOffline) {
            setGlobalGiftDashboard({
                giftStream: [
                    ...giftDashboard.giftStream,
                    {
                        gift: giftToLog(),
                        type,
                        memberId,
                        actionDetails:
                            getPlayerActionDetails && getPlayerActionDetails(),
                    },
                ],
            });
        } else {
            service.logGiftStream(
                giftToLog(),
                type,
                memberId,
                getPlayerActionDetails && getPlayerActionDetails(),
            );
        }
    };

    useEffect(() => {
        let interval: NodeJS.Timer;
        if (isPlaying) {
            interval = setInterval(() => {
                logGiftStream();
            }, 5000);
        }
        return () => clearInterval(interval);
    }, [isPlaying, memberId, giftToLog()?.contentTitle]);

    return {
        logGiftOpen: (actionDetails?: ActionDetails) => {
            if (isOffline) {
                setGlobalGiftDashboard({
                    giftOpen: [
                        ...giftDashboard.giftOpen,
                        {
                            gift: giftToLog(),
                            type,
                            memberId,
                            actionDetails,
                        },
                    ],
                });
            } else {
                sendPush(SentGiftAction.OPEN);
                service.logGiftOpen(giftToLog(), type, memberId, actionDetails);
            }
        },
        logGiftCompleted: (actionDetails?: ActionDetails) => {
            if (isOffline) {
                setGlobalGiftDashboard({
                    giftCompleted: [
                        ...giftDashboard.giftCompleted,
                        {
                            gift: giftToLog(),
                            type,
                            memberId,
                            actionDetails,
                        },
                    ],
                });
            } else {
                sendPush(SentGiftAction.COMPLETED);
                service.logGiftCompleted(
                    giftToLog(),
                    type,
                    memberId,
                    actionDetails,
                );
            }
        },
    };
};

export const useGetGiftDashboard = () => {
    const [loading, setLoading] = useState<boolean>();
    const [isError, setIsError] = useState<boolean>(false);
    const [giftDashboardLogs, setGiftDashboardLogs] = useState<
        DashboardActivity[]
    >([]);
    const { coursesFirebaseSignIn } = useFirebaseAuth();
    const service = new GiftDashboardService();

    const onLogs = (logs: DashboardActivity[]) => {
        setLoading(false);
        setIsError(false);
        setGiftDashboardLogs(logs);
    };

    useEffect(() => {
        let snap: Unsubscribe;
        const createSnap = async () => {
            setLoading(true);
            await coursesFirebaseSignIn();
            snap = service.getDataSnapshot(onLogs, (err) => {
                setIsError(true);
                setLoading(false);
            });
        };
        createSnap();
        return () => !!snap && snap();
    }, []);

    return {
        loading,
        giftDashboardLogs,
        isError,
    };
};

export const useDashboardPushStatus = () => {
    const service = new GiftDashboardService();
    const { enqueueSnackbar } = useSnackbar();
    const { coursesFirebaseSignIn } = useFirebaseAuth();

    const [loading, setLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const [error, setError] = useState(false);
    const [status, setStatus] = useState(false);
    const onStatus = (status: boolean) => {
        setLoading(false);
        setStatus(status);
    };
    const onError = (error: any) => {
        setLoading(false);
        setError(true);
    };

    const setPushStatus = async (status: boolean) => {
        setStatusLoading(true);
        service
            .setPushStatus(config.user.memberId, status)
            .then(() =>
                enqueueSnackbar(messaging.giftDashboard.pushSetSuccess, {
                    variant: "success",
                }),
            )
            .catch((err) => {
                enqueueSnackbar(messaging.giftDashboard.pushSetFailed, {
                    variant: "error",
                });
            })
            .finally(() => setStatusLoading(false));
    };

    useEffect(() => {
        setLoading(true);

        let snap: Unsubscribe;
        (async () => {
            await coursesFirebaseSignIn();
            snap = service.observePushStatus(
                config.user.memberId,
                onStatus,
                onError,
            );
        })();
        return () => snap && snap();
    }, []);

    return { loading, status, setPushStatus, error, statusLoading };
};

export const useGiftDashboardSent = () => {
    const service = new GiftDashboardService();
    return {
        logGiftSent: (gift: SentGift, type: SentGiftType) =>
            service.logGiftSent(gift, type),
    };
};

export const useGiftDashboardRoot = () => {
    const service = new GiftDashboardService();
    const { giftDashboard, setGlobalGiftDashboard } = useGlobalGiftDashboard();

    const logGiftStream = async () => {
        giftDashboard.giftStream.forEach(async (val) => {
            await service.logGiftStream(
                val.gift,
                val.type,
                val.memberId,
                val.actionDetails,
            );
        });
    };
    const logGiftOpen = () => {
        giftDashboard.giftOpen.forEach((val) => {
            service.logGiftOpen(
                val.gift,
                val.type,
                val.memberId,
                val.actionDetails,
            );
        });
    };
    const logGiftCompleted = () => {
        giftDashboard.giftCompleted.forEach((val) => {
            service.logGiftCompleted(
                val.gift,
                val.type,
                val.memberId,
                val.actionDetails,
            );
        });
    };

    useEffect(() => {
        if (giftDashboard.giftOpen?.length) {
            logGiftOpen();
        }
        if (giftDashboard.giftStream?.length) {
            logGiftStream();
        }
        if (giftDashboard.giftCompleted?.length) {
            logGiftCompleted();
        }
        setGlobalGiftDashboard({
            giftCompleted: [],
            giftOpen: [],
            giftStream: [],
        });
    }, []);
};
