export interface CustomCommonRealtimeLogs {
    action: CustomRealtimeAction;
    appVersion: string;
    category: "General";
    iboId: string;
    marketAlias: "LTD";
    platform: "React-Media";
    ts: string;
    tz: string;
}

export enum CustomRealtimeAction {
    RECOMMENDATIONS_OPENED = "Recommendations Opened",
    RECOMMENDATIONS_BATCH_FETCHED = "Recommendations Batch Fetched",
    RECOMMENDATIONS_BATCH_FETCH_FAILED = "Recommendations Batch Fetch Failed",
    DOWNLOAD_SAVING_FAILED = "Download Saving Failed",
    DOWNLOADING_FAILED = "Downloading Failed",
    DOWNLOAD_SUCCEEDED = "Download Succeeded",
    DOWNLOAD_SAVED_SUCCESSFULLY = "Download Saved Successfully",
    DOWNLOAD_STARTED = "Download Started",
}

export enum CustomReatimeEventName {
    RECOMMENDATIONS_OPENED = "recommendationsOpened",
    RECOMMENDATIONS_BATCH_FETCHED = "recommendationsBatchFetched",
    RECOMMENDATIONS_BATCH_FETCH_FAILED = "recommendationsBatchFetchFailed",
    DOWNLOAD_SAVING_FAILED = "downloadSavingFailed",
    DOWNLOADING_FAILED = "downloadingFailed",
    DOWNLOAD_SUCCEEDED = "downloadSucceeded",
    DOWNLOAD_SAVED_SUCCESSFULLY = "downloadSavedSuccessfully",
    DOWNLOAD_STARTED = "downloadStarted",
}

export const customNameToAction: {
    [key in CustomReatimeEventName]: CustomRealtimeAction;
} = {
    recommendationsOpened: CustomRealtimeAction.RECOMMENDATIONS_OPENED,
    recommendationsBatchFetched:
        CustomRealtimeAction.RECOMMENDATIONS_BATCH_FETCHED,
    recommendationsBatchFetchFailed:
        CustomRealtimeAction.RECOMMENDATIONS_BATCH_FETCH_FAILED,
    downloadSavingFailed: CustomRealtimeAction.DOWNLOAD_SAVING_FAILED,
    downloadingFailed: CustomRealtimeAction.DOWNLOADING_FAILED,
    downloadSucceeded: CustomRealtimeAction.DOWNLOAD_SUCCEEDED,
    downloadSavedSuccessfully: CustomRealtimeAction.DOWNLOAD_SAVED_SUCCESSFULLY,
    downloadStarted: CustomRealtimeAction.DOWNLOAD_STARTED,
};

export interface CustomRealtimeLogs {
    errorName: string;
    errorMessage: string;
    nid: string;
    contentName: string;
    type: string;
}
