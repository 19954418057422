import {
    GiftDashboardState,
    setGlobalGiftDashboard,
} from "JS/Redux/GiftDashboard";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";

export const useGlobalGiftDashboard = () => {
    const dispatch = useAppDispatch();

    return {
        giftDashboard: useAppSelector((state) => state.giftDashboard),
        setGlobalGiftDashboard: (state: Partial<GiftDashboardState>) =>
            dispatch(setGlobalGiftDashboard(state)),
    };
};
