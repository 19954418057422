import React from "react";
import clsx from "clsx";
import {
    InternalStandardProps,
    Theme,
    StandardTextFieldProps,
    TextField,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FieldState } from "JS/Types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& MuiInput-root": {
                borderColor: theme.palette.common.black,
            },
            "& label": {
                "&.Mui-focused": {
                    color: theme.palette.primary.main,
                },
                "&.Mui-error": {
                    color: "red !important",
                },
            },

            "& .Mui-error": {
                "&::after": {
                    borderBottomColor: "red",
                },
            },

            "& .MuiInput-input": {
                color: theme.palette.common.black,
            },
            "& .MuiInputLabel-root": {
                color: `${theme.palette.grey[600]}`,
            },
            "& label.Mui-focused": {
                color: `${theme.palette.primary.main}`,
            },
            "&.MuiInput-underline": {
                borderBottomColor: `${theme.palette.grey[600]} !important`,
            },
            "& .MuiInput-underline:before": {
                borderBottomColor: `${theme.palette.grey[600]} !important`,
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: `${theme.palette.primary.main} !important`,
            },

            "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus":
                {
                    transition:
                        "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
                    transitionDelay: "background-color 5000s, color 5000s",
                },
        },
    }),
);

export interface AppStandardTextFieldProps
    extends InternalStandardProps<StandardTextFieldProps> {
    errorInfo?: FieldState;
}

export const AppStandardTextField = (props: AppStandardTextFieldProps) => {
    const classes = useStyles(props);
    const { className, errorInfo, ...rest } = props;

    return (
        <TextField
            className={clsx(className, classes.root)}
            variant="standard"
            helperText={
                errorInfo && errorInfo.errors.length > 0
                    ? errorInfo.errors[0].message
                    : ""
            }
            error={errorInfo && errorInfo.errors.length > 0}
            {...rest}
        />
    );
};

export const AppStandardTextFieldWithRef = React.forwardRef(
    (props: AppStandardTextFieldProps, ref) => {
        const classes = useStyles(props);
        const { className, errorInfo, ...rest } = props;

        return (
            <TextField
                ref={ref as any}
                className={clsx(className, classes.root)}
                variant="standard"
                helperText={
                    errorInfo && errorInfo.errors.length > 0
                        ? errorInfo.errors[0].message
                        : ""
                }
                error={errorInfo && errorInfo.errors.length > 0}
                {...rest}
            />
        );
    },
);
