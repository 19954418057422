import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "JS/Models";
import { VariantType } from "notistack";

export interface MediaState {
    lastCachedTime?: number;
    isZoomAllowed?: boolean;
    lastLoggedInUser?: User["member_id"];
    commonMessages?: {
        show: boolean;
        message: string;
        variant: VariantType;
    };
}

export const initialCommonMessages: MediaState["commonMessages"] = {
    message: "",
    show: false,
    variant: "success",
};

const initialState: MediaState = {
    lastCachedTime: 0,
    isZoomAllowed: false,
    commonMessages: initialCommonMessages,
};

export const mediaStateSlice = createSlice({
    name: "MediaState",
    initialState,
    reducers: {
        setGlobalMedia: (state, data: PayloadAction<MediaState>) => {
            state.lastCachedTime = data.payload.lastCachedTime;
            state.isZoomAllowed = data.payload.isZoomAllowed;
            return state;
        },
        setCommonMessage: (
            state,
            data: PayloadAction<MediaState["commonMessages"]>,
        ) => {
            state.commonMessages = data.payload;
            return state;
        },
        setLastLoggedInUser: (
            state,
            data: PayloadAction<MediaState["lastLoggedInUser"]>,
        ) => {
            state.lastLoggedInUser = data.payload;
            return state;
        },
        resetMediaState: (state) => {
            return {
                ...initialState,
                lastLoggedInUser: state.lastLoggedInUser,
            };
        },
    },
});
export const {
    setGlobalMedia,
    setCommonMessage,
    setLastLoggedInUser,
    resetMediaState,
} = mediaStateSlice.actions;
