import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "JS/Models";

export interface LoggedInUserState {
    loggedInUser: Partial<User>;
}
const initialState: LoggedInUserState = {
    loggedInUser: {},
};

export const loggedInUserStateSlice = createSlice({
    name: "UserState",
    initialState,
    reducers: {
        setLoggedInUser: (state, data: PayloadAction<User>) => {
            state = {
                ...state,
                loggedInUser: data.payload,
            };
            return state;
        },
        resetLoggedInUser: () => {
            return initialState;
        },
    },
});
export const { resetLoggedInUser, setLoggedInUser } =
    loggedInUserStateSlice.actions;
