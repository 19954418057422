interface FBCourseAnalytics {
    completed_count: number;
    completed_percentage: number;
    completed_steps: number;
    first_completed_date: number;
    first_start_date: number;
    last_completed_date: number;
    last_opened: number;
    last_step_start_date: number;
    last_step_started: string;
    opened_count: number;
    progress_state: number;
    total_steps: number;
}

interface StepAnalytics {
    completed_count: number;
    completed_percentage: number;
    duration: number;
    first_completed_date: number;
    first_start_date: number;
    last_completed_date: number;
    last_opened: number;
    last_played: number;
    last_start_date: number;
    maximum_played: number;
    opened_count: number;
    played_count: number;
    progress_state: number;
}

export enum StepContentType {
    MP3 = "MP3",
    Video = "Video",
    Quiz = "quiz",
    PDF = "PDF",
}

export enum StepActions {
    Open = "Open",
    Complete = "Complete",
    Play = "Play",
    Progress = "Progress",
    QuizInProgress = "QuizInProgress",
    PlayerReady = "PlayerReady",
}

export interface FBStepStats {
    content_type: StepContentType;
    position: number;
    title: string;
    analytics: Partial<StepAnalytics>;
    quiz?: FBQuizStats;
}
export interface FBFunctionStepStats extends FBStepStats {
    durationToLog?: number;
    playerTime?: number;
    total_questions?: number;
}
interface FBSteps {
    [key: string]: FBStepStats;
}

export interface FBCourseStats {
    last_updated: number;
    purchased_state: number;
    state: number;
    steps: FBSteps;
    title: string;
    analytics: Partial<FBCourseAnalytics>;
    type: "GiftCourse" | "Course";
}

export interface FBCourseResponse {
    isCourseCompleted: boolean;
    stats: FBCourseStats;
}
export interface FBStepResponse {
    isCourseCompleted: boolean;
    stats: FBStepStats;
}

export interface FBQuizStats {
    content_type: StepContentType.Quiz;
    type: "GiftCourse" | "Course";
    result: {
        correct_answers: number;
        total_questions: number;
        wrong_answers: number;
    };
    questions?: {
        [key: string]: FBCourseQuestion;
    };
}

interface FBCourseQuestion {
    correct_answer: number;
    options: {
        [key: number]: string;
    };
    position: number;
    question: string;
    user_answer: number;
}

export interface OfflineFBCourseStats {
    [key: string]: FBCourseStats;
}
