import {
    CustomRealtimeLogs,
    CustomReatimeEventName,
    EventNames,
    FirebaseEventAction,
} from "JS/Models";
import { courseFirebaseService } from "JS/Services/Firebase";
import { loggingFirebaseService } from "JS/Services/Firebase/LoggingService";

export const useFirebaseLogger = () => {
    const logFirebaseEvent = (
        eventNames: EventNames,
        action: FirebaseEventAction,
    ) => {
        return courseFirebaseService.logFirebaseEvent(eventNames, action);
    };

    const logCustomEventStats = (
        name: CustomReatimeEventName,
        stats?: Partial<CustomRealtimeLogs>,
    ) => {
        return loggingFirebaseService.logRealtimeAnalysis(name, stats);
    };

    return { logFirebaseEvent, logCustomEventStats };
};
