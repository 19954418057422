import {
    Box,
    Dialog,
    DialogContent,
    DialogProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import clsx from "clsx";
import { AppTypography } from "./AppTypography";
import { AppStandardTextFieldWithRef } from "./TextFields/AppStandardTextField";
import { createStyles, makeStyles } from "@mui/styles";
import { AppButton } from "./AppButton";
import { useEffect, useRef, useState } from "react";
import { AppCircularProgress } from "./Progress/AppCircularProgress";

export interface InputDialogProps extends InternalStandardProps<DialogProps> {
    title: string;
    hint: string;
    cancelText: string;
    okText: string;
    cancelClicked: () => void;
    okClicked: (input: string) => void;
    existingInput?: string;
    confirmLoading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        dialogWrapper: {
            backgroundColor: theme.palette.background.default,
        },
        dialogTitle: {
            color: theme.palette.common.black,
        },
    }),
);

export const InputDialog = (props: InputDialogProps) => {
    const classes = useStyles(props);
    const {
        className,
        title,
        hint,
        cancelText,
        okText,
        okClicked,
        cancelClicked,
        existingInput,
        confirmLoading,
        ...rest
    } = props;
    const [input, setInput] = useState<string>();

    const inputRef = useRef(null);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            const inputElement = document.activeElement as HTMLInputElement;
            if (inputElement) {
                inputElement.blur();
            }
        }
    };

    useEffect(() => {
        setInput(existingInput);
    }, [existingInput]);

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            className={clsx(className, classes.root)}
            {...rest}
        >
            <Box className={classes.dialogWrapper}>
                <DialogContent>
                    <AppTypography className={classes.dialogTitle}>
                        {title}
                    </AppTypography>
                </DialogContent>
                <DialogContent>
                    <AppStandardTextFieldWithRef
                        fullWidth
                        ref={inputRef}
                        label={hint}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyPress}
                    />
                </DialogContent>
                <DialogContent>
                    <Box>
                        <AppButton
                            buttonVariant="primary-filled"
                            style={{ float: "left" }}
                            onClick={cancelClicked}
                        >
                            {cancelText}
                        </AppButton>
                        <AppButton
                            buttonVariant="primary-filled"
                            disabled={confirmLoading}
                            style={{ float: "right" }}
                            onClick={() => okClicked(input)}
                        >
                            {!confirmLoading ? (
                                okText
                            ) : (
                                <AppCircularProgress size={"small"} />
                            )}
                        </AppButton>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    );
};
