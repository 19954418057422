import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Course, PurchasedGfitCourse, ReceivedCourse } from "JS/Models";

export interface CourseState {
    courses?: Course[];
    receivedCourses?: ReceivedCourse[];
    purchasableCoursesForMembers?: Course[];
    purchasedGiftCourses?: PurchasedGfitCourse[];
    giftsGiven?: any;
    activeCourse?: ActiveCourse;
    isUpdateRequired?: boolean;
}

export enum ActiveCourseType {
    RECEIVED = "RECEIVED",
    UNRECIEVED = "UNRECEIVED",
}

interface BaseActiveCourse {
    type: ActiveCourseType;
}

interface ActiveUnReceivedCourse extends BaseActiveCourse {
    type: ActiveCourseType.UNRECIEVED;
    course: Course;
}
interface ActiveReceivedCourse extends BaseActiveCourse {
    type: ActiveCourseType.RECEIVED;
    course: ReceivedCourse;
}

export type ActiveCourse = ActiveUnReceivedCourse | ActiveReceivedCourse;

const initialState: CourseState = {
    courses: [],
    receivedCourses: [],
    purchasableCoursesForMembers: [],
    activeCourse: null,
    purchasedGiftCourses: [],
    giftsGiven: [],
    isUpdateRequired: false,
};

export const courseStateSlice = createSlice({
    name: "courseState",
    initialState,
    reducers: {
        setGlobalCourses: (state, data: PayloadAction<CourseState>) => {
            state.courses = data.payload.courses;
            state.isUpdateRequired = false;
            return state;
        },
        setGlobalReceivedCourses: (state, data: PayloadAction<CourseState>) => {
            state.receivedCourses = data.payload.receivedCourses;

            return state;
        },
        setGlobalPurchasableCourses: (
            state,
            data: PayloadAction<CourseState>,
        ) => {
            if (data.payload.purchasableCoursesForMembers)
                state.purchasableCoursesForMembers =
                    data.payload.purchasableCoursesForMembers;

            return state;
        },
        setGlobalPurchasedCourses: (
            state,
            data: PayloadAction<CourseState>,
        ) => {
            if (data.payload.purchasedGiftCourses)
                state.purchasedGiftCourses = data.payload.purchasedGiftCourses;

            return state;
        },
        setActiveCourse: (state, data: PayloadAction<CourseState>) => {
            state.activeCourse = data.payload.activeCourse;

            return state;
        },
        setGlobalCourseGiftsGiven: (
            state,
            data: PayloadAction<CourseState>,
        ) => {
            if (data.payload.giftsGiven)
                state.giftsGiven = data.payload.giftsGiven;
        },
        resetCourseState: () => {
            return initialState;
        },
        setCourseUpdateRequired: (state) => {
            return {
                ...state,
                isUpdateRequired: true,
            };
        },
    },
});
export const {
    setGlobalCourses,
    setGlobalReceivedCourses,
    setGlobalPurchasableCourses,
    setActiveCourse,
    setGlobalPurchasedCourses,
    setGlobalCourseGiftsGiven,
    resetCourseState,
    setCourseUpdateRequired,
} = courseStateSlice.actions;
