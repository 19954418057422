import { HttpRequest } from "@smithy/protocol-http";
import { S3RequestPresigner } from "@aws-sdk/s3-request-presigner";
import { parseUrl } from "@aws-sdk/url-parser";
import { Sha256 } from "@aws-crypto/sha256-browser";
import { formatUrl } from "@aws-sdk/util-format-url";
import AWS from "aws-sdk";
import { config } from "JS/Config";

export class S3Helper {
    sdk: AWS.S3;

    constructor() {
        this.sdk = new AWS.S3({
            credentials: {
                accessKeyId: config.s3.accessKey,
                secretAccessKey: config.s3.secretAccessKey,
            },
            region: config.s3.region,
        });
    }
}

export const getSizeOfContent = (
    key: string,
    bucket: string,
): Promise<number> => {
    AWS.config.update({
        credentials: {
            accessKeyId: config.s3.accessKey,
            secretAccessKey: config.s3.secretAccessKey,
        },
        region: config.s3.region,
    });

    const s3 = new AWS.S3();
    if (bucket?.length > 0 && bucket.startsWith("https://")) {
        const bucketName = bucket?.split("//")[1]?.split(".")[0];

        return new Promise((res, _) => {
            s3.headObject({ Key: key, Bucket: bucketName }, (err, data) => {
                if (err) res(0);
                const sizeInBytes = data?.ContentLength;
                const sizeInMB = sizeInBytes / 1048576;
                res(sizeInMB);
            });
        });
    }
};

export const getPublicUrl = async (
    prefix: string,
    postfix: string,
    fileName: string,
) => {
    if (!fileName) return "";
    const s3ObjectUrl = parseUrl(`${prefix}${postfix}/${fileName}`);

    const presigner = new S3RequestPresigner({
        credentials: {
            accessKeyId: config.s3.accessKey,
            secretAccessKey: config.s3.secretAccessKey,
        },
        region: config.s3.region,
        sha256: Sha256,
    });

    const url = await presigner.presign(new HttpRequest(s3ObjectUrl), {
        expiresIn: +config.s3.signedUrlExpiry,
    });

    return formatUrl(url);
};

export const getSignedPublicUrl = async (completeURL: string) => {
    const s3ObjectUrl = completeURL && parseUrl(completeURL);

    const presigner = new S3RequestPresigner({
        credentials: {
            accessKeyId: config.s3.accessKey,
            secretAccessKey: config.s3.secretAccessKey,
        },
        region: config.s3.region,
        sha256: Sha256,
    });

    const url = await presigner.presign(new HttpRequest(s3ObjectUrl), {
        expiresIn: +config.s3.signedUrlExpiry,
    });

    return formatUrl(url);
};

export const checkAwsKeysValidation = () => {
    AWS.config.update({
        credentials: {
            accessKeyId: config.s3.accessKey,
            secretAccessKey: config.s3.secretAccessKey,
        },
        region: config.s3.region,
    });

    const sts = new AWS.STS();
    return new Promise((res, rej) => {
        sts.getCallerIdentity((err) => {
            if (err) {
                rej(false);
            } else {
                res(true);
            }
        });
    }) as Promise<boolean>;
};

export const getS3CompletePathFromPreSigned = (url: string) => {
    if (!url) return "";

    return url.split("?")[0];
};
