import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Grid,
    IconButton,
    Theme,
    InternalStandardProps,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { RenderPrintProps, printPlugin } from "@react-pdf-viewer/print";
import { isIOS } from "react-device-detect";
import clsx from "clsx";

import {
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
} from "JS/Models";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useGlobalMedia } from "JS/React/Hooks/Media";
import { useRouting } from "JS/React/Hooks/Routes";
import { useAppSelector } from "JS/Redux/Store";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import {
    useDeleteDocument,
    useDocumentDownloadedURL,
} from "JS/React/Hooks/Database/Document";
import { AppDocument } from "JS/React/Components/AppDocument";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";
import { pdfName } from "JS/Helpers/DocumentHelpers";

export interface OfflineDocumentDetailProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const OfflineDocumentDetail = (props: OfflineDocumentDetailProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const { linkProvider } = useRouting();

    const history = useHistory();
    const params: any = useParams();
    const { pdf, description } = params;
    const [isOpenConfirmDailog, setIsOpenConfirmDailog] = useState(false);

    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );

    const { pdfWrapperRef, downloadedDocumentUrl } = useVariables(pdf);
    const { handleDocumentOpened } = useIndexHooks(pdf);

    const handleToggleConfirmDialog = () => {
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const { deleteDocument } = useDeleteDocument();
    const handleDelete = async () => {
        deleteDocument(pdfName(pdf), downloadedContentNids, () => {
            handleBackNavigation();
        });
        setIsOpenConfirmDailog(false);
    };

    const handleBackNavigation = () => {
        history.push(linkProvider.react.offline().documents().index());
    };

    const printPluginInstance = printPlugin();
    const { Print } = printPluginInstance;

    return (
        <>
            <AppHeader
                title={description}
                canGoBack
                searchable={false}
                onBackClick={handleBackNavigation}
            />
            <div {...rest} className={clsx(className, classes.root)}>
                <Grid container>
                    <Grid item xs={12} className={classes.topNav}>
                        {!isIOS && (
                            <Print>
                                {(props: RenderPrintProps) => (
                                    <IconButton onClick={props.onClick}>
                                        <FontAwesomeIcon
                                            style={{ fontSize: "22px" }}
                                            icon={["fas", "print"]}
                                            className={classes.icon}
                                        />
                                    </IconButton>
                                )}
                            </Print>
                        )}
                        <IconButton>
                            <IconButton>
                                <AppDownloadingIcons
                                    iconType="trash"
                                    onClick={handleToggleConfirmDialog}
                                />
                            </IconButton>
                            {isOpenConfirmDailog && (
                                <AppConfimationDialog
                                    open={isOpenConfirmDailog}
                                    onConfirm={() => handleDelete()}
                                    onClose={handleToggleConfirmDialog}
                                    popupMainText="Are you sure you want to delete document?"
                                    confirmText="Delete"
                                    cancelButtonText="Cancel"
                                    showConfirmFirst={true}
                                    disableScrollLock
                                />
                            )}
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        ref={pdfWrapperRef}
                        className={classes.documentGrid}
                    >
                        {downloadedDocumentUrl && (
                            <AppDocument
                                printPlugin={printPluginInstance}
                                fileUrl={downloadedDocumentUrl}
                                onDocumentOpen={handleDocumentOpened}
                                loader={
                                    <Box display="flex" justifyContent="center">
                                        <AppBackdropProgress
                                            open
                                            backdropText={
                                                messaging.loader.document
                                            }
                                        />
                                    </Box>
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

const useIndexHooks = (pdfName: string) => {
    const { logFirebaseEvent } = useFirebaseLogger();

    const handleDocumentOpened = () => {
        const action: FirebaseEventAction = {
            action: EventActions.OPEN,
            contentTitle: pdfName,
            category: EventCategories.DOCUMENTS,
        };
        logFirebaseEvent(EventNames.DOCUMENT_OPENED, action);
    };

    return {
        handleDocumentOpened,
    };
};

const useVariables = (pdf) => {
    const [downloadedDocumentUrl, setDownloadedDocumentUrl] =
        useState<string>();
    const { setIsZoomAllowed } = useGlobalMedia();
    const pdfWrapperRef = useRef(null);
    const { downloadedURL } = useDocumentDownloadedURL(pdfName(pdf));

    useEffect(() => {
        setIsZoomAllowed(true);
        setDownloadedDocumentUrl(downloadedURL);

        return () => {
            setIsZoomAllowed(false);
        };
    }, [downloadedURL]);

    return {
        pdfWrapperRef,
        downloadedDocumentUrl,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        topNav: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        documentGrid: {
            overflow: "hidden",
        },
    }),
);
