import { User } from "JS/Models";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { setLoggedInUser } from "JS/Redux/User";
import { clearHistory, setLoginDetails } from "JS/Redux/UserHistory";

export const useUserHistory = () => {
    const dispatch = useAppDispatch();

    return {
        clearData: () => {
            dispatch(clearHistory());
        },
        addUser: (user: User) => {
            dispatch(setLoginDetails([user]));
            dispatch(setLoggedInUser(user));
        },
        history: useAppSelector((state) => state.userHistory)?.login_details,
    };
};
