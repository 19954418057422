import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import { useGlobalGifts } from "JS/React/Hooks/Gifts";
import { useRouting } from "JS/React/Hooks/Routes";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Layout, NavigationTypes } from "../Layout";
import { useLocation } from "react-router-dom";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { GiftCategories } from "../Gifts";
import { FavoritesTypes } from "../Favorites";
import { AppDivider } from "JS/React/Components/AppDivider";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import qs from "qs";

export const BundleDetail = (props: BundleDetailProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const { audios, resumeUserBundles, isAudioUpdateRequired } =
        useGlobalAudios();
    const params: any = useParams();

    const { contentId, categoryId, isGift } = params;

    const history = useHistory();
    const { linkProvider } = useRouting();
    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();
    const { homeStack } = useGlobalNavStack();
    const isReceived = () => {
        return isGift === "true";
    };

    const queryParams = useQueryParams();
    const { isMixedContent, scrollTo = "0" } = queryParams;

    const audio = useMemo(() => {
        if (isReceived()) {
            const receivedMemberGift = receivedMemberAudioGifts?.find(
                (g) => g.nid === contentId,
            );
            if (receivedMemberGift) return receivedMemberGift;
            return receivedProspectAudioGifts?.find((g) => g.nid === contentId);
        } else return audios?.find((audio) => audio.nid === contentId);
    }, [
        audios,
        contentId,
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
    ]);

    const { loading } = useAudios(
        !!audios && audios.length > 0,
        isAudioUpdateRequired,
    );
    const isResumeable = (nid, mediaTitle) => {
        let resumeData =
            resumeUserBundles &&
            resumeUserBundles.find(
                (x) => x.nid === nid && x.title === mediaTitle,
            );
        return !!resumeData;
    };

    const navId =
        isReceived() &&
        (categoryId === GiftCategories.RECEIVED_AS_MEMBER ||
            categoryId === GiftCategories.RECEIVED_AS_PROSPECT)
            ? NavigationTypes.MORE
            : categoryId === FavoritesTypes.FAVAUDIOS
            ? NavigationTypes.MORE
            : NavigationTypes.AUDIOS;

    const handleBackNavigation = () => {
        if (!isMixedContent) {
            if (!homeStack.isHomeTrack) {
                if (isReceived()) {
                    if (
                        categoryId === GiftCategories.RECEIVED_AS_MEMBER ||
                        categoryId === GiftCategories.RECEIVED_AS_PROSPECT
                    ) {
                        history.push(
                            linkProvider.react
                                .gifts()
                                .giftAudioDetail(
                                    contentId,
                                    categoryId,
                                    "Audio",
                                ),
                        );
                    } else {
                        if (categoryId === FavoritesTypes.FAVAUDIOS) {
                            history.push(
                                linkProvider.react
                                    .favorites()
                                    .favoriteAudiosDetail(
                                        contentId,
                                        categoryId,
                                        "true",
                                    ),
                            );
                        } else {
                            history.push(
                                linkProvider.react
                                    .audios()
                                    .detail(contentId, categoryId),
                            );
                        }
                    }
                } else {
                    if (categoryId === FavoritesTypes.FAVAUDIOS) {
                        history.push(
                            linkProvider.react
                                .favorites()
                                .favoriteAudiosDetail(
                                    contentId,
                                    categoryId,
                                    "true",
                                    "false",
                                    { scrollTo: +scrollTo },
                                ),
                        );
                    } else {
                        history.push(
                            linkProvider.react
                                .audios()
                                // eslint-disable-next-line no-restricted-globals
                                .detail(contentId, categoryId, "", {
                                    scrollTo: +scrollTo,
                                }),
                        );
                    }
                }
            } else {
                history.goBack();
            }
        } else {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .audioDetail(contentId, categoryId, "false", {
                        isMixedContent: true,
                        scrollTo: scrollTo.toString(),
                    }),
            );
        }
    };

    const onBundleItemClick = (position: number) => {
        if (!isMixedContent) {
            if (categoryId === "favAudios") {
                history.push(
                    linkProvider.react
                        .favorites()
                        .favoriteBundlePlay(contentId, {
                            categoryId: categoryId,
                            isGift: isGift,
                            index: (position - 1).toString(),
                            scrollTo: +scrollTo,
                        }),
                );
            } else {
                history.push(
                    linkProvider.react.bundle().play(contentId, {
                        categoryId: categoryId,
                        isGift: isGift,
                        index: (position - 1).toString(),
                        scrollTo: +scrollTo,
                    }),
                );
            }
        } else {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .bundlePlay(
                        contentId,
                        categoryId,
                        isGift,
                        (position - 1).toString(),
                        { isMixedContent: true, scrollTo: scrollTo.toString() },
                    ),
            );
        }
    };

    return (
        <Layout navId={navId} navPath={useLocation().pathname}>
            <AppHeader
                title={audio?.title}
                canGoBack
                searchable={false}
                onBackClick={handleBackNavigation}
            />
            {!loading &&
                audio &&
                audio.media
                    .map((a) => a)
                    .sort((a, b) => a.position - b.position)
                    ?.map((m) => {
                        return (
                            <Box
                                className={clsx(classes.root, className)}
                                {...rest}
                                p={2}
                                onClick={() => onBundleItemClick(m?.position)}
                                key={m.media_title}
                            >
                                <Box className={classes.listItemWrapper}>
                                    <AppTypography
                                        className={classes.listItemText}
                                    >
                                        {m.media_title}
                                    </AppTypography>
                                    {isResumeable(audio.nid, m.media_title) && (
                                        <FontAwesomeIcon
                                            style={{
                                                fontSize: "22px",
                                                color: "#01445E",
                                            }}
                                            icon={["fas", "play"]}
                                        />
                                    )}
                                </Box>
                                <AppDivider />
                            </Box>
                        );
                    })}

            {loading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.audios}
                />
            )}
        </Layout>
    );
};

export interface BundleDetailProps extends InternalStandardProps<BoxProps> {}

const useQueryParams = () => {
    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { isMixedContent = false, scrollTo = "0" } = parsedQueryString;

        return {
            isMixedContent: !!isMixedContent ? true : false,
            scrollTo: scrollTo,
        };
    }, [location?.search]);

    return queryParams;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.grey[600],
        },
        listItemWrapper: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        listItemText: {
            color: theme.palette.grey[600],
        },
    }),
);
