export const setThemeModeLocal = (data: themeMode) => {
    const prevData = localStorage.getItem("themeMode")
        ? JSON.parse(localStorage.getItem("themeMode"))
        : null;
    const updatedData = {
        ...prevData,
        ...data,
    };
    localStorage.setItem("themeMode", JSON.stringify(updatedData));
};

export const getThemeModeLocal = (): themeMode => {
    return localStorage.getItem("themeMode")
        ? JSON.parse(localStorage.getItem("themeMode"))
        : null;
};

export const getLocalDefaultSystem = (): boolean => {
    const themeMode = getThemeModeLocal();
    if (themeMode?.defaultSystem !== undefined) {
        return themeMode?.defaultSystem === true ? true : false;
    } else {
        return true;
    }
};

export const getLocalDefaultMode = (): "light" | "dark" => {
    const themeMode = getThemeModeLocal();
    if (themeMode?.mode) {
        return themeMode?.mode === "dark" ? "dark" : "light";
    } else {
        return "light";
    }
};

export interface themeMode {
    mode?: "light" | "dark";
    defaultSystem?: boolean;
}
