import { config } from "JS/Config";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";
import { ContentAttribute } from "JS/Models/Attributes";

export class AttributesService extends BaseService {
    async getAttributesByContentType(contentType: string) {
        return (
            await this.doXHR<AppResponse<ContentAttribute[]>>({
                url: this.routes.server.api.attibutes.getAttributesByContentType(
                    config?.user?.memberId,
                    contentType,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }
}
