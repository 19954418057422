import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { AppTypography } from "JS/React/Components/AppTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppCompletionProgress } from "JS/React/Components/AppCompletionProgress";
import { GraphicEq as GraphicEqIcon } from "@mui/icons-material";
import { messaging } from "JS/Helpers/UserMessaging";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            minHeight: "90px",
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        ListTitleWarpper: {
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
            padding: "2%",
        },
        ProgressMainWarpper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        ProgressWarpper: {
            width: "95%",
        },
        ListIconsWrapper: {
            width: "10%",
            margin: theme.spacing(1),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        ListMainIcon: {
            fontSize: "25px",
        },
        ContentTitleWrapper: {
            width: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        ContentTitle: {
            width: "95%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.palette.primary.main,
        },
    }),
);

export interface QueueItemsProps extends InternalStandardProps<BoxProps> {
    onCancelDownload?: () => void;
    contentTitle: string;
    isDownloading: boolean;
    timeRemaining?: string;
    downloadingSpeed?: string;
    downloadPercentage?: number;
    isShowProgress?: boolean;
    contentType?: string;
    status?: string;
}

export const QueueItems = (props: QueueItemsProps) => {
    const classes = useStyles(props);
    const {
        className,
        isDownloading,
        onCancelDownload,
        downloadingSpeed,
        contentTitle,
        timeRemaining,
        downloadPercentage,
        isShowProgress,
        contentType,
        status,
        ...rest
    } = props;
    return (
        <Box {...rest} className={clsx(className, classes.root)}>
            <Box className={classes.ListTitleWarpper}>
                <Box className={classes.ListIconsWrapper}>
                    {(contentType === "video" ||
                        contentType === "giftVideo" ||
                        contentType === "courseVideo") && (
                        <FontAwesomeIcon
                            className={clsx(
                                className,
                                classes.ListMainIcon,
                                classes.grey600,
                            )}
                            icon={["fas", "video"]}
                        />
                    )}
                    {contentType === "document" ||
                        (contentType === "coursePdf" && (
                            <FontAwesomeIcon
                                className={clsx(
                                    className,
                                    classes.ListMainIcon,
                                    classes.grey600,
                                )}
                                icon={["far", "clipboard"]}
                            />
                        ))}
                    {(contentType === "audio" ||
                        contentType === "giftAudioBundle" ||
                        contentType === "audioBundle" ||
                        contentType === "giftAudio" ||
                        contentType === "courseMP3") && (
                        <GraphicEqIcon
                            className={classes.grey600}
                            fontSize="large"
                        />
                    )}
                </Box>
                <Box className={classes.ContentTitleWrapper}>
                    <AppTypography
                        variant="h6"
                        className={classes.ContentTitle}
                    >
                        {contentTitle}
                    </AppTypography>
                    <AppTypography
                        variant="body1"
                        className={classes.ContentTitle}
                    >
                        {isDownloading || status === "inProgress"
                            ? `${messaging.downloadingQueue.downloading}`
                            : `${messaging.downloadingQueue.Queued}`}
                    </AppTypography>
                </Box>
            </Box>
            <Box className={classes.ProgressMainWarpper}>
                <Box className={classes.ProgressWarpper}>
                    {isShowProgress && (
                        <AppCompletionProgress
                            isDownloadingQueue={true}
                            loading={false}
                            title={downloadingSpeed}
                            lowerTitle={timeRemaining}
                            value={
                                !isDownloading && status === "inProgress"
                                    ? 100
                                    : downloadPercentage
                            }
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};
