import { messaging } from "JS/Helpers/UserMessaging";
import { useRouting } from "JS/React/Hooks/Routes";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const AttachedSkuOffline = (props: AttachedSkuOfflineProps) => {
    useRedirectToOfflineHome();
    return null;
};

const useRedirectToOfflineHome = () => {
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { enqueueSnackbar } = useSnackbar();

    const redirectToHomeWithMessage = () => {
        enqueueSnackbar(messaging.attachSku.offline, {
            variant: "warning",
        });
        history.replace(linkProvider.react.offline().index());
    };

    useEffect(() => {
        redirectToHomeWithMessage();
    }, []);
};

export interface AttachedSkuOfflineProps {}
