import { EventCategories, EventNames } from "JS/Models";
import { MiniPlayerState } from "JS/Redux/MiniPlayer";

export const getCategoryForType = (
    contentItemName: string, // "GiftVideo" | "GiftProsVideo" | "MP3" | "GiftMP3",
): EventCategories => {
    if (contentItemName === "MP3") return EventCategories.AUDIOS;
    if (["GiftMP3", "GiftProsMP3"].includes(contentItemName))
        return EventCategories.GIFT_AUDIOS;
    if (contentItemName === "Video") return EventCategories.VIDEOS;
    if (["GiftVideo", "GiftProsVideo"].includes(contentItemName))
        return EventCategories.GIFT_VIDEOS;
};

export const getEventNameForType = (
    contentItemName: string, // "GiftVideo" | "GiftProsVideo" | "MP3" | "GiftMP3",
    isDownloaded?: boolean,
    isRecommended?: boolean,
): EventNames => {
    if (isRecommended) {
        if (isDownloaded) return EventNames.RECOMMENDATION_PLAYED;

        return EventNames.RECOMMENDATION_LIVE_STREAM;
    }
    if (contentItemName === "MP3")
        return isDownloaded
            ? EventNames.AUDIO_PLAYED
            : EventNames.AUDIO_LIVE_STREAM;
    if (["GiftMP3", "GiftProsMP3"].includes(contentItemName))
        return isDownloaded
            ? EventNames.GIFT_AUDIO_PLAYED
            : EventNames.GIFT_AUDIO_LIVESTREAM;
    if (contentItemName === "Video")
        return isDownloaded
            ? EventNames.VIDEO_PLAYED
            : EventNames.VIDEO_LIVE_STREAM;
    if (["GiftVideo", "GiftProsVideo"].includes(contentItemName))
        return isDownloaded
            ? EventNames.GIFT_VIDEO_PLAYED
            : EventNames.GIFT_VIDEO_LIVESTREAM;
};

export const getCurrentUrl = () => {
    return `${window.location.pathname}${window.location.search}`;
};

export const setMiniPlayerInLocalStorage = (player: MiniPlayerState) => {
    if (player) {
        localStorage.setItem("miniplayer", JSON.stringify(player));
    }
};

export const getMiniPlayerFromLocalStorage = (): MiniPlayerState => {
    const player = localStorage.getItem("miniplayer");

    if (player) {
        localStorage.removeItem("miniplayer");
        return JSON.parse(player);
    }

    return null;
};
