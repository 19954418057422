import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { AppButton } from "../AppButton";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },

        content: {
            position: "relative",
            minHeight: "150px",
            display: "flex",
            justifyContent: "flex-start",
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    }),
);

export interface RedirectionDialogProps
    extends InternalStandardProps<DialogProps> {
    onClose: () => void;
    open: boolean;
    dialogMessage?: string;
}

export const RedirectionDialog = (props: RedirectionDialogProps) => {
    const classes = useStyles(props);
    const { className, onClose, dialogMessage, open } = props;

    return (
        <Box className="App" style={{ textAlign: "center" }}>
            <Dialog
                data-testid="consent-redirection-dialog"
                role="dialog"
                aria-modal="true"
                maxWidth={"sm"}
                fullWidth
                className={clsx(className, classes.root)}
                open={open}
            >
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>

                <DialogActions
                    style={{
                        display: "flex",
                    }}
                >
                    <AppButton
                        role="button"
                        buttonVariant="primary-filled"
                        onClick={onClose}
                    >
                        Next
                    </AppButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
