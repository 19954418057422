import { getDexieConnectionRefresh } from "./Dexie";

export const deleteCourseById = async (id: string) => {
    await getDexieConnectionRefresh().courses.delete(id);
};

export const bulkDeleteStepsByIds = async (ids: string[]) => {
    await getDexieConnectionRefresh().courses.bulkDelete(ids);
    return ids;
};

export const getStepById = async (
    id: string /**memberId-courseNid-stepNid */,
) => {
    return await getDexieConnectionRefresh().courses.get(id);
};

export const bulkGetSteps = async (
    ids: string[] /**memberId-courseNid-stepNid */,
) => {
    return await getDexieConnectionRefresh().courses.bulkGet(ids);
};
