import { config } from "JS/Config";
import { getSignedPublicUrl } from "JS/Helpers";
import { DocumentDetails } from "JS/Models";
import { BaseService } from "../BaseService";

export class DocumentsService extends BaseService {
    async getDocumentDetails() {
        const docsConfig = config?.s3?.documents;

        const url = await getSignedPublicUrl(
            `${docsConfig?.baseUrl}/${docsConfig?.description}`,
        );

        return (
            await this.doXHR<DocumentDetails[]>({
                url: url,
                method: "GET",
            })
        ).data;
    }
}
