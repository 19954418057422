import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RecommendationBatch, Recommendations } from "JS/Models";

export interface RecommendationsState {
    recommendations: Recommendations[];
    batch: RecommendationBatch;
    lastFetched: number;
    noOfPopups: number;
}

const initialState: RecommendationsState = {
    recommendations: null,
    batch: null,
    lastFetched: 0,
    noOfPopups: 0,
};

export const recommendationsStateSlice = createSlice({
    name: "recommendationsState",
    initialState,
    reducers: {
        setGlobalRecommendations: (
            state,
            data: PayloadAction<Partial<RecommendationsState>>,
        ) => {
            state = {
                ...state,
                ...data.payload,
            };
            return state;
        },
        resetRecommendationsState: () => {
            return initialState;
        },
    },
});
export const { setGlobalRecommendations, resetRecommendationsState } =
    recommendationsStateSlice.actions;
