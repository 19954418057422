import axios from "axios";
import { config } from "JS/Config";
import { deviceDetect } from "react-device-detect";
import { routesForContext } from "JS/Routing";
import { FeedbackResponse } from "JS/Models/Feedback";

export class FeedbackService {
    protected routes = routesForContext()();
    async sendFeedback(field: string): Promise<FeedbackResponse> {
        const formData = new FormData();
        formData.append("ltdid", config.user.memberId);
        formData.append("feedback[content]", field);
        formData.append(
            "feedback[os]",
            deviceDetect(window?.navigator?.userAgent)?.osVersion,
        );
        formData.append(
            "feedback[hardware]",
            `${deviceDetect(window?.navigator?.userAgent)?.model} - React`,
        );
        formData.append("feedback[internet_connection]", "");
        formData.append(
            "feedback[application_version]",
            `${config.appVersion}`,
        );
        formData.append("feedback[application_name]", "LTDMedia");

        return (
            await axios({
                url: this.routes.server.apiFeedback.feedback.sendFeedback(),
                method: "POST",
                data: formData,
            })
        ).data;
    }
}
