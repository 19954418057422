import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import { config } from "JS/Config";
import {
    getFirestore,
    Firestore,
    doc,
    setDoc,
    FirestoreError,
    onSnapshot,
} from "firebase/firestore";
import { isStageEnvironment } from "JS/Helpers";
import { BaseService } from "../BaseService";
import { captureSentryError } from "JS/Helpers/SentryHelper";
import {
    AudioVideoResume,
    FirestoreResumeData,
    PlaylistResume,
} from "JS/Models/Firebase/Resume";

export class ResumeService extends BaseService {
    private app: FirebaseApp;
    private store: Firestore;
    private baseCollection = "ResumeData";
    private activeCollection = "Active";
    private mergeOption = { merge: true };

    constructor() {
        super();

        const apps = getApps();
        const alreadyExist = apps?.find((x) => x.name === "[DEFAULT]");
        if (!alreadyExist) {
            this.app = initializeApp(config.firebase.courses);
        } else {
            this.app = alreadyExist;
        }
        this.store = getFirestore(this.app);
    }

    public async logResumeData(
        resumeData: AudioVideoResume[] | PlaylistResume[],
        contentType: string,
        memberId: string,
        deviceId: string,
    ) {
        if (this.shouldLog(contentType)) {
            const docRef = this.getDocRef(memberId, deviceId);

            setDoc(docRef, { [contentType]: resumeData }, this.mergeOption)
                .then(() => {
                    this.logOnConsole(
                        resumeData,
                        contentType,
                        memberId,
                        deviceId,
                    );
                })
                .catch((error) => {
                    console.log("Failed to sync offline resume data", error);
                });
        }
    }

    public getDataSnapshot = (
        memberId: string,
        deviceId: string,
        callback: (activity) => void,
        onError: (error: FirestoreError) => void,
    ) => {
        return onSnapshot(
            this.getDoc(memberId, deviceId),
            (snapshot) => {
                const data = snapshot.data();
                const allLogs: FirestoreResumeData = data
                    ? JSON.parse(JSON.stringify(data))
                    : [];
                callback(allLogs);
            },
            (error) => onError(error),
        );
    };

    private shouldLog(contentType: string) {
        if (!this.store) this.store = getFirestore(this.app);
        const storeInitialized = !!this.store;

        if (!storeInitialized) {
            const err = new Error("Store not initilized for logs.");
            captureSentryError(err, {
                location: `${contentType}-Resume`,
            });
        }

        return storeInitialized;
    }

    private getDocRef(memberId: string, deviceId: string) {
        return doc(
            this.store,
            this.baseCollection,
            memberId,
            this.activeCollection,
            deviceId,
        );
    }

    private logOnConsole(
        resumeData: AudioVideoResume[] | PlaylistResume[],
        type: string,
        memberId: string,
        deviceId: string,
    ) {
        if (isStageEnvironment())
            console.log("Resume Logs", memberId, deviceId, type, resumeData);
    }

    private getDoc(memberId: string, deviceId: string) {
        return doc(
            this.store,
            this.baseCollection,
            memberId,
            this.activeCollection,
            deviceId,
        );
    }
}
