import React from "react";

import { SnackbarProvider } from "notistack";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            backgroundColor: `${theme.snackbar.variantError} !important`,
        },
        success: {
            backgroundColor: `${theme.snackbar.variantSuccess} !important`,
        },
        info: {
            backgroundColor: `${theme.snackbar.variantInfo} !important`,
        },
        warning: {
            backgroundColor: `${theme.snackbar.variantWarning} !important`,
        },
    }),
);

export const AppSnackbarProvider = (props: { children: React.ReactNode }) => {
    const classes = useStyles(props);

    return (
        <SnackbarProvider
            classes={{
                variantError: classes.error,
                variantSuccess: classes.success,
                variantInfo: classes.info,
                variantWarning: classes.warning,
            }}
            maxSnack={4}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            autoHideDuration={3000}
        >
            {props.children}
        </SnackbarProvider>
    );
};
