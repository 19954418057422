import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { routesForContext } from "JS/Routing";
import { config } from "JS/Config";
import { clearLocalStorage } from "JS/Helpers";
import { store } from "JS/Redux/Store";
import { resetCourseState } from "JS/Redux/Course";
import { resetEventsState } from "JS/Redux/Event";
import { softResetGiftState } from "JS/Redux/Gift";
import { resetHomeState } from "JS/Redux/Home";
import { resetWebcastState } from "JS/Redux/Webcast";
import { resetMediaState } from "JS/Redux/media";
import { resetNavStack } from "JS/Redux/NavStack";
import { messaging } from "JS/Helpers/UserMessaging";
import { resetRecommendationsState } from "JS/Redux/Recommendations";
import { resetMediaEssentials } from "JS/Redux/MediaEssentials";
import {
    resetGlobalDownloadClickCount,
    resetGlobalDownloadCount,
} from "JS/Redux/DownloadCount";
import {
    deleteDeviceTokenApi,
    getLocalFcmToken,
} from "JS/Helpers/PushNotifications";
import { setEncryptedLocalStorageItem } from "JS/Helpers/LocalStorageHelpers";
import { appConstants } from "JS/Helpers/Contants";
import { resetAudioState } from "JS/Redux/Audio";
import { resetVideoState } from "JS/Redux/Video";
import { resetPlaylistResumeMap } from "JS/Redux/Playlist";
import { resetMixedContentState } from "JS/Redux/MixedContent";

const setAccessToken = (accessToken: string) => {
    setEncryptedLocalStorageItem(
        accessToken,
        appConstants?.localStorage.accessToken,
    );
    config.accessToken = accessToken;
};

const setRefreshToken = (refreshToken: string) => {
    setEncryptedLocalStorageItem(
        refreshToken,
        appConstants?.localStorage.refreshToken,
    );
    config.refreshToken = refreshToken;
};

export const logoutUser = async () => {
    const token = getLocalFcmToken();
    if (token) {
        try {
            await deleteDeviceTokenApi(config.user.memberId, token);
        } catch (err) {}
    }

    clearLocalStorage();
    store.dispatch(resetAudioState());
    store.dispatch(resetVideoState());
    store.dispatch(resetPlaylistResumeMap());
    store.dispatch(resetCourseState());
    store.dispatch(resetEventsState());
    store.dispatch(softResetGiftState());
    store.dispatch(resetHomeState());
    store.dispatch(resetWebcastState());
    store.dispatch(resetMediaState());
    store.dispatch(resetNavStack());
    store.dispatch(resetRecommendationsState());
    store.dispatch(resetMixedContentState());
    if (
        store?.getState()?.mediaEssentials?.download_warning_cleared_on_logout
    ) {
        store.dispatch(resetGlobalDownloadCount());
    }
    store.dispatch(resetGlobalDownloadClickCount());
    store.dispatch(resetMediaEssentials());
    setTimeout(() => {
        window.location.reload();
    }, 500);
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export class BaseService {
    private manager: AxiosInstance;
    protected routes = routesForContext()();

    constructor() {
        this.manager = axios.create({
            headers: {
                //Instead of X-CSRF-TOKEN, rely on X-XSRF-TOKEN, laravel supposedly sent it with every response.
                //It contains CSRF token in encrypted form. With every response its lifetime/value should reflect most recent one in case previous session expires and new one exists as a results of rememberMe
                //'X-CSRF-TOKEN': csrftoken,
                Accept: "application/json",
            },
            baseURL: this.routes.server.root(),
            // baseURL: `https:/ltd-server-staging.herokuapp.com/feedbacks`,
        });

        this.manager.interceptors.request.use((config) => {
            if (
                // eslint-disable-next-line @typescript-eslint/prefer-includes
                config.url.indexOf(this.routes.server.root()) > -1 ||
                // eslint-disable-next-line @typescript-eslint/prefer-includes
                config.baseURL.indexOf(this.routes.server.root()) > -1
            ) {
            }
            return config;
        });

        this.manager.interceptors.response.use(
            (res) => {
                return res;
            },
            async (err) => {
                const originalRequest = err.config;
                if (err.response) {
                    // Access Token was expired.
                    if (
                        (err.response.status === 401 ||
                            err.response.status === 498 ||
                            err.response.status === 419 ||
                            err.response.status === 400 ||
                            err.response.status === 499) &&
                        !originalRequest._retry
                    ) {
                        if (isRefreshing) {
                            return new Promise(function (resolve, reject) {
                                failedQueue.push({ resolve, reject });
                            })
                                .then((token) => {
                                    if (
                                        originalRequest.headers["authorization"]
                                    ) {
                                        originalRequest.headers[
                                            "authorization"
                                        ] = `Bearer ${token}`;
                                    } else {
                                        const reqFormData =
                                            originalRequest.data;
                                        reqFormData.delete("access_token");
                                        reqFormData.append(
                                            "access_token",
                                            `${token}`,
                                        );
                                    }
                                    originalRequest._retry = true;
                                    return Promise.resolve(
                                        this.manager(originalRequest),
                                    );
                                })
                                .catch((err) => {
                                    return Promise.reject(err);
                                });
                        }
                        // Rebuilding Token from refresh token.
                        try {
                            originalRequest._retry = true;
                            isRefreshing = true;

                            const formData = new FormData();
                            formData.append(
                                "refresh_token",
                                `${config.refreshToken}`,
                            );

                            let rs = await this.manager.post(
                                this.routes.server.api.users.rebuildAuth,
                                formData,
                            );

                            if (
                                rs &&
                                rs.status === 200 &&
                                rs?.data?.response?.status
                            ) {
                                const { access_token, refresh_token } =
                                    rs.data?.response?.data?.users;
                                setAccessToken(access_token);
                                setRefreshToken(refresh_token);
                                if (originalRequest.headers["authorization"]) {
                                    originalRequest.headers[
                                        "authorization"
                                    ] = `Bearer ${access_token}`;
                                } else {
                                    let reqFormData = originalRequest.data;
                                    reqFormData.delete("access_token");
                                    reqFormData.append(
                                        "access_token",
                                        `${access_token}`,
                                    );
                                }
                                originalRequest._retry = true;
                                processQueue(null, access_token);
                                return Promise.resolve(
                                    this.manager(originalRequest),
                                );
                            } else {
                                // Refresh Token is expired.
                                isRefreshing = false;
                                processQueue(err, null);
                                logoutUser();
                                return Promise.reject(err);
                            }
                        } catch (err) {
                            // Refresh Token is expired.
                            isRefreshing = false;
                            processQueue(err, null);
                            logoutUser();
                            return Promise.reject(err);
                        } finally {
                            isRefreshing = false;
                        }
                    }
                    return Promise.reject(err);
                }
            },
        );
    }

    protected doXHR<T>(
        config: AxiosRequestConfig,
    ): Promise<AxiosResponse<T, any>> {
        return this.manager
            .request<T>(config)
            .then((res) => {
                return res;
            })
            .catch(async (err): Promise<any> => {
                if (err.message === messaging.apiError.network) {
                    return {
                        data: {
                            response: {
                                data: {},
                                status: false,
                                message: messaging.apiError.somethingWentWrong,
                            },
                            data: {},
                            status: false,
                            message: messaging.apiError.somethingWentWrong,
                        },
                        status: 0,
                        statusText: "",
                        config: {},
                        headers: {},
                    };
                } else {
                    throw err;
                }
            });
    }
}

export const routes = routesForContext()();
const axiosInstance = axios.create({
    headers: {
        Accept: "application/json",
    },
    baseURL: routes.server.root(),
});
export function doXHR<T>(
    config: AxiosRequestConfig,
): Promise<AxiosResponse<T, any>> {
    return axiosInstance
        .request<T>(config)
        .then((res) => {
            return res;
        })
        .catch(async (err): Promise<any> => {
            if (err.message === messaging.apiError.network) {
                return {
                    data: {
                        response: {
                            data: {},
                            status: false,
                            message: messaging.apiError.somethingWentWrong,
                        },
                        data: {},
                        status: false,
                        message: messaging.apiError.somethingWentWrong,
                    },
                    status: 0,
                    statusText: "",
                    config: {},
                    headers: {},
                };
            } else {
                throw err;
            }
        });
}
