import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useGetGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import { DashboradLogs, GiftDashboardListing } from "./GiftDashboardListing";
import groupBy from "lodash/groupBy";
import { PaginationInfo, paginateData } from "JS/Types/Pagination";
import { messaging } from "JS/Helpers/UserMessaging";
import { InfiniteScrollManager } from "JS/React/Container/InfiniteScrollManager";
import moment from "moment";
import { sortArrayByDate, sortArrayByKey } from "JS/Helpers";
import { AppTypography } from "JS/React/Components/AppTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GiftDashboardFiltersDialog } from "./Components/GiftDashboardFiltersDialog";
import { AppRadioGroup } from "JS/React/Components/AppRadioGroup";
import { GiftDashboardFilters } from "JS/Models";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { Layout } from "../Layout";
import { useRouting } from "JS/React/Hooks/Routes";
import { useHistory } from "react-router-dom";
import { useGlobalGiftDashboard } from "JS/React/Hooks/GiftDashboard";

export const GiftDashboard = (props: GiftDashboardProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const {
        activeFilter,
        filterDailog,
        toogleFilterDialog,
        filterOptions,
        handleFilterChange,
        handleGoBack,
    } = useFilterDashboardHandler();
    const {
        finalDashboardlogs,
        giftDashboardLoading,
        paginationInfo,
        giftDashboardError,
        setPaginationInfo,
    } = useHandlerGiftDashboard(activeFilter);

    return (
        <Layout>
            <Box className={clsx(className, classes.root)} {...rest}>
                {giftDashboardLoading && (
                    <AppBackdropProgress
                        open
                        backdropText={messaging?.giftDashboard?.fetching}
                    />
                )}
                <Box className={classes.giftDashboardHeader}>
                    <Box
                        className={classes.giftDashBackWrapper}
                        onClick={handleGoBack}
                    >
                        <FontAwesomeIcon
                            className={classes.backIcon}
                            icon={["fas", "chevron-left"]}
                        />
                        <AppTypography
                            className={clsx(classes.headingItem)}
                            fontWeight={"bold"}
                            variant="h5"
                        >
                            Dashboard
                        </AppTypography>
                    </Box>
                    {!giftDashboardLoading && (
                        <>
                            <Box>
                                <FontAwesomeIcon
                                    className={clsx(classes.filterIcon)}
                                    icon={["fas", "filter"]}
                                    onClick={toogleFilterDialog}
                                />
                            </Box>
                            <GiftDashboardFiltersDialog
                                open={filterDailog}
                                onClose={toogleFilterDialog}
                                showTitle={true}
                                titleText="Filter"
                            >
                                <AppRadioGroup
                                    onChange={handleFilterChange}
                                    radios={filterOptions}
                                    variant={"small"}
                                    value={activeFilter}
                                />
                            </GiftDashboardFiltersDialog>
                        </>
                    )}
                </Box>
                {!giftDashboardLoading && (
                    <InfiniteScrollManager
                        onLastContentHit={() => {
                            setPaginationInfo({
                                ...paginationInfo,
                                perPage: paginationInfo.perPage + 25,
                            });
                        }}
                        paginationInfo={paginationInfo}
                    >
                        <GiftDashboardListing
                            giftDashboardLogs={finalDashboardlogs}
                            activeFilter={activeFilter}
                            apiError={giftDashboardError}
                        />
                    </InfiniteScrollManager>
                )}
            </Box>
        </Layout>
    );
};

interface GiftFilterOptions {
    label: string;
    value: string;
}

const useFilterDashboardHandler = () => {
    const [filterDailog, setFilterDialog] = useState<boolean>(false);
    const { linkProvider } = useRouting();
    const history = useHistory();

    const filterOptions: GiftFilterOptions[] = useMemo(() => {
        return Object.keys(GiftDashboardFilters).map((key) => {
            return {
                label: GiftDashboardFilters[key],
                value: GiftDashboardFilters[key],
            };
        });
    }, []);

    const { homeStack } = useGlobalNavStack();
    const { giftDashboard, setGlobalGiftDashboard } = useGlobalGiftDashboard();

    const toogleFilterDialog = () => {
        setFilterDialog(!filterDailog);
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const filterType = e.target.value as GiftDashboardFilters;
        setGlobalGiftDashboard({ dashboadActiveFilter: filterType });
        toogleFilterDialog();
    };

    const handleGoBack = () => {
        if (!homeStack?.isHomeTrack) {
            history.push(linkProvider.react.more().index());
        } else {
            history.push(linkProvider.react.home());
        }
    };

    return {
        activeFilter: giftDashboard?.dashboadActiveFilter,
        filterDailog,
        toogleFilterDialog,
        filterOptions,
        handleFilterChange,
        handleGoBack,
    };
};

const useHandlerGiftDashboard = (activeFilter: GiftDashboardFilters) => {
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 50,
        total: 0,
    });

    const {
        giftDashboardLogs,
        loading: giftDashboardLoading,
        isError: giftDashboardError,
    } = useGetGiftDashboard();

    const updatedLogsList: DashboradLogs[] = useMemo(() => {
        setPaginationInfo({
            ...paginationInfo,
            total: giftDashboardLogs?.length,
        });
        return (
            giftDashboardLogs &&
            giftDashboardLogs.map((item) => {
                return {
                    ...item,
                    createdDate: moment
                        .unix(item.createdAt / 1000)
                        .format("DD/MM/YYYY"),
                    receiverFullName:
                        item?.receiverFirstName && item?.receiverLastName
                            ? `${item.receiverFirstName} ${item.receiverLastName}`
                            : "Others",
                };
            })
        );
    }, [giftDashboardLogs, activeFilter]);

    const sortedDashboardLogs = useMemo(() => {
        const sortedDateWise = sortArrayByDate(
            updatedLogsList,
            "createdAt",
            "DESC",
        ) as DashboradLogs[];
        if (activeFilter === GiftDashboardFilters.BYDATE) {
            return sortedDateWise;
        } else if (activeFilter === GiftDashboardFilters.BYTYPE) {
            return sortArrayByKey(
                sortedDateWise,
                "contentType",
                "ASC",
            ) as DashboradLogs[];
        } else if (activeFilter === GiftDashboardFilters.BYMEMBER) {
            return sortArrayByKey(
                sortedDateWise,
                "receiverFullName",
                "ASC",
            ) as DashboradLogs[];
        } else {
            return sortedDateWise;
        }
    }, [updatedLogsList, activeFilter]);

    const paginatedLogs = useMemo(() => {
        return paginateData(sortedDashboardLogs, paginationInfo).data;
    }, [paginationInfo, sortedDashboardLogs]);

    const finalDashboardlogs = useMemo(() => {
        if (activeFilter === GiftDashboardFilters.BYDATE) {
            return groupBy(paginatedLogs, "createdDate");
        } else if (activeFilter === GiftDashboardFilters.BYTYPE) {
            return groupBy(paginatedLogs, "contentType");
        } else if (activeFilter === GiftDashboardFilters.BYMEMBER) {
            return groupBy(paginatedLogs, "receiverFullName");
        } else {
            return { All: paginatedLogs };
        }
    }, [paginatedLogs, activeFilter]);

    return {
        finalDashboardlogs,
        giftDashboardLoading,
        paginationInfo,
        giftDashboardError,
        setPaginationInfo,
    };
};

export interface GiftDashboardProps extends InternalStandardProps<BoxProps> {}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
        },
        giftDashboardHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: theme.spacing(2),
        },
        headingItem: {
            color: theme.palette.grey.A100,
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        filterIcon: {
            fontSize: "26px",
            cursor: "pointer",
            color: theme.palette.primary.main,
        },
        backIcon: {
            color: theme.palette.grey.A100,
            fontSize: "20px",
            marginRight: theme.spacing(2),
        },
        giftDashBackWrapper: {
            display: "flex",
            alignItems: "center",
        },
    }),
);
