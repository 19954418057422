import { config } from "JS/Config";
import { appConstants } from "JS/Helpers/Contants";
import { setEncryptedLocalStorageItem } from "JS/Helpers/LocalStorageHelpers";
import { courseFirebaseService } from "JS/Services/Firebase";
import { FBAuthService } from "JS/Services/Firebase";
import { loggingFirebaseService } from "JS/Services/Firebase/LoggingService";
import { webcastFirebaseService } from "JS/Services/Firebase/WebcastService";

export const fbAuthService = new FBAuthService();

export const useFirebaseAuth = () => {
    const coursesFirebaseSignIn = async () => {
        try {
            if (!config.firebase.courses.token.length) {
                const res = await fbAuthService.getFirebaseToken("courses");
                config.firebase.courses.token = res.response.data;
                setEncryptedLocalStorageItem(
                    res.response.data,
                    appConstants?.localStorage.courseFirebaseToken,
                );
            }

            await courseFirebaseService.signInWithToken();
        } catch (err) {
            if (err.code === "auth/invalid-custom-token") {
                const res = await fbAuthService.getFirebaseToken("courses");
                config.firebase.courses.token = res.response.data;
                setEncryptedLocalStorageItem(
                    res.response.data,
                    appConstants?.localStorage.courseFirebaseToken,
                );
                await courseFirebaseService.signInWithToken();
            }
        }
    };

    const webCastsFirebaseSignIn = async () => {
        signInToFirebase(true);
    };

    const signInToFirebase = async (isFirst: boolean) => {
        try {
            if (!config.firebase.webcast.token.length) await setTokenFromAPI();
            await webcastFirebaseService.signInWithToken();
        } catch (e) {
            if (e.code === "auth/invalid-custom-token" && isFirst) {
                await setTokenFromAPI();
                await signInToFirebase(false);
            }
        }
    };

    const setTokenFromAPI = async () => {
        const res = await fbAuthService.getFirebaseToken("webcasts");
        config.firebase.webcast.token = res.response.data;
        setEncryptedLocalStorageItem(
            res.response.data,
            appConstants?.localStorage.webcastsFirebaseToken,
        );
    };

    const loggingFirebaseSignIn = async () => {
        try {
            await loggingFirebaseService.signInAnonymously();
        } catch (err) {}
    };

    return {
        coursesFirebaseSignIn,
        webCastsFirebaseSignIn,
        loggingFirebaseSignIn,
    };
};
