import { messaging } from "JS/Helpers/UserMessaging";
import { useCheckOnlineStatus } from "../Hooks/Media";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { Box, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AppButton } from "../Components/AppButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppTypography } from "../Components/AppTypography";
import { useResumeData } from "../Hooks/Firebase/Resume";
import { useEffect } from "react";
import { config } from "JS/Config";

export interface NetworkModeManagerProps {
    onlineRoot: (isOnline: boolean) => React.ReactNode;
    offlineRoot: React.ReactNode;
}

export const NetworkModeManager = (props: NetworkModeManagerProps) => {
    const { onlineRoot, offlineRoot } = props;
    const { isOnline, goingOffline, setIsOnline, goingOnline } =
        useCheckOnlineStatus();
    const {
        logAudioResume,
        logVideoResume,
        logPlaylistResume,
        logAudioBundleResume,
    } = useResumeData();

    const classes = useStyles(props);

    const memberId = config.user.memberId;

    useEffect(() => {
        if (goingOnline && memberId) {
            logAudioResume(memberId);
            logAudioBundleResume(memberId);
            logVideoResume(memberId);
            logPlaylistResume(memberId);
        }
    }, [goingOnline, memberId]);

    if (isOnline)
        return (
            <>
                {goingOffline && (
                    <AppConfimationDialog
                        open={goingOffline}
                        onClose={() => {
                            setIsOnline(false);
                        }}
                        titleSize="small"
                        popupMainText={messaging.networkMode.goingOffline}
                        cancelButtonText={messaging.networkMode.goOffline}
                    />
                )}
                {onlineRoot(isOnline)}
            </>
        );
    return (
        <>
            {goingOnline && (
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    className={classes.goingOnline}
                >
                    <AppTypography textColor="black" variant="h6">
                        Internet is available
                    </AppTypography>
                    <AppButton
                        onClick={() => {
                            setIsOnline(true);
                        }}
                        color="primary"
                    >
                        <FontAwesomeIcon
                            className={classes.globeIcon}
                            icon={["fas", "globe"]}
                        />
                        Go Online
                    </AppButton>
                </Box>
            )}
            {offlineRoot}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        fab: {
            position: "fixed",
            left: "10px",
            bottom: "30px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: "1",
        },
        floatingButton: {
            background: theme.palette.primary.main,
            "&:hover": {
                background: theme.palette.primary.main,
            },
            color: theme.colors.white,
        },
        globeIcon: {
            color: theme.colors.white,
            fontSize: "20px",
            marginRight: "10px",
        },
        goingOnline: {
            padding: theme.spacing(1),
            background: theme.palette.background.grey200,
        },
    }),
);
