import { useState, useCallback, useEffect } from "react";
import {
    Gifts,
    ResumeVideoContent,
    VideoContent,
    VideosAndGifts,
} from "JS/Models";
import { AppResponse } from "JS/Types";
import {
    createOfflineIDBID,
    getUniqueValues,
    sortArrayByKey,
} from "JS/Helpers";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    setGlobalVideos,
    setGlobalPlayedVideos,
    setGlobalUserResumeVideos,
} from "JS/Redux/Video";
import { VideoService } from "JS/Services/Video";
import { setGlobalMedia } from "JS/Redux/media";
import moment from "moment";
import { config } from "JS/Config";
import { useBulkUpdateVideosDB, useVideoIndexFromDb } from "./Database/Video";
import { VideoDexie } from "JS/Database/Dexie";
import { keyBy } from "lodash";
import {
    AudioVideoResume,
    PlayedAudiosVideos,
} from "JS/Models/Firebase/Resume";
import { useGlobalMediaEssentials } from "./MediaEssentials";

export const service = new VideoService();

export const useVideos = (skip: boolean, isUpdateRequired: boolean) => {
    const [loading, setLoading] = useState(false);

    const [loaded, setLoaded] = useState(false);
    const [response, setResponse] = useState<AppResponse<VideoContent[]>>(null);
    const [videos, setVideos] = useState<VideoContent[]>([]);

    const { resumeVideos, playedVideos } = useGlobalVideos();
    const playedVideosList =
        playedVideos && playedVideos[config.user.memberId]
            ? playedVideos[config.user.memberId]
            : [];

    const dispatch = useAppDispatch();
    const { syncOfflineVideos } = useSyncOfflineVideos();
    const { mediaEssentials } = useGlobalMediaEssentials();
    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getVideosIndex()

            .then(async (val) => {
                const response = val?.response;

                if (!response?.status) {
                    enqueueSnackbar(response.message, {
                        variant: "error",
                    });
                } else {
                    setResponse(val);
                    if (val?.response?.data) {
                        const data = val.response.data;

                        syncOfflineVideos(data);
                        const allVideos: VideoContent[] = data.map((item) => {
                            if (
                                playedVideosList.filter(
                                    (x) => x.nid === item.nid,
                                ).length > 0
                            ) {
                                return {
                                    ...item,
                                    played: playedVideosList.filter(
                                        (x) => x.nid === item.nid,
                                    )[0].played,
                                    isReceived: false,
                                };
                            } else {
                                return {
                                    ...item,
                                    isReceived: false,
                                    played: "0",
                                };
                            }
                        });

                        const categoryList: VideoContent[] = getUniqueValues(
                            allVideos,
                            "content_category_id",
                        );

                        const videosList = sortArrayByKey(
                            allVideos,
                            "title",
                            "ASC",
                        );
                        setVideos(videosList);

                        dispatch(
                            setGlobalVideos({
                                videosList: videosList,
                                catagoriesList: sortArrayByKey(
                                    categoryList,
                                    "content_category_title",
                                    "ASC",
                                ),
                                playedVideos: playedVideos
                                    ? { ...playedVideos }
                                    : {},
                                resumeVideos: resumeVideos
                                    ? { ...resumeVideos }
                                    : {},
                            }),
                        );

                        dispatch(
                            setGlobalMedia({ lastCachedTime: moment().unix() }),
                        );

                        return {
                            allVideos,
                            categoryList,
                            status: response.status,
                        };
                    }
                }

                return {
                    allVideos: [],
                    categoryList: [],
                    status: false,
                };
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });

                return {
                    allVideos: [],
                    categoryList: [],
                    status: false,
                };
            })

            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (
            !skip ||
            (mediaEssentials?.allow_rule_engine_requests && isUpdateRequired)
        ) {
            refetch();
        }
    }, [skip]);
    return {
        refetch,
        loaded,
        videos,
        response,
        loading,
    };
};

export const useGlobalVideos = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.videos);
    const played = useAppSelector((state) => state.videos.playedVideos);
    const resume = useAppSelector((state) => state.videos.resumeVideos);

    const resumeUserVideos =
        resume && resume[`${config?.user?.memberId}`]
            ? resume[`${config?.user?.memberId}`]
            : [];

    const currentPlayedMap = played ? played : {};
    const resumeMap = resume ? resume : {};
    const playedUserVideos =
        played && played[`${config?.user?.memberId}`]
            ? played[`${config?.user?.memberId}`]
            : [];
    return {
        videos: useAppSelector((state) => state.videos)?.videosList,
        resumeVideos: resume || {},
        resumeUserVideos: resumeUserVideos,

        categorizedVideos: useAppSelector((state) => state.videos)
            .catagoriesList,
        playedVideos: currentPlayedMap ? currentPlayedMap : {},
        playedUserVideos,
        setGlobalVideos: (videos: VideoContent[]) => {
            dispatch(
                setGlobalVideos({
                    ...allData,
                    videosList: videos,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },

        updateRecentlyPlayed: (video: VideoContent | Gifts) => {
            const currentPlayed: (VideoContent | Gifts | PlayedAudiosVideos)[] =
                currentPlayedMap[config.user.memberId]
                    ? currentPlayedMap[config.user.memberId]
                    : [];
            const newMap = { ...currentPlayedMap };
            const newPlayed = [...currentPlayed].filter(
                (c) => c.nid !== video.nid,
            );
            newPlayed.push(video);
            newMap[config.user.memberId] = newPlayed;
            dispatch(setGlobalPlayedVideos(newMap));
        },
        updateResumeVideos: (resumeVideo: ResumeVideoContent) => {
            const currentUserResume: ResumeVideoContent[] = resumeMap[
                config.user.memberId
            ]
                ? resumeMap[config.user.memberId]
                : [];
            const newMap = { ...resumeMap };
            const newResume = [...currentUserResume].filter(
                (c) => c.nid !== resumeVideo.nid,
            );
            newResume.push(resumeVideo);
            newMap[config.user.memberId] = newResume;
            dispatch(setGlobalUserResumeVideos(newMap));
        },
        isVideoUpdateRequired: allData?.isUpdateRequired,
        syncResumeVideos: (resumeAudios: AudioVideoResume[]) => {
            dispatch(
                setGlobalUserResumeVideos({
                    [config.user.memberId]: resumeAudios,
                }),
            );
        },
        syncPlayedVideos: (playedAudios: PlayedAudiosVideos[]) => {
            dispatch(
                setGlobalPlayedVideos({
                    [config.user.memberId]: playedAudios,
                }),
            );
        },
    };
};

export const useSyncOfflineVideos = () => {
    const { refetch: refetchOfflineVideos } = useVideoIndexFromDb(true);
    const { bulkUpdateVideos } = useBulkUpdateVideosDB();

    const syncOfflineVideos = useCallback(
        async (videos: VideosAndGifts[]) => {
            const offlineVideos = await refetchOfflineVideos();

            if (offlineVideos && offlineVideos.length) {
                const allIds = offlineVideos.map((d) => d.id);

                const filteredVideos = videos.filter((x) =>
                    allIds.includes(
                        createOfflineIDBID(config.user.memberId, x.nid),
                    ),
                );
                const offlineVidMap = keyBy(offlineVideos, (x) => x.id);
                const newOfflineVideos: VideoDexie[] = filteredVideos.map(
                    (v) => {
                        const currVid =
                            offlineVidMap[
                                createOfflineIDBID(config.user.memberId, v.nid)
                            ];
                        return {
                            ...currVid,
                            description: v.description,
                            name: v.title,
                            release_date: v.release_date,
                        };
                    },
                );

                await bulkUpdateVideos(newOfflineVideos);
            }
        },
        [refetchOfflineVideos, bulkUpdateVideos],
    );

    return {
        syncOfflineVideos,
    };
};
