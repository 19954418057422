import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { InternalStandardProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Layout } from "../Layout";
import { useHistory } from "react-router-dom";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
    useReceivedProspectAudioGifts,
} from "JS/React/Hooks/Gifts";
import { AudioContent, EventActions, EventCategories, EventNames, FirebaseEventAction } from "JS/Models";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRouting } from "JS/React/Hooks/Routes";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { uniqBy } from "lodash-es";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import { messaging } from "JS/Helpers/UserMessaging";
import { FavoritesTypes } from ".";
import { ListingComponent } from "../Audios/ListingComponent";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
export interface CategoriesProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > { }

export const FavoritesAudiosListing = (props: any) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const {
        homeStack,
        favoritesAudiosStack,
        setGlobalFavoritesAudiosStack,
        resetFavoritesAudiosStack,
    } = useGlobalNavStack();
    const [searchQuery, setSearchQuery] = useState<string>(
        favoritesAudiosStack?.searchQuery || "",
    );
    const [headerTitle, setHeaderTitle] = useState("Favorite Audios");
    const { resumeUserBundles, resumeUserAudios } = useGlobalAudios();

    const history = useHistory();

    const { audios, isAudioUpdateRequired } = useGlobalAudios();
    const { loading: audiosLoading } = useAudios(!!audios && audios.length > 0, isAudioUpdateRequired);

    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();

    const { loading: receivedMemberAudioGiftsLoading } =
        useReceivedMemberAudioGifts(
            !!receivedMemberAudioGifts && receivedMemberAudioGifts.length > 0,
        );

    const { loading: receivedProspectAudioGiftsLoading } =
        useReceivedProspectAudioGifts(
            !!receivedProspectAudioGifts &&
            receivedProspectAudioGifts.length > 0,
        );

    const { favoritesAudios } = useGlobalFavorites();
    const { loading: favLoading } = useGetFavorites(
        !!favoritesAudios && favoritesAudios.length > 0,
    );

    const { linkProvider } = useRouting();

    const filteredAudios = useMemo(() => {
        return uniqBy(
            [
                ...audios,
                ...receivedMemberAudioGifts,
                ...receivedProspectAudioGifts,
            ],
            (x) => x.nid,
        );
    }, [audios, receivedMemberAudioGifts, receivedProspectAudioGifts]);

    const filterFavoritesAudios = useMemo(() => {
        return filteredAudios.filter((audio) =>
            favoritesAudios.some((favAud) => favAud.nid === audio.nid),
        );
    }, [filteredAudios, favoritesAudios]);

    const searchResults = useMemo(() => {
        return filterFavoritesAudios?.filter(
            (d) =>
                d?.title
                    ?.toLocaleLowerCase()
                    ?.includes(searchQuery?.toLocaleLowerCase().trim()) ||
                d?.description
                    ?.toLocaleLowerCase()
                    ?.includes(searchQuery?.toLocaleLowerCase().trim()),
        );
    }, [filterFavoritesAudios, searchQuery]);

    useEffect(() => {
        setGlobalFavoritesAudiosStack({
            searchQuery: searchQuery,
        });
    }, [searchResults]);

    const isAudiosContentLoaded =
        !audiosLoading &&
        !receivedMemberAudioGiftsLoading &&
        !receivedProspectAudioGiftsLoading;

    useEffect(() => {
        window.scrollTo(0, 500);
        window.scrollTo(0, 0);
    }, []);
    const isResumeable = (filteredAudio: AudioContent) => {
        if (filteredAudio.media.length > 1) {
            let filterResumeBundle =
                resumeUserBundles &&
                resumeUserBundles.filter((x) => x.nid === filteredAudio.nid);
            return filterResumeBundle.length > 0;
        } else {
            if (
                resumeUserAudios &&
                resumeUserAudios.filter((x) => x.nid === filteredAudio.nid)
                    .length > 0
            ) {
                const resumeData = resumeUserAudios.find(
                    (x) => x.nid === filteredAudio.nid,
                );
                return !!resumeData;
            } else {
                return false;
            }
        }
    };
    const { handleListItemClick } = useHandlers(searchQuery, FavoritesTypes.FAVAUDIOS);
    return (
        <Layout>
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title={headerTitle || "Audios"}
                    canGoBack
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Audios by title/description"}
                    onBackClick={() => {
                        setSearchQuery("");
                        resetFavoritesAudiosStack();
                        if (!homeStack?.isHomeTrack) {
                            history.push(
                                linkProvider.react.favorites().index(),
                            );
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                />
                {isAudiosContentLoaded && !favLoading ? (
                    <ListingComponent
                        listContent={searchResults as AudioContent[]}
                        searchQuery={searchQuery}
                        categoryId={FavoritesTypes.FAVAUDIOS}
                        showSortHeader={true}
                        setGlobalStack={setGlobalFavoritesAudiosStack}
                        globalStack={favoritesAudiosStack}
                        isResumeable={isResumeable}
                        favoritesContent={favoritesAudios}
                        handleListItemClick={handleListItemClick}
                        controlType="audioFavorite"
                    />
                ) : (
                    <AppBackdropProgress
                        open={true}
                        backdropText={
                            favLoading
                                ? messaging.loader.favorites
                                : messaging.loader.audios
                        }
                    />
                )}
            </div>
        </Layout>
    );
};
const useHandlers = (searchQuery: string, categoryId: string) => {


    const { logFirebaseEvent } = useFirebaseLogger();
    const { linkProvider } = useRouting();
    const history = useHistory();

    const handleListItemClick = (
        filteredAudio: AudioContent,
        isAutoPlay: string,
        scrollIndex: number
    ) => {
        if (searchQuery) {
            const action: FirebaseEventAction = {
                action: EventActions.SEARCH,
                category: filteredAudio.isReceived
                    ? EventCategories.GIFT_AUDIOS
                    : EventCategories.AUDIOS,
                nId: filteredAudio.nid,
                skuId: filteredAudio.sku_id,
                contentTitle: filteredAudio.title,
                searchQuery: searchQuery,
            };

            logFirebaseEvent(
                filteredAudio.isReceived
                    ? EventNames.GIFT_AUDIO_SEARCH_OPENED
                    : EventNames.AUDIO_SEARCH_AUDIO_OPENED,
                action,
            );
        }
        history.push(
            linkProvider.react
                .favorites()
                .favoriteAudiosDetail(
                    filteredAudio.nid,
                    categoryId ? categoryId : filteredAudio.content_category_id,
                    "true",
                    isAutoPlay,
                    {
                        scrollTo: scrollIndex ? +scrollIndex : 0
                    }
                ),
        );
    };

    return { handleListItemClick };
};
const useStyles = makeStyles((theme: Theme) => ({
    root: {},
}));

