import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Checkbox,
    Grid,
    GridProps,
    InternalStandardProps,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Theme,
} from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        listItem: {
            color: `${theme.palette.grey[800]}`,
        },
        chkBoxItem: {
            color: `${theme.palette.grey[700]}`,
            "&.Mui-checked": {
                color: `${theme.palette.primary.main} !important`,
            },
        },
        listItemsText: {
            maxWidth: "80%",
            minWidth: "80%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        [theme.breakpoints.down("md")]: {
            listItemsText: {
                maxWidth: "70%",
                minWidth: "70%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
        [theme.breakpoints.down("sm")]: {
            listItemsText: {
                maxWidth: "65%",
                minWidth: "65%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    }),
);

export interface AppCheckboxListProps extends InternalStandardProps<GridProps> {
    checkboxList: any[];
    onToggleItemSelect: (item: string) => void;
    selectedMembers: any[];
}

export const AppCheckboxList = (props: AppCheckboxListProps) => {
    const classes = useStyles(props);
    const { className, checkboxList, selectedMembers, onToggleItemSelect } =
        props;

    return (
        <div className={clsx(className, classes.root)}>
            <Grid item xs={12}>
                <List
                    sx={{
                        width: "100%",
                    }}
                >
                    {checkboxList &&
                        checkboxList.length > 0 &&
                        checkboxList.map((value) => {
                            const labelId = `checkbox-list-secondary-label-${value.member_id}`;
                            return (
                                <ListItem
                                    key={value.member_id}
                                    secondaryAction={
                                        <Checkbox
                                            edge="end"
                                            onChange={() =>
                                                onToggleItemSelect(value)
                                            }
                                            checked={
                                                selectedMembers.findIndex(
                                                    (x) =>
                                                        x.member_id ===
                                                        value.member_id,
                                                ) !== -1
                                            }
                                            inputProps={{
                                                "aria-labelledby": labelId,
                                            }}
                                            className={classes.chkBoxItem}
                                        />
                                    }
                                    className={classes.listItem}
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemText
                                            id={labelId}
                                            primary={`${value.first_name} ${value.last_name}`}
                                            className={classes.listItemsText}
                                        />
                                        <ListItemText
                                            id={`${labelId}_${value.first_name}`}
                                            primary={`${value.member_id}`}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                </List>
            </Grid>
        </div>
    );
};
