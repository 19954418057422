import { Theme } from "@mui/material/styles/createTheme";
import { useTheme } from "@mui/styles";

export const useThemeColors = () => {
    const theme = useTheme<Theme>();
    const darkToneColors = Object.values(theme?.colors?.darkTone);
    const lightToneColors = Object.values(theme?.colors?.lightTone);
    const mediumToneColors = Object.values(theme?.colors?.mediumTone);

    return {
        darkToneColors,
        lightToneColors,
        mediumToneColors,
    };
};
