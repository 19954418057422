import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { AppButton } from "JS/React/Components/AppButton";
import { AppTypography } from "JS/React/Components/AppTypography";
import { FBStepStats } from "JS/Models/Firebase/Courses";
import { CourseQuizQuestion } from "JS/Models";
import { QuizQuestion } from "./QuizQuestion";
import { calculatePercentage } from "JS/Helpers";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        btn: {
            width: "150px",
        },
        display: {
            marginTop: theme.spacing(3),
            width: "100%",
        },
        btnFinishedWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "2%",
        },
    }),
);

export interface QuizResultProps extends InternalStandardProps<BoxProps> {
    toggleShowResult: () => void;
    onFinish: () => void;
    quiz: CourseQuizQuestion[];
    stats: Partial<FBStepStats>;
    showResult: boolean;
    hideFinishBtn?: boolean;
}

export const QuizResult = (props: QuizResultProps) => {
    const classes = useStyles(props);
    const {
        className,
        onFinish,
        quiz,
        stats,
        showResult,
        hideFinishBtn,
        toggleShowResult,
        ...rest
    } = props;

    const sortedQuiz = quiz?.slice()?.sort((a, b) => {
        return a.position - b.position;
    });

    return (
        <>
            {!showResult && (
                <Box
                    {...rest}
                    className={clsx(className, classes.root)}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between">
                    <Box textAlign={"center"}>
                        <AppTypography
                            fontWeight={"bold"}
                            variant="h1"
                            textColor="grey-600">
                            {calculatePercentage(
                                stats.quiz.result.correct_answers,
                                stats.quiz.result.total_questions,
                            ).toFixed(0)}
                            %
                        </AppTypography>
                        <AppTypography
                            variant="h6"
                            fontWeight={"bold"}
                            textColor="grey-500">
                            {stats.title}
                        </AppTypography>
                        <AppTypography variant="h6" textColor="grey-500">
                            {stats.quiz.result.correct_answers}/
                            {stats.quiz.result.total_questions}
                        </AppTypography>
                    </Box>
                    <AppButton
                        onClick={!hideFinishBtn ? onFinish : toggleShowResult}
                        className={classes.btn}
                        buttonVariant="grey-outlined"
                        rounded>
                        {!hideFinishBtn ? "Finish" : "Ok"}
                    </AppButton>
                </Box>
            )}

            {showResult && (
                <Box
                    {...rest}
                    className={clsx(className, classes.root)}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between">
                    <Box className={classes.display}>
                        {sortedQuiz.map((q) => (
                            <QuizQuestion
                                key={q.id}
                                readOnly={true}
                                disableOptions
                                question={q}
                                answer={q.options.find(
                                    (o) =>
                                        o.position ===
                                        stats.quiz.questions[`q${q.id}`]
                                            ?.user_answer,
                                )}
                            />
                        ))}
                    </Box>
                    <Box className={classes.btnFinishedWrapper}>
                        <AppButton
                            onClick={onFinish}
                            className={classes.btn}
                            buttonVariant="grey-outlined"
                            rounded>
                            Finish
                        </AppButton>
                    </Box>
                </Box>
            )}
        </>
    );
};
