import { config } from "JS/Config";
import { UserTeamInfo } from "JS/Models";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";
import { LandingPageHelper } from "./LandingPageHelper";

export class HomeService extends BaseService {
    private helper = new LandingPageHelper();

    async getTeamsStatusForUser(
        sendCall: boolean,
        defaultTeamInfo: UserTeamInfo,
    ) {
        if (sendCall || !defaultTeamInfo?.teamName)
            return (
                await this.doXHR<AppResponse<UserTeamInfo>>({
                    url: this.routes.server.api.home.getTeamStatusForUser(
                        config?.user?.memberId,
                    ),
                    method: "GET",
                    headers: {
                        authorization: `Bearer ${config?.accessToken}`,
                    },
                })
            ).data;
        else
            return new Promise<AppResponse<UserTeamInfo>>((res) => {
                res({
                    response: {
                        data: defaultTeamInfo,
                        status: true,
                        message: "",
                        key: 0,
                    },
                });
            });
    }

    async getLandingPageJsonFile(
        filePath: string,
    ): Promise<AWS.S3.GetObjectOutput> {
        return new Promise((res, _) => {
            this.helper.initConfig();
            const s3 = this.helper.getS3();
            s3.getObject(this.helper.jsonFileParams(filePath), (err, data) => {
                if (err) res(null);
                res(data);
            });
        });
    }
}
