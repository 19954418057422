import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { InternalStandardProps, Theme, Box, GridProps } from "@mui/material";
import { Event } from "JS/Models/Events";
import moment from "moment";
import { AppTypography } from "./AppTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppDivider } from "./AppDivider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "90px",
        },
        ListTitle: {
            width: "80%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        ListIconsWrapper: {
            width: "10%",
        },
        forwardIcon: {
            color: theme.palette.common.black,
        },
        eventItemTitle: {
            width: "100%",
            color: theme.palette.primary.main,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        eventItemCaption: {
            color: theme.palette.common.black,
        },
    }),
);

export interface EventItemProps extends InternalStandardProps<GridProps> {
    event: Event;
    onEventItemClick: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        eventName: string,
    ) => void;
}

export const EventItem = (props: EventItemProps) => {
    const classes = useStyles(props);
    const { className, event, onEventItemClick } = props;
    return (
        <>
            <Box
                className={clsx(className, classes.root)}
                onClick={(e) => onEventItemClick(e, event?.EventName)}
            >
                <Box className={classes.ListTitle}>
                    <AppTypography
                        variant="body1"
                        className={classes.eventItemTitle}
                    >
                        {event?.EventName}
                    </AppTypography>
                    <AppTypography
                        variant="caption"
                        className={classes.eventItemCaption}
                    >
                        {moment(
                            event?.EventStartDateAndTime.split("T")[0],
                        ).format("LL") +
                            " to " +
                            moment(
                                event?.EventEndDateAndTime.split("T")[0],
                            ).format("LL")}
                    </AppTypography>
                </Box>
                <Box className={classes.ListIconsWrapper}>
                    <FontAwesomeIcon
                        className={classes.forwardIcon}
                        icon={["fas", "chevron-right"]}
                    />
                </Box>
            </Box>
            <AppDivider />
        </>
    );
};
