import {
    getLocalDefaultMode,
    getLocalDefaultSystem,
    setThemeModeLocal,
} from "JS/Helpers/ThemeHelper";
import { useEffect, useMemo, useState } from "react";

export const useThemeColorMode = () => {
    const [mode, setMode] = useState<"light" | "dark">(getLocalDefaultMode());
    const [defaultSystem, setDefaultSystem] = useState<boolean>(
        getLocalDefaultSystem(),
    );

    let themeColorMode = useMemo(
        () => ({
            toggleColorMode: (mode: "light" | "dark", defaultSys: boolean) => {
                setThemeModeLocal({
                    mode: mode,
                    defaultSystem: defaultSys,
                });
                setMode(mode);
                setDefaultSystem(defaultSys);
            },
            toogleSystemMode: () => {
                setThemeModeLocal({
                    defaultSystem: true,
                });
                setDefaultSystem(true);
                setMode(mode);
            },
            mode: getLocalDefaultMode(),
            defaultSystem: getLocalDefaultSystem(),
        }),
        [mode, defaultSystem],
    );

    useEffect(() => {
        const onVisibilitychange = () => {
            const deviceColorMode = getDeviceThemeMode();
            if (getLocalDefaultSystem()) {
                if (deviceColorMode) {
                    themeColorMode.toggleColorMode("dark", true);
                } else {
                    themeColorMode.toggleColorMode("light", true);
                }
            }
        };

        document.addEventListener("visibilitychange", onVisibilitychange);

        return () => {
            document.removeEventListener(
                "visibilitychange",
                onVisibilitychange,
            );
        };
    }, []);

    const prefersDarkMode = getDeviceThemeMode();

    useEffect(() => {
        if (defaultSystem) {
            if (prefersDarkMode) {
                setMode("dark");
                setThemeModeLocal({
                    mode: "dark",
                    defaultSystem: defaultSystem,
                });
            } else {
                setMode("light");
                setThemeModeLocal({
                    mode: "light",
                    defaultSystem: defaultSystem,
                });
            }
        }
    }, [prefersDarkMode, defaultSystem]);

    return {
        mode,
        themeColorMode,
    };
};

export const getDeviceThemeMode = () => {
    return (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)")?.matches
    );
};
