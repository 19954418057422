import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserTeamInfo } from "JS/Models";
import { FycStatus } from "JS/Models/FycStatus";

export interface HomeState {
    userTeamInformation?: UserTeamInfo;
    fycStatusInfo?: FycStatus | undefined;
    landingPageJson?: any;
    delTokenRetry?: number;
}

const initialState: HomeState = {
    userTeamInformation: {
        teamName: "",
        isTestUser: false,
        activeSubscriptions: [],
    },
    fycStatusInfo: undefined,
    landingPageJson: {},
    delTokenRetry: 3,
};

export const homeStateSlice = createSlice({
    name: "homeState",
    initialState,
    reducers: {
        setUserTeamInfo: (state, data: PayloadAction<HomeState>) => {
            state.userTeamInformation = data.payload.userTeamInformation;

            return state;
        },
        setFycStatusInfo: (state, data: PayloadAction<HomeState>) => {
            state.fycStatusInfo = data.payload.fycStatusInfo;

            return state;
        },
        setLandingPageJson: (state, data: PayloadAction<HomeState>) => {
            state.landingPageJson = data.payload.landingPageJson;

            return state;
        },
        decrementTokenRetry: (state) => {
            state.delTokenRetry = state.delTokenRetry - 1;
            return state;
        },
        resetHomeState: () => {
            return initialState;
        },
    },
});
export const {
    setUserTeamInfo,
    setFycStatusInfo,
    setLandingPageJson,
    resetHomeState,
    decrementTokenRetry,
} = homeStateSlice.actions;
