import React from "react";
import { InternalStandardProps, Theme, Menu, MenuProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            "& .MuiPaper-root": {
                backgroundColor: `${theme.palette.background.default} !important`,
                color: `${theme.palette.grey[600]} !important`,
            },
        },
    }),
);

export interface AppMenuListingProps extends InternalStandardProps<MenuProps> {
    id: string;
    anchorEl: Element | ((element: Element) => Element);
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

export const AppMenuListing = (props: AppMenuListingProps) => {
    const classes = useStyles(props);
    const { id, anchorEl, open, onClose, children, ...rest } = props;

    return (
        <Menu
            className={classes.menu}
            id={id}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            {...rest}
        >
            {children}
        </Menu>
    );
};
