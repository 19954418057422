import { useHistory } from "react-router-dom";
import {
    AudioContent,
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    Gifts,
} from "JS/Models";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useRouting } from "JS/React/Hooks/Routes";

export const useAudiosHandlers = (searchQuery: string, categoryId: string) => {
    const { linkProvider } = useRouting();
    const { logFirebaseEvent } = useFirebaseLogger();
    const history = useHistory();

    const handleRedirectToDetail = (
        filteredAudio: Gifts | AudioContent,
        isAutoPlay: boolean = false,
        scrollIndex: number = 0,
    ) => {
        if (searchQuery) {
            const action: FirebaseEventAction = {
                action: EventActions.SEARCH,
                category: filteredAudio.isReceived
                    ? EventCategories.GIFT_AUDIOS
                    : EventCategories.AUDIOS,
                nId: filteredAudio.nid,
                skuId: filteredAudio.sku_id,
                contentTitle: filteredAudio.title,
                searchQuery: searchQuery,
            };

            logFirebaseEvent(
                filteredAudio.isReceived
                    ? EventNames.GIFT_AUDIO_SEARCH_OPENED
                    : EventNames.AUDIO_SEARCH_AUDIO_OPENED,
                action,
            );
        }
        history.push(
            linkProvider.react
                .audios()
                .detail(
                    filteredAudio.nid,
                    categoryId ? categoryId : filteredAudio.content_category_id,
                    `${isAutoPlay}`,
                    { scrollTo: scrollIndex },
                ),
        );
    };

    return { handleRedirectToDetail };
};
