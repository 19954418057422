import { config } from "JS/Config";
import { getDownloadedPartsNids } from "JS/Helpers";
import { AudioContent, Gifts } from "JS/Models";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import { useGetDownloadedAudio } from "JS/React/Hooks/Database";
import { useAppSelector } from "JS/Redux/Store";

export const useAudioResumeHelper = () => {
    const { resumeUserBundles, resumeUserAudios } = useGlobalAudios();

    const isResumable = (audioItem: AudioContent | Gifts): boolean => {
        if (audioItem.media.length > 1) {
            const resumeBundle = resumeUserBundles?.filter(
                (x) => x.nid === audioItem.nid,
            );
            return resumeBundle?.length > 0;
        } else {
            const resumeAudio = resumeUserAudios?.find(
                (x) => x.nid === audioItem.nid,
            );
            return !!resumeAudio;
        }
    };

    return { isResumable };
};

export const useDownloadAudiosHelpers = () => {
    const { getDownloadedAudioById } = useGetDownloadedAudio();

    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );
    const isAudioDownloaded = async (audioNid: string, length: number) => {
        if (length > 1) {
            const downloadedPartsNids = await getDownloadedPartsNids(audioNid);
            return downloadedPartsNids?.length === length;
        } else {
            const response = await getDownloadedAudioById(
                `${config.user.memberId}-${audioNid}`,
            );
            return !!response;
        }
    };

    const inProgressDownloaded = (nid: string, length: number): boolean => {
        if (downloadedContentNids) {
            if (length > 1) {
                const downloadedIds = downloadedContentNids.filter(
                    (downloadedNid) =>
                        downloadedNid.startsWith(
                            `${config.user.memberId}-${nid}`,
                        ),
                );
                return downloadedIds.length === length;
            } else {
                return downloadedContentNids.includes(
                    `${config.user.memberId}-${nid}`,
                );
            }
        }
        return false;
    };

    return {
        isAudioDownloaded,
        inProgressDownloaded,
    };
};
