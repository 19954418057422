import { useEffect, useMemo } from "react";
import { Provider } from "react-redux";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";

import { AppSnackbarProvider } from "JS/React/Components/AppSnackbarProvider";
import { AuthenticationProvider } from "JS/React/Context/AuthenticationProvider";
import { Root } from "JS/React/Root";
import { lightTheme, darkTheme } from "JS/React/Style/Theme";
import { store, persistor } from "JS/Redux/Store";
import ErrorBoundary from "JS/React/Scenes/ErrorBoundary";
import { RootOffline } from "JS/React/RootOffline";
import { initializeSentryUser } from "JS/Helpers/SentryHelper";
import { useThemeColorMode } from "JS/React/Hooks/ThemeColorMode";
import { ThemeModeProvider } from "JS/React/Context/ThemeModeProvider";
import { NetworkModeManager } from "JS/React/Container/NetworkModeManager";

const PDF_WORKER_URL =
    "https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js";

function App() {
    const { mode, themeColorMode } = useThemeColorMode();

    const theme = useMemo(
        () => (mode === "light" ? lightTheme : darkTheme),
        [mode],
    );

    useEffect(() => {
        if (document?.body?.style)
            document.body.style.backgroundColor =
                theme.palette.background.default;
    }, [mode]);

    return (
        <ThemeModeProvider value={themeColorMode}>
            <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <ErrorBoundary>
                        <Provider store={store}>
                            <PersistGate persistor={persistor}>
                                <CssBaseline />
                                <AppSnackbarProvider>
                                    <Worker workerUrl={PDF_WORKER_URL}>
                                        <NetworkModeManager
                                            onlineRoot={(isOnline) => (
                                                <AuthenticationProvider
                                                    isOnline={isOnline}
                                                >
                                                    <Root />
                                                </AuthenticationProvider>
                                            )}
                                            offlineRoot={<RootOffline />}
                                        />
                                    </Worker>
                                </AppSnackbarProvider>
                            </PersistGate>
                        </Provider>
                    </ErrorBoundary>
                </StyledEngineProvider>
            </ThemeProvider>
        </ThemeModeProvider>
    );
}

initializeSentryUser();

export default Sentry.withProfiler(App);
