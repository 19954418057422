import { ConsentFormDexie, getDexieConnectionRefresh } from "./Dexie";

export const deleteConsentStatusById = async (id: string) =>
    await getDexieConnectionRefresh().consentForm.delete(id);

export const getConsentStatusById = async (id: string) =>
    await getDexieConnectionRefresh().consentForm.get(id);

export const setConsentStatusById = async (updatedStatus: ConsentFormDexie) =>
    await getDexieConnectionRefresh().consentForm.put(updatedStatus);

export const updateConsentStatusById = async (
    id: string,
    updatedStatus: ConsentFormDexie,
) => await getDexieConnectionRefresh().consentForm.update(id, updatedStatus);
