import { createStyles, makeStyles } from "@mui/styles";
import { Switch, Theme } from "@mui/material";
import clsx from "clsx";

export interface AppSwitchProps {
    onSwitchChange: () => {};
    checked: boolean;
}

export const AppSwitch = (props: AppSwitchProps) => {
    const classes = useStyles(props);
    const { onSwitchChange, checked } = props;

    return (
        <Switch
            className={clsx(classes.switch)}
            checked={checked}
            size="medium"
            color="primary"
            onChange={onSwitchChange}
        />
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        switch: {
            "& .Mui-checked": {
                color: theme.palette.primary.main,
            },
            "& .Mui-checked+.MuiSwitch-track": {
                background: theme.palette.primary.main,
            },
            "& .MuiSwitch-track": {
                backgroundColor: theme.palette.grey["A100"],
            },
        },
    }),
);
