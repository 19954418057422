import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { InternalStandardProps, Theme } from "@mui/material";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useRouting } from "JS/React/Hooks/Routes";
import { useHistory } from "react-router-dom";
import {
    useCourseDownloadedStatus,
    useDownloadedCourses,
} from "JS/React/Hooks/DownloadCourses";
import { useGlobalCourses } from "JS/React/Hooks/Course/Course";
import { InfiniteScrollManager } from "JS/React/Container/InfiniteScrollManager";
import { OfflineCourseList } from "./Components/OfflineCourseList";

export interface CoursesProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > { }

export const OfflineCourses = (props: CoursesProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const { userDownloadedCourses } = useDownloadedCourses();
    const { isCourseDownloaded } = useCourseDownloadedStatus();
    const { linkProvider } = useRouting();
    const history = useHistory();

    const NEXT_PAGE_SIZE = 25;

    const { courses } = useGlobalCourses();
    const [searchQuery, setSearchQuery] = useState("");

    const allCourses = useMemo(() => {
        const viewOnlyCourses = courses
            ? courses.filter((course) => !isCourseDownloaded(course?.sku_id))
            : [];

        return [...userDownloadedCourses, ...viewOnlyCourses];
    }, [userDownloadedCourses, courses]);

    const searchResults = useMemo(() => {
        return allCourses?.filter(
            (d) =>
                d?.title
                    ?.toLocaleLowerCase()
                    ?.includes(searchQuery.toLocaleLowerCase().trim()) ||
                d?.description
                    ?.toLocaleLowerCase()
                    ?.includes(searchQuery.toLocaleLowerCase().trim()),
        );
    }, [allCourses, searchQuery]);



    const finalData = useMemo(
        () => searchResults,
        [searchResults],
    );

    useEffect(() => {
        if (searchQuery !== "") {
            window.scrollTo(0, 500);
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            <AppHeader
                title="Courses"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                searchPlaceholder={"Search Courses by title/description"}
                canGoBack
                onBackClick={() => {
                    history.push(linkProvider.react.home());
                }}
            />
            <InfiniteScrollManager>
                <OfflineCourseList downloadedCourses={finalData} />
            </InfiniteScrollManager>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        tab: {
            cursor: "pointer",
            fontSize: "1rem",
            color: theme.palette.grey[300],
            fontWeight: 500,

            "&:hover": {
                color: theme.palette.grey[600],
            },
        },
        grey600: {
            color: theme.palette.grey[600],
        },
    }),
);
