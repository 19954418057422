import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Webcast } from "JS/Models/Events";

export interface WebcastState {
    webcasts?: Webcast[];
}

const initialState: WebcastState = {
    webcasts: [],
};

export const webcastStateSlice = createSlice({
    name: "webcastState",
    initialState,
    reducers: {
        setGlobalWebcast: (state, data: PayloadAction<WebcastState>) => {
            state.webcasts = data.payload.webcasts;
            return state;
        },
        resetWebcastState: () => {
            return initialState;
        },
    },
});
export const { setGlobalWebcast, resetWebcastState } =
    webcastStateSlice.actions;
