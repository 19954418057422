import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "JS/Models";

export interface UserHistoryState {
    login_details?: User[];
}
const initialState: UserHistoryState = {
    login_details: [],
};

export const userHistoryStateSlice = createSlice({
    name: "UserHistoryState",
    initialState,
    reducers: {
        setLoginDetails: (state, data: PayloadAction<User[]>) => {
            state = {
                ...state,
                login_details: state.login_details.filter(
                    (a) => a.member_id !== data.payload[0].member_id,
                ),
            };

            state = {
                ...state,
                login_details: [...data.payload, ...state.login_details],
            };

            return state;
        },
        clearHistory: () => {
            return initialState;
        },
    },
});
export const { clearHistory, setLoginDetails } = userHistoryStateSlice.actions;
