import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Theme, InternalStandardProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { sortBy } from "lodash";
import clsx from "clsx";
import { getPublicUrl } from "JS/Helpers";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { MixedContentItem } from "./MixedContentItem";
import { MappedListingType } from ".";
import { useRouting } from "JS/React/Hooks/Routes";
import { HomeSegment } from "JS/React/Hooks/NavigationEngine";
import { Course } from "JS/Models";
import { useGestureToScroll } from "JS/React/Hooks/Common/GestureControl";

export const MixedContentListing = (props: MixedContentListingProps) => {
    const classes = useStyles();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { className, searchQuery, content, scrollToIndex, ...rest } = props;
    const itemRefs = useRef([]);

    const { finalMixedContent, thumbnails } = useMixContentSearch({
        content,
        searchQuery,
    });
    const { addScrollIndexInListingUrl } = useGestureToScroll();

    const handleRedirectToDetail = (
        content: MappedListingType,
        index: number,
    ) => {
        const mixContentParameter = {
            isMixedContent: true,
            scrollTo: `${index}`,
        };
        if (content.contentType === "Audio") {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .audioDetail(
                        content.nid,
                        "All",
                        "false",
                        mixContentParameter,
                    ),
            );
        }
        if (content.contentType === "Video") {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .videoDetail(
                        content.nid,
                        "All",
                        "true",
                        mixContentParameter,
                    ),
            );
        }
        if (content.contentType === "Course") {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .courseDetail(
                        content.nid,
                        content.isReceived ? "true" : "false",
                        HomeSegment.ALL,
                        mixContentParameter,
                    ),
            );
        }
    };

    useEffect(() => {
        if (
            +scrollToIndex >= 0 &&
            itemRefs?.current &&
            itemRefs?.current[+scrollToIndex]
        ) {
            itemRefs.current[+scrollToIndex - 2]?.scrollIntoView({
                behavior: "instant",
            });
        }
    }, [scrollToIndex, itemRefs]);

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            {finalMixedContent?.map((item, index) => {
                return (
                    <MixedContentItem
                        key={item.nid}
                        itemReference={(el) => {
                            itemRefs.current[index] = el;
                        }}
                        title={item.title}
                        description={item.description}
                        contentType={item.contentType}
                        imageUrl={thumbnails && thumbnails[item.nid]}
                        onListItemClick={() => {
                            addScrollIndexInListingUrl(index);
                            handleRedirectToDetail(item, index);
                        }}
                    />
                );
            })}
        </div>
    );
};

const useMixContentSearch = (props: MixContentSearchProps) => {
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    const { content, searchQuery } = props;

    const searchResults = useMemo(() => {
        return content?.filter(
            (d) =>
                d.title
                    .toLocaleLowerCase()
                    .includes(searchQuery?.toLocaleLowerCase()?.trim()) ||
                d.description
                    .toLocaleLowerCase()
                    ?.includes(searchQuery?.toLocaleLowerCase()?.trim()),
        );
    }, [content, searchQuery]);

    const sortedMixedContent = useMemo(
        () => sortBy(sortBy(searchResults, "title"), "position"),
        [searchResults],
    );
    const finalMixedContent = useMemo(() => {
        return sortedMixedContent?.map((item) => {
            if (item.contentType === "Course") {
                return {
                    ...item,
                    ...(item as Course).image,
                };
            }
            return item;
        });
    }, [sortedMixedContent]);

    const createImageUrl = async (content) => {
        const url = await getPublicUrl(
            content.image_url_prefix,
            content.image_url_postfix,
            content.image_file_name,
        );

        return {
            id: content.nid,
            url: url,
        };
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        finalMixedContent?.forEach((item) => {
            if (!thumbnails || (thumbnails && !thumbnails[item.nid])) {
                const promise = createImageUrl(item);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    useEffect(() => {
        if (finalMixedContent) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [finalMixedContent]);

    return {
        finalMixedContent,
        thumbnails,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "75vh",
            overflowX: "hidden",
            overflowY: "scroll",
            paddingBottom: "50px",
        },
    }),
);

export interface MixedContentListingProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    searchQuery: string;
    content: MappedListingType[];
    scrollToIndex: string;
}

export interface MixContentSearchProps {
    content: MappedListingType[];
    searchQuery: string;
}
