import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    CircularProgress,
    CircularProgressProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.primary.main,
        },
        lightColor: {
            color: theme.colors.white,
        },
    }),
);

export interface AppCircularProgressProps
    extends InternalStandardProps<CircularProgressProps> {
    loaderSize?: "small";
    notPrimary?: boolean;
}

export const AppCircularProgress = (props: AppCircularProgressProps) => {
    const classes = useStyles(props);
    const { className, loaderSize, notPrimary, ...rest } = props;

    return (
        <CircularProgress
            {...rest}
            className={clsx(
                className,
                notPrimary ? classes.lightColor : classes.root,
            )}
            size={loaderSize === "small" ? 20 : 40}
        />
    );
};
