import { createStyles, makeStyles } from "@mui/styles";
import {
    Backdrop,
    BackdropProps,
    Box,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { AppCircularProgress } from "./AppCircularProgress";
import { AppTypography } from "../AppTypography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        backdrop: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minWidth: "200px",
            background: `${theme.palette.background.default}`,
            padding: "20px",
        },
        text: {
            color: `${
                theme.palette.mode === "light"
                    ? theme.palette.text.primary
                    : theme.palette.grey[200]
            }`,
            fontSize: "15px",
        },
    }),
);

export interface AppBackdropProgressProps
    extends InternalStandardProps<BackdropProps> {
    backdropText: string;
}

export const AppBackdropProgress = (props: AppBackdropProgressProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    return (
        <Backdrop
            {...rest}
            sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            onClick={() => {}}
        >
            <Box className={classes.backdrop}>
                <AppCircularProgress />
                <Box height={10} />
                <AppTypography className={classes.text}>
                    {props.backdropText}
                </AppTypography>
            </Box>
        </Backdrop>
    );
};
