import downloadDisabled from "Images/Content/download-disabled.png";
import downloadDisabledDark from "Images/Content/download-disabled-dark.png";
import { useThemeModeContext } from "../Context/ThemeModeProvider";

export const DownloadDisabled = () => {
    const themeModeContext = useThemeModeContext();
    return (
        <img
            src={
                themeModeContext.mode === "light"
                    ? downloadDisabled
                    : downloadDisabledDark
            }
            style={{
                height: "26px",
            }}
            alt="download-disabled"
            onClick={(e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
                e.preventDefault();
            }}
        />
    );
};
