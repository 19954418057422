import { EventActions, EventCategories, EventNames, FirebaseEventAction, Gifts, VideoContent } from "JS/Models";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useRouting } from "JS/React/Hooks/Routes";
import { useHistory } from "react-router-dom";

export const useVideosHandlers = (searchQuery: string, categoryId: string) => {
    const { linkProvider } = useRouting();
    const { logFirebaseEvent } = useFirebaseLogger();
    const history = useHistory();

    const handleListItemClick = (
        filteredVideo: VideoContent | Gifts,
        isAutoPlay: boolean,
        scrollIndex: number = 0
    ) => {
        if (searchQuery) {
            const action: FirebaseEventAction = {
                action: EventActions.SEARCH,
                category: filteredVideo.isReceived
                    ? EventCategories.GIFT_VIDEOS
                    : EventCategories.VIDEOS,
                skuId: filteredVideo.sku_id,
                nId: filteredVideo.nid,
                contentTitle: filteredVideo.title,
                searchQuery: searchQuery,
            };

            logFirebaseEvent(
                filteredVideo.isReceived
                    ? EventNames.GIFT_VIDEO_SEARCH_OPENED
                    : EventNames.VIDEO_SEARCH_VIDEO_OPENED,
                action,
            );
        }
        history.push(
            linkProvider.react
                .videos()
                .detail(
                    filteredVideo.nid,
                    categoryId ? categoryId : filteredVideo.content_category_id,
                    isAutoPlay.toString(), { scrollTo: scrollIndex }
                ),
        );
    };

    return {
        handleListItemClick,
    };
};
