import { useGlobalWebcast, useGetWebcast } from "JS/React/Hooks/Webcast";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AppHeader } from "JS/React/Components/AppHeader";
import { Layout } from "../Layout";
import { AppTypography } from "JS/React/Components/AppTypography";
import { Grid, Theme, Box, InternalStandardProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useMemo, useState } from "react";
import { useRouting } from "JS/React/Hooks/Routes";
import { AppButton } from "JS/React/Components/AppButton";
import { webcastFirebaseService } from "JS/Services/Firebase/WebcastService";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useSnackbar } from "notistack";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import qs from "qs";
export interface WebcastProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const WebcastDetail = (props: WebcastProps) => {
    const classes = useStyles(props);
    const params: any = useParams();
    const { webcasts } = useGlobalWebcast();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { title } = params;
    const { loading } = useGetWebcast(webcasts && webcasts.length > 0);
    const [isAuthorized, setAuthorized] = useState(true);
    const [isStarted, setStarted] = useState(true);
    const [isEnded, setEnded] = useState(false);
    const [remaining, setRemaining] = useState<number>(undefined);

    const { queryParams, handleBackNavigation } = useHandler();

    useEffect(() => {
        webcastFirebaseService.writeOpenDate(title);
    }, [title]);

    const { enqueueSnackbar } = useSnackbar();

    const webcast = useMemo(() => {
        const w = webcasts?.find((w) => w.Description === title);
        if (w) {
            setAuthorized(w.isAuthorized);
            const startDate = new Date(w.StartDate);
            const endDate = new Date(w.EndDate);
            const hasStarted =
                Date.now() >= startDate.getTime() &&
                Date.now() < endDate.getTime();
            setStarted(hasStarted);
            setEnded(Date.now() > endDate.getTime());
            return w;
        } else setAuthorized(false);
    }, [title, webcasts]);

    useEffect(() => {
        let interval = null;
        if (!isStarted && webcast) {
            interval = setInterval(() => {
                const startDate = new Date(webcast.StartDate);
                const diff = startDate.getTime() - Date.now();
                if (diff === 0) setStarted(true);
                setRemaining(diff);
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [isStarted, webcast]);

    const openWebcast = () => {
        if (isStarted) {
            webcastFirebaseService.writeStartDate(title);
            history.push(
                linkProvider.react
                    .webcast()
                    .stream(
                        title,
                        queryParams?.backNav === "true" ? true : false,
                    ),
            );
        } else {
            enqueueSnackbar("Your event has not started yet.", {
                variant: "warning",
            });
        }
    };

    const timerText = () => {
        const totalSeconds = remaining / 1000;
        const seconds = (totalSeconds % 60).toFixed(0);
        const minutes = (Math.floor(totalSeconds / 60) % 60).toFixed(0);
        const hours = (Math.floor(totalSeconds / (60 * 60)) % 24).toFixed(0);
        const days = Math.floor(totalSeconds / 86400).toFixed(0);
        return `${days}D:${hours}H:${minutes}M:${seconds}S`;
    };

    return (
        <Layout>
            <AppHeader
                title={"Webcast"}
                canGoBack
                searchable={false}
                onBackClick={handleBackNavigation}
            />
            {loading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={"Fetching Webcast"}
                />
            )}
            {!isAuthorized && (
                <AppTypography className={classes.header}>
                    You are not authorized to access this webcast.
                </AppTypography>
            )}
            {isAuthorized && isEnded && (
                <AppTypography className={classes.header}>
                    There is no scheduled webcast at this time.
                </AppTypography>
            )}
            {isAuthorized && !isEnded && webcast && (
                <Grid item xs={12} md={12} className={classes.layout}>
                    <AppTypography variant="h6" className={classes.header}>
                        {webcast.Description}
                    </AppTypography>
                    <Box style={{ top: "50%" }}>
                        <AppTypography variant="body1" className={classes.text}>
                            You are authorized to access this webcast.
                        </AppTypography>
                        <br />
                        {isStarted && (
                            <>
                                <AppTypography
                                    variant="body1"
                                    className={classes.text}
                                >
                                    Your event has started.
                                    <br />
                                    Password: {webcast.WebcastPassword}
                                </AppTypography>
                                <br />
                                <Box display={"flex"} justifyContent={"center"}>
                                    <AppButton
                                        onClick={openWebcast}
                                        style={{ height: "40px" }}
                                        rounded
                                        buttonVariant="grey-outlined"
                                    >
                                        Start Streaming
                                    </AppButton>
                                </Box>
                            </>
                        )}
                        {!isStarted && remaining && (
                            <>
                                <AppTypography className={classes.text}>
                                    Your event will start in:
                                </AppTypography>
                                <AppTypography className={classes.text}>
                                    {timerText()}
                                </AppTypography>
                            </>
                        )}
                    </Box>
                </Grid>
            )}
        </Layout>
    );
};

const useHandler = () => {
    const location = useLocation();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { homeStack } = useGlobalNavStack();
    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { backNav = "" } = parsedQueryString;

        return {
            backNav: backNav.toString(),
        };
    }, [location?.search]);

    const handleBackNavigation = () => {
        if (queryParams?.backNav === "true") {
            history.goBack();
        } else {
            if (!homeStack?.isHomeTrack) {
                history.push(linkProvider.react.webcast().index());
            } else {
                history.goBack();
            }
        }
    };
    return { queryParams, handleBackNavigation };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        header: {
            textAlign: "center",
            color: theme.palette.primary.main,
        },
        text: {
            textAlign: "center",
            color: theme.palette.common.black,
        },
        loaderWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - ${theme.footer.height})`,
        },
        layout: {
            display: "grid",
            justifyItems: "center",
            justifyContent: "center",
            height: `calc(100vh - ${theme.footer.height})`,
        },
    }),
);
