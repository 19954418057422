import { useHistory, useLocation } from "react-router-dom";

export const useGestureToScroll = () => {
    const history = useHistory();
    const location = useLocation();

    const addScrollIndexInListingUrl = (scrollIndex: number) => {
        if (
            location?.pathname &&
            scrollIndex !== undefined &&
            !isNaN(scrollIndex)
        ) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("scrollTo", scrollIndex.toString());
            history.replace({
                pathname: location.pathname,
                search: searchParams.toString(),
            });
        }
    };

    return { addScrollIndexInListingUrl };
};
