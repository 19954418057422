import AWS from "aws-sdk";
import { config } from "JS/Config";
import { GetObjectRequest } from "aws-sdk/clients/s3";

export class MediaHelper {
    public bucket = config.s3.media.bucketName;
    public filePath = config.s3.media.betaUserFilePath;

    public getS3(): AWS.S3 {
        return new AWS.S3();
    }

    public initConfig() {
        AWS.config.update({
            credentials: {
                accessKeyId: config.s3.accessKey,
                secretAccessKey: config.s3.secretAccessKey,
            },
            region: config.s3.region,
        });
    }

    public jsonFileParams(): GetObjectRequest {
        return {
            Bucket: this.bucket,
            Key: this.filePath,
            ResponseCacheControl: 'no-cache',
        };
    }
}
