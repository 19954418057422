export interface PaginationInfo {
    perPage: number;
    currentPage: number;
    total: number;
}
export interface PaginationInfoDetail extends PaginationInfo {
    startingItem?: number;
    endingItem?: number;
    totalPages?: number;
}

export interface PaginatedData<T> {
    data: T[];
    meta: {
        pagination: PaginationInfoDetail;
    };
}

export function paginateData<T>(
    data: T[],
    paginationDef: PaginationInfo,
): PaginatedData<T> {
    const start = (paginationDef.currentPage - 1) * paginationDef.perPage;
    return {
        data: data.slice(
            start,
            start + paginationDef.perPage <= paginationDef.total
                ? start + paginationDef.perPage
                : paginationDef.total,
        ),
        meta: {
            pagination: extractDetailedPagination(paginationDef),
        },
    };
}

export function extractDetailedPagination(
    info: PaginationInfo,
): PaginationInfoDetail {
    return {
        ...info,
        startingItem: (info.currentPage - 1) * info.perPage + 1,
        endingItem: Math.min(
            (info.currentPage - 1) * info.perPage + info.perPage,
            info.total,
        ),
        totalPages: parseInt(Math.ceil(info.total / info.perPage).toString()),
    };
}
