import React, { useEffect } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { AppTypography } from "JS/React/Components/AppTypography";
import {
    EventCategories,
    CourseQuizOption,
    CourseQuizQuestion,
    EventActions,
    EventNames,
    CourseDetail,
} from "JS/Models";
import { AppRadioGroup } from "JS/React/Components/AppRadioGroup";
import { QuizFooter } from "./QuizFooter";
import { CourseLogFirebaseEventSettings } from "../Steps/CourseStep";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        question: {
            marginBottom: theme.spacing(1),
        },
        marginTop: {
            marginTop: theme.spacing(3),
        },
        quesNo: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
        },
    }),
);

export interface QuizQuestionProps extends InternalStandardProps<BoxProps> {
    question: CourseQuizQuestion;
    answer: CourseQuizOption;
    disableOptions?: boolean;
    readOnly?: boolean;
    course?: CourseDetail;
    handleLogFirebaseEvents?: (
        type: "course" | "step" | "question",
        settings: CourseLogFirebaseEventSettings,
    ) => void;
    setAnswer?: (v: CourseQuizOption) => void;
}

export const QuizQuestion = (props: QuizQuestionProps) => {
    const classes = useStyles(props);
    const {
        className,
        question,
        answer,
        readOnly,
        disableOptions,
        course,
        handleLogFirebaseEvents,
        setAnswer,
        ...rest
    } = props;

    const options = question?.options
        ?.slice()
        ?.sort()
        ?.map((o) => ({
            label: o.description,
            value: `${o.position}`,
        }));

    const correctOption = question.options.find((d) => d.is_correct);

    useEffect(() => {
        if (!readOnly && course) {
            if (course.content_item_type_name === "Course") {
                handleLogFirebaseEvents("question", {
                    eventNames: EventNames.COURSE_STEP_QUESTION_OPENED,
                    action: EventActions.QUESTION_OPENED,
                    category: EventCategories.COURSE_STEP,
                    questionId: +question.id,
                    questionPos: question.position,
                });
            }
            if (course.content_item_type_name === "GiftCourse") {
                handleLogFirebaseEvents("question", {
                    eventNames: EventNames.GIFT_COURSE_STEP_QUESTION_OPENED,
                    action: EventActions.QUESTION_OPENED,
                    category: EventCategories.GIFT_COURSE_STEP,
                    questionId: +question.id,
                    questionPos: question.position,
                });
            }
        }
    }, [readOnly, question]);

    return (
        <Box {...rest} className={clsx(className, classes.root)}>
            {readOnly && (
                <AppTypography
                    className={classes.quesNo}
                    textColor="grey-a100"
                    variant="h5"
                >
                    Question {question.position + 1}
                </AppTypography>
            )}
            <AppTypography
                fontWeight={500}
                textColor="grey-500"
                className={clsx(
                    classes.question,
                    !readOnly && classes.marginTop,
                )}
                variant="h4"
            >
                {question.question}
            </AppTypography>
            <AppRadioGroup
                onChange={(e) => {
                    if (!disableOptions) {
                        const op = question.options.find(
                            (x) => x.position === +e.target.value,
                        );
                        setAnswer(op);
                    }
                }}
                radios={options}
                value={answer?.position}
            />
            {readOnly && answer.position !== correctOption.position && (
                <QuizFooter
                    className={classes.marginTop}
                    title="Correct Answer:"
                    subTitle={correctOption.description}
                />
            )}
        </Box>
    );
};
