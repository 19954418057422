import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogProps,
    Grid,
    InternalStandardProps,
    Theme,
    Box,
} from "@mui/material";
import { AppTypography } from "./AppTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isAndroid } from "react-device-detect";

export const AppFiltersDialog = (props: AppFiltersDialogProps) => {
    const classes = useStyles(props);
    const {
        className,
        onClose,
        showTitle = true,
        titleText,
        children,
        open,
        selectedSpeakers,
        ...rest
    } = props;

    const { dialogHeaderRef } = useFilterDialogHandlers(props);

    return (
        <Dialog
            role="dialog"
            aria-modal="true"
            maxWidth={"sm"}
            fullWidth
            className={clsx(className, classes.root)}
            scroll="paper"
            classes={{
                scrollPaper: classes.topScrollPaper,
                paperScrollBody: classes.topPaperScrollBody,
            }}
            open={open}
            {...rest}
        >
            <Box className={clsx(className, classes.dialogWarpper)}>
                {showTitle && (
                    <DialogTitle
                        className={classes.filterDialogTitle}
                        ref={dialogHeaderRef}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <AppTypography
                                    variant="h6"
                                    className={classes.dialogHeaderTxt}
                                    aria-label={titleText}
                                >
                                    {titleText}
                                </AppTypography>
                                <FontAwesomeIcon
                                    className={classes.filterIcon}
                                    icon={["fas", "xmark"]}
                                    onClick={onClose}
                                />
                            </Grid>
                        </Grid>
                    </DialogTitle>
                )}
                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Box>
        </Dialog>
    );
};

const useFilterDialogHandlers = (props: AppFiltersDialogProps) => {
    const dialogHeaderRef = useRef(null);
    const { open, selectedSpeakers } = props;
    useEffect(() => {
        if (open && isAndroid) {
            setTimeout(() => {
                dialogHeaderRef.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }, 100);
        }
    }, [open, isAndroid, selectedSpeakers]);

    return { dialogHeaderRef };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        dialogWarpper: {
            minHeight: "30vh",
            backgroundColor: `${theme.palette.background.default}`,
            color: `${theme.palette.common.black}`,
        },
        dialogContent: {
            backgroundColor: `${theme.palette.background.default}`,
            color: `${theme.palette.common.black}`,
        },
        filterDialogTitle: {
            color: theme.palette.common.black,
        },
        filterIcon: {
            fontSize: "22px",
            cursor: "pointer",
            color: theme.palette.grey[600],
        },
        dialogHeaderTxt: {
            color: theme.palette.grey[600],
        },
        topScrollPaper: {
            alignItems: "flex-start",
        },
        topPaperScrollBody: {
            verticalAlign: "top",
        },
    }),
);

export interface AppFiltersDialogProps
    extends InternalStandardProps<DialogProps> {
    onClose: () => void;
    showTitle?: boolean;
    titleText?: string;
    children: React.ReactNode;
    selectedSpeakers?: string[];
}
