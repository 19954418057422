import {
    PlayerSeekAction,
    getSignedPublicUrl,
    isAndroidDevice,
    isBetaEnvironment,
    isStageEnvironment,
} from "JS/Helpers";
import { messaging } from "JS/Helpers/UserMessaging";
import { useSnackbar } from "notistack";
import { useVerifyAwsKeys } from "./Media";
import { useState } from "react";

export const useMediaPlayer = (isMini?: boolean) => {
    const { enqueueSnackbar } = useSnackbar();
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const [maintainPlaying, setMaintainPlaying] = useState(false);

    const capturePlayerError = (err: any, expiry: boolean = false) => {
        const toSendErr = new Error(err);
        toSendErr.name = expiry
            ? `${toSendErr?.name}-MediaPlayerExpiry`
            : `${toSendErr?.name}-MediaPlayer`;
        toSendErr.message = err?.message;
        toSendErr.stack = err?.stack;
    };

    const onMediaPlayerError = (
        e,
        unSignedUrl: string,
        setContentUrl: (val: string) => void = () => {},
        onUpdateExpiredUrl: () => void = () => {},
        onError?: (e: any) => void,
    ) => {
        const shouldSetUrl = e?.target?.error?.code === 2;

        if (shouldSetUrl && unSignedUrl) {
            if (isBetaEnvironment() || isStageEnvironment())
                capturePlayerError(e?.target?.error, true);

            verifyAwsKeys().then(() => {
                getSignedPublicUrl(unSignedUrl).then((res) => {
                    if (res) {
                        setContentUrl(res);
                        setMaintainPlaying(true);
                        onUpdateExpiredUrl();
                    }
                });
            });
        } else {
            capturePlayerError(e?.target?.error);
            onError && onError(e);
        }
        //this snackbar will only visible on android
        if (e?.type === "error" && isAndroidDevice() && !shouldSetUrl) {
            enqueueSnackbar(messaging?.common?.error, {
                variant: "error",
            });
        }
    };

    return {
        maintainPlaying,
        onMediaPlayerError,
    };
};

export const usePlayerHandlers = (ref: React.MutableRefObject<any>) => {
    const handleChangeSeek = (seekType: PlayerSeekAction, seekTime: number) => {
        const currentTime = ref?.current?.getCurrentTime();
        const duration = ref?.current?.getDuration();
        if (PlayerSeekAction.REWIND === seekType) {
            if (parseInt(currentTime) - seekTime <= 0) {
                ref?.current?.seekTo(0, "seconds");
            } else {
                ref?.current?.seekTo(
                    parseInt(currentTime) - seekTime,
                    "seconds",
                );
            }
        } else {
            if (parseInt(currentTime) + seekTime < parseInt(duration)) {
                ref?.current?.seekTo(
                    parseInt(currentTime) + seekTime,
                    "seconds",
                );
            }
        }
    };
    return {
        handleChangeSeek,
    };
};

export const useContentPlayingHandlers = (
    setCurrentPlayingState: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const handlePlayerPlay = () => {
        setCurrentPlayingState(true);
    };
    const handlePlayerPause = () => {
        setCurrentPlayingState(false);
    };

    return { handlePlayerPlay, handlePlayerPause };
};
