import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DownloadedCourseDetail, OfflineFBCourseStats } from "JS/Models";

export interface DownloadedCourseState {
    downloadedCourses: DownloadedCourseDetail[];
    firebaseCourseStats: OfflineFBCourseStats;
}


const initialState: DownloadedCourseState = {
    downloadedCourses: [],
    firebaseCourseStats: {},
};

export const downloadCourseStateSlice = createSlice({
    name: "downloadCourseState",
    initialState,
    reducers: {
        setGlobalDownloadedCourses: (
            state,
            data: PayloadAction<DownloadedCourseDetail[]>,
        ) => {
            state = { ...state, downloadedCourses: data.payload };
            return state;
        },
        setGlobalFBCourseStats: (
            state,
            data: PayloadAction<OfflineFBCourseStats>,
        ) => {
            state = { ...state, firebaseCourseStats: data.payload };
            return state;
        },
        resetDownloadedCourseState: () => {
            return initialState;
        },
    },
});
export const { setGlobalDownloadedCourses, setGlobalFBCourseStats, resetDownloadedCourseState } =
    downloadCourseStateSlice.actions;
