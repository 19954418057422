import {
    getLocalDefaultMode,
    getLocalDefaultSystem,
} from "JS/Helpers/ThemeHelper";
import { createContext, useContext } from "react";

export interface IThemeModeContext {
    toggleColorMode: (mode: "light" | "dark", defaultSys: boolean) => void;
    toogleSystemMode: () => void;
    mode: string;
    defaultSystem: boolean;
}

export const ThemeModeContext = createContext<IThemeModeContext>({
    toggleColorMode: (mode: "light" | "dark", defaultSys: boolean) => {},
    toogleSystemMode: () => {},
    mode: getLocalDefaultMode(),
    defaultSystem: getLocalDefaultSystem(),
});

export const useThemeModeContext = () => useContext(ThemeModeContext);

export const ThemeModeProvider = (props: ThemeModeProviderProps) => {
    const { children, value } = props;
    return (
        <ThemeModeContext.Provider value={value}>
            {children}
        </ThemeModeContext.Provider>
    );
};

export interface ThemeModeProviderProps {
    children: React.ReactNode;
    value: IThemeModeContext;
}
