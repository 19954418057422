import { defaultTo, orderBy } from "lodash-es";

export type SortDirection = "asc" | "desc" | false;

export interface ColumnSort {
    col: string;
    dir?: SortDirection;
    position: number;
}

export type Sorting = ColumnSort[];

export function addColumnSort(
    sort: ColumnSort,
    sorting: Sorting = [],
): Sorting {
    sorting = defaultTo(sorting, []);
    const existingIndex = sorting.findIndex((s) => s.col === sort.col);
    if (existingIndex >= 0) {
        sort = {
            ...sort,
            position: sorting[existingIndex].position,
        };
    }
    let spec = sorting.filter((s) => s.col !== sort.col);

    spec.push(sort);
    spec = spec
        .sort((pre, current) => {
            return pre.position === current.position
                ? 0
                : pre.position < current.position
                ? -1
                : 1;
        })
        .filter((a) => a.dir !== null);

    return spec;
}

export function getColumnSort(sorting: Sorting, { col, position }: ColumnSort) {
    let spec = sorting.find((x) => x.col === col);

    if (!spec) {
        spec = {
            col,
            position,
            dir: null,
        };
    }

    return spec;
}

export function getToggledSortDirection(
    columnName: string,
    currentDirection: SortDirection,
): SortDirection {
    if (
        currentDirection === null &&
        (columnName.includes("date") || columnName.includes("played"))
    )
        return "desc";
    return currentDirection === "asc" ? "desc" : "asc";
}

export function sortData<T>(data: T[], orders: ColumnSort[]): T[] {
    const filteredOrders = orders.filter((order) => order.dir !== null);
    return orderBy(
        data,
        filteredOrders.map(({ col }) => col),
        filteredOrders.map(({ dir }) => dir as "asc"),
    );
}

export function sortViaAcquiredDate<T extends { acquired_date: string }>(
    data: T[],
    orders: ColumnSort[],
) {
    const acquired = sortData(
        data.filter((c) => c.acquired_date && c.acquired_date !== "0"),

        orders,
    );

    const notAcquired = sortData(
        data.filter((c) => !acquired.includes(c)),
        [
            {
                col: "title",
                dir: "asc",
                position: 0,
            },
        ],
    );

    const all: T[] = [];

    acquired.forEach((c) => all.push(c));
    notAcquired.forEach((c) => all.push(c));

    return all;
}

export function sortViaPlayedDate<T extends { played: string }>(
    data: T[],
    orders: ColumnSort[],
) {
    const playedData = sortData(
        data.filter((c) => c.played && c.played !== "0"),
        orders,
    );
    const notplayedData = sortData(
        data.filter((c) => !playedData.includes(c)),
        [
            {
                col: "title",
                dir: "asc",
                position: 0,
            },
        ],
    );
    const all: T[] = [];
    playedData.forEach((c) => all.push(c));
    notplayedData.forEach((c) => all.push(c));
    return all;
}
