import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    Grid,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { AppButton } from "./AppButton";
import { AppTypography } from "./AppTypography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        dialogWarpper: {
            backgroundColor: `${theme.palette.background.default}`,
        },
        grey600: {
            color: theme.palette.grey[600],
        },
    }),
);

export interface AppEmptyAlertDialogProps
    extends InternalStandardProps<DialogProps> {
    onClose: () => void;
    popupMainText: string;
    cancelButtonText: string;
    showConfirmFirst?: boolean;
}

export const AppEmptyAlertDialog = (props: AppEmptyAlertDialogProps) => {
    const classes = useStyles(props);
    const {
        className,
        onClose,
        cancelButtonText,
        popupMainText,
        showConfirmFirst,
        ...rest
    } = props;

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            className={clsx(className, classes.root)}
            {...rest}
        >
            <Box className={classes.dialogWarpper}>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <AppTypography
                                variant="body1"
                                className={classes.grey600}
                            >
                                {popupMainText}
                            </AppTypography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!showConfirmFirst && (
                        <>
                            <AppButton
                                buttonVariant="grey-text"
                                onClick={onClose}
                            >
                                {cancelButtonText}
                            </AppButton>
                        </>
                    )}
                </DialogActions>
            </Box>
        </Dialog>
    );
};
