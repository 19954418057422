import { Box, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "fixed",
            width: "100vw",
            height: theme.footer.height,
            zIndex: 100,
            background: theme.palette.background.paper,
            bottom: 0,
            left: 0,
            transform: `translateY(${theme.footer.height})`,
            willChange: "transform",
            transition: "transform .3s ease-out",
            color: theme.palette.grey[700],
        },
        slideInView: {
            transform: "translateY(0)",
        },
    }),
);

export const BottomSheet = (props) => {
    const { children, open } = props;
    const classes = useStyles(props);
    return (
        <Box className={clsx(classes.root, open && classes.slideInView)} data-testid="bottom-sheet">
            {children}
        </Box>
    );
};
