import { Course, CourseDetail, CourseStep, DownloadedCourseDetail, ReceivedCourse } from "JS/Models";
import { useDownloadedCourses } from "../DownloadCourses";
import { cloneDeep } from "lodash-es";
import { config } from "JS/Config";

export const useSyncOfflineCourses = () => {
    const { downloadedCourses, userDownloadedCourses, setDownloadedCourses } =
        useDownloadedCourses();

    const syncOfflineCourses = (allCourses: (Course | ReceivedCourse)[]) => {
        let tempDownloadedCourses = cloneDeep(downloadedCourses);
        const mappedData = allCourses?.filter((x) =>
            userDownloadedCourses.some((z) => z.nid === x.nid),
        );

        mappedData?.forEach((x) => {
            let dataIndex =
                tempDownloadedCourses &&
                tempDownloadedCourses.findIndex(
                    (z) =>
                        z.nid === x.nid && z.memberId === config.user.memberId,
                );

            if (dataIndex !== -1) {
                tempDownloadedCourses[dataIndex] = {
                    memberId: config.user.memberId,
                    releaseDate: x.release_date,
                    isSequenceOn: x.isSequenceOn,
                    displayMode: x.displayMode === 1 ? true : false,
                    isPurchased:
                        x.content_item_type_name === "GiftCourse"
                            ? tempDownloadedCourses[dataIndex].isPurchased
                            : x.is_purchased,
                    sku_id: x.sku_id,
                    title: x.title,
                    description: x.description,
                    nid: x.nid,
                    content_item_type_name: x.content_item_type_name,
                    steps: tempDownloadedCourses[dataIndex].steps,
                } as DownloadedCourseDetail;
            }
        });

        setDownloadedCourses(tempDownloadedCourses);
    };

    const syncOfflineCourseSteps = (crsDetail: CourseDetail) => {
        let tempDownloadedCourses = cloneDeep(downloadedCourses);

        let courseIndex = tempDownloadedCourses?.findIndex(
            (x) =>
                x.nid === crsDetail?.nid && x.memberId === config.user.memberId,
        );
        if (courseIndex !== -1) {
            if (crsDetail.steps && crsDetail.steps.length > 0) {
                const tempCourseStep: CourseStep[] = crsDetail.steps.map(
                    (x) => {
                        return {
                            ...x,
                            isDownloaded: tempDownloadedCourses[
                                courseIndex
                            ]?.steps.find((z) => z.nid === x.nid)
                                ? tempDownloadedCourses[
                                      courseIndex
                                  ]?.steps.find((z) => z.nid === x.nid)
                                      ?.isDownloaded
                                : x.content_item_type_name === "quiz"
                                ? true
                                : false,
                        };
                    },
                );
                tempDownloadedCourses[courseIndex].steps = tempCourseStep;
            } else {
                tempDownloadedCourses[courseIndex].steps = [];
            }
        }
        setDownloadedCourses(tempDownloadedCourses);
    };

    return {
        syncOfflineCourses,
        syncOfflineCourseSteps,
    };
};