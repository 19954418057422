import { ColumnSort, getToggledSortDirection } from "JS/Types";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { InternalStandardProps, Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.grey[300],
            display: "flex",
            cursor: "pointer",
            fontSize: "1rem",
            fontWeight: 500,

            "&:hover": {
                color: theme.palette.grey[600],
            },
        },

        sortIcon: {},
        iconWrapper: {
            margin: theme.spacing(0, 1),
        },
        grey600: {
            color: theme.palette.grey[600],
        },
    }),
);
export function sortableHeader<T>(Component: React.ComponentType<T> | string) {
    type SortDirection = "asc" | "desc";
    interface Props extends InternalStandardProps<{}> {
        sort: ColumnSort;
        upDirection: SortDirection;
        downDirection: SortDirection;
        onSortChanged: (sort: ColumnSort, oldOrder: number) => void;
        children: React.ReactNode;
    }

    type HOCProps = OwnProps;
    type OwnProps = Props & Partial<Omit<T, "children">>; //Props that are allowed to passed from Resulting component returned from HOC.

    function SortableHeader(props: HOCProps) {
        const { sort, onSortChanged = () => {}, ...rest } = props;
        const {
            children,
            className,
            upDirection,
            downDirection,
            ...cellProps
        } = rest;
        const tsBypass: T = cellProps as any;
        const classes = useStyles(props);

        const onSortChange = (e) => {
            onSortChanged(
                {
                    ...sort,
                    dir: getToggledSortDirection(sort.col, sort.dir),
                },
                sort.position,
            );
        };

        return (
            <Component
                {...tsBypass}
                onClick={onSortChange}
                className={clsx(
                    className,
                    classes.root,
                    sort.dir && classes.grey600,
                )}>
                {children}{" "}
                <span className={classes.iconWrapper}>
                    {sort.dir === downDirection && (
                        <FontAwesomeIcon
                            className={classes.sortIcon}
                            icon={["fas", "arrow-down"]}
                        />
                    )}
                    {sort.dir === upDirection && (
                        <FontAwesomeIcon
                            className={classes.sortIcon}
                            icon={["fas", "arrow-up"]}
                        />
                    )}
                </span>
            </Component>
        );
    }

    return SortableHeader;
}

export default sortableHeader;
