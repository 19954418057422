import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MixedContent } from "JS/Models/MixedConent";

export interface MixedContentState {
    mixedContentJson: MixedContent;
}

const initialState: MixedContentState = {
    mixedContentJson: {},
};

export const mixedContentStateSlice = createSlice({
    name: "mixedContent",
    initialState,
    reducers: {
        setMixedContentJson: (
            state,
            data: PayloadAction<MixedContentState>,
        ) => {
            state.mixedContentJson = data.payload.mixedContentJson;
            return state;
        },
        resetMixedContentState: () => {
            return initialState;
        },
    },
});
export const { setMixedContentJson, resetMixedContentState } =
    mixedContentStateSlice.actions;
