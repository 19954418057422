import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { InternalStandardProps, Theme, Radio, Typography } from "@mui/material";
import { paymentMethodToString } from "JS/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppCircularProgress } from "../Progress/AppCircularProgress";
import { AppTypography } from "../AppTypography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        icon: {
            fontSize: "22px",
        },
        radioContainer: {
            display: "flex",
            alignItems: "center",
            marginRight: theme.spacing(2),
        },
        radio: {
            color: theme.palette.grey[500],
            "&.Mui-checked": {
                color: theme.palette.grey[500],
            },
        },
        creditsWrapper: {
            background:
                theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            padding: theme.spacing(1),
        },
    }),
);

export enum PaymentMethod {
    MY_CREDITS = "MY_CREDITS",
    CARD = "CARD",
}

export interface PaymentMethodRadioProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    credits: number;
    onMethodChange: (method: PaymentMethod) => void;
    method: PaymentMethod;
    isRedemeableByToken: string;
    availableCreditsLoading: boolean;
    contentPrice?: string;
}

export const PaymentMethodRadio = (props: PaymentMethodRadioProps) => {
    const classes = useStyles(props);
    const {
        className,
        credits,
        isRedemeableByToken,
        method,
        onMethodChange,
        availableCreditsLoading,
        contentPrice,
        ...rest
    } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onMethodChange(event.target.value as PaymentMethod);
    };

    const controlProps = (item: string) => ({
        checked: method === item,
        onChange: handleChange,
        value: item,
        name: "payment-method-radio",
        inputProps: { "aria-label": item },
    });

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <span className={classes.radioContainer}>
                <Radio
                    {...controlProps(PaymentMethod.MY_CREDITS)}
                    className={classes.radio}
                />
                <Typography className={classes.grey600} variant="body1">
                    {paymentMethodToString(PaymentMethod.MY_CREDITS)}
                </Typography>
                {availableCreditsLoading && (
                    <AppCircularProgress
                        style={{
                            height: "20px",
                            width: "20px",
                        }}
                    />
                )}
                {!availableCreditsLoading && (
                    <AppTypography
                        variant="body1"
                        className={classes.creditsWrapper}
                    >
                        {credits ? credits : 0}
                    </AppTypography>
                )}
            </span>
            {+contentPrice > 0 && (
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Radio
                        {...controlProps(PaymentMethod.CARD)}
                        className={classes.radio}
                    />
                    <FontAwesomeIcon
                        className={clsx(classes.grey600, classes.icon)}
                        icon={["fas", "credit-card"]}
                    />
                </span>
            )}
        </div>
    );
};
