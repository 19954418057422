import { config } from "JS/Config";
import { captureSentryError } from "JS/Helpers/SentryHelper";
import { FirebaseApp, getApps, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import { BaseService } from "../BaseService";

export class FirestoreService extends BaseService {
    protected app: FirebaseApp;
    protected store: Firestore;
    protected mergeOption = { merge: true };

    constructor() {
        super();
        const apps = getApps();
        const alreadyExist = apps?.find((x) => x.name === "[DEFAULT]");
        if (!alreadyExist) this.app = initializeApp(config.firebase.courses);
        else this.app = alreadyExist;
        this.store = getFirestore(this.app);
    }

    protected isStoreActive() {
        if (!this.store) this.store = getFirestore(this.app);
        const storeInitialized = !!this.store;

        if (!storeInitialized) {
            const err = new Error("Store not initilized for logs.");
            captureSentryError(err, {
                location: `ConsentForm`,
            });
        }

        return storeInitialized;
    }
}
