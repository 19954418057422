import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DownloadState {
    showDownloadProgress?: boolean;
    downloadContentName?: string;
    downloadContentPercentage?: number;
    inProgressNid?: string;
    downloadedContentNids?: string[];
    lastFailedContent?: {
        nid: string;
        name: string;
        msg: string;
        variant: "info" | "error";
    };
    loadedContent?: number;
    contentLength?: number;
    startTime?: number;
}

const initialState: DownloadState = {
    showDownloadProgress: false,
    downloadContentName: "",
    downloadContentPercentage: -1,
    inProgressNid: "",
    downloadedContentNids: [],
    lastFailedContent: {
        nid: "",
        name: "",
        msg: "",
        variant: "info",
    },
    loadedContent: 0,
    contentLength: 0,
    startTime: 0,
};

export const downloadStateSlice = createSlice({
    name: "downloadState",
    initialState,
    reducers: {
        setDownloadProgress: (state, data: PayloadAction<DownloadState>) => {
            state.showDownloadProgress = data.payload.showDownloadProgress;
            state.downloadContentName = data.payload.downloadContentName;
            state.downloadContentPercentage =
                data.payload.downloadContentPercentage;
            state.inProgressNid = data.payload.inProgressNid;
            state.loadedContent = data.payload.loadedContent;
            state.contentLength = data.payload.contentLength;
            state.startTime = data.payload.startTime;

            return state;
        },
        setDownloadedContentNids: (
            state,
            data: PayloadAction<DownloadState>,
        ) => {
            state.downloadedContentNids = data.payload.downloadedContentNids;

            return state;
        },
        setFailedContent: (state, data: PayloadAction<DownloadState>) => {
            state.lastFailedContent = data.payload.lastFailedContent;

            return state;
        },
        resetDownloadState: (state) => {
            return {
                ...initialState,
                lastFailedContent: state.lastFailedContent,
                downloadedContentNids: state.downloadedContentNids,
            };
        },
    },
});

export const {
    setDownloadProgress,
    resetDownloadState,
    setDownloadedContentNids,
    setFailedContent,
} = downloadStateSlice.actions;
