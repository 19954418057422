import { ReactNode } from "react";

export const RenderIf = (props: {
    children: ReactNode;
    isTrue: boolean;
    fallback?: ReactNode;
}) => {
    const { children, isTrue, fallback } = props;

    return <>{isTrue ? children : fallback}</>;
};
