import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { GridProps, InternalStandardProps, Theme } from "@mui/material";
import { AppCircularProgress } from "../Progress/AppCircularProgress";
import downloadDisabled from "Images/Content/download-disabled.png";
import downloadDisabledDark from "Images/Content/download-disabled-dark.png";

import { AppDownloadingIcons } from "./AppDownloadingIcons";
import { useThemeModeContext } from "JS/React/Context/ThemeModeProvider";

export const DownloadStatusIcon = (props: DownloadStatusIconProps) => {
    const classes = useStyles(props);
    const themeModeContext = useThemeModeContext();

    const { downloadStatus, isCourse, sizesLoading, isSizeValid } = props;

    if (downloadStatus === "downloaded") {
        return isCourse ? (
            <AppDownloadingIcons iconType="trash" />
        ) : (
            <FontAwesomeIcon
                className={clsx(classes.icon, classes.secondaryIcon)}
                icon={["far", "floppy-disk"]}
            />
        );
    }

    if (downloadStatus === "in-progress") {
        return (
            <AppDownloadingIcons iconType="in-progress" addMargin={!isCourse} />
        );
    }

    if (downloadStatus === "queued") {
        return <AppDownloadingIcons iconType="queued" addMargin={!isCourse} />;
    }

    if (isCourse) {
        return sizesLoading ? (
            <AppCircularProgress loaderSize="small" />
        ) : isSizeValid ? (
            <AppDownloadingIcons iconType="download" />
        ) : (
            <img
                src={
                    themeModeContext.mode === "light"
                        ? downloadDisabled
                        : downloadDisabledDark
                }
                style={{
                    height: "26px",
                }}
                alt="download-disabled"
                onClick={(
                    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
                ) => {
                    e.preventDefault();
                }}
            />
        );
    }

    return <></>;
};

interface DownloadStatusIconProps extends InternalStandardProps<GridProps> {
    downloadStatus?: StepListDownloadType;
    isCourse?: boolean;
    sizesLoading?: boolean;
    isSizeValid?: boolean;
}

export type StepListDownloadType = "in-progress" | "queued" | "downloaded";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        materialIcon: {
            color: theme.palette.grey[600],
            fontSize: "25px",
        },
        secondaryIcon: {
            marginTop: "15px",
        },
    }),
);
