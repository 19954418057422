import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import DownloadingIcon from "@mui/icons-material/Downloading";
import clsx from "clsx";

export const AppDownloadingIcons = (props: AppDownloadingIconsProps) => {
    const classes = useStyles(props);
    const { className, iconType, addMargin, isFloating, ...rest } = props;

    const fontAwessomeIconList = ["download", "trash"];
    const materialIconList = ["in-progress", "queued"];

    if (fontAwessomeIconList.includes(iconType)) {
        if (iconType === "download")
            return (
                <Box
                    className={!isFloating && classes.fontAwesomeIcons}
                    {...rest}
                >
                    <FontAwesomeIcon icon={["fas", "download"]} />
                </Box>
            );
        else
            return (
                <Box
                    className={!isFloating && classes.fontAwesomeIcons}
                    {...rest}
                >
                    <FontAwesomeIcon icon={["fas", "trash"]} />
                </Box>
            );
    }
    if (materialIconList.includes(iconType)) {
        if (iconType === "in-progress") {
            return (
                <Box
                    display={"flex"}
                    className={clsx(
                        classes.materialIcons,
                        addMargin && classes.secondaryIcon,
                    )}
                    {...rest}
                >
                    <DownloadingIcon />
                </Box>
            );
        } else {
            return (
                <Box
                    display={"flex"}
                    className={clsx(
                        classes.materialIcons,
                        addMargin && classes.secondaryIcon,
                    )}
                    {...rest}
                >
                    <HourglassEmptyIcon />
                </Box>
            );
        }
    }

    return <></>;
};

export type AppIconType = "download" | "in-progress" | "queued" | "trash";

export interface AppDownloadingIconsProps
    extends InternalStandardProps<BoxProps> {
    iconType: AppIconType;
    addMargin?: boolean;
    isFloating?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fontAwesomeIcons: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        materialIcons: {
            color: theme.palette.grey[600],
            fontSize: "25px",
        },
        secondaryIcon: {
            marginTop: "15px",
        },
    }),
);
