import {
    Grid,
    GridProps,
    InternalStandardProps,
    Paper,
    Theme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useRouting } from "JS/React/Hooks/Routes";
import { useThemeColors } from "JS/React/Hooks/ThemeColors";
import { useHistory } from "react-router-dom";

export enum DownloadMenuTitle {
    AUDIOS = "Audios",
    VIDEOS = "Videos",
    PLAY_LISTS = "Playlists",
    COURSES = "Courses",
    DOCUMENTS = "Documents",
}

export interface DownloadMenuProps extends InternalStandardProps<GridProps> {}

export const DownloadMenu = (props: DownloadMenuProps) => {
    const classes = useStyles(props);
    const { handleListingNavigation } = useHandlers();
    const { lightToneColors, mediumToneColors } = useThemeColors();

    return (
        <>
            <AppHeader
                title={"Downloads"}
                canGoBack={false}
                searchable={false}
                showTabs={false}
            />
            <Grid container spacing={0.2}>
                {Object.values(DownloadMenuTitle).map((item, idx) => (
                    <Grid
                        item={true}
                        xs={6}
                        md={3}
                        key={item}
                        justifyContent="center"
                        onClick={() => handleListingNavigation(item)}
                    >
                        <Item
                            bgColor={
                                idx % 2 === 0
                                    ? mediumToneColors
                                    : lightToneColors
                            }
                        >
                            {item}
                        </Item>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

const useHandlers = () => {
    const history = useHistory();
    const { linkProvider } = useRouting();

    const handleListingNavigation = (id: string) => {
        if (
            id === DownloadMenuTitle.AUDIOS ||
            id === DownloadMenuTitle.VIDEOS
        ) {
            history.push(linkProvider.react.offline().listing(id));
        } else if (id === DownloadMenuTitle.COURSES) {
            history.push(linkProvider.react.offline().courses().index());
        } else if (id === DownloadMenuTitle.PLAY_LISTS) {
            history.push(linkProvider.react.offline().playlist().index());
        } else {
            history.push(linkProvider.react.offline().documents().index());
        }
    };

    return {
        handleListingNavigation,
    };
};
type PaperProps = {
    bgColor: string[];
};

const Item = styled(Paper)<PaperProps>(({ theme, bgColor }) => ({
    ...(bgColor && {
        background: `linear-gradient(180deg, ${bgColor.join(",")})`,
    }),
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: "left",
    minHeight: "10rem",
    color: theme.palette.grey[200],
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "end",
    borderRadius: "0",
    boxShadow: "none",
    cursor: "pointer",
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        categoryTextWarpper: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "90%",
        },
        gridContainer: {
            padding: "8px",
        },
    }),
);
