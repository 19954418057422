import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Theme, InternalStandardProps, GridProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { DocumentDetails } from "JS/Models";
import { AppTypography } from "JS/React/Components/AppTypography";
import { DocumentDexie } from "JS/Database/Dexie";

export interface DocumentItemProps extends InternalStandardProps<GridProps> {
    document: DocumentDetails | DocumentDexie;
    isDownloadedDoc: boolean;
    index: number;
    onDocumentClick: (pdfName: string, description?: string) => void;
}

export const OfflineDocumentItem = (props: DocumentItemProps) => {
    const classes = useStyles();
    const { document, isDownloadedDoc, index, onDocumentClick } = props;
    const downloadedDoc = isDownloadedDoc && (document as DocumentDexie);
    const viewOnlyDoc = !isDownloadedDoc && (document as DocumentDetails);

    return (
        <>
            <Grid
                key={index}
                container
                className={clsx(
                    classes.itemContainer,
                    !isDownloadedDoc && classes.offline,
                )}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                onClick={() => {
                    isDownloadedDoc &&
                        onDocumentClick(
                            downloadedDoc.id,
                            downloadedDoc.description,
                        );
                }}
            >
                <Grid
                    item
                    sm={10}
                    xs={10}
                    display={"flex"}
                    alignItems={"center"}
                >
                    <FontAwesomeIcon
                        className={clsx(
                            classes.icons,
                            classes.pdfIcon,
                            !isDownloadedDoc && classes.offline,
                        )}
                        icon={["fas", "file-pdf"]}
                    />
                    <AppTypography
                        className={clsx(
                            classes.description,
                            !isDownloadedDoc && classes.offline,
                        )}
                        variant="h4"
                    >
                        {isDownloadedDoc
                            ? downloadedDoc?.description
                            : viewOnlyDoc?.Description}
                    </AppTypography>
                </Grid>
                <Grid
                    item
                    sm={1}
                    xs={1}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <FontAwesomeIcon
                        className={clsx(
                            classes.icons,
                            !isDownloadedDoc && classes.offline,
                        )}
                        icon={["fas", "chevron-right"]}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        itemContainer: {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1),

            "&:hover": {
                background: theme.palette.grey["200"],
                cursor: "pointer",
            },
        },
        icons: {
            color: `${theme.palette.common.black}`,
            fontSize: "35px",
            [theme.breakpoints.down("md")]: {
                fontSize: "22px",
            },
        },
        description: {
            fontSize: "30px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.palette.grey[600],
            [theme.breakpoints.down("md")]: {
                fontSize: "20px",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "18px",
            },
        },
        pdfIcon: {
            color: "red",
            margin: theme.spacing(0, 2),
            fontSize: "2rem",
        },
        offline: {
            color: theme.palette.grey[400],
        },
    }),
);
