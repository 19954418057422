import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@mui/styles";
import { Box, Fab, InternalStandardProps, Theme } from "@mui/material";

import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
    useReceivedProspectAudioGifts,
} from "JS/React/Hooks/Gifts";
import { useAudios, useGlobalAudios } from "JS/React/Hooks/Audio";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRouting } from "JS/React/Hooks/Routes";
import { useScrollWithDelay } from "JS/React/Hooks/Media";
import { AudioContent, Gifts } from "JS/Models";
import {
    AudioCategoryTitle,
    useAudioAttributesHandlers,
    useCreatePlaylistHandlers,
    useFilterAudioHandlers,
} from "./index";
import { audioCategoryTitleToString } from "JS/Helpers/Helpers";
import { getUniqueValues } from "JS/Helpers";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { AudioDexie, getDexieConnectionRefresh } from "JS/Database/Dexie";
import { config } from "JS/Config";
import { SpeakerPlaylistDialog } from "./SpeakerPlaylistDialog";
import { Layout } from "../Layout";
import { ListingComponent } from "./ListingComponent";
import { useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { useAudiosHandlers } from "./AudioHooks";
import { useAudioResumeHelper } from "./AudioHelpers";
import { useDownloadedContentStatus } from "../Videos/useDownloadedContentStatus";

export const AudiosContentListing = (props: any) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const {
        setGlobalAudiosStack,
        resetGlobalAudiosStack,
        audiosStack,
        homeStack,
    } = useGlobalNavStack();
    const [filteredAudios, setFiltersAudios] = useState<AudioContent[]>([]);
    const { favoritesAudios } = useGlobalFavorites();
    const [searchQuery, setSearchQuery] = useState(audiosStack?.searchQuery);
    const [headerTitle, setHeaderTitle] = useState("");
    const history = useHistory();

    const { audios, isAudioUpdateRequired } = useGlobalAudios();
    const { loading } = useAudios(
        !!audios && audios.length > 0,
        isAudioUpdateRequired,
    );
    const { linkProvider } = useRouting();
    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();

    const { loading: receivedMemberAudioGiftsLoading } =
        useReceivedMemberAudioGifts(
            !!receivedMemberAudioGifts && receivedMemberAudioGifts.length > 0,
        );

    const { loading: receivedProspectAudioGiftsLoading } =
        useReceivedProspectAudioGifts(
            !!receivedProspectAudioGifts &&
                receivedProspectAudioGifts.length > 0,
        );

    const {
        mediaEssentials,
        audioUniqueSpeakers,
        audioSelectedSpeakers,
        attributesLoading,
        onToggleSpeakerSelect,
        audioContentSpeakers,
        handleClearFilters,
        handleResetAudioAttributes,
    } = useAudioAttributesHandlers();

    const params: any = useParams();
    const { categoryId } = params;

    const [downloadAudios, setAudios] = useState<AudioDexie[]>([]);

    useEffect(() => {
        (async () => {
            if (categoryId === "saved-audios") {
                let bundleMap = {};
                const toRetAudios: AudioDexie[] = [];
                await getDexieConnectionRefresh().audios.each((audio) => {
                    if (audio.isBundle) {
                        const splittednid = audio.id.split("-");
                        if (splittednid[0] && splittednid[1]) {
                            let nid = `${splittednid[0]}-${splittednid[1]}`;
                            if (bundleMap[nid]) {
                                bundleMap[nid] = bundleMap[nid] + 1;
                                if (bundleMap[nid] === audio?.no_of_files) {
                                    toRetAudios.push(audio);
                                }
                            } else {
                                bundleMap[nid] = 1;
                            }
                        }
                    } else {
                        toRetAudios.push(audio);
                    }
                });
                setAudios(
                    toRetAudios.filter((d) =>
                        d.id.startsWith(config.user.memberId),
                    ),
                );
            }
        })();
    }, []);

    useEffect(() => {
        if (categoryId === AudioCategoryTitle.ALL_AUDIOS) {
            let data = [
                ...audios,
                ...receivedMemberAudioGifts,
                ...receivedProspectAudioGifts,
            ];
            setFiltersAudios(getUniqueValues(data, "nid"));
            setHeaderTitle(
                audioCategoryTitleToString(AudioCategoryTitle.ALL_AUDIOS),
            );
        } else if (categoryId === AudioCategoryTitle.MY_AUDIOS) {
            let audiosList = audios?.filter(
                (audio) => audio.publish_free === "1" || audio.is_purchased,
            );
            let data = [
                ...audiosList,
                ...receivedMemberAudioGifts,
                ...receivedProspectAudioGifts,
            ];
            setFiltersAudios(getUniqueValues(data, "nid"));
            setHeaderTitle(
                audioCategoryTitleToString(AudioCategoryTitle.MY_AUDIOS),
            );
        } else if (categoryId === AudioCategoryTitle.RECENTLY_PLAYED) {
            const audiosList = audios?.filter((audio) => audio.played !== "0");
            const data = [
                ...audiosList,
                ...receivedMemberAudioGifts.filter(
                    (audio) => audio.played !== "0",
                ),
                ...receivedProspectAudioGifts.filter(
                    (audio) => audio.played !== "0",
                ),
            ];
            setFiltersAudios(getUniqueValues(data, "nid"));

            setHeaderTitle(
                audioCategoryTitleToString(AudioCategoryTitle.RECENTLY_PLAYED),
            );
        } else if (categoryId === AudioCategoryTitle.SAVED) {
            const audioNids = [];
            if (downloadAudios) {
                downloadAudios.forEach((a) =>
                    audioNids.push(a.id.split("-")[1]),
                );
            }

            const data = [
                ...audios?.filter((audio) => audioNids?.includes(audio.nid)),
                ...receivedMemberAudioGifts.filter((audio) =>
                    audioNids?.includes(audio.nid),
                ),
                ...receivedProspectAudioGifts.filter((audio) =>
                    audioNids?.includes(audio.nid),
                ),
            ];
            setFiltersAudios(getUniqueValues(data, "nid"));

            setHeaderTitle(
                audioCategoryTitleToString(AudioCategoryTitle.SAVED),
            );
        } else if (categoryId === AudioCategoryTitle.RECOMMENDED) {
            //do nothing
        } else {
            const selectedAudios = audios?.filter(
                (audio) => audio.content_category_id === categoryId,
            );
            setFiltersAudios(selectedAudios);
            setHeaderTitle(
                selectedAudios[0]?.content_category_title || "Audios",
            );
        }
    }, [
        downloadAudios,
        audios,
        categoryId,
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
    ]);

    const { searchResults } = useFilterAudioHandlers(
        filteredAudios,
        searchQuery,
        audioSelectedSpeakers,
        audioContentSpeakers,
    );

    const {
        createClicked,
        handleCreateClick,
        handleClose,
        handleCreatePlaylist,
        onToggleSelectItem,
        selectedContentNids,
        finalData,
        createPlaylistLoading,
        playlistName,
        setPlaylistName,
    } = useCreatePlaylistHandlers(searchResults);

    useEffect(() => {
        setGlobalAudiosStack({
            searchQuery: searchQuery,
        });
    }, [searchResults, audioSelectedSpeakers]);

    const { isResumable } = useAudioResumeHelper();
    const { handleRedirectToDetail } = useAudiosHandlers(
        searchQuery,
        categoryId,
    );
    const useAudiosDownloadHelperData = (data: (AudioContent | Gifts)[]) => {
        const { getDownloadedStatusById } = useDownloadedContentStatus(
            data,
            "audio",
        );
        return getDownloadedStatusById;
    };
    const isContentLoaded =
        !loading &&
        !receivedMemberAudioGiftsLoading &&
        !receivedProspectAudioGiftsLoading &&
        !attributesLoading;

    useScrollWithDelay();

    return (
        <Layout>
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title={headerTitle || "Audios"}
                    canGoBack
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Audios by title/description"}
                    onBackClick={() => {
                        setFiltersAudios([]);
                        setSearchQuery("");
                        resetGlobalAudiosStack();
                        handleResetAudioAttributes();
                        if (!homeStack?.isHomeTrack) {
                            history.push(linkProvider.react.audios().index());
                        } else {
                            history.push(linkProvider.react.home());
                        }
                    }}
                    filterable={mediaEssentials?.allow_speaker_feature}
                    onToggleSpeakerSelect={onToggleSpeakerSelect}
                    uniqueSpeakers={audioUniqueSpeakers}
                    selectedSpeakers={audioSelectedSpeakers}
                    handleClearFilters={handleClearFilters}
                />
                {mediaEssentials?.allow_speaker_feature &&
                    audioSelectedSpeakers?.length > 0 &&
                    searchResults?.length > 0 && (
                        <Box className={clsx(className, classes.fab)}>
                            <Fab
                                color="primary"
                                aria-label="add"
                                className={clsx(
                                    className,
                                    classes.floatingButton,
                                )}
                                onClick={handleCreateClick}
                            >
                                <FontAwesomeIcon icon={["fas", "music"]} />
                            </Fab>
                        </Box>
                    )}
                {createClicked && (
                    <SpeakerPlaylistDialog
                        open={createClicked}
                        audios={finalData}
                        handleClose={handleClose}
                        handleCreatePlaylist={handleCreatePlaylist}
                        onToggleSelectItem={onToggleSelectItem}
                        selectedContentNids={selectedContentNids}
                        sortType={audiosStack?.sorting[0].col}
                        playlistName={playlistName}
                        setPlaylistName={setPlaylistName}
                        createPlaylistLoading={createPlaylistLoading}
                    />
                )}

                {isContentLoaded ? (
                    <ListingComponent
                        listContent={searchResults}
                        searchQuery={searchQuery}
                        categoryId={categoryId}
                        showSortHeader={true}
                        setGlobalStack={setGlobalAudiosStack}
                        globalStack={audiosStack}
                        isResumeable={isResumable}
                        favoritesContent={favoritesAudios}
                        handleListItemClick={handleRedirectToDetail}
                        getDownloadHelperData={useAudiosDownloadHelperData}
                        controlType="audio"
                    />
                ) : (
                    <AppBackdropProgress
                        open={true}
                        backdropText={
                            !attributesLoading
                                ? messaging.loader.audios
                                : messaging.loader.fectchingSpeakers
                        }
                    />
                )}
            </div>
        </Layout>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    createPlaylistButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: theme.spacing(2),
    },
    plusIcon: {
        height: "20px",
        width: "20px",
        padding: theme.spacing(1),
    },
    fab: {
        position: "fixed",
        right: "10px",
        bottom: `calc(${theme.footer.height} + 10px)`,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        zIndex: "1",
    },
    floatingButton: {
        background: theme.palette.primary.main,
        "&:hover": {
            background: theme.palette.primary.main,
        },
    },
}));

export interface CategoriesProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}
