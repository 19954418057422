import { useEffect } from "react";
import { InternalStandardProps } from "@mui/material";
import { useGlobalMedia, useVerifyAwsKeys } from "../Hooks/Media";
import { useSnackbar } from "notistack";
import { initialCommonMessages } from "JS/Redux/media";
import { useMiniPlayer, useRebuildBlobUrl } from "../Hooks/MiniPlayer";
import { getMiniPlayerFromLocalStorage } from "JS/Helpers/MiniPlayerHelper";
import {
    checkIfUrlIsBlob,
    getS3CompletePathFromPreSigned,
    getSignedPublicUrl,
} from "JS/Helpers";
import { useGiftDashboardRoot } from "../Hooks/Firebase/GiftDashboard";
import { useAuth } from "../Context/AuthenticationProvider";
import {
    getLoggedInUserMigrated,
    setLoggedInUserMigrated,
} from "JS/Helpers/LocalStorageHelpers";
import { useUserHistory } from "../Hooks/UserHistory";
import { config } from "JS/Config";
import { useGlobalLoggedInUser } from "../Hooks/Users";

export interface CommonActionsProps extends InternalStandardProps<{}> {}

export const CommonActions = (props: CommonActionsProps) => {
    const { commonMessages, setCommonMessages } = useGlobalMedia();
    const { enqueueSnackbar } = useSnackbar();
    const { resetGlobalMiniPlayer, setGlobalMiniPlayer } = useMiniPlayer();
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const { rebuildBolbUrl } = useRebuildBlobUrl();
    const { setLoggedInUserData } = useGlobalLoggedInUser();

    useGiftDashboardRoot();
    const { isAuthenticated } = useAuth();
    const { history } = useUserHistory();

    const migrateLoggedInUserData = () => {
        const data = history?.filter(
            (x) => x.member_id === config?.user?.memberId,
        )[0];
        setLoggedInUserData(data);
    };

    useEffect(() => {
        if (isAuthenticated) {
            if (!(getLoggedInUserMigrated() === "true")) {
                migrateLoggedInUserData();
                setLoggedInUserMigrated("true");
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (commonMessages && commonMessages.show) {
            enqueueSnackbar(commonMessages.message, {
                variant: commonMessages.variant,
            });
            setCommonMessages(initialCommonMessages);
        }
    }, [commonMessages]);

    useEffect(() => {
        const setIfMiniExist = async () => {
            const player = getMiniPlayerFromLocalStorage();
            if (player && player.url) {
                const prevUrl = player.url;
                const isBlob = checkIfUrlIsBlob(prevUrl);
                const newPlyr = player;

                if (!isBlob) {
                    const unSigned = getS3CompletePathFromPreSigned(prevUrl);
                    await verifyAwsKeys().then(async () => {
                        newPlyr.url = await getSignedPublicUrl(unSigned);
                    });
                } else {
                    await rebuildBolbUrl(player).then((recentURL) => {
                        if (recentURL) {
                            newPlyr.url = recentURL;
                        }
                    });
                }
                setGlobalMiniPlayer(newPlyr);
            } else {
                resetGlobalMiniPlayer();
            }
        };
        setTimeout(() => setIfMiniExist(), 1000);

        return () => resetGlobalMiniPlayer();
    }, []);

    return null;
};
