import React, { useState } from "react";
import { AppConfimationDialog } from "./AppConfirmationDialog";
import { useGlobalDownloadCount } from "../Hooks/DownloadWarning";
import { useGlobalMediaEssentials } from "../Hooks/MediaEssentials";
import { isAndroidDevice } from "JS/Helpers";

export const DownloadWarningDialog = (props: DownloadWarningDialogProps) => {
    const {
        open,
        mediaEssentials,
        doNotShow,
        askConfirmation,
        handleCancel,
        handleConfirm,
        setDoNotShow,
    } = useHandlers(props);

    const { children } = props;

    return (
        <React.Fragment>
            {open && (
                <AppConfimationDialog
                    type="download-warning"
                    popupMainText="Warning!"
                    smallBodyText={mediaEssentials?.download_warning_msg}
                    cancelButtonText="Cancel"
                    confirmText="Confirm"
                    open={open}
                    onConfirm={handleConfirm}
                    doNotShow={doNotShow}
                    setDoNotShow={setDoNotShow}
                    onClose={handleCancel}
                />
            )}
            {children({
                askConfirmation,
            })}
        </React.Fragment>
    );
};

const useHandlers = (props: DownloadWarningDialogProps) => {
    const { onConfirm = () => {}, onClose = () => {} } = props;
    const { downloadCount, setGlobalDownloadCount } = useGlobalDownloadCount();
    const { mediaEssentials } = useGlobalMediaEssentials();

    const [open, setOpen] = useState<boolean>();
    const [doNotShow, setDoNotShow] = useState<boolean>(
        !downloadCount.showDialog,
    );

    const askConfirmation = () => {
        if (
            downloadCount.downloadClickCount %
                mediaEssentials.download_warning_count ===
                0 &&
            downloadCount.showDialog &&
            isAndroidDevice()
        ) {
            setOpen(true);
        } else {
            onConfirm();
        }

        setGlobalDownloadCount({
            ...downloadCount,
            downloadClickCount: downloadCount.downloadClickCount + 1,
        });
    };

    const handleConfirm = () => {
        setGlobalDownloadCount({
            ...downloadCount,
            showDialog: !doNotShow,
        });
        onConfirm();
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
        setDoNotShow(false);
        onClose();
    };

    return {
        open,
        mediaEssentials,
        doNotShow,
        askConfirmation,
        handleCancel,
        handleConfirm,
        setDoNotShow,
    };
};

export interface DownloadWarningDialogProps {
    children: (
        renderProps: DownloadWarningDialogRenderProps,
    ) => React.ReactNode;
    onConfirm?: () => void;
    onClose?: () => void;
}

export interface DownloadWarningDialogRenderProps {
    askConfirmation: () => void;
}
