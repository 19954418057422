import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { Box, InternalStandardProps, Theme } from "@mui/material";
import { AppButton } from "./AppButton";
import { AppDivider } from "./AppDivider";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

export interface ListDialogProps extends InternalStandardProps<DialogProps> {
    open: boolean;
    onSelection: (value: string) => void;
    items: { id: string; title: string }[];
    onClose: () => void;
}

export const ListDialog = (props: ListDialogProps) => {
    const classes = useStyles(props);
    const { className, onClose, onSelection, open, items, ...rest } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value: string) => {
        onSelection(value);
    };

    return (
        <Dialog
            className={clsx(classes.root, className)}
            onClose={handleClose}
            open={open}
            {...rest}
        >
            <Box className={classes.dialogWrapper}>
                <DialogTitle>Select Playlist</DialogTitle>
                <AppDivider />
                <List sx={{ pt: 0 }}>
                    {items.map((i, index) => (
                        <>
                            <ListItem
                                button
                                onClick={() => handleListItemClick(i.id)}
                                key={index}
                            >
                                <ListItemText primary={i.title} />
                            </ListItem>
                            <AppDivider />
                        </>
                    ))}
                </List>
                <Box p={1}>
                    <AppButton
                        buttonVariant="primary-filled"
                        style={{ float: "right", width: "50px" }}
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </AppButton>
                </Box>
            </Box>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        dialogWrapper: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.common.black,
        },
    }),
);
