import clsx from "clsx";
import {
    Box,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { AppTypography } from "JS/React/Components/AppTypography";
import { DashboradLogs } from "./GiftDashboardListing";
import { DashboardListItemIcon } from "./Components/DashboardListItemIcon";
import moment from "moment";
import { AppDivider } from "JS/React/Components/AppDivider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { secondsToPlayerDuration } from "JS/Helpers";

export const GiftDashboardListItem = (props: GiftDashboadListItemProps) => {
    const classes = useStyles(props);

    const { className, giftActivity: content, ...rest } = props;

    const formatDateTime = (createdAt: number) =>
        moment.unix(createdAt / 1000).format("DD-MM-YYYY h:mm A");

    return (
        <>
            <Grid container className={clsx(className, classes.root)} {...rest}>
                <Grid
                    item
                    xs={2}
                    display="flex"
                    justifyContent="center"
                    alignItems={"center"}
                >
                    <DashboardListItemIcon contentType={content.contentType} />
                </Grid>
                <Grid item xs={10} className={clsx(classes.centerItem)}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <AppTypography
                            className={clsx(classes.title, classes.grey600)}
                        >
                            {formatDateTime(content?.createdAt)}
                        </AppTypography>
                    </Box>
                    <Box display={"flex"} justifyContent={"start"}>
                        {content?.receiverFirstName &&
                            content?.receiverLastName && (
                                <>
                                    <FontAwesomeIcon
                                        className={clsx(
                                            className,
                                            classes.primaryColor,
                                        )}
                                        icon={["fas", "user"]}
                                    />
                                    &nbsp;
                                    <AppTypography
                                        className={clsx(
                                            classes.skuId,
                                            classes.grey600,
                                        )}
                                    >
                                        {content.receiverFirstName}{" "}
                                        {content.receiverLastName} |{" "}
                                        {content.contentType} |{" "}
                                        {content.contentTitle} |{" "}
                                        {content.activity.actionDetails
                                            ?.stepName
                                            ? `Step | ${content.activity.actionDetails?.stepName} | `
                                            : ""}
                                        {content.activity.action}{" "}
                                        {!!content?.activity?.actionDetails
                                            ?.duration &&
                                            `| Duration:
                                ${secondsToPlayerDuration(
                                    content?.activity?.actionDetails?.duration,
                                )}`}
                                    </AppTypography>
                                </>
                            )}
                    </Box>
                </Grid>
            </Grid>
            <AppDivider />
        </>
    );
};

export interface GiftDashboadListItemProps
    extends InternalStandardProps<GridProps> {
    giftActivity: DashboradLogs;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1.5, 1),
        },
        centerItem: {
            marginLeft: "-10px",
        },
        description: {
            fontSize: "12px",
        },
        clampText: {
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
        },
        title: {
            fontSize: "18px",
            marginBottom: "5px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        skuId: { fontSize: "14px" },
        grey600: {
            color: theme.palette.grey[600],
        },
        thumbnail: {
            width: "60px",
            height: "auto",
            objectFit: "contain",
        },
        primaryColor: {
            color: theme.palette.primary.main,
        },
    }),
);
