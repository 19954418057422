import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";
import {
    DownloadStatus,
    getDownloadingStatus,
} from "JS/Helpers/ContentDownloadHelper";

export const useDownloadingStatus = (key?: string, bucket?: string) => {
    const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>(
        DownloadStatus.BLOCK,
    );
    const { enqueueSnackbar } = useSnackbar();

    const showWarningSnackbarIfRequired = () => {
        if (downloadStatus === DownloadStatus.WARNING)
            enqueueSnackbar(messaging.storageQuota.warning, {
                variant: "warning",
            });
    };

    const setStatus = async () => {
        setDownloadStatus(await getDownloadingStatus(key, bucket));
    };

    useEffect(() => {
        if (!!key && !!bucket) setStatus();
    }, [key, bucket]);

    return {
        downloadStatus,
        showWarningSnackbarIfRequired,
    };
};
