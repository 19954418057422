import { ContentAttribute } from "JS/Models/Attributes";
import {
    AttributeType,
    ContentTypeAttributes,
    resetAllAttributesState,
    resetAudAttributesState,
    resetVidAttributesState,
    setGlobalAudioAttributes,
    setGlobalVideoAttributes,
} from "JS/Redux/Attributes";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { AttributesService } from "JS/Services/Attributes";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import uniqBy from "lodash-es/uniqBy";
import sortBy from "lodash-es/sortBy";

export const service = new AttributesService();

export const useAttributes = (contentType: string, skip?: boolean) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [attributes, setAttributes] = useState<ContentAttribute[]>([]);
    const {
        setGlobalAudAttributes,
        setGlobalVidAttributes,
        audioSelectedSpeakers,
        videoSelectedSpeakers,
    } = useGlobalAttributes();

    const { enqueueSnackbar } = useSnackbar();

    const fetchAttributesByContentType = useCallback((contentType: string) => {
        setLoading(true);
        return service
            .getAttributesByContentType(contentType)
            .then(async (res) => {
                const response = res?.response;
                if (response?.status) {
                    const contentSpeakers = response.data
                        .map((item) => {
                            if (
                                item?.attributes.filter(
                                    (x) =>
                                        x.name === "Speaker" ||
                                        x.name === "Speaker/Author",
                                )?.length === 1
                            ) {
                                return {
                                    nid: item?.nid,
                                    speakerName: item?.attributes.find(
                                        (x) =>
                                            x.name === "Speaker" ||
                                            x.name === "Speaker/Author",
                                    )?.value,
                                };
                            } else {
                                return item?.attributes
                                    .filter(
                                        (x) =>
                                            x.name === "Speaker" ||
                                            x.name === "Speaker/Author",
                                    )
                                    ?.map((g) => {
                                        return {
                                            nid: item?.nid,
                                            speakerName: g?.value,
                                        };
                                    });
                            }
                        })
                        ?.flat();
                    const uniqueSpeakers = sortBy(
                        uniqBy(contentSpeakers, "speakerName"),
                        "speakerName",
                    );
                    setAttributes(response.data);
                    if (contentType === AttributeType.AUDIO) {
                        if (audioSelectedSpeakers?.length > 0) {
                            const audselectedSpeakers =
                                audioSelectedSpeakers?.filter((x) =>
                                    uniqueSpeakers?.some(
                                        (z) => z.speakerName === x,
                                    ),
                                );
                            if (audselectedSpeakers?.length > 0) {
                                setGlobalAudAttributes({
                                    selectedSpeakers: audselectedSpeakers,
                                });
                            }
                        }
                        setGlobalAudAttributes({
                            attributesList: response.data,
                            isAttributeFetched: true,
                            contentSpeakers: contentSpeakers,
                            uniqueSpeakers: uniqueSpeakers,
                        });
                    }
                    if (contentType === AttributeType.VIDEO) {
                        const vidSelectedSpeakers =
                            videoSelectedSpeakers?.filter((x) =>
                                uniqueSpeakers?.some(
                                    (z) => z.speakerName === x,
                                ),
                            );
                        if (vidSelectedSpeakers?.length > 0) {
                            setGlobalVidAttributes({
                                selectedSpeakers: vidSelectedSpeakers,
                            });
                        }
                        setGlobalVidAttributes({
                            attributesList: response.data,
                            isAttributeFetched: true,
                            contentSpeakers: contentSpeakers,
                            uniqueSpeakers: uniqueSpeakers,
                        });
                    }
                    return res;
                }
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            fetchAttributesByContentType(contentType);
        }
    }, [skip]);

    return {
        attributes,
        fetchAttributesByContentType,
        loading,
    };
};

export const useGlobalAttributes = () => {
    const audioMeta = useAppSelector((state) => state.attributes)?.audio;
    const audioAttibutesList = audioMeta?.attributesList || [];
    const audioUniqueSpeakers = audioMeta?.uniqueSpeakers || [];
    const audioFetchStatus = audioMeta?.isAttributeFetched;
    const audioSelectedSpeakers = audioMeta?.selectedSpeakers;
    const audioContentSpeakers = audioMeta?.contentSpeakers;
    const audioPlaylistSnackCount = audioMeta?.playlistSnackMsgCount;

    const videoMeta = useAppSelector((state) => state.attributes)?.video;
    const videoAttibutesList = videoMeta?.attributesList || [];
    const videoUniqueSpeakers = videoMeta?.uniqueSpeakers || [];
    const videoFetchStatus = videoMeta?.isAttributeFetched;
    const videoSelectedSpeakers = videoMeta?.selectedSpeakers;
    const videoContentSpeakers = videoMeta?.contentSpeakers;

    const dispatch = useAppDispatch();

    return {
        audioMeta,
        audioAttibutesList,
        audioFetchStatus,
        audioUniqueSpeakers,
        audioSelectedSpeakers,
        audioContentSpeakers,
        audioPlaylistSnackCount,

        videoAttibutesList,
        videoUniqueSpeakers,
        videoFetchStatus,
        videoSelectedSpeakers,
        videoContentSpeakers,

        setGlobalAudAttributes: (attributes: ContentTypeAttributes) => {
            dispatch(
                setGlobalAudioAttributes({
                    ...audioMeta,
                    ...attributes,
                }),
            );
        },
        setGlobalVidAttributes: (attributes: ContentTypeAttributes) => {
            dispatch(
                setGlobalVideoAttributes({
                    ...videoMeta,
                    ...attributes,
                }),
            );
        },
        resetAudioAttributes: () => {
            dispatch(resetAudAttributesState());
        },
        resetVideoAttributes: () => {
            dispatch(resetVidAttributesState());
        },
        resetAllAttributes: () => {
            dispatch(resetAllAttributesState());
        },
    };
};
