import { useState, useCallback, useEffect } from "react";
import {
    Course,
    CourseDetail,
    EventActions,
    EventCategories,
    EventNames,
    FBCourseStats,
    PurchasedGfitCourse,
    ReceivedCourse,
} from "JS/Models";
import { AppResponse } from "JS/Types";
import { sortArrayByKey } from "JS/Helpers";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { CoursesService } from "JS/Services/Courses";
import {
    ActiveCourse,
    setActiveCourse,
    setGlobalCourseGiftsGiven,
    setGlobalCourses,
    setGlobalPurchasableCourses,
    setGlobalPurchasedCourses,
    setGlobalReceivedCourses,
} from "JS/Redux/Course";
import { setGlobalMedia } from "JS/Redux/media";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { CourseLogFirebaseEventSettings } from "../../Scenes/Courses/Steps/CourseStep";
import { useSyncOfflineCourses } from "./SyncOffline";
import { useGlobalMediaEssentials } from "../MediaEssentials";

export const service = new CoursesService();

export const useCourses = (
    call: boolean = false,
    updateRequired: boolean = true,
) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [response, setResponse] =
        useState<AppResponse<{ courses: Course[] }>>(null);
    const [coursesLocal, setCoursesLocal] = useState<Course[]>([]);
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { mediaEssentials } = useGlobalMediaEssentials();
    const allCoursesState = useAppSelector((state) => state.courses);
    const { syncOfflineCourses } = useSyncOfflineCourses();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getCoursesIndex()
            .then(async (val) => {
                const apiResponse = val?.response;
                if (!apiResponse?.status) {
                    enqueueSnackbar(apiResponse.message, {
                        variant: "error",
                    });
                } else {
                    setResponse(val);
                    if (val?.response?.data) {
                        const allCourses =
                            val.response.data.courses &&
                            val.response.data.courses.map((item) => ({
                                ...item,
                                isReceived: false,
                            }));
                        const toSave = sortArrayByKey(
                            allCourses,
                            "title",
                            "ASC",
                        );
                        setCoursesLocal(toSave);
                        dispatch(
                            setGlobalCourses({
                                courses: toSave,
                            }),
                        );
                        dispatch(
                            setGlobalMedia({ lastCachedTime: moment().unix() }),
                        );
                        syncOfflineCourses(allCourses as Course[]);
                    }
                }

                return apiResponse;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    data: { courses: [] },
                    status: err.toJSON().status,
                    message: err.message,
                };
            })

            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (
            call ||
            (mediaEssentials?.allow_rule_engine_requests && updateRequired)
        ) {
            refetch();
        }
    }, [call, refetch, updateRequired]);

    return {
        refetch,
        response,
        loading,
        loaded,
        courses: allCoursesState.courses,
        coursesLocal,
    };
};

export const usePurchasableCourses = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] =
        useState<AppResponse<{ courses: Course[] }>>(null);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const allCoursesState = useAppSelector((state) => state.courses);

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getPurchasableGiftsForMembers()
            .then(async (val) => {
                const apiResponse = val?.response;

                if (!apiResponse?.status) {
                    enqueueSnackbar(apiResponse.message, {
                        variant: "error",
                    });
                } else {
                    setResponse(val);
                    if (val?.response?.data) {
                        const allCourses = val.response.data.courses;

                        dispatch(
                            setGlobalPurchasableCourses({
                                purchasableCoursesForMembers: sortArrayByKey(
                                    allCourses,
                                    "title",
                                    "ASC",
                                ),
                            }),
                        );
                        dispatch(
                            setGlobalMedia({ lastCachedTime: moment().unix() }),
                        );
                    }
                }

                return apiResponse;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    data: [],
                    status: err.toJSON().status,
                    message: err.message,
                };
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        response,
        loading,
        courses: allCoursesState.courses,
    };
};

export const usePurchasedGiftCourses = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] =
        useState<AppResponse<{ courses: PurchasedGfitCourse[] }>>(null);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const allCoursesState = useAppSelector((state) => state.courses);
    const { purchasableCoursesForMembers } = useGlobalCourses();
    const { refetch: fetchPurchasable } = usePurchasableCourses(true);
    const refetch = useCallback(async () => {
        setLoading(true);

        if (
            !purchasableCoursesForMembers ||
            purchasableCoursesForMembers.length === 0
        )
            await fetchPurchasable();

        return service
            .getPurchasedGiftCourses()
            .then(async (val) => {
                const apiResponse = val?.response;

                if (!apiResponse?.status) {
                    enqueueSnackbar(apiResponse.message, {
                        variant: "error",
                    });
                } else {
                    setResponse(val);
                    if (val?.response?.data) {
                        const allCourses = val.response.data.courses;

                        dispatch(
                            setGlobalPurchasedCourses({
                                purchasedGiftCourses: allCourses,
                            }),
                        );
                        dispatch(
                            setGlobalMedia({ lastCachedTime: moment().unix() }),
                        );
                    }
                }

                return apiResponse;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    data: [],
                    status: err.toJSON().status,
                    message: err.message,
                };
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        response,
        loading,
        courses: allCoursesState.courses,
    };
};

export const useReceivedCourses = (skip: boolean = false) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [response, setResponse] =
        useState<AppResponse<ReceivedCourse[]>>(null);
    const [receivedLocal, setReceivedLocal] = useState<ReceivedCourse[]>([]);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const allCoursesState = useAppSelector((x) => x.courses);

    const getGiftDate = (nid: string) =>
        allCoursesState?.receivedCourses?.find((c) => c.nid === nid)?.sender
            ?.sending_date;

    const { syncOfflineCourses } = useSyncOfflineCourses();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getReceivedCourses()
            .then(async (val) => {
                const apiResponse = val?.response;

                if (!apiResponse?.status) {
                    enqueueSnackbar(apiResponse.message, {
                        variant: "error",
                    });
                } else {
                    setResponse(val);
                    if (val?.response?.data) {
                        const allCourses =
                            val.response.data &&
                            val.response.data.map((item) => ({
                                ...item,
                                isReceived: true,
                            }));
                        const toSave = sortArrayByKey(
                            allCourses,
                            "title",
                            "ASC",
                        );
                        setReceivedLocal(toSave);

                        dispatch(
                            setGlobalReceivedCourses({
                                receivedCourses: toSave,
                            }),
                        );
                        dispatch(
                            setGlobalMedia({ lastCachedTime: moment().unix() }),
                        );
                        syncOfflineCourses(allCourses as ReceivedCourse[]);
                    }
                }

                return apiResponse;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    data: [],
                    status: err.toJSON().status,
                    message: err.message,
                };
            })

            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        response,
        loading,
        loaded,
        receivedLocal,
        receivedCourses: allCoursesState.receivedCourses,
        getGiftDate,
    };
};

export const useCourseGiftsGiven = () => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<AppResponse<any>>(null);

    const dispatch = useAppDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const allCoursesState = useAppSelector((x) => x.courses);

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getCoursesGiven()
            .then(async (val) => {
                const apiResponse = val?.response;

                if (!apiResponse?.status) {
                    enqueueSnackbar(apiResponse.message, {
                        variant: "error",
                    });
                } else {
                    setResponse(val);
                    if (val?.response?.data) {
                        const allCourses =
                            val.response.data &&
                            val.response.data.map((item) => ({
                                ...item,
                                isReceived: true,
                            }));

                        dispatch(
                            setGlobalCourseGiftsGiven({
                                giftsGiven: allCourses,
                            }),
                        );
                        dispatch(
                            setGlobalMedia({ lastCachedTime: moment().unix() }),
                        );
                    }
                }

                return apiResponse;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    data: [],
                    status: err.toJSON().status,
                    message: err.message,
                };
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        refetch();
    }, []);

    return {
        refetch,
        response,
        loading,
        receivedCourses: allCoursesState.receivedCourses,
    };
};

export const useCourseDetail = (
    received: boolean,
    activeCourse: Course | ReceivedCourse,
) => {
    const withMedia =
        received ||
        (!received &&
            ((activeCourse as Course)?.is_purchased ||
                (activeCourse as Course)?.publish_free));

    const detailWithMedia = useCourseDetailWithMedia(
        withMedia ? activeCourse?.sku_id : null,
    );
    const detailWithOutMedia = useCourseDetailWithOutMedia(
        !withMedia ? activeCourse?.sku_id : null,
    );

    const { detail, loading: detailLoading } = withMedia
        ? detailWithMedia
        : detailWithOutMedia;

    return {
        detail,
        withMedia,
        detailLoading,
    };
};

export const useCourseDetailWithMedia = (id: string = null) => {
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState<CourseDetail>(null);
    const [response, setResponse] = useState<AppResponse<CourseDetail>>(null);
    const { syncOfflineCourseSteps } = useSyncOfflineCourses();

    const refetch = useCallback((id: string) => {
        setLoading(true);
        setDetail(null);

        return service
            .getCourseDetailWithMedia(id)
            .then(async (val) => {
                const apiResponse = val?.response;

                if (!apiResponse?.status) {
                    enqueueSnackbar(apiResponse.message, {
                        variant: "error",
                    });
                } else {
                    setResponse(val);
                    if (val?.response?.data) {
                        const detail = val.response.data;
                        setDetail(detail);
                        syncOfflineCourseSteps(cloneDeep(val.response.data));
                    }
                }

                return apiResponse;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    data: [],
                    status: err.toJSON().status,
                    message: err.message,
                };
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (id != null) {
            refetch(id);
        }
    }, [id]);

    return {
        refetch,
        detail,
        response,
        loading,
    };
};

export const useCourseDetailWithOutMedia = (id: string = null) => {
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState<CourseDetail>(null);
    const [response, setResponse] = useState<AppResponse<CourseDetail>>(null);

    const refetch = useCallback((id: string) => {
        setLoading(true);
        setDetail(null);

        return service
            .getCourseDetailWithOutMedia(id)
            .then(async (val) => {
                const apiResponse = val?.response;

                if (!apiResponse?.status) {
                    enqueueSnackbar(apiResponse.message, {
                        variant: "error",
                    });
                } else {
                    setResponse(val);
                    if (val?.response?.data) {
                        setDetail(val.response.data);
                    }
                }

                return apiResponse;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    data: [],
                    status: err.toJSON().status,
                    message: err.message,
                };
            })

            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (id != null) {
            refetch(id);
        }
    }, [id]);

    return {
        refetch,
        detail,
        response,
        loading,
    };
};

export const useGlobalCourses = () => {
    const dispatch = useAppDispatch();

    const allCources = useAppSelector((state) => state.courses);

    return {
        setGlobalCourses: (courses: Course[]) => {
            dispatch(
                setGlobalCourses({
                    courses: courses,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },

        setGlobalReceivedCourses: (courses: ReceivedCourse[]) => {
            dispatch(
                setGlobalReceivedCourses({
                    receivedCourses: courses,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },
        setGlobalPurchasableCourses: (courses) => {
            dispatch(
                setGlobalPurchasableCourses({
                    purchasableCoursesForMembers: courses,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },
        setGlobalPurchasedCourses: (courses) => {
            dispatch(
                setGlobalPurchasedCourses({
                    ...courses,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },
        setGlobalCourseGiftsGiven: (courses) => {
            dispatch(
                setGlobalCourseGiftsGiven({
                    giftsGiven: courses,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },
        courses: allCources.courses,
        receivedCourses: allCources.receivedCourses,
        purchasableCoursesForMembers: allCources.purchasableCoursesForMembers,
        purchasedGiftCourses: allCources.purchasedGiftCourses,
        giftsGiven: allCources.giftsGiven,
        isCourseUpdateRequired: allCources.isUpdateRequired,
    };
};

export const useActiveCourse = () => {
    const dispatch = useAppDispatch();

    return {
        activeCourse: useAppSelector((state) => state.courses).activeCourse,
        setActiveCourse: (course: ActiveCourse) => {
            dispatch(
                setActiveCourse({
                    type: course.type,
                    course: course.course,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },
    };
};

export const useCourseCompletion = (courseType: string) => {
    async function updateCourseCompletion(
        latestCourseStats: FBCourseStats,
        updateCourseStats: (
            stats: FBCourseStats,
            refetch?: boolean,
        ) => Promise<void>,
        handleLogFirebaseEvents: (
            type: "course" | "step" | "question",
            settings: CourseLogFirebaseEventSettings,
        ) => void,
    ) {
        if (latestCourseStats) {
            let allStepsCompletedCount = [];

            if (latestCourseStats?.steps) {
                for (const key of Object.keys(latestCourseStats.steps)) {
                    allStepsCompletedCount.push(
                        latestCourseStats?.steps[key].analytics
                            ?.completed_count,
                    );
                }
            }

            const completedSteps = allStepsCompletedCount?.filter(
                (n) => n > 0,
            )?.length;

            const courseCompletionCount = Math.min(...allStepsCompletedCount);

            if (
                latestCourseStats.analytics.completed_count <
                    courseCompletionCount &&
                completedSteps === latestCourseStats?.analytics?.total_steps
            ) {
                if (courseType === "Course") {
                    handleLogFirebaseEvents("course", {
                        action: EventActions.COMPLETED,
                        category: EventCategories.COURSE,
                        eventNames: EventNames.COURSE_COMPLETED,
                    });
                }
                if (courseType === "GiftCourse") {
                    handleLogFirebaseEvents("course", {
                        action: EventActions.COMPLETED,
                        category: EventCategories.GIFT_COURSE,
                        eventNames: EventNames.GIFT_COURSE_COMPLETED,
                    });
                }
            }

            const completedCount =
                completedSteps === latestCourseStats.analytics.total_steps
                    ? courseCompletionCount
                    : 0;

            const coursePercentage = +(
                (completedSteps / latestCourseStats?.analytics?.total_steps) *
                100
            ).toFixed(0);

            latestCourseStats.analytics.last_completed_date =
                completedCount === 0 ? 0 : moment().valueOf();

            latestCourseStats.analytics.completed_count = completedCount;

            if (
                !latestCourseStats.analytics.first_completed_date ||
                completedCount === 0
            ) {
                latestCourseStats.analytics.first_completed_date =
                    completedCount === 0 ? 0 : moment().valueOf();
            }

            const computeProgressState = () => {
                if (completedSteps === 0 || !latestCourseStats?.steps) {
                    return 0;
                } else {
                    return completedSteps ===
                        latestCourseStats.analytics.total_steps
                        ? 2
                        : 1;
                }
            };

            await updateCourseStats(
                {
                    ...latestCourseStats,
                    last_updated: moment().valueOf(),
                    analytics: {
                        ...latestCourseStats.analytics,
                        progress_state: computeProgressState(),
                        completed_steps: completedSteps,
                        completed_percentage: coursePercentage,
                    },
                },
                true,
            );
        }
    }

    return {
        updateCourseCompletion,
    };
};
