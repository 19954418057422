import React from "react";
import clsx from "clsx";
import {
    InternalStandardProps,
    Theme,
    OutlinedTextFieldProps,
    TextField,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& label.Mui-focused": {
                color: `${theme.colors.textField.focus}`,
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: `${theme.colors.textField.focus}`,
            },
            "& .MuiOutlinedInput-root": {
                color: `${theme.palette.common.black} !important`,
                "& fieldset": {
                    borderColor: `${theme.palette.grey[600]}`,
                },
                "&:hover fieldset": {
                    borderColor: `${theme.colors.textField.focus}`,
                },
                "&.Mui-focused fieldset": {
                    borderColor: `${theme.colors.textField.focus} !important`,
                },
            },

            "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus":
                {
                    transition:
                        "background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s",
                    transitionDelay: "background-color 5000s, color 5000s",
                },
        },
    }),
);

export interface AppOutlinedTextFieldPropsProps
    extends InternalStandardProps<OutlinedTextFieldProps> {}

export const AppOutlineTextFeild = (props: AppOutlinedTextFieldPropsProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    return (
        <TextField
            className={clsx(className, classes.root)}
            style={{
                paddingBottom: "20px",
            }}
            {...rest}
        />
    );
};
