/* eslint-disable no-loop-func */
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import { Box, InternalStandardProps, Theme } from "@mui/material";
import { AppHeader } from "JS/React/Components/AppHeader";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import { useRouting } from "JS/React/Hooks/Routes";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { IndexableTypeArray, IndexableTypePart } from "dexie";
import { OfflineBundle } from "JS/Models/Common";
import { AppDivider } from "JS/React/Components/AppDivider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        listItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        listItemText: {
            color: theme.palette.grey[600],
        },
        playIcon: {
            fontSize: "22px",
            color: "#01445E",
        },
    }),
);

export interface BundleDetailOfflineProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const BundleDetailOffline = (props: BundleDetailOfflineProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const { resumeUserBundles } = useGlobalAudios();
    const [audio, setAudio] = useState<OfflineBundle>();
    const params: { contentId: string } = useParams();

    const { contentId } = params;
    const history = useHistory();
    const { linkProvider } = useRouting();

    const isResumeable = (nid, mediaTitle) => {
        let resumeData =
            resumeUserBundles &&
            resumeUserBundles.find(
                (x) => x.nid === nid && x.title === mediaTitle,
            );
        return !!resumeData;
    };

    const handleBackNavigation = () => {
        history.goBack();
    };

    const prepareBundle = async (contentId: string) => {
        const bundleCollection = getDexieConnectionRefresh().audios.filter(
            (audio) => audio.isBundle === true,
        );
        let availableBundlePartsNids: string[] = [];
        const splittedContentId = contentId.split("-");
        const id = `${splittedContentId[0]}-${splittedContentId[1]}`;
        let audio: OfflineBundle = {};

        await bundleCollection.keys().then((res) => {
            if (res && res.length) {
                let downloadedBundlePartsNids: IndexableTypeArray = res;
                downloadedBundlePartsNids.forEach((nid: IndexableTypePart) => {
                    if (nid?.toString()?.startsWith(id)) {
                        availableBundlePartsNids.push(nid?.toString());
                    }
                });
            }
        });

        for (const nid of availableBundlePartsNids) {
            await getDexieConnectionRefresh()
                .audios.get(nid)
                .then((res) => {
                    if (res) {
                        if (Object.keys(audio).length) {
                            audio = {
                                ...audio,
                                media: [
                                    ...audio.media,
                                    {
                                        media_title: res.media_title,
                                        position: res.position,
                                        blob: res.blob,
                                    },
                                ],
                            };
                        } else {
                            audio = {
                                id,
                                name: res.name,
                                media: [
                                    {
                                        media_title: res.media_title,
                                        position: res.position,
                                        blob: res.blob,
                                    },
                                ],
                                skuId: res.skuId,
                                description: res.description,
                                release_date: res.release_date,
                            };
                        }
                    }
                });
            setAudio(audio);
        }
    };

    const redirectToBundlePlay =
        (contentId: string, position: string) => () => {
            history.push(
                linkProvider.react.offline().bundle().play(contentId, position),
            );
        };

    useEffect(() => {
        if (contentId) {
            prepareBundle(contentId);
        }
    }, [contentId]);

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            <AppHeader
                title={audio?.name}
                canGoBack
                searchable={false}
                onBackClick={handleBackNavigation}
            />
            {audio &&
                audio?.media
                    ?.map((a) => a)
                    .sort((a, b) => a.position - b.position)
                    ?.map((m) => {
                        return (
                            <Box
                                p={2}
                                onClick={redirectToBundlePlay(
                                    contentId,
                                    (m.position - 1).toString(),
                                )}
                                key={m.media_title}
                            >
                                <Box className={classes.listItem}>
                                    <AppTypography
                                        className={classes.listItemText}
                                    >
                                        {m.media_title}
                                    </AppTypography>
                                    {isResumeable(
                                        audio?.id?.split("-")[1],
                                        m?.media_title,
                                    ) && (
                                        <FontAwesomeIcon
                                            className={classes.playIcon}
                                            icon={["fas", "play"]}
                                        />
                                    )}
                                </Box>
                                <AppDivider />
                            </Box>
                        );
                    })}
        </div>
    );
};
