import { Box, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";

export const MainPlayerOverlay = (props: MainPlayerOverlayProps) => {
    const classes = useStyles(props)();

    return (
        <div className={clsx(classes.root, classes.visible)}>
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <AppCircularProgress />
            </Box>
        </div>
    );
};

const useStyles = (props) =>
    makeStyles((theme: Theme) =>
        createStyles({
            root: {
                position: "absolute",
                width: "90%",
                margin: "0 5% 0 5%",
                height: "inherit",
                padding: theme.spacing(1),
                background: theme.palette.common.black,
                opacity: 0,
                zIndex: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                transition: "all 0.5s ease",
            },
            visible: {
                opacity: 0.5,
            },
        }),
    );

export interface MainPlayerOverlayProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {}
