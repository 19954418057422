import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Dialog,
    DialogContent,
    DialogProps,
    Grid,
    IconButton,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { AppButton } from "../AppButton";
import { AppTypography } from "../AppTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppCircularProgress } from "../Progress/AppCircularProgress";
import { RenderIf } from "../Wrapper/RenderIf";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        warpperDialog: {
            backgroundColor: theme.palette.background.default,
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        icon: {
            cursor: "pointer",
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        content: {
            position: "relative",
            minHeight: "150px",
            display: "grid",
        },
        sendToMemberBtn: {
            height: "35px",
        },
        warpperBtnSend: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        sendIcon: {
            cursor: "pointer",
            color: theme.palette.grey[600],
            fontSize: "22px",
            marginRight: theme.spacing(2),
        },
        membersWrapper: {
            background: theme.palette.grey[300],
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        okayButton: {
            width: "25%",
            padding: "0",
            height: "35px",
        },
        boldText: {
            fontWeight: "bold",
        },
        leftAlign: {
            textAlign: "left",
        },
    }),
);

export interface TransactionCompleteDialogProps
    extends InternalStandardProps<DialogProps> {
    onClose: () => void;
    onSendContent?: () => void;
    quantityLoading?: boolean;
    activeGiftItem?: {
        title: string;
        giftPurchasedEnabled: boolean;
        quantity: number;
        giftPurchasedSuccess: boolean;
    };
    selectedMembers?: any[];
    dialogMessage?: string;
    dialogTitle?: string;
    isGenericDialog?: boolean;
}

export const TransactionCompleteDialog = (
    props: TransactionCompleteDialogProps,
) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles(props);
    const {
        className,
        selectedMembers,
        onClose,
        activeGiftItem,
        onSendContent,
        quantityLoading,
        dialogMessage,
        dialogTitle,
        isGenericDialog,
        ...rest
    } = props;

    const handleOnSendContent = () => {
        if (activeGiftItem?.title === "Member") {
            onSendContent();
        } else {
            enqueueSnackbar(messaging.common.featureComingSoon, {
                variant: "info",
            });
        }
    };

    return (
        <Dialog
            role="dialog"
            maxWidth={"sm"}
            fullWidth
            className={clsx(className, classes.root)}
            {...rest}
        >
            <Box className={classes.warpperDialog}>
                <DialogContent>
                    <div className={classes.content}>
                        <RenderIf
                            isTrue={isGenericDialog}
                            fallback={
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignContent="baseline"
                                >
                                    <IconButton
                                        aria-label="Close"
                                        onClick={onClose}
                                    >
                                        <FontAwesomeIcon
                                            className={classes.icon}
                                            icon={["fas", "xmark"]}
                                        />
                                    </IconButton>
                                </Box>
                            }
                        >
                            <section role="presentation">
                                <AppTypography
                                    alignSelf={"center"}
                                    textAlign={"center"}
                                    justifySelf={"center"}
                                    className={clsx(
                                        classes.grey600,
                                        classes.boldText,
                                    )}
                                    variant="body1"
                                >
                                    {dialogTitle}
                                </AppTypography>
                                <AppTypography
                                    alignSelf={"center"}
                                    textAlign={"center"}
                                    justifySelf={"center"}
                                    marginTop={"5%"}
                                    className={clsx(
                                        classes.grey600,
                                        classes.leftAlign,
                                    )}
                                    variant="body1"
                                >
                                    {dialogMessage}
                                </AppTypography>
                            </section>
                        </RenderIf>

                        {activeGiftItem?.giftPurchasedSuccess && (
                            <section role="presentation">
                                <AppTypography
                                    alignSelf={"center"}
                                    textAlign={"center"}
                                    justifySelf={"center"}
                                    className={clsx(classes.grey600)}
                                    variant="body1"
                                >
                                    {messaging.gifts.successTitle}
                                </AppTypography>
                                <AppTypography
                                    alignSelf={"center"}
                                    textAlign={"center"}
                                    justifySelf={"center"}
                                    marginTop={"5%"}
                                    className={clsx(classes.grey600)}
                                    variant="body1"
                                >
                                    {messaging.gifts.successMsg(
                                        selectedMembers?.length,
                                    )}
                                </AppTypography>
                            </section>
                        )}

                        <RenderIf isTrue={isGenericDialog}>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignContent="baseline"
                            >
                                <AppButton
                                    className={classes.okayButton}
                                    onClick={onClose}
                                >
                                    OK
                                </AppButton>
                            </Box>
                        </RenderIf>
                        {activeGiftItem?.giftPurchasedEnabled &&
                            !activeGiftItem?.giftPurchasedSuccess && (
                                <>
                                    <AppTypography
                                        alignSelf={"center"}
                                        textAlign={"center"}
                                        justifySelf={"center"}
                                        className={classes.grey600}
                                        variant="body2"
                                    >
                                        Click the button below to send purchase
                                        content.
                                    </AppTypography>
                                    <Grid
                                        container
                                        className={classes.warpperBtnSend}
                                    >
                                        <Grid
                                            item
                                            xs={10}
                                            md={6}
                                            className={classes.warpperBtnSend}
                                        >
                                            {quantityLoading ? (
                                                <AppCircularProgress loaderSize="small" />
                                            ) : (
                                                <AppButton
                                                    onClick={
                                                        handleOnSendContent
                                                    }
                                                    className={
                                                        classes.sendToMemberBtn
                                                    }
                                                    rounded
                                                    buttonVariant="grey-text"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={[
                                                            "far",
                                                            "paper-plane",
                                                        ]}
                                                        className={
                                                            classes.sendIcon
                                                        }
                                                    />
                                                    Send to{" "}
                                                    {activeGiftItem?.title}s
                                                </AppButton>
                                            )}
                                            {selectedMembers &&
                                                selectedMembers.length > 0 && (
                                                    <span
                                                        className={
                                                            classes.membersWrapper
                                                        }
                                                    >
                                                        {selectedMembers.length}
                                                    </span>
                                                )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                    </div>
                </DialogContent>
            </Box>
        </Dialog>
    );
};
