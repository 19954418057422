import React, { useState } from "react";
import clsx from "clsx";
import {
    Card,
    CardContent,
    CardMedia,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Layout } from "../Layout";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";

import { AppHeader } from "JS/React/Components/AppHeader";
import { Icon, Icons } from "JS/React/Components/Icon";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { AllTabs } from "JS/Models";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },
        card: {
            width: "100%",
            borderRadius: 0,
            display: "flex",
            marginBottom: "15px",
            backgroundColor: theme.palette.background.default,
        },
        cardMedia: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        cardContent: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.grey[600],
        },
        giftIcon: {
            fontSize: "40px",
        },
        categoryTextWarpper: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "90%",
        },
        cardTitle: {
            color: theme.palette.grey[600],
        },
    }),
);

export interface GiftsProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export enum GiftCategories {
    PURCHASABLE_BY_MEMBERS = "purchase-gifts-for-members",
    PURCHASABLE_BY_PROSPECTS = "purchase-gifts-for-prospects",
    SEND_MEMBER = "send-member-gifts",
    RECEIVED_AS_MEMBER = "received-gifts-as-member",
    RECEIVED_AS_PROSPECT = "received-gifts-as-prospect",
    GIVEN = "gifts-given",
}

export enum GiftContentItemTypes {
    GIFT_MP3 = "GiftMP3",
    GIFT_VIDEO = "GiftVideo",
    GIFT_COURSE = "GiftCourse",
    GIFT_PROS_MP3 = "GiftProsMP3",
    GIFT_PROS_VIDEO = "GiftProsVideo",
}

export const giftCat = {
    [GiftCategories.PURCHASABLE_BY_MEMBERS]: {
        title: "Purchase Gifts for Members",
        icon: Icons.purchaseGiftIcon,
        contentItemTypeName: {
            audio: GiftContentItemTypes.GIFT_MP3,
            video: GiftContentItemTypes.GIFT_VIDEO,
            course: GiftContentItemTypes.GIFT_COURSE,
        },
    },
    [GiftCategories.PURCHASABLE_BY_PROSPECTS]: {
        title: "Purchase Gifts for Prospects",
        icon: Icons.purchaseGiftIcon,
        contentItemTypeName: {
            audio: GiftContentItemTypes.GIFT_PROS_MP3,
            video: GiftContentItemTypes.GIFT_PROS_VIDEO,
        },
    },
    [GiftCategories.SEND_MEMBER]: {
        title: "Send Member Gifts",
        icon: Icons.sendGiftIcon,
        contentItemTypeName: {
            audio: GiftContentItemTypes.GIFT_MP3,
            video: GiftContentItemTypes.GIFT_VIDEO,
            course: GiftContentItemTypes.GIFT_COURSE,
        },
    },
    [GiftCategories.RECEIVED_AS_MEMBER]: {
        title: "Received Gifts as Member",
        icon: Icons.receivedGiftIcon,
    },
    [GiftCategories.RECEIVED_AS_PROSPECT]: {
        title: "Received Gifts as Prospect",
        icon: Icons.receivedGiftIcon,
    },
    [GiftCategories.GIVEN]: {
        title: "Gifts Given",
        icon: Icons.giftGivenIcon,
    },
};

export const Gifts = (props: GiftsProps) => {
    const classes = useStyles(props);

    const history = useHistory();

    const { homeStack } = useGlobalNavStack();

    const { linkProvider } = useRouting();
    useSeamlessUpdate();

    const { className, ...rest } = props;

    const loading = false;
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <Layout>
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title="Gifts"
                    searchable={false}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Gifts by title/description"}
                    canGoBack
                    onBackClick={() => {
                        if (homeStack?.isHomeTrack) {
                            history.replace(linkProvider.react.home());
                        } else {
                            history.replace(linkProvider.react.more().index());
                        }
                    }}
                />

                {!loading && (
                    <Grid container spacing={0.2}>
                        {Object.keys(giftCat)?.map((category, idx) => (
                            <Card
                                key={idx}
                                className={clsx(classes.card)}
                                elevation={0}
                                onClick={() =>
                                    category === GiftCategories.GIVEN
                                        ? history.push(
                                              linkProvider.react
                                                  .gifts()
                                                  .giftsGivenListing(category),
                                          )
                                        : history.push(
                                              linkProvider.react
                                                  .gifts()
                                                  .listing(
                                                      category,
                                                      AllTabs.AUDIOS,
                                                  ),
                                          )
                                }
                            >
                                <CardMedia className={clsx(classes.cardMedia)}>
                                    <Icon icon={giftCat[category].icon} />
                                </CardMedia>
                                <CardContent
                                    className={clsx(classes.cardContent)}
                                >
                                    <Typography
                                        className={clsx(classes.cardTitle)}
                                    >
                                        {giftCat[category].title}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                )}
                {loading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.gift}
                    />
                )}
            </div>
        </Layout>
    );
};
