import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    InternalStandardProps,
    Radio,
    RadioGroup,
    RadioGroupProps,
    Theme,
} from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        label: {
            fontSize: "2rem",
            marginLeft: theme.spacing(2),
            color: theme.palette.grey[800],
        },
        smallLabel: {
            fontSize: "1rem",
            marginLeft: theme.spacing(1),
            color: theme.palette.grey[800],
        },
        radio: {
            color: theme.palette.grey[800],
            margin: theme.spacing(1, 0),

            "&.Mui-checked": {
                color: theme.palette.grey[800],
            },
            "& .MuiSvgIcon-root": {
                fontSize: 28,
            },
        },
    }),
);

export interface AppRadioGroupProps
    extends InternalStandardProps<RadioGroupProps> {
    radios: {
        label: string;
        value: string;
    }[];
    label?: string;
    variant?: string;
}

export const AppRadioGroup = (props: AppRadioGroupProps) => {
    const classes = useStyles(props);
    const {
        className,
        label,
        defaultValue,
        radios,
        variant = "large",
        ...rest
    } = props;

    return (
        <FormControl className={clsx(className, classes.root)}>
            {label && <FormLabel>{label}</FormLabel>}
            <RadioGroup {...rest}>
                {radios.map((d) => (
                    <FormControlLabel
                        key={d.value}
                        classes={{
                            label:
                                variant === "large"
                                    ? classes.label
                                    : classes.smallLabel,
                        }}
                        value={d.value}
                        control={<Radio className={classes.radio} />}
                        label={d.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
