import { useState, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { Webcast } from "JS/Models/Events";
import { WebcastService } from "JS/Services/Events/WebcastService";
import { setGlobalWebcast } from "JS/Redux/Webcast";

export const service = new WebcastService();

export const useGetWebcast = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [isError, setError] = useState(false);

    const dispatch = useAppDispatch();

    const fetch = useCallback(() => {
        setLoading(true);

        return service
            .getWebcasts()
            .then(async (res) => {
                dispatch(
                    setGlobalWebcast({
                        webcasts: res,
                    }),
                );
            })
            .catch((err) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) fetch();
    }, [skip]);

    return {
        loading,
        isError,
    };
};

export const useGlobalWebcast = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.webcasts);

    return {
        webcasts: useAppSelector((state) => state.webcasts)?.webcasts,
        setGlobalEvents: (events: Webcast[]) =>
            dispatch(
                setGlobalWebcast({
                    ...allData,
                    eventsList: events,
                }),
            ),
    };
};
