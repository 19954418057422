import {
    AudioContent,
    Course,
    Gifts,
    ReceivedCourse,
    VideoContent,
} from "JS/Models";
import sortableHeader from "JS/React/HOC/SortableHeader";
import {
    ColumnSort,
    getColumnSort,
    sortData,
    Sorting,
    sortViaAcquiredDate,
    sortViaPlayedDate,
} from "JS/Types";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { getPublicUrl } from "JS/Helpers";
import { useGetFavorites } from "JS/React/Hooks/Favorites";
import { AppTypography } from "JS/React/Components/AppTypography";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";
import { messaging } from "JS/Helpers/UserMessaging";
import { makeStyles } from "@mui/styles";
import { Box, InternalStandardProps, Theme } from "@mui/material";
import {
    createRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { VideoCategoryTitle } from "../Videos";
import moment from "moment";
import clsx from "clsx";
import { GiftNavStack, INavStack } from "JS/Models/navStack";
import AutoSizer from "react-virtualized-auto-sizer";
import qs from "qs";
import { FixedSizeList, ListOnItemsRenderedProps } from "react-window";
import { useHistory, useLocation } from "react-router-dom";
import { CONTENT_TILE_SIZE, nidControlTypes } from "JS/Helpers/Contants";
import { AppContentRow } from "JS/React/Components/AppContentRow";
import { AudioCategoryTitle } from ".";
import { PlaylistAudioContent } from "../DownloadsOffline/Playlist/PlaylistDetail";
import { useAppSelector } from "JS/Redux/Store";
import { AppContentListItemType } from "JS/React/Components/AppContentListItem";
export interface VideoDownloadProps {
    nid: string;
    isDownloadInProgress: boolean;
    isInDownloadQueue: boolean;
    isDownloaded: Promise<boolean>;
    inProgressDownloaded: boolean;
}
export interface AudioDownloadProps {
    nid: string;
    isDownloading: boolean;
    inProgressDownloaded: boolean;
    isInDownloadedQueue: boolean;
    isAudioDownloaded: Promise<boolean>;
}
export interface ListProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    listContent: (
        | VideoContent
        | AudioContent
        | Gifts
        | Course
        | ReceivedCourse
        | PlaylistAudioContent
    )[];
    searchQuery?: string;
    categoryId?: string;
    isChecked?: boolean;
    showSortHeader?: boolean;
    selectedPlaylistContent?: string[];
    setGlobalStack?: (
        payload: INavStack,
    ) => void | ((payload: GiftNavStack) => void);
    globalStack?: INavStack;
    favoritesContent?: (AudioContent | VideoContent)[];
    handleListItemClick?: (
        filteredVideo?:
            | VideoContent
            | AudioContent
            | Gifts
            | Course
            | ReceivedCourse,
        isAutoPlay?: boolean | string,
        scrollIndex?: number,
    ) => void;
    getDownloadHelperData?: (
        param: (VideoContent | AudioContent | Gifts)[],
    ) => (nid: string) => AudioDownloadProps | VideoDownloadProps;
    controlType?: string;
    isResumeable?: (
        filteredVideo: VideoContent | AudioContent | Gifts,
    ) => boolean;
    selectMode?: boolean;
    selectedItems?: string[];
    onGiftCheckChanged?: (nid: string, checked: boolean) => void;
    shouldSort?: boolean;
    checkIsCrsDownloaded?: (
        content: Course | ReceivedCourse,
    ) => Promise<boolean>;
    listHeight?: string;
    inProgressNid?: string;
    contentType?: AppContentListItemType;
    parentSort?: Sorting;
}
const SortableHeader = sortableHeader<HTMLSpanElement>("span");
export const ListingComponent = (props: ListProps) => {
    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const classes = useStyles({ ...props, inProgressNid });
    const {
        className,
        listContent: content = [],
        categoryId,
        searchQuery,
        setGlobalStack,
        globalStack,
        favoritesContent,
        handleListItemClick,
        getDownloadHelperData,
        controlType,
        showSortHeader,
        isResumeable,
        selectMode = false,
        selectedItems = [],
        onGiftCheckChanged,
        shouldSort = true,
        checkIsCrsDownloaded,
        contentType,
        parentSort,
        ...rest
    } = props;
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const history = useHistory();
    const location = useLocation();
    useGetFavorites(!!favoritesContent && favoritesContent.length > 0);
    const sortingHandler = () => {
        if (controlType === "video") {
            return categoryId === VideoCategoryTitle.RECENTLY_PLAYED;
        } else if (controlType === "audio") {
            return categoryId === AudioCategoryTitle.RECENTLY_PLAYED;
        }
    };
    const [sorting, setSorting] = useState<Sorting>(
        sortingHandler()
            ? [
                {
                    col: "played",
                    dir: "desc",
                    position: 0,
                },
            ]
            : globalStack?.sorting,
    );
    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);
    const onSortChange = useCallback(
        (columnSort: ColumnSort, oldOrder: number) => {
            setSorting([columnSort]);
            setGlobalStack({ sorting: [columnSort] });
        },
        [setGlobalStack],
    );
    const finalData = useMemo(() => {
        if (!sorting) return content;
        if (
            shouldSort &&
            categoryId &&
            (controlType.includes("video") || controlType.includes("audio"))
        ) {
            if (sorting[0].col === "acquired_date") {
                const newContent: (VideoContent | AudioContent | Gifts)[] =
                    content.map((d: VideoContent | AudioContent | Gifts) => {
                        if (d.acquired_date) {
                            return d;
                        } else {
                            if (d.sender) {
                                if (d.sender.sending_date) {
                                    const timeStamp = moment(
                                        d.sender.sending_date_time,
                                    )
                                        .unix()
                                        .toString();
                                    return {
                                        ...d,
                                        acquired_date: timeStamp,
                                    };
                                } else {
                                    return d;
                                }
                            } else {
                                return d;
                            }
                        }
                    });
                return sortViaAcquiredDate(newContent, sorting);
            }
            if (sorting[0].col === "played")
                return sortViaPlayedDate(
                    content as (VideoContent | AudioContent | Gifts)[],
                    sorting,
                );
            return sortData(content, sorting);
        } else return content;
    }, [categoryId, content, controlType, shouldSort, sorting]);

    const { visibleIndices, onRender } = useItemsRendered();

    const visibleData = useMemo(() => {
        if (!finalData || !visibleIndices) return [];
        return finalData.slice(visibleIndices.start, visibleIndices.end);
    }, [finalData, visibleIndices]);
    function isCorrectTypeForControlType(
        data: any,
    ): data is (VideoContent | AudioContent | Gifts)[] {
        return data.every((item: VideoContent | AudioContent | Gifts) => {
            if (
                controlType === "video" ||
                controlType === "audio" ||
                controlType === "course"
            ) {
                return true;
            }
            return false;
        });
    }
    let getDownloadedStatusById;
    if (isCorrectTypeForControlType(visibleData)) {
        getDownloadedStatusById = getDownloadHelperData
            ? getDownloadHelperData(visibleData)
            : false;
    }
    useEffect(() => {
        if (!finalData || !Array.isArray(finalData) || finalData.length === 0) {
            return;
        }
        verifyAwsKeys().then(() => {
            fetchThumbnails();
        });
    }, [visibleData]);
    const createImageUrl = async (
        content: VideoContent | AudioContent | Gifts | Course | ReceivedCourse,
    ) => {
        const url = await getPublicUrl(
            "image" in content
                ? content.image.image_url_prefix
                : content.image_url_prefix,
            "image" in content
                ? content.image.image_url_postfix
                : content.image_url_postfix,
            "image" in content
                ? content.image.image_file_name
                : content.image_file_name,
        );

        return {
            id: content.nid,
            url: url,
        };
    };
    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];
        visibleData.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d);
                promises.push(promise);
            }
        });
        const thumbs = await Promise.all(promises);
        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };
    const isFavouriteContent = (
        filteredContent: VideoContent | AudioContent | Gifts,
    ) => {
        if (
            favoritesContent &&
            favoritesContent.find((x) => x.nid === filteredContent.nid)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const courseContentType = (content: Course | ReceivedCourse) => {
        return (content as Course)?.publish_free ||
            (content as Course)?.is_purchased ||
            content.isReceived
            ? "courses"
            : "purchasableCourses";
    };
    const getDownloadStatus = (
        content: VideoContent | AudioContent | Gifts,
    ): VideoDownloadProps | AudioDownloadProps => {
        return getDownloadedStatusById(content?.nid);
    };
    const { scrollTo = "0" } = useQueryParams();
    const listRef = createRef<FixedSizeList>();
    const scrollControlRef = useRef<boolean>(false);
    const searchScrollRef = useRef<boolean>(false);

    useEffect(() => {
        if (+scrollTo >= 0 && listRef.current && !scrollControlRef.current) {
            let scrollToElem = +scrollTo;
            if (nidControlTypes.includes(controlType)) {
                scrollToElem = getIndexByNid(scrollTo as string);
            }
            listRef.current.scrollToItem(scrollToElem, "center");
            scrollControlRef.current = true;
        }
    }, [scrollTo, finalData, listRef]);
    useEffect(() => {
        if (listRef.current && searchQuery && searchScrollRef.current) {
            resetScrollings();
        }
        searchScrollRef.current = true;
    }, [searchQuery]);

    useEffect(() => {
        const changedOccured = sorting ?? parentSort;
        if (listRef.current && changedOccured) {
            const searchParams = new URLSearchParams(location.search);
            let scrollNid = 0;
            if (nidControlTypes.includes(controlType)) {
                scrollNid = +finalData[0].nid;
            }
            resetScrollings();

            searchParams.set("scrollTo", scrollNid.toString());
            history.replace({
                pathname: location.pathname,
                search: searchParams.toString(),
            });

        }
    }, [sorting, parentSort]);

    const resetScrollings = () => {
        listRef?.current?.scrollToItem(0, "start");
    }
    const getIndexByNid = (nid: string) => {
        return finalData.findIndex((elem) => elem.nid === nid);
    };
    const getNidOfZeroIndex = () => {
        if (finalData.length > 0) {
            return finalData[0].nid;
        } else {
            return 0;
        }
    }

    return (
        <div className={clsx(className, classes.root)} {...rest}>
            {!!showSortHeader && (
                <Box display={"flex"} justifyContent={"space-around"}>
                    <SortableHeader
                        upDirection="asc"
                        downDirection="desc"
                        sort={getColumnSort(sorting, {
                            col: "title",
                            position: sorting.length,
                        })}
                        onSortChanged={onSortChange}
                    >
                        <span>A-Z</span>
                    </SortableHeader>
                    <SortableHeader
                        upDirection="desc"
                        downDirection="asc"
                        sort={getColumnSort(sorting, {
                            col: "release_date",
                            position: sorting.length,
                        })}
                        onSortChanged={onSortChange}
                    >
                        <span>Released</span>
                    </SortableHeader>
                    <SortableHeader
                        upDirection="desc"
                        downDirection="asc"
                        sort={getColumnSort(sorting, {
                            col: "acquired_date",
                            position: sorting.length,
                        })}
                        onSortChanged={onSortChange}
                    >
                        <span>Acquired</span>
                    </SortableHeader>
                    <SortableHeader
                        upDirection="desc"
                        downDirection="asc"
                        sort={getColumnSort(sorting, {
                            col: "played",
                            position: sorting.length,
                        })}
                        onSortChanged={onSortChange}
                    >
                        <span>Played</span>
                    </SortableHeader>
                </Box>
            )}
            <RenderIf
                isTrue={!!finalData?.length}
                fallback={
                    <AppTypography
                        className={classes.emptyList}
                        align="center"
                        variant="h6"
                    >
                        {messaging.common.noItemsFound}
                    </AppTypography>
                }
            >
                <Box className={classes.listHeight}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <FixedSizeList
                                height={height}
                                itemCount={finalData?.length || 0}
                                itemSize={CONTENT_TILE_SIZE}
                                width={width}
                                ref={listRef}
                                onItemsRendered={onRender}
                            >
                                {({ index, style }) => (
                                    <AppContentRow
                                        index={index}
                                        style={style}
                                        listData={finalData[index]}
                                        sorting={sorting}
                                        thumbnails={thumbnails}
                                        controlType={controlType}
                                        isResumeable={isResumeable}
                                        isFavouriteContent={isFavouriteContent}
                                        {...((controlType === "audio" ||
                                            controlType === "video") && {
                                            getDownloadStatus,
                                        })}
                                        handleListItemClick={
                                            handleListItemClick
                                        }
                                        onGiftCheckChanged={onGiftCheckChanged}
                                        selectMode={selectMode}
                                        isSelected={selectedItems.includes(
                                            finalData[index].nid,
                                        )}
                                        contentType={contentType}
                                        {...(controlType === "course"
                                            ? {
                                                type: courseContentType,
                                                checkIsCrsDownloaded,
                                            }
                                            : {})}
                                        {...(controlType === "offlinePlaylist"
                                            ? {
                                                isAllowClick: (
                                                    finalData[
                                                    index
                                                    ] as PlaylistAudioContent
                                                ).isDownloaded,
                                                isDisabled: !(
                                                    finalData[
                                                    index
                                                    ] as PlaylistAudioContent
                                                ).isDownloaded,
                                            }
                                            : {})}
                                    />
                                )}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </Box>
            </RenderIf>
        </div>
    );
};

const useItemsRendered = () => {
    const [visibleIndices, setVisibleIndices] = useState<{
        start: number;
        end: number;
    }>();

    const onRender = (props: ListOnItemsRenderedProps) => {
        setVisibleIndices({
            start: props.visibleStartIndex,
            end: props.visibleStopIndex,
        });
    };

    return {
        visibleIndices,
        onRender,
    };
};

export const useQueryParams = () => {
    const location = useLocation();
    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const { scrollTo } = parsedQueryString;
        return {
            scrollTo,
        };
    }, [location?.search]);

    return queryParams;
};
const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    emptyList: {
        marginTop: theme.spacing(3),
        color: theme.palette.grey[500],
    },
    listHeight: (props: ListProps) => ({
        [theme.breakpoints.down("lg")]: {
            height: "90vh",
        },
        [theme.breakpoints.down("md")]: {
            height: "70vh",
        },
        [theme.breakpoints.down("sm")]: {
            height:
                props.listHeight || (!!props.inProgressNid ? "65vh" : "70vh"),
        },
    }),
}));
