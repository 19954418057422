import axios from "axios";
import { BaseService } from "../BaseService";
import { MediaHelper } from "./mediaHelper";

export class MediaService extends BaseService {
    private helper = new MediaHelper();

    async getMediaBlob(url: string) {
        return axios({
            url: url,
            method: "GET",
            responseType: "blob",
        });
    }

    async getMediaUserJsonFile(
    ): Promise<AWS.S3.GetObjectOutput> {
        return new Promise((res, _) => {
            this.helper.initConfig();
            const s3 = this.helper.getS3();
            s3.getObject(this.helper.jsonFileParams(), (err, data) => {
                if (err) res(null);
                res(data);
            });
        });
    }
}
