import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    InternalStandardProps,
    Grid,
    GridProps,
    Box,
    Theme,
} from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        commonText: {
            textAlign: "center",
            fontWeight: "200",
            textTransform: "uppercase",
            margin: `4% !important`,
        },
        headingText: {
            color: theme.colors.red,
        },
        descriptionText: {
            color: theme.palette.primary.main,
        },
        btnWrapper: {
            display: "flex",
            justifyContent: "center",
            margin: `4% !important`,
        },
        btnGoToHome: {
            borderRadius: "2em",
            display: "inline-block",
            padding: "0.35em 1.2em",
            border: `0.2px solid ${theme.palette.primary.main}`,
            background: "transparent",
            margin: "0 0.3em 0.3em 0",
            boxSizing: "border-box",
            textDecoration: "none",
            fontFamily: "Roboto,sans-serif",
            fontWeight: "300",
            color: theme.palette.common.black,
            textAlign: "center",
            transition: "all 0.2s",
            cursor: "pointer !important",
            "&:hover": {
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
            },
        },
    }),
);

export interface AppFallBackComponentProps
    extends InternalStandardProps<GridProps> {
    title?: string;
    desc: string;
    routeText: string;
    route: string;
}

export const AppFallBackComponent = (props: AppFallBackComponentProps) => {
    const classes = useStyles(props);
    const { className, title, desc, routeText, route, ...rest } = props;

    return (
        <Grid container className={clsx(classes.root, className)} {...rest}>
            <Grid item xs={12}>
                {title && (
                    <h2
                        className={clsx(
                            classes.commonText,
                            classes.headingText,
                        )}
                    >
                        {title}
                    </h2>
                )}
                <h3
                    className={clsx(
                        classes.commonText,
                        classes.descriptionText,
                    )}
                >
                    {desc}
                </h3>
                <Box className={classes.btnWrapper}>
                    <button
                        className={classes.btnGoToHome}
                        onClick={() => window.location.replace(route)}
                    >
                        {routeText}
                    </button>
                </Box>
            </Grid>
        </Grid>
    );
};
