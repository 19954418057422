import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "../Context/AuthenticationProvider";
import { useRouting } from "../Hooks/Routes";
import { AppBackdropProgress } from "../Components/Progress/AppBackdropProgress";
import { messaging } from "JS/Helpers/UserMessaging";

interface PrivateRouteProps extends RouteProps {}

const PrivateRoute = ({
    render,
    component: Component,
    path,
    ...rest
}: PrivateRouteProps) => {
    const { isAuthenticated, loading, sessionLoading } = useAuth();
    const { linkProvider } = useRouting();

    if (loading || sessionLoading) {
        return (
            <AppBackdropProgress
                open={true}
                backdropText={messaging?.loader?.loadingSession}
            />
        );
    }

    return !loading && isAuthenticated === true ? (
        <Route
            {...rest}
            render={() => <Route component={Component} render={render} />}
        />
    ) : (
        <Redirect to={linkProvider.react.login()} />
    );
};

export default PrivateRoute;
