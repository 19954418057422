import { config } from "JS/Config";
import AWS from "aws-sdk";

export class ConsentTextService {
    public bucket = config.s3.mediaEssentials.bucketName;
    private fileName = config.consentForm.fileName;

    private getS3(): AWS.S3 {
        return new AWS.S3();
    }

    private initConfig() {
        AWS.config.update({
            credentials: {
                accessKeyId: config.s3.accessKey,
                secretAccessKey: config.s3.secretAccessKey,
            },
            region: config.s3.region,
        });
    }

    public getConsentFormText(): Promise<AWS.S3.GetObjectOutput> {
        this.initConfig();
        return new Promise((res, rej) => {
            this.getS3().getObject(
                {
                    Bucket: this.bucket,
                    Key: `ConsentForm/${this.fileName}`,
                    ResponseCacheControl: "no-cache",
                },
                (err, data) => {
                    if (!!err) rej(err);
                    else res(data);
                },
            );
        });
    }
}
