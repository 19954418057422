import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Theme, InternalStandardProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

import { config } from "JS/Config";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppTypography } from "JS/React/Components/AppTypography";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useUserSavedDocIds } from "JS/React/Hooks/Database/Document";
import {
    useDocumentsDetails,
    useGlobalDocuments,
} from "JS/React/Hooks/Documents";
import { useRouting } from "JS/React/Hooks/Routes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        itemContainer: {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1),

            "&:hover": {
                background:
                    theme.palette.mode === "light"
                        ? theme.palette.grey["200"]
                        : theme.palette.grey[900],
                cursor: "pointer",
            },
        },
        icons: {
            fontSize: "35px",
            [theme.breakpoints.down("md")]: {
                fontSize: "22px",
            },
        },
        description: {
            fontSize: "30px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.palette.grey[600],
            [theme.breakpoints.down("md")]: {
                fontSize: "20px",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "18px",
            },
        },
        pdfIcon: {
            color: "red",
            height: "35px",
            margin: theme.spacing(0, 2),
        },
        rightIcons: {
            margin: theme.spacing(0, 1),
            color: theme.palette.common.black,
        },
        floppyIcon: {
            fontSize: "26px",
            paddingTop: "2px",
        },
    }),
);

export interface DocumentsListProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const DocumentsList = (props: DocumentsListProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const history = useHistory();
    const { linkProvider } = useRouting();

    const { documentUserFavorites, downloadedContentNids } =
        useGlobalDocuments();

    const { userDownloadedDocIds } = useUserSavedDocIds();

    const { docDetails, loading: docDetailsLoading } = useDocumentsDetails();

    const onDocumentClick = (pdfName: string, description?: string) => {
        history.push(
            linkProvider.react.documents().detail(pdfName, description),
        );
    };

    const isFavDocument = (name: string) =>
        documentUserFavorites?.findIndex((x) => x.Name === name) !== -1;

    const isDownloadedDoc = (pdf: string) =>
        userDownloadedDocIds?.includes(`${config.user.memberId}-${pdf}`) ||
        downloadedContentNids?.includes(`${config.user.memberId}-${pdf}`);

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            {docDetailsLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.documents}
                />
            )}

            {docDetails?.map((d, idx) => (
                <Grid
                    key={d.Name + idx}
                    container
                    className={classes.itemContainer}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    onClick={() => onDocumentClick(d.Name, d.Description)}
                >
                    <Grid
                        item
                        sm={9}
                        xs={9}
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <FontAwesomeIcon
                            className={clsx(classes.icons, classes.pdfIcon)}
                            icon={["fas", "file-pdf"]}
                        />
                        <AppTypography
                            className={classes.description}
                            variant="h4"
                        >
                            {d.Description}
                        </AppTypography>
                    </Grid>
                    <Grid
                        item
                        sm={3}
                        xs={3}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Grid item sm={1} xs={1} className={classes.rightIcons}>
                            {isDownloadedDoc(d.Name) && (
                                <FontAwesomeIcon
                                    className={clsx(
                                        classes.grey600,
                                        classes.floppyIcon,
                                    )}
                                    icon={["far", "floppy-disk"]}
                                />
                            )}
                        </Grid>
                        <Grid item sm={1} xs={1} className={classes.rightIcons}>
                            {isFavDocument(d.Name) && (
                                <FontAwesomeIcon
                                    className={clsx(
                                        classes.icons,
                                        classes.grey600,
                                    )}
                                    icon={["fas", "star"]}
                                />
                            )}
                        </Grid>
                        <Grid item sm={1} xs={1} className={classes.rightIcons}>
                            <FontAwesomeIcon
                                className={classes.icons}
                                icon={["fas", "chevron-right"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};
