import React, { useState } from "react";
import {
    AppConfimationDialog,
    AppConfimationDialogProps,
} from "./AppConfirmationDialog";

export const AskConfirmationDialog = (props: AskConfirmationDialogProps) => {
    const { onConfirm, onClose = () => {}, children, ...rest } = props;

    const [open, setOpen] = useState<boolean>();

    const askConfirmation = () => {
        setOpen(true);
    };

    return (
        <React.Fragment>
            {open && (
                <AppConfimationDialog
                    open={open}
                    onConfirm={() => {
                        onConfirm();
                        setOpen(false);
                    }}
                    onClose={() => {
                        setOpen(false);
                        onClose();
                    }}
                    {...rest}
                />
            )}
            {children({
                askConfirmation,
            })}
        </React.Fragment>
    );
};

export interface AskConfirmationDialogProps
    extends Omit<AppConfimationDialogProps, "onClose" | "open"> {
    children: (
        renderProps: AskConfirmationDialogRenderProps,
    ) => React.ReactNode;
    onClose?: () => void;
}

export interface AskConfirmationDialogRenderProps {
    askConfirmation: () => void;
}
