import { config } from "JS/Config";
import { buildUrlWithQueryParams } from "JS/Helpers";
import { AuthorizedRoutingContext } from "./Context/AuthorizedRoutingContext";
import { BaseRoutingContext } from "./Context/BaseRoutingContext";

export const routesForContext = () => () => {
    const authorizedContext = new AuthorizedRoutingContext();
    const unauthorizedContext = new BaseRoutingContext();

    return {
        react: {
            root: () => unauthorizedContext.buildUrl(`/`),
            login: (historyIndex?: string) =>
                historyIndex
                    ? unauthorizedContext.buildUrl(
                          `/login?history_index=${historyIndex}`,
                      )
                    : unauthorizedContext.buildUrl("/login"),

            userHistory: () => unauthorizedContext.buildUrl(`/userHistory`),

            home: () => authorizedContext.buildUrl(`/home`),
            attachedSku: () => authorizedContext.buildUrl(`/attached_sku`),
            videos: () => ({
                index: () => authorizedContext.buildUrl(`/videos`),
                detail: (
                    id: string = ":contentId",
                    categoryId: string = ":categoryId",
                    isAutoPlay: string = ":isAutoPlay",
                    queryParams?: {
                        scrollTo?: number;
                    },
                ) => {
                    const url = `/videos/${id}/${categoryId}/detail/${isAutoPlay}`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                listing: (
                    id: string = ":categoryId",
                    queryParams?: {
                        scrollTo?: number;
                    },
                ) => {
                    const url = `/videos/${id}/listings`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
            }),
            audios: () => ({
                index: (queryParams?: { scrollTo?: number }) => {
                    const url = `/audios`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                detail: (
                    id: string = ":contentId",
                    categoryId: string = ":categoryId",
                    isAutoPlay: string = ":isAutoPlay",
                    queryParams?: {
                        recId?: number;
                        displayCount?: number;
                        source?: string;
                        scrollTo?: number;
                    },
                ) => {
                    isAutoPlay = "false";
                    const url = `/audios/${id}/${categoryId}/detail/${isAutoPlay}`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                listing: (
                    id: string = ":categoryId",
                    queryParams?: {
                        scrollTo?: number;
                    },
                ) => {
                    const url = `/audios/${id}/listings`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
            }),
            bundle: () => ({
                audios: (
                    id: String = ":contentId",
                    categoryId: string = ":categoryId",
                    isGift: string = ":isGift",
                    queryParams?: {
                        scrollTo?: number;
                    },
                ) => {
                    const url = categoryId
                        ? `/bundle/${id}/audios/${isGift}/${categoryId}`
                        : `/bundle/${id}/audios/${isGift}`;

                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                play: (
                    id: string = ":contentId",
                    queryParams?: {
                        categoryId?: string;
                        isGift?: string;
                        index?: string;
                        scrollTo?: number;
                    },
                ) => {
                    const url = `/bundle/${id}/play`;
                    // isGift=${isGift}&index=${index}&categoryId=${categoryId}
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
            }),
            courses: () => ({
                index: (queryParams?: {
                    segment?: string;
                    scrollTo?: number;
                }) => {
                    const url = `/courses`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                detail: (
                    id: string = ":id",
                    queryParams?: {
                        received?: string;
                        segment?: string;
                        scrollTo?: number;
                    },
                ) => {
                    const url = `/courses/${id}/detail`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                mediaStepDetail: (
                    detailSkuId: string = ":detailSkuId",
                    stepSkuId: string = ":stepSkuId",
                    isAutoPlay: string = ":isAutoPlay",
                    queryParams?: {
                        displayMode?: boolean;
                        contentType?: string;
                        scrollTo?: string;
                    },
                ) => {
                    const url = `/courses/detail/${detailSkuId}/step/${stepSkuId}/${isAutoPlay}/media`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
            }),
            mixedContent: () => ({
                index: (
                    filePath?: string,
                    queryParams?: {
                        scrollTo: string;
                    },
                ) => {
                    const url = authorizedContext.buildUrl(
                        filePath
                            ? `/mixedContent?filePath=${filePath}`
                            : `/mixedContent`,
                    );
                    if (!!queryParams)
                        return buildUrlWithQueryParams(url, queryParams);
                    return url;
                },
                audioDetail: (
                    id: string = ":contentId",
                    categoryId: string = ":categoryId",
                    isAutoPlay: string = ":isAutoPlay",
                    queryParams?: {
                        isMixedContent: boolean;
                        scrollTo: string;
                    },
                ) => {
                    isAutoPlay = "false";
                    const url = `/mixedContent/audios/${id}/${categoryId}/detail/${isAutoPlay}`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                videoDetail: (
                    id: string = ":contentId",
                    categoryId: string = ":categoryId",
                    isAutoPlay: string = ":isAutoPlay",
                    queryParams?: {
                        isMixedContent: boolean;
                        scrollTo: string;
                    },
                ) => {
                    const url = `/mixedContent/videos/${id}/${categoryId}/detail/${isAutoPlay}`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                bundleDetail: (
                    id: String = ":contentId",
                    categoryId: string = ":categoryId",
                    isGift: string = ":isGift",
                    queryParams?: {
                        isMixedContent: boolean;
                        scrollTo: string;
                    },
                ) => {
                    let url = ``;
                    if (categoryId) {
                        url = `/mixedContent/bundle/${id}/audios/${isGift}/${categoryId}`;
                    } else {
                        url = `/mixedContent/bundle/${id}/audios/${isGift}`;
                    }
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                bundlePlay: (
                    id: string = ":contentId",
                    categoryId: string = ":categoryId",
                    isGift?: string,
                    index?: string,
                    queryParams?: {
                        isMixedContent: boolean;
                        scrollTo: string;
                    },
                ) => {
                    let url = "";
                    if (isGift && index && categoryId) {
                        url = `/mixedContent/bundle/${id}/play?isGift=${isGift}&index=${index}&categoryId=${categoryId}`;
                    } else {
                        if (index && categoryId) {
                            url = `/mixedContent/bundle/${id}/play?index=${index}&categoryId=${categoryId}`;
                        } else {
                            if (isGift && index) {
                                url = `/mixedContent/bundle/${id}/play?isGift=${isGift}&index=${index}`;
                            } else {
                                url = `/mixedContent/bundle/${id}/play`;
                            }
                        }
                    }
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                courseDetail: (
                    id: string = ":id",
                    received?: string,
                    contentType?: string,
                    queryParams?: {
                        isMixedContent: boolean;
                        scrollTo: string;
                    },
                ) => {
                    let url = "";
                    if (received && contentType) {
                        url = `/mixedContent/courses/${id}/detail?received=${received}&segment=${contentType}`;
                    } else {
                        if (received) {
                            url = `/mixedContent/courses/${id}/detail?received=${received}`;
                        } else {
                            url = `/mixedContent/courses/${id}/detail`;
                        }
                    }
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                courseMediaStepDetail: (
                    detailSkuId: string = ":detailSkuId",
                    stepSkuId: string = ":stepSkuId",
                    isAutoPlay: string = ":isAutoPlay",
                    displayMode?: boolean,
                    contentType?: string,
                    queryParams?: {
                        isMixedContent: boolean;
                        scrollTo: number;
                    },
                ) => {
                    let url = "";
                    if (displayMode && contentType) {
                        url = `/mixedContent/courses/detail/${detailSkuId}/step/${stepSkuId}/${isAutoPlay}/media?displayMode=${displayMode}&segment=${contentType}`;
                    } else {
                        if (displayMode) {
                            url = `/mixedContent/courses/detail/${detailSkuId}/step/${stepSkuId}/${isAutoPlay}/media?displayMode=${displayMode}`;
                        } else {
                            url = `/mixedContent/courses/detail/${detailSkuId}/step/${stepSkuId}/${isAutoPlay}/media`;
                        }
                    }
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
            }),
            gifts: () => ({
                index: () => authorizedContext.buildUrl(`/gifts`),
                listing: (
                    id: string = ":categoryId",
                    selectedTab: string = ":selectedTab",
                    queryParams?: {
                        scrollTo: string;
                    },
                ) => {
                    const url = `/gifts/${id}/${selectedTab}/listings`;
                    if (!!queryParams)
                        return buildUrlWithQueryParams(url, queryParams);
                    return authorizedContext.buildUrl(url);
                },
                detail: (
                    id: string = ":contentId",
                    categoryId: string = ":categoryId",
                    selectedTab: string = ":selectedTab",
                    queryParams?: {
                        scrollTo: string;
                    },
                ) => {
                    const url = `/gifts/${categoryId}/${selectedTab}/${id}/detail`;
                    if (!!queryParams)
                        return buildUrlWithQueryParams(url, queryParams);
                    return authorizedContext.buildUrl(url);
                },
                giftAudioDetail: (
                    id: string = ":contentId",
                    categoryId: string = ":categoryId",
                    selectedTab: string = ":selectedTab",
                    isAutoPlay: string = ":isAutoPlay",
                    queryParams?: {
                        scrollTo: string;
                    },
                ) => {
                    const url = `/received/audios/${id}/detail/${categoryId}/${selectedTab}/${isAutoPlay}`;
                    if (!!queryParams)
                        return buildUrlWithQueryParams(url, queryParams);
                    return authorizedContext.buildUrl(url);
                },
                giftsGivenListing: (
                    categoryId: string = ":categoryId",
                    queryParams?: {
                        scrollTo: number;
                    },
                ) => {
                    const url = `/gifts-given/listings/${categoryId}`;
                    if (!!queryParams)
                        return buildUrlWithQueryParams(url, queryParams);
                    return authorizedContext.buildUrl(url);
                },
                giftsGivenContentListing: (
                    memberId: string = ":memberId",
                    selectedTab: string = ":selectedTab",
                    categoryId: string = ":categoryId",
                    queryParams?: {
                        scrollTo?: number;
                    },
                ) => {
                    const url = categoryId
                        ? `/gifts-given/${memberId}/${selectedTab}/listing/${categoryId}`
                        : `/gifts-given/${memberId}/${selectedTab}/listing`;
                    if (!!queryParams)
                        return buildUrlWithQueryParams(url, queryParams);
                    return authorizedContext.buildUrl(url);
                },
                sync: () => `/sync`,
                courseDetail: (
                    id: string = ":id",
                    queryParams: {
                        received?: string;
                        giftTab?: string;
                        contentType?: string;
                        scrollTo?: string;
                    } = null,
                ) => {
                    const url = `/gift/courses/${id}/detail`;
                    if (!!queryParams)
                        return buildUrlWithQueryParams(url, queryParams);
                    return authorizedContext.buildUrl(url);
                },
                courseMediaStepDetail: (
                    detailSkuId: string = ":detailSkuId",
                    stepSkuId: string = ":stepSkuId",
                    isAutoPlay: string = ":isAutoPlay",
                    displayMode?: boolean,
                    contentType?: string,
                    giftTab?: string,
                ) => {
                    return displayMode && contentType
                        ? authorizedContext.buildUrl(
                              `/gift/courses/detail/${detailSkuId}/step/${stepSkuId}/${isAutoPlay}/media?displayMode=${displayMode}&segment=${contentType}&giftTab=${giftTab}`,
                          )
                        : displayMode && giftTab
                        ? authorizedContext.buildUrl(
                              `/gift/courses/detail/${detailSkuId}/step/${stepSkuId}/${isAutoPlay}/media?displayMode=${displayMode}&giftTab=${giftTab}`,
                          )
                        : giftTab
                        ? authorizedContext.buildUrl(
                              `/gift/courses/detail/${detailSkuId}/step/${stepSkuId}/${isAutoPlay}/media?giftTab=${giftTab}`,
                          )
                        : authorizedContext.buildUrl(
                              `/gift/courses/detail/${detailSkuId}/step/${stepSkuId}/${isAutoPlay}/media`,
                          );
                },
            }),
            more: () => ({
                index: () => authorizedContext.buildUrl(`/more`),
            }),
            events: () => ({
                index: () => authorizedContext.buildUrl(`/events`),
                detail: (
                    eventName: string = ":eventName",
                    selectedTab: string = ":selectedTab",
                ) =>
                    selectedTab
                        ? authorizedContext.buildUrl(
                              `/events/${eventName}/detail/${selectedTab}`,
                          )
                        : authorizedContext.buildUrl(
                              `/events/${eventName}/detail`,
                          ),
            }),
            fycStatus: () => ({
                index: (selectedTab: string) =>
                    selectedTab
                        ? authorizedContext.buildUrl(
                              `/fyc_status/${selectedTab}`,
                          )
                        : authorizedContext.buildUrl(
                              `/fyc_status/primary_user`,
                          ),
            }),
            feedback: () => ({
                index: () => authorizedContext.buildUrl(`/feedback`),
            }),
            documents: () => ({
                index: () => authorizedContext.buildUrl(`/documents/index`),
                detail: (
                    pdfName: string = ":pdf",
                    description: string = ":description",
                    isFavoriteTab: string = ":isFavoriteTab",
                ) =>
                    isFavoriteTab
                        ? authorizedContext.buildUrl(
                              `/documents/detail/${isFavoriteTab}/${description}/${pdfName}`,
                          )
                        : authorizedContext.buildUrl(
                              `/documents/detail/${description}/${pdfName}`,
                          ),
            }),

            webcast: () => ({
                index: (backNav?: boolean) =>
                    backNav
                        ? authorizedContext.buildUrl(
                              `/webcast?backNav=${backNav}`,
                          )
                        : authorizedContext.buildUrl(`/webcast`),
                detail: (title: String = ":title", backNav?: boolean) =>
                    backNav
                        ? authorizedContext.buildUrl(
                              `/webcast/${title}/detail?backNav=${backNav}`,
                          )
                        : authorizedContext.buildUrl(
                              `/webcast/${title}/detail`,
                          ),
                stream: (title: String = ":title", backNav?: boolean) =>
                    backNav
                        ? authorizedContext.buildUrl(
                              `/webcast/${title}/stream?backNav=${backNav}`,
                          )
                        : authorizedContext.buildUrl(
                              `/webcast/${title}/stream`,
                          ),
            }),
            appInfo: () => ({
                index: () => authorizedContext.buildUrl(`/appinfo`),
            }),
            offline: () => ({
                index: () => authorizedContext.buildUrl(`/downloads`),
                attachedSku: () => authorizedContext.buildUrl(`/attached_sku`),
                listing: (
                    id: string = ":category",
                    queryParams?: { scrollTo?: number },
                ) => {
                    const url = `/downloads/${id}`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                playlist: () => ({
                    index: () =>
                        authorizedContext.buildUrl(`/playlist/downloads`),
                    detail: (
                        id: String = ":id",
                        queryParams?: {
                            scrollTo?: string;
                        },
                    ) => {
                        const url = `offline/playlist/detail/${id}`;
                        return authorizedContext.buildUrl(
                            queryParams
                                ? buildUrlWithQueryParams(url, queryParams)
                                : url,
                        );
                    },
                    play: (
                        id: string = ":id",
                        queryParams?: {
                            index?: string;
                            bundleIndex?: string;
                            scrollTo?: string;
                        },
                    ) => {
                        const url = `/playlist/offline/${id}/play`;
                        return authorizedContext.buildUrl(
                            queryParams
                                ? buildUrlWithQueryParams(url, queryParams)
                                : url,
                        );
                    },
                }),
                courses: () => ({
                    index: (queryParams?: { scrollTo?: number }) => {
                        const url = `/courses/downloads`;
                        return authorizedContext.buildUrl(
                            queryParams
                                ? buildUrlWithQueryParams(url, queryParams)
                                : url,
                        );
                    },
                    detail: (
                        id: string = ":id",
                        queryParams?: {
                            scrollTo?: number;
                        },
                    ) => {
                        const url = `/courses/downloads/detail/${id}`;
                        return authorizedContext.buildUrl(
                            queryParams
                                ? buildUrlWithQueryParams(url, queryParams)
                                : url,
                        );
                    },
                    mediaStepDetail: (
                        detailSkuId: string = ":detailSkuId",
                        stepSkuId: string = ":stepSkuId",
                        isAutoPlay: string = ":isAutoPlay",
                        displayMode?: boolean,
                    ) => {
                        return displayMode
                            ? authorizedContext.buildUrl(
                                  `/courses/downloads/detail/${detailSkuId}/step/${stepSkuId}/media/${isAutoPlay}?displayMode=${displayMode}`,
                              )
                            : authorizedContext.buildUrl(
                                  `/courses/downloads/detail/${detailSkuId}/step/${stepSkuId}/media/${isAutoPlay}`,
                              );
                    },
                }),
                documents: () => ({
                    index: () =>
                        authorizedContext.buildUrl(`documents/downloads`),
                    detail: (
                        pdfName: string = ":pdf",
                        description: string = ":description",
                        isFavoriteTab: string = ":isFavoriteTab",
                    ) =>
                        isFavoriteTab
                            ? authorizedContext.buildUrl(
                                  `/documents/downloads/detail/${isFavoriteTab}/${description}/${pdfName}`,
                              )
                            : authorizedContext.buildUrl(
                                  `/documents/downloads/detail/${description}/${pdfName}`,
                              ),
                }),
                downloaddetail: (
                    nid: string = ":nid",
                    type: string = ":type",
                    isAutoPlay: string = ":isAutoPlay",
                    queryParams?: {
                        scrollTo?: number;
                    },
                ) => {
                    const url = `/download-detail/${type}/${nid}/${isAutoPlay}`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                bundle: () => ({
                    audios: (id: String = ":contentId") =>
                        authorizedContext.buildUrl(
                            `/downloaded-bundle/${id}/audios`,
                        ),
                    play: (id: string = ":contentId", index?: string) =>
                        index
                            ? authorizedContext.buildUrl(
                                  `/downloaded-bundle/${id}/play?index=${index}`,
                              )
                            : authorizedContext.buildUrl(
                                  `/downloaded-bundle/${id}/play`,
                              ),
                }),
            }),
            playlist: () => ({
                index: (segment?: string) =>
                    segment
                        ? authorizedContext.buildUrl(
                              `/playlist?segment=${segment}`,
                          )
                        : authorizedContext.buildUrl("/playlist"),
                detail: (
                    id: String = ":id",
                    queryParams?: { scrollTo?: number },
                ) => {
                    const url = `/playlist/${id}/detail`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                play: (
                    id: string = ":id",
                    index?: string,
                    bundleIndex?: string,
                    queryParams?: { scrollTo?: number },
                ) => {
                    const url =
                        id && index && bundleIndex
                            ? `/playlist/${id}/play?index=${index}&bundleIndex=${bundleIndex}`
                            : `/playlist/${id}/play`;

                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
            }),
            favorites: () => ({
                index: () => authorizedContext.buildUrl(`/favorites`),
                favoriteAudiosListing: (queryParams?: {
                    scrollTo?: number;
                }) => {
                    const url = `/favorites/Audios/listing`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                favoriteVideosListing: (queryParams?: {
                    scrollTo?: number;
                }) => {
                    const url = `/favorites/Videos/listing`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                favoriteDocumentsListing: () =>
                    authorizedContext.buildUrl(`/favorites/Documents/listing`),
                favoriteVideosDetail: (
                    id: string = ":contentId",
                    categoryId: string = ":categoryId",
                    isFavorite: string = ":isFavorite",
                    isAutoPlay: string = ":isAutoPlay",
                    queryParams?: {
                        scrollTo?: number;
                    },
                ) => {
                    const url = `favorites/videos/${id}/${categoryId}/detail/${isFavorite}/${isAutoPlay}`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                favoriteAudiosDetail: (
                    id: string = ":contentId",
                    categoryId: string = ":categoryId",
                    isFavorite: string = ":isFavorite",
                    isAutoPlay: string = ":isAutoPlay",
                    queryParams?: {
                        scrollTo?: number;
                    },
                ) => {
                    isAutoPlay = "false";
                    const url = `favorites/audios/${id}/${categoryId}/detail/${isFavorite}/${isAutoPlay}`;
                    return authorizedContext.buildUrl(
                        queryParams
                            ? buildUrlWithQueryParams(url, queryParams)
                            : url,
                    );
                },
                favoriteBundleAudios: (
                    id: String = ":contentId",
                    categoryId: string = ":categoryId",
                    isGift: string = ":isGift",
                    queryParams?: {
                        scrollTo?: number;
                    },
                ) => {
                    const url = categoryId
                        ? `/bundle/favorites/${id}/audios/${isGift}/${categoryId}`
                        : `/bundle/favorites/${id}/audios/${isGift}`;
                    if (!!queryParams)
                        return buildUrlWithQueryParams(url, queryParams);
                    return authorizedContext.buildUrl(url);
                },
                favoriteBundlePlay: (
                    id: string = ":contentId",
                    queryParams?: {
                        categoryId: string;
                        isGift?: string;
                        index?: string;
                        scrollTo?: number;
                    },
                ) => {
                    const url = `/bundle/favorites/${id}/play`;
                    if (!!queryParams)
                        return buildUrlWithQueryParams(url, queryParams);
                    return authorizedContext.buildUrl(url);
                },
            }),
            downloadingQueue: () => ({
                index: (contentType?: string) =>
                    contentType
                        ? authorizedContext.buildUrl(
                              `/downloading_queue?contentType=${contentType}`,
                          )
                        : authorizedContext.buildUrl(`/downloading_queue`),
            }),
            recommendations: () => ({
                index: (auto: boolean = false) => {
                    const url = "/recommendations";
                    return authorizedContext.buildUrl(
                        auto ? `${url}?auto=${auto}` : url,
                    );
                },
            }),
            consentForm: () => ({
                index: () => authorizedContext.buildUrl("/consent_form"),
            }),
            appSettings: () => ({
                index: () => {
                    return authorizedContext.buildUrl(`/settings`);
                },
            }),
            giftDashboard: () => ({
                index: () => {
                    return authorizedContext.buildUrl(`/gift_dashboard`);
                },
            }),
        },
        server: {
            root: () => config.baseApiUrl,

            api: ((base: string) => ({
                root: () => base,
                users: {
                    login: () => `${base}/oauth/media/login-user`,
                    getFirebaseToken: (
                        memberId: string,
                        dbName: "webcasts" | "courses",
                    ) =>
                        `${base}/users/${memberId}/app/auth/setup?dbName=${dbName}`,
                    seamlessUpdate: (
                        memberId: string,
                        version: string,
                        lastCachedTime: number,
                        betaVersion: string,
                    ) =>
                        `${base}/user/seamless_update?memberId=${memberId}&version=${version}&beta_version=${betaVersion}&contentCachedTime=${lastCachedTime}`,
                    rebuildAuth: `${base}/oauth/media/rebuild-auth-token`,
                },
                audios: {
                    getAudiosIndex: () => `${base}/oauth/media/get-audios`,
                },
                videos: {
                    getVideosIndex: () => `${base}/oauth/media/get-all-videos`,
                },
                purchase: {
                    getAvailableAudioCredits: () =>
                        `${base}/oauth/media/get-available-audio-credits`,
                    purchaseContentRequest: () =>
                        `${base}/oauth/media/purchase-content-request`,
                    confirmContentPurchase: () =>
                        `${base}/oauth/media/confirm-content-purchase`,
                    redeemAudioCredits: () =>
                        `${base}/oauth/media/redeem-audio-credits`,
                    redeemGiftByCredits: () =>
                        `${base}/media-service/multiple-sku-credit-redemption`,
                },
                gifts: {
                    getPurchasableGiftsByMembers: () =>
                        `${base}/oauth/gifting-app/cmlm-get-all-published-gift-details`,
                    giftRemainingQuantity: () =>
                        `${base}/oauth/gifting-app/get-gifts-remaining-quantity`,
                    giftProsRemainingQuantity: () =>
                        `${base}/prospect-gifting-app/get-gifts-remaining-quantity`,
                    allMembersOfGroup: () =>
                        `${base}/oauth/gifting-app/get-all-paged-member-of-group`,
                    sendGift: () => `${base}/oauth/media/send-gift`,
                    getPurchasableGiftsAcquiredDates: (
                        memberId: string,
                        contentType,
                        pageOffset,
                        pageSize,
                        sincePaymentDate,
                    ) =>
                        `users/${memberId}/gifts-payment-details?content_type=${contentType}&page_offset=${pageOffset}&page_size=${pageSize}${
                            sincePaymentDate
                                ? `&since_payment_date=${sincePaymentDate}`
                                : ""
                        }`,
                    getSentMediaGifts: () =>
                        `${base}/oauth/gifting-app/mlm-get-gifts-sent-quantity-temp`,
                    getReceivedMemberMediaGifts: () =>
                        `${base}/oauth/gifting-app/mlm-get-received-gifts`,
                    getReceivedProspectMediaGifts: (
                        memberId: string,
                        giftType: string,
                    ) =>
                        `${base}/prospect/receivedGifts/${memberId}?giftType=${giftType}`,
                    getMediaGiftsGiven: () =>
                        `${base}/oauth/gifting-app/mlm-get-gifts-given`,
                    syncDYS: (memberId: string) =>
                        `${base}/prospect/mapibo/${memberId}`,
                    getUserName: () => `${base}/gifting-app/get-username`,
                },
                courses: {
                    getCoursesIndex: (memberId: string) =>
                        `${base}/course/member/${memberId}`,
                    getReceivedCourses: (memberId: string) =>
                        `${base}/users/${memberId}/received-gift-courses`,
                    getCourseDetailWithMedia: (skuId: string) =>
                        `${base}/course/detailWithMedia/${skuId}`,
                    getCourseDetailWithOutMedia: (skuId: string) =>
                        `${base}/course/detailWithoutMedia/${skuId}`,
                    getPurchasableCoursesForMembers: (memberId: string) =>
                        `${base}/users/${memberId}/member-gift-courses`,
                    getPurchasedGiftCourses: (memberId: string) =>
                        `${base}/users/${memberId}/purchased-gift-courses`,
                    getCoursesGiven: (memberId: string) =>
                        `${base}/users/${memberId}/sent-gift-courses`,
                },

                events: {
                    getBarCode: () => `${base}/oauth/media/get-user-barcode`,
                    getS3PostFileUrl: (
                        memberId: string,
                        isPrimaryUser: number,
                    ) =>
                        `${base}/users/${memberId}/file-url?isPrimaryUser=${isPrimaryUser}`,
                },
                fycStatus: {
                    getFycStatus: (memberId: string) =>
                        `${base}/users/${memberId}/fyc_status`,
                },
                home: {
                    getTeamStatusForUser: (memberId: string) =>
                        `${base}/users/${memberId}/team_information`,
                },
                playlist: {
                    getPlaylists: () => `${base}/oauth/media/get-my-playlists`,
                    create: () => `${base}/oauth/media/create-audio-playlist`,
                    delete: () => `${base}/oauth/media/delete-audio-playlist`,
                    rename: () => `${base}/oauth/media/update-audio-playlist`,
                    getAudios: () => `${base}/oauth/media/playlist-contents`,
                    removeAudio: () =>
                        `${base}/oauth/media/remove-audio-playlist`,
                    addAudio: () => `${base}/oauth/media/add-audio-playlist`,
                    sort: () => `${base}/oauth/media/sort-playlist-contents`,
                    orderPlaylists: (memberId: string) =>
                        `${base}/user/${memberId}/playlist/sort`,
                    createPlaylistWithContents: () =>
                        `${base}/oauth/media/create-audio-playlist-with-contents`,
                },
                favorites: {
                    getFavorites: () => `${base}/oauth/media/get-favorites`,
                    markContentFavorites: () =>
                        `${base}/oauth/media/add-content-favorite`,
                    removeContentFavorites: () =>
                        `${base}/oauth/media/remove-content-favorite`,
                },
                recommendations: {
                    getRecommendations: (
                        memberId: string,
                        contentType: "MP3",
                    ) =>
                        `${base}/user/${memberId}/ibo-recommendations?content_type=${contentType}`,
                },
                pushNotifications: {
                    registerDeviceToken: (memberId: string) =>
                        `${base}/web-push/${memberId}/registerDeviceToken`,
                    updateDeviceToken: (memberId: string) =>
                        `${base}/web-push/${memberId}/updateDeviceToken`,
                    deleteDeviceToken: (memberId: string) =>
                        `${base}/web-push/${memberId}/deleteDeviceToken`,
                },
                attibutes: {
                    getAttributesByContentType: (
                        memberId: string,
                        contentType: string,
                    ) =>
                        `${base}/users/${memberId}/contents/attributes?contentType=${contentType}`,
                },
                dashboard: {
                    push: () => `${base}/oauth/media/send-push`,
                },
                consentForm: {
                    postConsent: () =>
                        `${base}/oauth/media/consent_form_action`,
                    getConsent: (memberId: string) =>
                        `${base}/oauth/media/${memberId}/consentStatus`,
                },
            }))(`${config.baseApiUrl}`),
            apiFeedback: ((base: string) => ({
                root: () => base,
                feedback: {
                    sendFeedback: () => `${base}/feedbacks`,
                },
            }))(`${config.feedbackBaseApiUrl}`),
        },
    };
};

export type Routes = ReturnType<typeof routesForContext>;
