import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RedirectionDialogState {
    showRedirectionDialog: boolean;
    loadingConsent: boolean;
}

const initialState: RedirectionDialogState = {
    showRedirectionDialog: false,
    loadingConsent: false,
};

export const consentStateSlice = createSlice({
    name: "consentFormState",
    initialState,
    reducers: {
        setRedirectionState: (
            state,
            data: PayloadAction<{ [key: string]: boolean }>,
        ) => {
            state.showRedirectionDialog = data.payload.showRedirectionDialog;
            return state;
        },
        setLoadingState: (
            state,
            data: PayloadAction<{ [key: string]: boolean }>,
        ) => {
            state.loadingConsent = data.payload.loadingConsent;
            return state;
        },
        setConsentFormStats: (
            state,
            data: PayloadAction<RedirectionDialogState>,
        ) => {
            state.loadingConsent = data.payload.loadingConsent;
            state.showRedirectionDialog = data.payload.showRedirectionDialog;
            return state;
        },
        resetRedirectionState: () =>  initialState,
    },
});
export const { setRedirectionState, setLoadingState, setConsentFormStats, resetRedirectionState } =
    consentStateSlice.actions;
