import numeral from "numeral";
import { v4 as uuidv4 } from "uuid";

export const isNumber = (value: any) => {
    if (value != null) {
        if (!isNaN(parseFloat(value))) {
            return true;
        } else {
            return numeral.validate(value.toString(), numeral.locale());
        }
    } else {
        return false;
    }
};

export const bytesToGB = (bytes: number) => {
    const gb = bytes / (1024 * 1024 * 1024);
    return +gb.toFixed(2);
};

export const generateRandomNumber = () => {
    const uuid = uuidv4();
    const randomHex = uuid.replace(/-/g, "").slice(0, 8);
    return parseInt(randomHex, 16) / 0xffffffff;
};
