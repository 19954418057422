import clsx from "clsx";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useCallback, useEffect, useMemo } from "react";
import {
    useGlobalBlackRecommendations,
    useGlobalRecommendations,
    useOpenAutoPopup,
    useRecommendations,
} from "JS/React/Hooks/Recommendations";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { RecommendationsBatchManager } from "./RecommendationsBatchManager";
import { useGlobalMediaEssentials } from "../Hooks/MediaEssentials";
import { useRouting } from "../Hooks/Routes";
import { useLocation, useParams } from "react-router-dom";
import { useOpenConsentForm } from "../Hooks/ConsentForm";
export interface RecommendationsManagerProps
    extends InternalStandardProps<BoxProps> {
    children: React.ReactNode;
}

export const RecommendationsManager = (props: RecommendationsManagerProps) => {
    const classes = useStyles(props);

    const { className, children, ...rest } = props;

    const { recommendationsLoading, open } = useVariables();
    useFirstStart(!open);
    useVisibilityChange(!open);

    const loading = recommendationsLoading;

    return (
        <RecommendationsBatchManager>
            <Box className={clsx(className, classes.root)} {...rest}>
                {loading ? (
                    <AppBackdropProgress
                        open
                        backdropText="Loading Recommendations"
                    />
                ) : (
                    <>{children}</>
                )}
            </Box>
        </RecommendationsBatchManager>
    );
};

const useVariables = () => {
    const { recommendationState } = useGlobalRecommendations();

    const skipRecommendations = recommendationState?.recommendations != null;

    const { loading: recommendationsLoading, loaded: recommendationsLoaded } =
        useRecommendations(true);

    const open = useMemo(() => {
        return skipRecommendations || recommendationsLoaded;
    }, [skipRecommendations, recommendationsLoaded]);
    return {
        recommendationsLoading,
        open,
    };
};

const useVisibilityChange = (skip: boolean = false) => {
    const { openPopup } = useOpenAutoPopup();
    const { linkProvider } = useRouting();
    const location = useLocation();
    const params: any = useParams();
    const { title } = params;
    const { mediaEssentials } = useGlobalMediaEssentials();
    const { openForm } = useOpenConsentForm();

    const allowRedirect =
        location.pathname === linkProvider.react.webcast().index() ||
        location.pathname === linkProvider.react.webcast().detail(title) ||
        location.pathname === linkProvider.react.webcast().stream(title);

    const onToggleVisibility = useCallback(() => {
        const state = document.visibilityState;

        if (
            state === "visible" &&
            !mediaEssentials?.has_event_started &&
            !allowRedirect
        ) {
            if (!skip) openPopup();
            openForm();
        }
    }, [skip, mediaEssentials]);

    useEffect(() => {
        document.addEventListener("visibilitychange", onToggleVisibility);

        return () =>
            document.removeEventListener(
                "visibilitychange",
                onToggleVisibility,
            );
    }, [onToggleVisibility]);
};

const useFirstStart = (skip: boolean = false) => {
    const { blackRecommendationState, setGlobalBlackRecommendations } =
        useGlobalBlackRecommendations();
    const { openPopup } = useOpenAutoPopup();
    const { openForm } = useOpenConsentForm();

    useEffect(() => {
        if (blackRecommendationState?.firstStart) {
            /**Anything here will run on application restart */
            setGlobalBlackRecommendations({ firstStart: false });
            if (!skip) openPopup();
            openForm();
        }
    }, [blackRecommendationState?.firstStart, skip]);
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
    }),
);
