import { ConsentFormStatus } from "JS/Models/ConsentForm";
import Dexie, { Table } from "dexie";

export interface VideoDexie {
    id: string;
    name: string;
    blob: Blob;
    skuId: string;
    description: string;
    release_date: string;
    isBundle?: boolean;
}
export interface AudioDexie {
    id: string;
    name: string;
    blob: Blob;
    skuId: string;
    description: string;
    release_date: string;
    isBundle?: boolean;
    media_title?: string;
    position?: number;
    no_of_files?: number;
}

export interface CourseDexie {
    id: string;
    courseNid: string;
    stepNid: string;
    blob: Blob;
}

export interface DocumentDexie {
    id: string;
    name: string;
    blob: Blob;
    description: string;
}
export interface ConsentFormDexie extends ConsentFormStatus {
    id: string;
}
class ContentDexie extends Dexie {
    // We just tell the typing system this is the case
    videos!: Table<VideoDexie>;
    audios!: Table<AudioDexie>;
    courses!: Table<CourseDexie>;
    documents!: Table<DocumentDexie>;
    consentForm!: Table<ConsentFormDexie>;

    constructor() {
        super("Downloads");
        this.version(4).stores({
            videos: "id, name, blob, skuId, description, release_date",
            audios: "id, name, blob, skuId, description, release_date",
            courses: "id, courseNid, stepNid, blob",
            documents: "id, name, blob, description",
            consentForm:
                "id, ltdConsentStatus, redirectToAmwayConsentForm, ammwayConsentFormLink",
        });
    }
}

export function getDexieConnectionRefresh() {
    const db = new ContentDexie();

    db.open().catch(function (err) {
        window.location.reload();
    });

    return db;
}

export function getDexieConnection() {
    return new ContentDexie();
}
