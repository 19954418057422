import { Layout } from "../Layout";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useGetWebcast, useGlobalWebcast } from "JS/React/Hooks/Webcast";
import { useRouting } from "JS/React/Hooks/Routes";
import { useEffect, useMemo } from "react";
import { AppHeader } from "JS/React/Components/AppHeader";
import { Grid, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
    webcastFirebaseService,
    DURATION_MILLIS,
} from "JS/Services/Firebase/WebcastService";
import moment from "moment";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import qs from "qs";
import { isAndroid } from "react-device-detect";
import { useMiniPlayer } from "JS/React/Hooks/MiniPlayer";

export interface WebcastProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const WebcastStream = (props: WebcastProps) => {
    const classes = useStyles(props);
    const params: any = useParams();
    const { title } = params;
    const { webcasts } = useGlobalWebcast();

    useGetWebcast(webcasts && webcasts.length > 0);

    const { handleBackNavigation } = useHandler();
    const { setGlobalMiniPlayer } = useMiniPlayer();

    useEffect(() => {
        updateTimes(title);
        const updateTime = setInterval(
            () => updateTimes(title),
            DURATION_MILLIS,
        );
        return () => clearInterval(updateTime);
    }, [title]);

    const url = useMemo(() => {
        if (webcasts) {
            const w = webcasts.find((w) => w.Description === title);
            return w?.WebcastUrl;
        } else return "";
    }, [title, webcasts]);

    useEffect(() => {
        const iframeFullscreenChangeSubscription = () => {
            if (isAndroid) {
                if (document.fullscreenElement !== null) {
                    window.screen.orientation.lock("landscape-primary");
                } else {
                    window.screen.orientation.lock("portrait-primary");
                }
            }
        };
        window.addEventListener(
            "fullscreenchange",
            iframeFullscreenChangeSubscription,
        );
        window.addEventListener(
            "webkitfullscreenchange",
            iframeFullscreenChangeSubscription,
        );
        window.addEventListener(
            "mozfullscreenchange",
            iframeFullscreenChangeSubscription,
        );

        return () => {
            window.removeEventListener(
                "fullscreenchange",
                iframeFullscreenChangeSubscription,
            );
            window.removeEventListener(
                "webkitfullscreenchange",
                iframeFullscreenChangeSubscription,
            );
            window.removeEventListener(
                "mozfullscreenchange",
                iframeFullscreenChangeSubscription,
            );
        };
    }, []);

    useEffect(() => {
        setGlobalMiniPlayer({
            forceStopPlaying: true,
        });
    }, []);

    return (
        <Layout>
            <AppHeader
                title={title}
                canGoBack
                searchable={false}
                onBackClick={handleBackNavigation}
            />
            {url && (
                <Grid className={classes.background}>
                    <iframe
                        src={url}
                        title={title}
                        style={{ width: "100%" }}
                        allow="fullscreen;"
                        frameBorder="0"
                    />
                </Grid>
            )}
        </Layout>
    );
};

const useHandler = () => {
    const params: any = useParams();
    const { title } = params;

    const location = useLocation();
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { homeStack } = useGlobalNavStack();
    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { backNav = "" } = parsedQueryString;

        return {
            backNav: backNav.toString(),
        };
    }, [location?.search]);

    const handleBackNavigation = () => {
        if (queryParams?.backNav === "true") {
            history.goBack();
        } else {
            if (!homeStack?.isHomeTrack) {
                history.push(linkProvider.react.webcast().detail(title));
            } else {
                history.goBack();
            }
        }
    };
    return { queryParams, handleBackNavigation };
};

function updateTimes(title: any) {
    webcastFirebaseService.updateWatchTime(title, moment().valueOf());
    webcastFirebaseService.updateDuration(title);
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: {
            display: "flex",
            width: `100%`,
            height: `calc(100vh - ${theme.footer.height} - 70px)`,
        },
    }),
);
