import { config } from "JS/Config";
import { AddToCart, ConfirmContentPurchase } from "JS/Models/Media";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";

export class PurchaseService extends BaseService {
    async getAvailableAudioCredits() {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);

        return (
            await this.doXHR<AppResponse<number>>({
                url: this.routes.server.api.purchase.getAvailableAudioCredits(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async redeemAudioCredits(skuId) {
        const formData = new FormData();
        formData.append("access_token", `${config?.accessToken}`);
        formData.append("sku_id", skuId);

        return (
            await this.doXHR<AppResponse<null>>({
                url: this.routes.server.api.purchase.redeemAudioCredits(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async redeemGiftByCredits(skuId: string, quantity: number) {
        const input = {
            ltdUserId: +config?.user?.memberId,
            sku: skuId,
            quantity: +quantity,
        };
        return (
            await this.doXHR<AppResponse<[]>>({
                url: this.routes.server.api.purchase.redeemGiftByCredits(),
                method: "POST",
                data: JSON.stringify(input),
                headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                },
            })
        ).data;
    }

    async confirmContentPurchase(
        cartId: string,
        quantity: number,
        sku_id: string,
    ) {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);
        formData.append("cart_id", cartId);
        formData.append("member_id", config?.user?.memberId);
        formData.append("quantity", `${quantity}`);
        formData.append("sku_id", sku_id);

        return (
            await this.doXHR<AppResponse<ConfirmContentPurchase>>({
                url: this.routes.server.api.purchase.confirmContentPurchase(),
                method: "POST",
                data: formData,
            })
        ).data;
    }

    async purchaseContentRequest(skuId, quantity) {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);
        formData.append("sku_id", skuId);
        formData.append("quantity", quantity);

        return (
            await this.doXHR<AppResponse<AddToCart>>({
                url: this.routes.server.api.purchase.purchaseContentRequest(),
                method: "POST",
                data: formData,
            })
        ).data;
    }
}
