import React, { useRef } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Dialog,
    DialogContent,
    DialogProps,
    Grid,
    InternalStandardProps,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Theme,
} from "@mui/material";
import { AppTypography } from "../AppTypography";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },

        content: {
            position: "relative",
            minHeight: "150px",
            display: "flex",
            justifyContent: "flex-start",
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    }),
);

export interface UploadImageDialogProps
    extends InternalStandardProps<DialogProps> {
    onClose: () => void;
    isImageExists: boolean;
    onUploadImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onWebCamImage: () => void;
    uploadingImage: boolean;
}

export const UploadImageDialog = (props: UploadImageDialogProps) => {
    const classes = useStyles(props);
    const {
        className,
        onClose,
        onUploadImage,
        onWebCamImage,
        isImageExists,
        uploadingImage,
        ...rest
    } = props;

    const hiddenFileInput = useRef(null);

    const handleUploadClick = (event) => {
        hiddenFileInput.current.click();
    };

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            className={clsx(className, classes.root)}
            {...rest}>
            <DialogContent>
                <Grid container className={classes.content}>
                    <Grid item xs={12}>
                        <AppTypography variant="body1" fontWeight={"bold"}>
                            {isImageExists
                                ? "Add Photo"
                                : "Please upload your photo to view this ticket"}
                        </AppTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText
                                        primary="Take Photo"
                                        onClick={onWebCamImage}
                                    />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleUploadClick}>
                                    <ListItemText primary="Choose from Gallery" />
                                </ListItemButton>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    ref={hiddenFileInput}
                                    accept="image/png, image/gif, image/jpeg"
                                    id="imageUpload"
                                    onChange={(event) => onUploadImage(event)}
                                />
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText
                                        primary="Cancel"
                                        onClick={onClose}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
