import { checkAwsKeysValidation } from "JS/Helpers";
import { resetAudioState, setAudioUpdateRequired } from "JS/Redux/Audio";
import { resetCourseState, setCourseUpdateRequired } from "JS/Redux/Course";
import { resetEventsState } from "JS/Redux/Event";
import { resetGiftState, softResetGiftState } from "JS/Redux/Gift";
import { resetHomeState } from "JS/Redux/Home";
import { resetDownloadState } from "JS/Redux/download";
import {
    MediaState,
    resetMediaState,
    setCommonMessage,
    setGlobalMedia,
    setLastLoggedInUser,
} from "JS/Redux/media";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { resetVideoState, setVideoUpdateRequired } from "JS/Redux/Video";
import { resetWebcastState } from "JS/Redux/Webcast";
import { MediaService } from "JS/Services/Media";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSeamlessUpdate } from "./Users";
import { resetPlaylistControls } from "JS/Redux/PlaylistControls";
import { resetNavStack } from "JS/Redux/NavStack";
import { resetFavoritesState } from "JS/Redux/Favorites";
import {
    AudioDexie,
    getDexieConnectionRefresh,
    VideoDexie,
} from "JS/Database/Dexie";
import { IndexableType, Table } from "dexie";
import { config } from "JS/Config";
import { resetRecommendationsState } from "JS/Redux/Recommendations";
import { useAllMembersOfGroup } from "./Gifts";
import { GiftsTypes, MediaTransactionDialogs, Members } from "JS/Models";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";
import { resetMediaEssentials } from "JS/Redux/MediaEssentials";
import {
    resetGlobalDownloadClickCount,
    resetGlobalDownloadCount,
} from "JS/Redux/DownloadCount";
import { useGlobalMediaEssentials } from "./MediaEssentials";
import { decrementNotiPermissionDialogCount } from "JS/Redux/PushNotifications";
import {
    resetAllAttributesState,
    resetOnlyAttributesState,
} from "JS/Redux/Attributes";
import { resetLoggedInUser } from "JS/Redux/User";
import { resetDocumentsListState } from "JS/Redux/Documents";
import { resetPlaylistResumeMap } from "JS/Redux/Playlist";
import { ActiveGiftItemType } from "../Scenes/Courses/CourseDetail";
import { resetMixedContentState } from "JS/Redux/MixedContent";

const service = new MediaService();

export const useVerifyAwsKeys = () => {
    const { update } = useSeamlessUpdate(true);
    const verifyAwsKeys = useCallback(async () => {
        try {
            const resp = await checkAwsKeysValidation();
            if (resp === false) {
                await update();
            }
        } catch (err) {
            await update();
        }
    }, []);

    return {
        verifyAwsKeys,
    };
};

export const useGetUserMediaApp = () => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const refetch = useCallback(async () => {
        setLoading(true);
        return service
            .getMediaUserJsonFile()
            .then((response) => {
                if (response) {
                    setLoading(false);
                    setResponse(JSON.parse(response?.Body?.toString()));
                    return JSON.parse(response?.Body?.toString());
                } else {
                    setLoading(false);
                    setResponse(null);
                    return null;
                }
            })
            .catch((ex) => {
                setLoading(false);
                setResponse(null);
                return null;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return {
        refetch,
        response,
        loading,
    };
};

export const useResetZoom = () => {
    const { setIsZoomAllowed } = useGlobalMedia();

    useEffect(() => {
        setIsZoomAllowed(false);
    }, []);
};

export const useCheckContentDownloaded = (
    nid: string,
    inProgressId: string,
    type: "video" | "audio",
    setDownloaded: (val: boolean) => void,
    setUrl: (url: string) => void,
) => {
    const checkIsDownloaded = (
        nid: string,
        type: "video" | "audio",
        setDownloaded: (val: boolean) => void,
        setUrl: (url: string) => void,
    ) => {
        const db = getDexieConnectionRefresh();
        const activeDb: Table<VideoDexie | AudioDexie, IndexableType> =
            type === "video" ? db.videos : db.audios;

        activeDb.get(`${config.user.memberId}-${nid}`).then((res) => {
            if (res) {
                setDownloaded(true);
                setUrl(URL.createObjectURL(res.blob));
            }
        });
    };

    useEffect(() => {
        if (nid && type) {
            checkIsDownloaded(nid, type, setDownloaded, setUrl);
        }
    }, [nid, type, inProgressId]);

    return {
        checkIsDownloaded,
    };
};

export const useResetGlobalState = () => {
    const dispatch = useAppDispatch();
    const { mediaEssentials } = useGlobalMediaEssentials();

    return {
        resetReduxState: () => {
            dispatch(resetCourseState());
            dispatch(resetEventsState());
            dispatch(softResetGiftState());
            dispatch(resetHomeState());
            dispatch(resetWebcastState());
            dispatch(resetMediaState());
            dispatch(resetDownloadState());
            dispatch(resetPlaylistControls());
            dispatch(resetNavStack());
            dispatch(resetFavoritesState());
            dispatch(resetRecommendationsState());
            dispatch(resetGlobalDownloadClickCount());
            if (mediaEssentials?.download_warning_cleared_on_logout) {
                dispatch(resetGlobalDownloadCount());
            }
            dispatch(resetMediaEssentials());
            dispatch(resetAllAttributesState());
            dispatch(resetLoggedInUser());
            dispatch(resetAudioState());
            dispatch(resetVideoState());
            dispatch(resetPlaylistResumeMap());
            dispatch(resetMixedContentState());
        },
        softResetReduxState: () => {
            dispatch(setAudioUpdateRequired());
            dispatch(setCourseUpdateRequired());
            dispatch(softResetGiftState());
            dispatch(resetHomeState());
            dispatch(setVideoUpdateRequired());
            dispatch(resetMediaState());
            dispatch(resetOnlyAttributesState());
        },
        resetLoginState: () => {
            dispatch(resetAudioState());
            dispatch(resetVideoState());
            dispatch(resetGiftState());
            dispatch(resetCourseState());
            dispatch(resetDocumentsListState());
        },
    };
};

export const useGlobalMedia = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.media);

    return {
        lastCachedTime: useAppSelector((state) => state.media)?.lastCachedTime,
        isZoomAllowed: useAppSelector((state) => state.media)?.isZoomAllowed,
        commonMessages: useAppSelector((state) => state.media)?.commonMessages,
        lastLoggedInUser: useAppSelector((state) => state.media)
            ?.lastLoggedInUser,
        notiPermissionDialogCount: useAppSelector(
            (state) => state.pushNotifications,
        )?.notiPermissionDialogCount,
        setGlobalMedia: (lastCachedTime: number) =>
            dispatch(
                setGlobalMedia({
                    ...allData,
                    lastCachedTime,
                }),
            ),
        setIsZoomAllowed: (allowed: boolean) =>
            dispatch(
                setGlobalMedia({
                    ...allData,
                    isZoomAllowed: allowed,
                }),
            ),
        setCommonMessages: (commonMessages: MediaState["commonMessages"]) =>
            dispatch(setCommonMessage(commonMessages)),
        decrementNotiPermissionDialogCount: () =>
            dispatch(decrementNotiPermissionDialogCount()),
        setLastLoggedInUser: (
            lastLoggedInUser: MediaState["lastLoggedInUser"],
        ) => dispatch(setLastLoggedInUser(lastLoggedInUser)),
        resetMediaState: () => dispatch(resetMediaState()),
    };
};

export const useSearchedLosMembers = () => {
    const { members: losMembersList } = useAllMembersOfGroup(false);

    const [searchQueryLos, setSearchQueryLos] = useState<string>("");

    const searchLosResults = useMemo(() => {
        return losMembersList?.filter(
            (d) =>
                d.first_name
                    .toLocaleLowerCase()
                    .includes(searchQueryLos.toLocaleLowerCase().trim()) ||
                d.last_name
                    .toLocaleLowerCase()
                    .includes(searchQueryLos.toLocaleLowerCase().trim()) ||
                d.member_id
                    .toLocaleLowerCase()
                    .includes(searchQueryLos.toLocaleLowerCase().trim()),
        );
    }, [losMembersList, searchQueryLos]);

    return { setSearchQueryLos, searchQueryLos, searchLosResults };
};

export const useHandleMediaGifts = (
    giftMemberQuanity: number,
    setIsReady: (val: boolean) => void,
    setPurchaseEnabled: (val: boolean) => void,
) => {
    const { enqueueSnackbar } = useSnackbar();

    const [activeGiftItem, setActiveGiftItem] = useState<ActiveGiftItemType>({
        title: "",
        giftPurchasedEnabled: false,
        giftPurchasedSuccess: false,
        quantity: 1,
    });
    const [dialogs, setDialogs] = useState<MediaTransactionDialogs>({
        transactionComplete: false,
        transactionConfirmation: false,
        selectMembers: false,
    });
    //Select Members For Send Gift States Section
    const [selectedMembers, setSelectedMembers] = useState<Members[]>([]);
    const [anchorSendGift, setAnchorSendGift] = useState<null | HTMLElement>(
        null,
    );
    const openSendGift = Boolean(anchorSendGift);

    const onQuantityChange = (event, action) => {
        if (action === "INCREMENT") {
            setActiveGiftItem({
                ...activeGiftItem,
                quantity: activeGiftItem?.quantity + 1,
            });
        } else {
            setActiveGiftItem({
                ...activeGiftItem,
                quantity:
                    activeGiftItem?.quantity === 1
                        ? 1
                        : activeGiftItem?.quantity - 1,
            });
        }
    };

    const onSendContent = () => {
        setIsReady(false);
        setDialogs({
            ...dialogs,
            transactionComplete: false,
            selectMembers: true,
        });
        setActiveGiftItem({
            ...activeGiftItem,
            quantity: giftMemberQuanity,
        });
    };

    const onToggleItemSelect = (value) => {
        if (
            selectedMembers.findIndex(
                (x) => x.member_id === value.member_id,
            ) !== -1
        ) {
            let tempArray = [...selectedMembers];
            tempArray.splice(
                tempArray.findIndex((x) => x.member_id === value.member_id),
                1,
            );
            setSelectedMembers(tempArray);
        } else {
            if (selectedMembers.length < giftMemberQuanity) {
                setSelectedMembers([...selectedMembers, value]);
            } else {
                enqueueSnackbar(messaging?.gifts?.inSufficientGiftQuantity, {
                    variant: "warning",
                });
            }
        }
    };

    const onCloseSelectMembers = () => {
        setPurchaseEnabled(false);
        setIsReady(false);
        setSelectedMembers([]);
        setDialogs({
            ...dialogs,
            transactionComplete: false,
            transactionConfirmation: false,
            selectMembers: false,
        });
        setActiveGiftItem({
            ...activeGiftItem,
            giftPurchasedEnabled: false,
            title: "",
            quantity: 1,
            giftPurchasedSuccess: false,
        });
    };

    const onGiftSendMenuItem = (
        e: React.MouseEvent<HTMLLIElement, MouseEvent>,
        item: string,
        id: string,
    ) => {
        if (item === GiftsTypes.MEMBER && giftMemberQuanity > 0) {
            setPurchaseEnabled(true);
            setActiveGiftItem({
                ...activeGiftItem,
                title: GiftsTypes.MEMBER,
                quantity: giftMemberQuanity,
                giftPurchasedEnabled: true,
            });
            setDialogs({
                ...dialogs,
                transactionComplete: false,
                selectMembers: true,
            });
            setIsReady(false);
            setAnchorSendGift(null);
        } else {
            enqueueSnackbar(messaging?.common?.noAccess, {
                variant: "warning",
            });
        }
    };

    const onSendItemsClose = () => {
        setPurchaseEnabled(true);
        setIsReady(false);
        setDialogs({
            ...dialogs,
            transactionComplete: true,
            transactionConfirmation: false,
            selectMembers: false,
        });
        setActiveGiftItem({
            ...activeGiftItem,
            giftPurchasedEnabled: false,
            title: "",
            quantity: 1,
            giftPurchasedSuccess: true,
        });
    };

    return {
        activeGiftItem,
        dialogs,
        selectedMembers,
        anchorSendGift,
        openSendGift,
        setDialogs,
        setActiveGiftItem,
        onQuantityChange,
        onSendContent,
        onToggleItemSelect,
        onCloseSelectMembers,
        setAnchorSendGift,
        onGiftSendMenuItem,
        onSendItemsClose,
    };
};

export const useScrollToTop = () => {
    useEffect(() => {
        window.scroll(0, 500);
        window.scroll(0, 0);
    }, []);
};
export const useScrollWithDelay = (delay: number = 1000) => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 500);
            window.scrollTo(0, 0);
        }, delay);
    }, []);
};

export const useScrollWithoutDelay = () => {
    useEffect(() => {
        window.scrollTo(0, 500);
        window.scrollTo(0, 0);
    }, []);
};

export const useCheckOnlineStatus = () => {
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);
    const [goingOnline, setGoingOnline] = useState(false);
    const [goingOffline, setGoingOffline] = useState(false);

    useEffect(() => {
        const onOnline = () => {
            setGoingOnline(true);
            setGoingOffline(false);
        };

        const onOffline = () => {
            setGoingOnline(false);
            setGoingOffline(true);
        };

        window.addEventListener("online", onOnline);
        window.addEventListener("offline", onOffline);

        return () => {
            window.removeEventListener("online", onOnline);
            window.removeEventListener("offline", onOffline);
        };
    }, []);

    return {
        isOnline,
        goingOnline,
        goingOffline,
        setIsOnline,
    };
};
