import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaymentMethod, PaymentMethodRadio } from "./PaymentMethodRadio";
import { TransactionCompleteDialog } from "./TransactionCompleteDialog";
import { TransactionConfirmDialog } from "./TransactionConfirmDialog";
import { AddToCart } from "JS/Models/Media";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: `${theme.palette.background.default}`,
        },
        backIcon: {
            color: theme.palette.grey.A100,
            fontSize: "20px",
            marginRight: theme.spacing(2),
        },
        leavePurchaseBtn: {
            color: theme.palette.grey.A100,
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        backBtnContainer: {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
        },
        paymentMethodTxtContainer: {
            marginTop: theme.spacing(2),
        },
        totalPriceWrapper: {
            background: theme.palette.grey[200],
            padding: theme.spacing(1, 2),
            borderRadius: theme.spacing(3),
        },
        horizontalLine: {
            flexGrow: 1,
            height: "3px",
            background: theme.palette.grey[200],
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
    }),
);

export interface ContentPurchaseProps extends InternalStandardProps<GridProps> {
    backTitle: string;
    method: PaymentMethod;
    credits: number;
    requiredCredits?: string;
    totalPrice: string;
    onBackClick: () => void;
    onMethodChange: (method: PaymentMethod) => void;
    buying: boolean;
    activeGiftItem?: {
        title: string;
        giftPurchasedEnabled: boolean;
        quantity: number;
        giftPurchasedSuccess: boolean;
    };
    dialogs: {
        transactionComplete: boolean;
        transactionConfirmation: boolean;
        selectMembers: boolean;
    };
    setDialogs: (dialog: {
        transactionComplete: boolean;
        transactionConfirmation: boolean;
        selectMembers: boolean;
    }) => void;
    onBuy?: () => void;
    onBuyGift?: () => void;
    onSendContent?: () => void;
    onCloseConfirmDialog?: () => void;
    isRedemeableByToken: string;
    availableCreditsLoading?: boolean;
    cart?: AddToCart;
    selectedMembers?: any[];
    quantityLoading?: boolean;
    contentPrice: string;
}

export const ContentPurchase = (props: ContentPurchaseProps) => {
    const classes = useStyles(props);
    const {
        className,
        backTitle,
        method,
        credits,
        requiredCredits,
        totalPrice,
        contentPrice,
        onMethodChange,
        onBackClick,
        dialogs,
        buying,
        setDialogs,
        onBuy,
        onBuyGift,
        isRedemeableByToken,
        activeGiftItem,
        onSendContent,
        cart,
        selectedMembers,
        onCloseConfirmDialog,
        availableCreditsLoading,
        quantityLoading,
        ...rest
    } = props;

    return (
        <Grid container {...rest} className={clsx(className, classes.root)}>
            <TransactionCompleteDialog
                open={dialogs.transactionComplete}
                activeGiftItem={activeGiftItem}
                onSendContent={onSendContent}
                selectedMembers={selectedMembers}
                onClose={onCloseConfirmDialog}
                quantityLoading={quantityLoading}
            />
            <TransactionConfirmDialog
                method={method}
                open={dialogs.transactionConfirmation}
                cart={cart}
                onClose={() => {
                    setDialogs({
                        ...dialogs,
                        transactionConfirmation: false,
                    });
                }}
                requiredCredits={requiredCredits}
                totalPrice={contentPrice}
                onBuy={onBuy}
                giftPurchasedEnabled={activeGiftItem?.giftPurchasedEnabled}
                onBuyGift={onBuyGift}
                buying={buying}
            />
            <Grid item xs={12}>
                <div className={classes.backBtnContainer} onClick={onBackClick}>
                    <FontAwesomeIcon
                        className={classes.backIcon}
                        icon={["fas", "chevron-left"]}
                    />
                    <Typography
                        className={clsx(classes.leavePurchaseBtn)}
                        variant="h5"
                    >
                        {backTitle}
                    </Typography>
                </div>
            </Grid>

            <Grid item xs={12} className={classes.paymentMethodTxtContainer}>
                <Typography
                    className={classes.grey600}
                    fontWeight={"bold"}
                    variant="body1"
                >
                    Payment Method
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <PaymentMethodRadio
                    isRedemeableByToken={isRedemeableByToken}
                    method={method}
                    onMethodChange={onMethodChange}
                    credits={credits}
                    availableCreditsLoading={availableCreditsLoading}
                    contentPrice={contentPrice}
                />
            </Grid>
        </Grid>
    );
};
