import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Theme, Typography, InternalStandardProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

import { messaging } from "JS/Helpers/UserMessaging";
import { AppTypography } from "JS/React/Components/AppTypography";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useDocumentsFavoritesDetails } from "JS/React/Hooks/Documents";
import { useRouting } from "JS/React/Hooks/Routes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        itemContainer: {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1),

            "&:hover": {
                background: theme.palette.grey["200"],
                cursor: "pointer",
            },
        },
        icons: {
            fontSize: "35px",
            [theme.breakpoints.down("md")]: {
                fontSize: "22px",
            },
        },
        description: {
            fontSize: "30px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.palette.grey[600],
            [theme.breakpoints.down("md")]: {
                fontSize: "20px",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "18px",
            },
        },
        pdfIcon: {
            color: "red",
            margin: theme.spacing(0, 2),
            fontSize: "2rem",
        },
        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - ${theme.footer.height})`,
            width: "100%",
            color: theme.palette.common.black,
        },
    }),
);

export interface DocumentFavListProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const DocumentFavList = (props: DocumentFavListProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const history = useHistory();
    const { linkProvider } = useRouting();

    const { docDetails, loading: docDetailsLoading } =
        useDocumentsFavoritesDetails();

    const onDocumentClick = (pdfName: string, description: string) => {
        history.push(
            linkProvider.react.documents().detail(pdfName, description, "true"),
        );
    };

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            {docDetailsLoading && (
                <AppBackdropProgress
                    open={true}
                    backdropText={messaging.loader.documents}
                />
            )}
            {!docDetailsLoading && docDetails?.length === 0 && (
                <Typography
                    align="center"
                    variant="h6"
                    className={classes.content}
                >
                    {messaging.common.noItemsFound}
                </Typography>
            )}

            {docDetails?.length !== 0 &&
                docDetails?.map((d, idx) => (
                    <Grid
                        key={idx}
                        container
                        className={classes.itemContainer}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        onClick={() => onDocumentClick(d.Name, d.Description)}
                    >
                        <Grid
                            item
                            sm={11}
                            xs={10}
                            display={"flex"}
                            alignItems={"center"}
                        >
                            <FontAwesomeIcon
                                className={clsx(classes.icons, classes.pdfIcon)}
                                icon={["fas", "file-pdf"]}
                            />
                            <AppTypography
                                className={classes.description}
                                variant="h4"
                            >
                                {d.Description}
                            </AppTypography>
                        </Grid>
                        <Grid
                            item
                            sm={1}
                            xs={2}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <FontAwesomeIcon
                                className={classes.icons}
                                icon={["fas", "chevron-right"]}
                            />
                        </Grid>
                    </Grid>
                ))}
        </div>
    );
};
