import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AudioContent, Gifts, ResumeAudioContent } from "JS/Models";
import {
    AudioVideoResume,
    PlayedAudiosVideos,
} from "JS/Models/Firebase/Resume";

export interface AudioState {
    catagoriesList?: AudioContent[];
    audiosList?: AudioContent[];
    resumeAudios?: ResumeAudiosMap;
    resumeBundles?: ResumeAudiosMap;
    playedAudios: PlayedAudiosMap;
    isUpdateRequired?: boolean;
}

export type PlayedAudiosMap = {
    [key: string]: (AudioContent | Gifts | PlayedAudiosVideos)[];
};

export type ResumeAudiosMap = {
    [key: string]: (ResumeAudioContent | AudioVideoResume)[];
};

const initialState: AudioState = {
    catagoriesList: [],
    audiosList: [],
    playedAudios: {},
    resumeAudios: {},
    resumeBundles: {},
    isUpdateRequired: false,
};

export const audioStateSlice = createSlice({
    name: "audioState",
    initialState,
    reducers: {
        setGlobalAudios: (state, data: PayloadAction<AudioState>) => {
            state.catagoriesList = data.payload.catagoriesList;
            state.audiosList = data.payload.audiosList;
            state.playedAudios = data.payload.playedAudios;
            state.isUpdateRequired = false;
            return state;
        },
        setGlobalPlayedAudios: (
            state,
            data: PayloadAction<PlayedAudiosMap>,
        ) => {
            state = { ...state, playedAudios: { ...data.payload } };
            return state;
        },
        setUserResumeAudios: (state, data: PayloadAction<ResumeAudiosMap>) => {
            state = { ...state, resumeAudios: data.payload };

            return state;
        },
        setUserResumeBundles: (state, data: PayloadAction<ResumeAudiosMap>) => {
            state = { ...state, resumeBundles: data.payload };

            return state;
        },
        resetAudioState: (state) => {
            return initialState;
        },
        setAudioUpdateRequired: (state) => {
            return { ...state, isUpdateRequired: true };
        },
    },
});

export const {
    setGlobalAudios,
    setGlobalPlayedAudios,
    resetAudioState,
    setUserResumeAudios,
    setUserResumeBundles,
    setAudioUpdateRequired,
} = audioStateSlice.actions;
