import { config } from "JS/Config";
import { AudioContent } from "JS/Models";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";

export class AudioService extends BaseService {
    async getAudiosIndex() {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);

        return (
            await this.doXHR<AppResponse<AudioContent[]>>({
                url: this.routes.server.api.audios.getAudiosIndex(),
                method: "POST",
                data: formData,
            })
        ).data;
    }
}
