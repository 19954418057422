import { useHistory } from "react-router-dom";
import clsx from "clsx";
import omit from "lodash-es/omit";
import {
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
    Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { VideoContent } from "JS/Models";
import { videoCategoryTitleToString } from "JS/Helpers";
import { useRouting } from "JS/React/Hooks/Routes";
import { useThemeColors } from "JS/React/Hooks/ThemeColors";
import { VideoCategoryTitle } from "..";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";

type PaperProps = {
    bgColor: string[];
};

const Item = styled(Paper)<PaperProps>(({ theme, bgColor }) => ({
    ...(bgColor && {
        background: `linear-gradient(180deg, ${bgColor.join(",")})`,
    }),
    ...theme.typography.body1,
    padding: theme.spacing(1),
    textAlign: "center",
    minHeight: "10rem",
    color: theme.palette.grey[200],
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "end",
    borderRadius: "0",
    boxShadow: "none",
    cursor: "pointer",
}));

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    categoryTextWarpper: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "90%",
    },
}));

export interface VideoCategoryListProps
    extends InternalStandardProps<GridProps> {
    videos: VideoContent[];
    categorizedVideos: VideoContent[];
}

export const VideoCategoryList = (props: VideoCategoryListProps) => {
    const classes = useStyles(props);
    const { className, videos, categorizedVideos, ...rest } = props;

    const history = useHistory();
    const { linkProvider } = useRouting();
    const { darkToneColors, lightToneColors, mediumToneColors } =
        useThemeColors();

    const updatedVideoCategoryTitle = omit(VideoCategoryTitle, "INDEX");

    const getBgColor = (idx: number, isCategorized: boolean): string[] => {
        if (isCategorized) {
            const populatedItems = Object.keys(
                updatedVideoCategoryTitle,
            ).length;
            return (populatedItems + idx) % 2 === 0
                ? lightToneColors
                : mediumToneColors;
        } else {
            return idx === 0
                ? darkToneColors
                : idx % 2 === 0
                ? lightToneColors
                : mediumToneColors;
        }
    };

    return (
        <Grid {...rest} container spacing={0.2}>
            {Object.values(updatedVideoCategoryTitle).map(
                (item, idx) =>
                    (item !== updatedVideoCategoryTitle.MY_VIDEOS ||
                        videos?.filter(
                            (video) =>
                                video.publish_free === "1" ||
                                video.is_purchased,
                        )?.length > 0) && (
                        <Grid
                            item={true}
                            xs={idx === 0 ? 12 : 6}
                            md={3}
                            key={item}
                            onClick={() =>
                                history.push(
                                    linkProvider.react.videos().listing(item),
                                )
                            }
                        >
                            <Item bgColor={getBgColor(idx, false)}>
                                {videoCategoryTitleToString(item)}
                            </Item>
                        </Grid>
                    ),
            )}

            <RenderIf isTrue={!!categorizedVideos?.length}>
                {categorizedVideos
                    .filter((d) => d.content_category_title !== "All Videos")
                    .map((item, idx) => (
                        <Grid
                            item={true}
                            xs={6}
                            md={3}
                            key={item.content_category_id}
                            onClick={() =>
                                history.push(
                                    linkProvider.react
                                        .videos()
                                        .listing(`${item.content_category_id}`),
                                )
                            }
                        >
                            <Item bgColor={getBgColor(idx, true)}>
                                <div
                                    className={clsx(
                                        classes.categoryTextWarpper,
                                    )}
                                >
                                    {item.content_category_title}
                                </div>
                            </Item>
                        </Grid>
                    ))}
            </RenderIf>
        </Grid>
    );
};
