import { Close } from "@mui/icons-material";
import { Box, Card, IconButton, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootBase: {
            width: "90vw",
            height: "200px",
            boxShadow: `0 0 10px -2px ${theme.palette.mode === "light"
                    ? theme.palette.grey[500]
                    : theme.palette.grey[800]
                }`,
            backgroundColor: theme.palette.background.default,
        },
        root: {
            position: "relative",
            backgroundColor: theme.palette.background.default,
        },
        rootFixed: {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 101,
        },
        dialog: {
            width: "90vw",
        },
        closeBtn: {
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
        },
        dialogContent: {
            marginTop: "45px",
            padding: "15px",
        },
    }),
);

export const AlertDialog = (props) => {
    const classes = useStyles(props);
    const { onClose, fixed, children } = props;
    return (
        <Card
            className={clsx(
                fixed ? classes.rootFixed : classes.root,
                classes.rootBase,
            )}
            data-testid="alert-dialog"
        >
            <IconButton className={clsx(classes.closeBtn)} onClick={onClose}>
                <Close />
            </IconButton>
            <Box className={classes.dialogContent}>{children}</Box>
        </Card>
    );
};
