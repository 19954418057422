import { createTheme, ThemeOptions } from "@mui/material/styles";
import React from "react";

declare module "@mui/material/styles" {
    interface TypeBackground {
        grey200: React.CSSProperties["color"];
    }
    //custom theme options typings
    interface Theme extends ThemeOptions {}
    interface ThemeOptions {
        colors?: {
            green: React.CSSProperties["color"];
            red?: React.CSSProperties["color"];
            redDark?: React.CSSProperties["color"];
            darkGrey?: React.CSSProperties["color"];
            yellow?: React.CSSProperties["color"];
            white?: React.CSSProperties["color"];
            textField?: {
                focus: React.CSSProperties["color"];
            };
            lightTone?: {
                primary: React.CSSProperties["color"];
                secondary: React.CSSProperties["color"];
            };
            darkTone?: {
                primary: React.CSSProperties["color"];
                secondary: React.CSSProperties["color"];
            };
            mediumTone?: {
                primary: React.CSSProperties["color"];
                secondary: React.CSSProperties["color"];
            };
        };
        header?: {
            height: React.CSSProperties["height"];
            color: React.CSSProperties["color"];
            titleFontSize: React.CSSProperties["fontSize"];
            background: React.CSSProperties["background"];
        };
        footer?: {
            height: React.CSSProperties["height"];
            zIndex: React.CSSProperties["zIndex"];
            aboveFooter: React.CSSProperties["zIndex"];
            zIndexOnModal: React.CSSProperties["zIndex"];
        };
        snackbar?: {
            variantError: React.CSSProperties["backgroundColor"];
            variantSuccess: React.CSSProperties["backgroundColor"];
            variantWarning: React.CSSProperties["backgroundColor"];
            variantInfo: React.CSSProperties["backgroundColor"];
        };
    }
}

function createAppLightTheme(options: ThemeOptions) {
    const primaryMain = "#01445E",
        primaryDark = "#012938";

    const grey = {
        "200": "#f2f2f2",
        "300": "#d9d9d9",
        "400": "#bfbfbf",
        "500": "#b3b3b3",
        "600": "#808080",
        "800": "#707070",
        "900": "#707070",
        A100: "#666666",
    };

    return createTheme({
        typography: {
            ...({
                useNextVariants: true,
            } as any),
        },

        header: {
            height: "70px",
            color: "#fff",
            background: `linear-gradient(to right, ${primaryMain}, ${primaryDark})`,
            titleFontSize: "20px",
        },

        footer: {
            height: "70px",
            zIndex: 1202,
            aboveFooter: 1304,
            zIndexOnModal: 1301,
        },
        palette: {
            mode: "light",
            primary: {
                main: primaryMain,
                dark: primaryDark,
                contrastText: "#fff",
                "900": "#5A23D9",
            },
            secondary: {
                main: "#E0E0E0",
                contrastText: "#fff",
            },
            success: {
                main: "#28a745",
                contrastText: "#fff",
            },
            error: {
                main: "#DC3545",
                contrastText: "#fff",
            },
            text: {
                primary: "#000000",
                secondary: "#b9b9d8",
            },
            background: {
                default: "#fff",
                paper: "#fff",
                grey200: grey[200],
            },
            common: {
                black: "#000000",
                white: "#fff",
            },
            grey: {
                "200": grey[200],
                "300": grey[300],
                "400": grey[400],
                "500": grey[500],
                "600": grey[600],
                "800": grey[800],
                "900": grey[900],
                A100: grey.A100,
            },
        },
        colors: {
            red: "#f44336",
            green: "#6eb072",
            darkGrey: "#25263e",
            redDark: "#e35454",
            yellow: "#EDE50E",
            white: "#FFFFFF",
            textField: {
                focus: "#1976D2",
            },
            lightTone: {
                primary: "#4097dc",
                secondary: "#2d6a9a",
            },
            darkTone: {
                primary: "#202a43",
                secondary: "#161d2f",
            },
            mediumTone: {
                primary: "#007680",
                secondary: "#00535a",
            },
        },
        snackbar: {
            variantError: "#d32f2f",
            variantInfo: "#2196f3",
            variantSuccess: "#43a047",
            variantWarning: "#ff9800",
        },
        zIndex: {},
        ...options,
    });
}

function createAppDarkTheme(options: ThemeOptions) {
    const primaryMain = "#7074D0",
        primaryDark = "##5A5DA6";

    const grey = {
        "200": "#f2f2f2",
        "300": "#d9d9d99c",
        "400": "#DCE1E7",
        "500": "#DCE1E7",
        "600": "#DCE1E7",
        "700": "#DCE1E7",
        "800": "#DCE1E7",
        "900": "#434343",
        A100: "#DCE1E7",
    };

    return createTheme({
        typography: {
            ...({
                useNextVariants: true,
            } as any),
        },

        header: {
            height: "70px",
            color: "#fff",
            background: `linear-gradient(to right, ${primaryMain}, ${primaryDark})`,
            titleFontSize: "20px",
        },

        footer: {
            height: "70px",
            zIndex: 1202,
            aboveFooter: 1304,
            zIndexOnModal: 1301,
        },
        palette: {
            mode: "dark",
            primary: {
                main: primaryMain,
                dark: primaryDark,
                contrastText: "#fff",
                "900": "#5A23D9",
            },
            secondary: {
                main: "#7074d052",
                contrastText: "#fff",
            },
            success: {
                main: "#28a745",
                contrastText: "#fff",
            },
            error: {
                main: "#DC3545",
                contrastText: "#fff",
            },
            text: {
                primary: "#7074D0",
                secondary: "#fff",
            },
            background: {
                default: "#1F1F1F",
                paper: "#141414",
                grey200: "#1F1F1F",
            },
            common: {
                black: "#ffff",
                white: "#000",
            },
            grey: {
                "200": grey[200],
                "300": grey[300],
                "400": grey[400],
                "500": grey[500],
                "600": grey[600],
                "700": grey[700],
                "800": grey[800],
                "900": grey[900],
                A100: grey.A100,
            },
        },
        colors: {
            red: "#f44336",
            green: "#6eb072",
            darkGrey: "#25263e",
            redDark: "#e35454",
            yellow: "#EDE50E",
            white: "#FFFFFF",
            textField: {
                focus: primaryMain,
            },
            lightTone: {
                primary: "#4097dc",
                secondary: "#2d6a9a",
            },
            darkTone: {
                primary: "#202a43",
                secondary: "#161d2f",
            },
            mediumTone: {
                primary: "#007680",
                secondary: "#00535a",
            },
        },
        snackbar: {
            variantError: "#820303",
            variantInfo: "#034f8c",
            variantSuccess: "#09660d",
            variantWarning: "#9c5e03",
        },

        zIndex: {},
        ...options,
    });
}
export const lightTheme = createAppLightTheme({});
export const darkTheme = createAppDarkTheme({});
