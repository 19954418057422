import { useEffect, useMemo, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { Box, Grid, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { useGlobalGifts } from "JS/React/Hooks/Gifts";
import { useGlobalCourses } from "JS/React/Hooks/Course/Course";
import { AppHeader } from "JS/React/Components/AppHeader";
import {
    AppContentListItem,
    AppContentListItemType,
} from "JS/React/Components/AppContentListItem";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import { AllTabs } from "JS/Models";
import { paginateData, PaginationInfo } from "JS/Types/Pagination";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { getPublicUrl } from "JS/Helpers";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRouting } from "JS/React/Hooks/Routes";
import { sortData } from "JS/Types";
import { messaging } from "JS/Helpers/UserMessaging";
import { Layout } from "../Layout";
import qs from "qs";

export const GiftsGivenContentListing = (props: any) => {
    const params: any = useParams();
    const { memberId, selectedTab, categoryId } = params;
    const Tabs: any = AllTabs;
    const classes = useStyles(props);
    const { linkProvider } = useRouting();
    const { className, ...rest } = props;
    const {
        homeStack,
        giftsStack,
        setGlobalGiftsCategoryStack,
        resetGlobalGiftsCategoryStack,
    } = useGlobalNavStack();
    const [filteredGifts, setFilteredGifts] = useState([]);
    const [searchQuery, setSearchQuery] = useState<string>(
        giftsStack[categoryId]?.searchQuery
            ? giftsStack[categoryId]?.searchQuery
            : "",
    );
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [headerTitle, setHeaderTitle] = useState("");
    const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
        currentPage: 1,
        perPage: 10,
        total: 0,
    });
    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    const history = useHistory();
    const { audioGiftsGiven, videoGiftsGiven } = useGlobalGifts();
    const { giftsGiven } = useGlobalCourses();
    const { scrollTo } = useQueryParams();
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const isLoading = () => {
        return false;
    };

    const handleGiftCheckChanged = (nid: string, checked: boolean) => {
        if (checked) setSelectedItems((prevState) => [...prevState, nid]);
        else
            setSelectedItems((prevState) =>
                prevState?.filter((item) => item !== nid),
            );
    };

    useEffect(() => {
        const receiver = audioGiftsGiven?.find(
            (agg) => agg.receiver?.member_id === memberId,
        )?.receiver;
        const receiverName = `${receiver?.first_name || ""} ${
            receiver?.last_name || ""
        }`;
        setHeaderTitle(receiverName.trim() || memberId || "Gifts Given");
        if (selectedTab === Tabs.AUDIOS) {
            setFilteredGifts(
                audioGiftsGiven?.filter(
                    (agg) => agg.receiver?.member_id === memberId,
                ),
            );
        } else if (selectedTab === Tabs.VIDEOS) {
            setFilteredGifts(
                videoGiftsGiven?.filter(
                    (agg) => agg.receiver?.member_id === memberId,
                ),
            );
        } else if (selectedTab === Tabs.COURSES) {
            setFilteredGifts(
                giftsGiven?.find((gg) => gg.receiver_id === memberId)
                    ?.gift_courses || [],
            );
        }
    }, [audioGiftsGiven, memberId, selectedTab, giftsGiven, videoGiftsGiven]);

    const searchResults = useMemo(() => {
        return filteredGifts?.filter(
            (d) =>
                d.title
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()) ||
                d.description
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()),
        );
    }, [filteredGifts, searchQuery]);

    const finalData = useMemo(() => {
        return paginateData(
            sortData(searchResults, [
                {
                    col: "title",
                    dir: "asc",
                    position: 0,
                },
            ]),
            paginationInfo,
        ).data;
    }, [searchResults, paginationInfo]);

    useEffect(() => {
        setPaginationInfo({
            ...paginationInfo,
            total: searchResults ? searchResults.length : 0,
        });
        setGlobalGiftsCategoryStack(
            {
                searchQuery: searchQuery,
            },
            categoryId,
        );
    }, [searchResults]);

    useEffect(() => {
        if (finalData) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [finalData]);

    const createImageUrl = async (gift) => {
        if (gift.content_item_type_name === "GiftCourse") {
            const url = await getPublicUrl(
                gift.image.image_url_prefix,
                gift.image.image_url_postfix,
                gift.image.image_file_name,
            );
            return {
                id: gift.nid,
                url: url,
            };
        } else {
            const url = await getPublicUrl(
                gift.image_url_prefix,
                gift.image_url_postfix,
                gift.image_file_name,
            );
            return {
                id: gift.nid,
                url: url,
            };
        }
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        finalData.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    let contentItemType: AppContentListItemType = "gift";

    const getCurrentTabData = () => {
        if (selectedTab === Tabs.AUDIOS) {
            return audioGiftsGiven?.filter(
                (agg) => agg.receiver?.member_id === memberId,
            );
        } else if (selectedTab === Tabs.VIDEOS) {
            return videoGiftsGiven?.filter(
                (agg) => agg.receiver?.member_id === memberId,
            );
        } else if (selectedTab === Tabs.COURSES) {
            return (
                giftsGiven?.find((gg) => gg.receiver_id === memberId)
                    ?.gift_courses || []
            );
        }
    };

    const goBack = () => {
        resetGlobalGiftsCategoryStack(categoryId);
        if (!homeStack?.isHomeTrack) {
            history.push(
                linkProvider.react
                    .gifts()
                    .giftsGivenListing("gifts-given", { scrollTo: +scrollTo }),
            );
        } else {
            history.goBack();
        }
    };

    const setSelectedTab = (tab: AllTabs) => {
        history.replace(
            linkProvider.react
                .gifts()
                .giftsGivenContentListing(memberId, tab, categoryId, {
                    scrollTo: +scrollTo,
                }),
        );
    };

    return (
        <Layout
            paginationInfo={!isLoading() && paginationInfo}
            onLastContentHit={() =>
                setPaginationInfo({
                    ...paginationInfo,
                    perPage: paginationInfo.perPage + 10,
                })
            }
        >
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title={headerTitle || "Gifts"}
                    canGoBack
                    searchable={true}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    tabs={Tabs}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    showTabs={true}
                    searchPlaceholder={`Search ${selectedTab}s by title or description`}
                    onBackClick={() => {
                        setFilteredGifts([]);
                        goBack();
                    }}
                />

                {!isLoading() && (
                    <Box>
                        {finalData?.map((filteredGift, idx) => (
                            <AppContentListItem
                                nid={filteredGift.nid}
                                type={contentItemType}
                                sender={filteredGift.sender}
                                key={idx}
                                title={filteredGift.title}
                                contentPrice={filteredGift.content_price}
                                isRedeemableByToken={
                                    filteredGift.is_redeemable_by_tokens
                                }
                                numberOfTokens={filteredGift.number_of_tokens}
                                skuId={filteredGift.sku_id}
                                onGiftCheckChanged={handleGiftCheckChanged}
                                giftChecked={selectedItems?.includes(
                                    filteredGift.sku_id,
                                )}
                                description={filteredGift.description}
                                showPlayIcon={
                                    filteredGift.is_purchased ||
                                    filteredGift.publish_free === "1"
                                }
                                isFavourite={filteredGift.is_favourite}
                                imageUrl={
                                    thumbnails && thumbnails[filteredGift.nid]
                                }
                                isAllowClick
                                onListItemClick={() => {}}
                                releaseDate={
                                    filteredGift.created
                                        ? filteredGift.created
                                        : filteredGift.sending_date
                                }
                                isGiftGiven={true}
                            />
                        ))}
                    </Box>
                )}

                {isLoading() && (
                    <Grid
                        className={clsx(classes.wrapperLoader)}
                        item={true}
                        xs={12}
                        md={12}
                    >
                        <AppCircularProgress />
                    </Grid>
                )}
                {!isLoading() && getCurrentTabData()?.length === 0 && (
                    <Typography
                        align="center"
                        variant="h6"
                        className={classes.noContent}
                    >
                        {messaging.common.noItemsFound}
                    </Typography>
                )}
            </div>
        </Layout>
    );
};

const useQueryParams = () => {
    const location = useLocation();
    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const { scrollTo = "0" } = parsedQueryString;

        return {
            scrollTo,
        };
    }, [location?.search]);

    return queryParams;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey600: {
            color: theme.palette.grey[600],
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - ${theme.footer.height})`,
        },
        sendCheckboxContainer: {
            textAlign: "center",
            margin: "15px 0",
        },
        checkboxControlLabel: {
            "& .MuiTypography-body1": {
                color: `${theme.palette.grey[700]} !important`,
                fontSize: "18px",
            },
        },
        bottomSheetBtn: {
            height: "100%",
            width: "100%",
            paddingTop: "15px",
            color: theme.palette.grey[700],
        },
        checkedIcon: {
            color: theme.palette.grey[700],
        },
        bottomSheetPadding: {
            paddingBottom: "120px",
        },
        dialog: {
            width: "90vw",
        },
        closeBtn: {
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
        },
        dialogContent: {
            marginTop: "20px",
        },
        dialogTypography: {
            textAlign: "center",
            color: theme.palette.grey[600],
            marginBottom: "10px",
        },
        noContent: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            color: `${theme.palette.common.black}`,
        },
    }),
);
