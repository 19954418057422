import { AudioDexie, getDexieConnectionRefresh } from "./Dexie";

export const deleteAudioById = async (id: string) => {
    await getDexieConnectionRefresh().audios.delete(id);
};

export const bulkDeleteAudiosByIds = async (ids: string[]) => {
    await getDexieConnectionRefresh().audios.bulkDelete(ids);
};

export const getAudioById = async (id: string) => {
    return await getDexieConnectionRefresh().audios.get(id);
};

export const getAudiosIndex = async () => {
    return await getDexieConnectionRefresh().audios.toArray();
};

export const bulkUpdateAudios = async (audios: AudioDexie[]) => {
    return await getDexieConnectionRefresh().audios.bulkPut(audios);
};

export const getAudioBundles = async () => {
    return await getDexieConnectionRefresh().audios.filter(
        (item) => item.isBundle === true,
    );
};
