import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    InternalStandardProps,
    Theme,
    Typography,
    TypographyProps,
} from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        greyA100: {
            color: theme.palette.grey.A100,
        },
        grey500: {
            color: theme.palette.grey[500],
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        black: {
            color: theme.palette.common.black,
        },
    }),
);

export interface AppTypographyProps
    extends InternalStandardProps<TypographyProps> {
    textColor?: "grey-a100" | "grey-500" | "grey-600" | "black";
}

export const AppTypography = (props: AppTypographyProps) => {
    const classes = useStyles(props);
    const { className, textColor, ...rest } = props;

    return (
        <Typography
            {...rest}
            className={clsx(className, classes.root, {
                [classes.greyA100]: textColor === "grey-a100",
                [classes.grey500]: textColor === "grey-500",
                [classes.grey600]: textColor === "grey-600",
                [classes.black]: textColor === "black",
            })}
        />
    );
};
