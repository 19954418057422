import { setGlobalPlaylistControls } from "JS/Redux/PlaylistControls";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";

export const useGlobalPlaylistControls = () => {
    const dispatch = useAppDispatch();
    const existing = useAppSelector((state) => state.playlistControls);

    return {
        playlistControls: useAppSelector((state) => state.playlistControls),
        toggleGlobalShuffle: () => {
            dispatch(
                setGlobalPlaylistControls({
                    ...existing,
                    shuffle: !existing.shuffle,
                }),
            );
        },
        toggleGlobalLoop: () => {
            dispatch(
                setGlobalPlaylistControls({
                    ...existing,
                    loop: !existing.loop,
                }),
            );
        },
    };
};
