import { useCallback, useMemo, useState } from "react";
import moment from "moment";
import {
    checkIfDownloadLimitReached,
    createCourseIDForStats,
    stringToContentType,
} from "JS/Helpers";
import {
    enqueueSteps,
    findStepsToDownload,
    getDownloadedSteps,
    getIndexDBIdsForAllSteps,
    markAllQuizzesAsDownloaded,
} from "JS/Helpers/CourseHelpers";
import { processDownloadQueue } from "JS/Helpers/ContentDownloadHelper";
import {
    CourseStep,
    DownloadedCourseDetail,
    FBCourseStats,
    OfflineFBCourseStats,
    FBStepStats,
    CourseDetail,
} from "JS/Models";
import { store, useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { uniqBy } from "lodash-es";
import {
    setGlobalDownloadedCourses,
    setGlobalFBCourseStats,
} from "JS/Redux/CourseDownload";
import { config } from "JS/Config";
import { useBulkDeleteSteps } from "./Database/Course";
import { useWriteOfflineCourseStatsFBFunc } from "./Firebase";

export const useGetLatestCourseStats = () => {
    const [loading, setLoading] = useState(false);
    const [courseStats, SetCourseStats] = useState<FBCourseStats>();
    const { userDownloadedCourses, refetchFbCourseStats } =
        useDownloadedCourses();

    const getLatestCourseStats = useCallback(
        (courseSkuId: string): FBCourseStats => {
            setLoading(true);

            const activeCourse = userDownloadedCourses?.find(
                (c) => c.sku_id === courseSkuId,
            );
            let firebaseCourseStats = refetchFbCourseStats();
            const initialStats: Omit<FBCourseStats, "steps"> = {
                last_updated: moment().valueOf(),
                type: activeCourse?.content_item_type_name,
                title: activeCourse?.title,
                purchased_state: activeCourse?.isPurchased ? 2 : 1,
                state: activeCourse?.isPurchased ? 2 : 1,
                analytics: {
                    completed_count: 0,
                    completed_percentage: 0,
                    completed_steps: 0,
                    first_completed_date: 0,
                    first_start_date: 0,
                    last_completed_date: 0,
                    last_opened: moment().valueOf(),
                    last_step_start_date: 0,
                    last_step_started: "",
                    opened_count: 0,
                    progress_state: 0,
                    total_steps: activeCourse?.steps
                        ? activeCourse.steps.length
                        : 0,
                },
            };
            const memeberCourseSkuId = createCourseIDForStats(
                activeCourse?.sku_id,
            );

            const latestCourseStats: FBCourseStats = firebaseCourseStats[
                memeberCourseSkuId
            ]
                ? firebaseCourseStats[memeberCourseSkuId]
                : (initialStats as FBCourseStats);
            setLoading(false);
            SetCourseStats(latestCourseStats);
            return latestCourseStats;
        },
        [],
    );
    return {
        getLatestCourseStats,
        loading,
        courseStats,
    };
};

export const useGetLatestStepStats = () => {
    const [loading, setLoading] = useState(false);
    const [stepStats, setStepStats] = useState<FBStepStats>();
    const { userDownloadedCourses, refetchFbCourseStats } =
        useDownloadedCourses();

    const getLatestStepsStats = useCallback(
        (courseSkuId: string, stepSkuId: string): FBStepStats => {
            setLoading(true);
            let firebaseCourseStats = refetchFbCourseStats();
            const activeCourse: DownloadedCourseDetail =
                userDownloadedCourses?.find((c) => c.sku_id === courseSkuId);

            const activeStep: CourseStep =
                activeCourse &&
                activeCourse.steps.find((x) => x.sku_id === stepSkuId);

            const initialStats: Omit<FBCourseStats, "steps"> = {
                last_updated: moment().valueOf(),
                type: activeCourse?.content_item_type_name,
                title: activeCourse?.title,
                purchased_state: activeCourse?.isPurchased ? 2 : 1,
                state: activeCourse?.isPurchased ? 2 : 1,
                analytics: {
                    completed_count: 0,
                    completed_percentage: 0,
                    completed_steps: 0,
                    first_completed_date: 0,
                    first_start_date: 0,
                    last_completed_date: 0,
                    last_opened: moment().valueOf(),
                    last_step_start_date: 0,
                    last_step_started: "",
                    opened_count: 0,
                    progress_state: 0,
                    total_steps: activeCourse?.steps
                        ? activeCourse.steps.length
                        : 0,
                },
            };

            const initialStepsStats: FBStepStats = {
                content_type: stringToContentType(
                    activeStep?.content_item_type_name,
                ),
                title: activeStep.title,
                position: +activeStep.position,
                analytics: {
                    completed_count: 0,
                    completed_percentage: 0,
                    first_completed_date: 0,
                    first_start_date: 0,
                    last_completed_date: 0,
                    last_opened: moment().valueOf(),
                    opened_count: 0,
                    progress_state: 0,
                    played_count: 0,
                    duration: 0,
                    // last_played: 0,
                    last_start_date: 0,
                    maximum_played: 0,
                    // type:initialStats.
                },
            };
            const memeberCourseSkuId = createCourseIDForStats(courseSkuId);

            const latestCourseStats: FBCourseStats = firebaseCourseStats[
                memeberCourseSkuId
            ]
                ? firebaseCourseStats[memeberCourseSkuId]
                : (initialStats as FBCourseStats);
            setLoading(false);
            if (latestCourseStats?.steps) {
                const latestStepStats: FBStepStats = latestCourseStats?.steps[
                    stepSkuId
                ]
                    ? latestCourseStats?.steps[stepSkuId]
                    : initialStepsStats;

                setStepStats(latestStepStats);
                return latestStepStats;
            } else {
                setStepStats(initialStepsStats);
                return initialStepsStats;
            }
        },
        [userDownloadedCourses],
    );
    return {
        getLatestStepsStats,
        loading,
        stepStats,
    };
};

export const useWriteOfflineCourseStats = () => {
    const [loading, setLoading] = useState(false);
    const { firebaseCourseStats, setFBCourseStats, refetchFbCourseStats } =
        useDownloadedCourses();

    const writeOfflineFBCourseStats = useCallback(
        (skuId: string, stats: FBCourseStats) => {
            setLoading(true);
            let courseStats: OfflineFBCourseStats = refetchFbCourseStats();

            const memeberCourseSkuId = createCourseIDForStats(skuId);

            if (courseStats) {
                courseStats = {
                    ...firebaseCourseStats,
                    [memeberCourseSkuId]: {
                        ...stats,
                        last_updated: moment().valueOf(),
                    },
                };
            } else {
                courseStats = {
                    [memeberCourseSkuId]: {
                        ...stats,
                        last_updated: moment().valueOf(),
                    },
                };
            }

            setFBCourseStats({ ...courseStats });
            setLoading(false);
        },
        [],
    );

    return {
        writeOfflineFBCourseStats,
        loading,
    };
};

export const useWriteOfflineStepStats = () => {
    const [loading, setLoading] = useState(false);
    const { firebaseCourseStats, setFBCourseStats, refetchFbCourseStats } =
        useDownloadedCourses();

    const writeOfflineFBStepStats = useCallback(
        (courseSkuId: string, stepSkuId: string, stats: FBStepStats) => {
            setLoading(true);
            let coursesStats: OfflineFBCourseStats = refetchFbCourseStats();

            const memeberCourseSkuId = createCourseIDForStats(courseSkuId);

            let courseStatsMeta: FBCourseStats =
                coursesStats && coursesStats[memeberCourseSkuId];

            let stepsStats = courseStatsMeta && courseStatsMeta.steps;

            courseStatsMeta = {
                ...courseStatsMeta,
                steps: { ...stepsStats, [stepSkuId]: { ...stats } },
            };

            if (courseStatsMeta) {
                coursesStats = {
                    ...firebaseCourseStats,
                    [memeberCourseSkuId]: {
                        ...courseStatsMeta,
                        last_updated: moment().valueOf(),
                    },
                };
            }
            setFBCourseStats({ ...coursesStats });
            setLoading(false);
        },
        [],
    );

    return {
        writeOfflineFBStepStats,
        loading,
    };
};

export const useSyncFirebaseCourseStats = () => {
    const [loading, setLoading] = useState(false);
    const { refetchFbCourseStats, userDownloadedCourses } =
        useDownloadedCourses();
    const { writeOffineCourseStatsFB } = useWriteOfflineCourseStatsFBFunc();
    const syncCourseStats = useCallback(() => {
        const offlineCoursesStats: OfflineFBCourseStats =
            refetchFbCourseStats();
        Object.keys(offlineCoursesStats)?.map(async (item) => {
            const offlineCourseStats: FBCourseStats = offlineCoursesStats[item];
            const downloadedItem: DownloadedCourseDetail =
                userDownloadedCourses?.find(
                    (x) => x.sku_id === item.split("@")[1],
                );

            if (offlineCourseStats && downloadedItem) {
                writeOffineCourseStatsFB(
                    downloadedItem?.sku_id,
                    offlineCourseStats,
                );
            }
        });
        setLoading(true);
    }, []);
    return {
        syncCourseStats,
        loading,
    };
};

export const useDownloadedCourses = () => {
    const dispatch = useAppDispatch();
    const crs = useAppSelector(
        (state) => state.downloadedCourse,
    ).downloadedCourses;

    const memberId = config.user.memberId;

    const userDownloadedCourses = useMemo(() => {
        return crs ? crs.filter((c) => c.memberId === memberId) : [];
    }, [crs, memberId]);

    return {
        downloadedCourses: crs,
        userDownloadedCourses: userDownloadedCourses,
        setDownloadedCourses: (courses: DownloadedCourseDetail[]) => {
            dispatch(setGlobalDownloadedCourses(courses));
        },
        firebaseCourseStats: useAppSelector((state) => state.downloadedCourse)
            ?.firebaseCourseStats,
        setFBCourseStats: (coursesStats: OfflineFBCourseStats) => {
            dispatch(setGlobalFBCourseStats(coursesStats));
        },
        refetchFbCourseStats: () => {
            return store?.getState()?.downloadedCourse?.firebaseCourseStats;
        },
    };
};

export const useCourseDownloadQueue = () => {
    const { downloadedCourses, setDownloadedCourses } = useDownloadedCourses();

    const addCourseInQueue = (
        course: DownloadedCourseDetail,
        onQueueLimitReached: () => void,
    ) => {
        const downloaded = downloadedCourses?.find(
            (d) => d.nid === course.nid && d.memberId === config.user.memberId,
        );

        const stepsToDownload = findStepsToDownload(course, downloaded);
        const quizMarked = markAllQuizzesAsDownloaded(stepsToDownload);

        const totalStepsToDownload = quizMarked.filter(
            (x) => x.content_item_type_name !== "quiz",
        );

        const isLimitReached = checkIfDownloadLimitReached(
            totalStepsToDownload.length,
        );

        if (!isLimitReached) {
            if (downloaded) {
                const dwnlodCrs: DownloadedCourseDetail[] =
                    downloadedCourses.map((c) => {
                        if (
                            c.memberId === config.user.memberId &&
                            c.nid === course.nid
                        ) {
                            const newSteps = uniqBy(
                                [...c.steps, ...quizMarked],
                                (x) => x.sku_id,
                            );

                            return {
                                ...c,
                                steps: newSteps,
                            };
                        } else {
                            return c;
                        }
                    });
                setDownloadedCourses(dwnlodCrs);
            } else {
                const markedSteps = markAllQuizzesAsDownloaded(course.steps);
                const newMarkedCourse: DownloadedCourseDetail = {
                    ...course,
                    steps: markedSteps,
                };
                setDownloadedCourses([...downloadedCourses, newMarkedCourse]);
            }

            enqueueSteps(course, quizMarked);
            processDownloadQueue();
        } else {
            onQueueLimitReached();
        }
    };

    return {
        addCourseInQueue,
    };
};

export const useDeleteCourse = () => {
    const { downloadedCourses, setDownloadedCourses } = useDownloadedCourses();
    const { deleteSteps } = useBulkDeleteSteps();

    const deleteCompleteCourse = async (courseNid: CourseDetail["nid"]) => {
        const member = config.user.memberId;

        const toDeleteCrs = downloadedCourses.find(
            (x) => x.nid === courseNid && x.memberId === member,
        );

        const downloadedSteps = getDownloadedSteps(toDeleteCrs);
        const toDelete = getIndexDBIdsForAllSteps(
            member,
            courseNid,
            downloadedSteps,
        );

        if (toDelete && toDelete.length) {
            const deletedIds = await deleteSteps(toDelete);

            if (deletedIds) {
                const filtered = downloadedCourses.filter((x) => {
                    const notRequired =
                        x.memberId === member && x.nid === courseNid;
                    return !notRequired;
                });

                setDownloadedCourses(filtered);
            }
        }
    };

    return { deleteCompleteCourse };
};

export const useCourseDownloadedStatus = () => {
    const { userDownloadedCourses } = useDownloadedCourses();
    const isCourseDownloaded = (courseSkuId: string) => {
        const downloadedCourse = userDownloadedCourses?.find(
            (x) => x.sku_id === courseSkuId,
        );

        if (
            downloadedCourse &&
            downloadedCourse?.steps?.every((x) => x.isDownloaded)
        )
            return true;

        return false;
    };

    return {
        isCourseDownloaded,
    };
};
