import { useMiniPlayer } from "JS/React/Hooks/MiniPlayer";
import { MiniMediaPlayer } from "./MiniMediaPlayer";
import { useEffect, useState } from "react";
import { ControlPosition, DraggableEventHandler } from "react-draggable";

export const MiniPlayer = (props: MiniPlayerProps) => {
    const { isOffline } = props;
    const { showMiniPlayer } = useAppMiniPlayer();
    const { miniPlayer } = useMiniPlayer();

    const [playing, setPlaying] = useState<boolean>(false);

    const { dragPosition, handleOnDrag } = useDraggable();

    if (!!showMiniPlayer)
        return (
            <MiniMediaPlayer
                key={miniPlayer?.parentLink}
                isOffline={isOffline}
                playing={playing}
                dragPosition={dragPosition}
                handleOnDrag={handleOnDrag}
                setPlaying={setPlaying}
            />
        );

    return null;
};

const useDraggable = () => {
    const [dragPosition, setDragPosition] = useState<ControlPosition>({
        x: 0,
        y: 0,
    });

    const handleOnDrag: DraggableEventHandler = (e, data) => {
        if (data) {
            setDragPosition({
                x: data.x,
                y: data.y,
            });
        }
    };

    useEffect(() => {
        const handleOrientationChange = () => {
            setDragPosition({
                x: 0,
                y: 0,
            });
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener(
                "orientationchange",
                handleOrientationChange,
            );
        };
    }, []);

    return {
        dragPosition,
        handleOnDrag,
    };
};

const useAppMiniPlayer = () => {
    const { miniPlayer } = useMiniPlayer();

    return {
        showMiniPlayer: miniPlayer?.url,
    };
};

export interface MiniPlayerProps {
    isOffline?: boolean;
}
