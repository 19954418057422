import { AudioService } from "JS/Services/Audio";
import { useState, useCallback, useEffect } from "react";
import {
    AudioContent,
    AudiosAndGifts,
    Gifts,
    ResumeAudioContent,
} from "JS/Models";
import { AppResponse } from "JS/Types";
import {
    createOfflineIDBID,
    getUniqueValues,
    sortArrayByKey,
} from "JS/Helpers";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    setGlobalAudios,
    setGlobalPlayedAudios,
    setUserResumeAudios,
    setUserResumeBundles,
} from "JS/Redux/Audio";
import { setGlobalMedia } from "JS/Redux/media";
import moment from "moment";
import { config } from "JS/Config";
import { useAudioIndexFromDb, useBulkUpdateAudiosDB } from "./Database/Audio";
import { keyBy } from "lodash";
import { AudioDexie } from "JS/Database/Dexie";
import {
    AudioVideoResume,
    PlayedAudiosVideos,
} from "JS/Models/Firebase/Resume";
import { useGlobalMediaEssentials } from "./MediaEssentials";

export const service = new AudioService();

export const useAudios = (skip: boolean, isUpdateRequired: boolean) => {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [response, setResponse] = useState<AppResponse<AudioContent[]>>(null);
    const [errorMsg, setErrorMsg] = useState<string>(null);
    const [audios, setAudios] = useState<AudioContent[]>([]);

    const { playedAudios, resumeAudios, resumeBundles } = useGlobalAudios();
    const playedAudiosList =
        playedAudios && playedAudios[config.user.memberId]
            ? playedAudios[config.user.memberId]
            : [];
    const dispatch = useAppDispatch();
    const { syncOfflineAudios } = useSyncOfflineAudios();
    const { mediaEssentials } = useGlobalMediaEssentials();
    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(() => {
        setLoading(true);

        return service
            .getAudiosIndex()
            .then(async (res) => {
                const apiResponse = res.response;
                setResponse(res);
                if (!apiResponse?.status) {
                    setErrorMsg(apiResponse?.message);
                    enqueueSnackbar(apiResponse?.message, {
                        variant: "error",
                    });
                } else {
                    const data = res?.response?.data;
                    syncOfflineAudios(data);
                    const allAudios = data?.map((item) => {
                        if (
                            playedAudiosList.filter((x) => x.nid === item.nid)
                                .length > 0
                        ) {
                            return {
                                ...item,
                                played: playedAudiosList.filter(
                                    (x) => x.nid === item.nid,
                                )[0].played,
                                isReceived: false,
                            };
                        } else {
                            return {
                                ...item,
                                isReceived: false,
                                played: "0",
                            };
                        }
                    });

                    let categoryList: AudioContent[] = getUniqueValues(
                        allAudios,
                        "content_category_id",
                    );

                    const audioList = sortArrayByKey(allAudios, "title", "ASC");
                    setAudios(audioList);

                    dispatch(
                        setGlobalAudios({
                            audiosList: audioList,
                            catagoriesList: sortArrayByKey(
                                categoryList,
                                "content_category_title",
                                "ASC",
                            ),
                            playedAudios: playedAudios
                                ? { ...playedAudios }
                                : {},
                            resumeAudios: resumeAudios
                                ? { ...resumeAudios }
                                : {},
                            resumeBundles: resumeBundles
                                ? { ...resumeBundles }
                                : {},
                        }),
                    );
                    dispatch(
                        setGlobalMedia({ lastCachedTime: moment().unix() }),
                    );
                    return {
                        allAudios,
                        categoryList,
                    };
                }
                return {
                    allAudios: [],
                    categoryList: [],
                };
            })
            .catch((err) => {
                setErrorMsg(err.message);
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
                return {
                    allAudios: [],
                    categoryList: [],
                };
            })
            .finally(() => {
                setLoading(false);
                setLoaded(true);
            });
    }, []);

    useEffect(() => {
        if (
            !skip ||
            (mediaEssentials?.allow_rule_engine_requests && isUpdateRequired)
        ) {
            refetch();
        }
    }, [skip, isUpdateRequired]);

    return {
        refetch,
        response,
        loading,
        loaded,
        errorMsg,
        audios,
    };
};

export const useGlobalAudios = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.audios);
    const played = useAppSelector((state) => state.audios?.playedAudios);
    const resumeAudios =
        useAppSelector((state) => state.audios?.resumeAudios) || {};
    const resumeBundles = useAppSelector(
        (state) => state.audios?.resumeBundles,
    );

    const resumeUserAudios =
        resumeAudios && resumeAudios[`${config?.user?.memberId}`]
            ? resumeAudios[`${config?.user?.memberId}`]
            : [];

    const playedUserAudios =
        played && played[`${config?.user?.memberId}`]
            ? played[`${config?.user?.memberId}`]
            : [];
    const resumeUserBundles =
        resumeBundles && resumeBundles[`${config?.user?.memberId}`]
            ? resumeBundles[`${config?.user?.memberId}`]
            : [];
    const currentPlayedMap = played ? played : {};
    const currentResumeAudiosMap = resumeAudios ? resumeAudios : {};
    const currentResumeBundlesMap = resumeBundles ? resumeBundles : {};

    return {
        audios: useAppSelector((state) => state.audios)?.audiosList,
        isAudioUpdateRequired: allData?.isUpdateRequired,
        playedAudios: currentPlayedMap,
        playedUserAudios,
        resumeAudios: resumeAudios || {},
        resumeBundles: resumeBundles || {},

        categorizedAudios: useAppSelector((state) => state.audios)
            .catagoriesList,
        resumeUserAudios: resumeUserAudios,
        resumeUserBundles: resumeUserBundles,

        setGlobalAudios: (audios: AudioContent[]) => {
            dispatch(
                setGlobalAudios({
                    ...allData,
                    audiosList: audios,
                }),
            );
            dispatch(setGlobalMedia({ lastCachedTime: moment().unix() }));
        },

        updateRecentlyPlayed: (audio: AudioContent | Gifts) => {
            const currentPlayed: (AudioContent | Gifts | PlayedAudiosVideos)[] =
                currentPlayedMap[config.user.memberId]
                    ? currentPlayedMap[config.user.memberId]
                    : [];
            const newMap = { ...currentPlayedMap };
            const newPlayed = [...currentPlayed].filter(
                (c) => c.nid !== audio.nid,
            );
            newPlayed.push(audio);
            newMap[config.user.memberId] = newPlayed;
            dispatch(setGlobalPlayedAudios(newMap));
        },

        updateResumeAudios: (resumeAudio: ResumeAudioContent) => {
            const currentUserResume: ResumeAudioContent[] =
                currentResumeAudiosMap[config.user.memberId]
                    ? currentResumeAudiosMap[config.user.memberId]
                    : [];
            const newMap = { ...currentResumeAudiosMap };
            const newResume = [...currentUserResume].filter(
                (c) => c.nid !== resumeAudio.nid,
            );
            newResume.push(resumeAudio);
            newMap[config.user.memberId] = newResume;
            dispatch(setUserResumeAudios(newMap));
        },

        updateResumeBundles: (resumeAudio: ResumeAudioContent) => {
            const userBundleList: ResumeAudioContent[] =
                currentResumeBundlesMap[config.user.memberId]
                    ? currentResumeBundlesMap[config.user.memberId]
                    : [];

            const newMap = { ...currentResumeBundlesMap };
            if (
                userBundleList.filter(
                    (c) =>
                        c.nid === resumeAudio.nid &&
                        c.title === resumeAudio.title,
                ).length > 0
            ) {
                let newResume = [...userBundleList];
                const index = newResume.findIndex(
                    (x) =>
                        x.nid === resumeAudio.nid &&
                        x.title === resumeAudio.title,
                );
                newResume[index] = resumeAudio;
                newMap[config.user.memberId] = newResume;
            } else {
                let newResume = [...userBundleList];
                newResume.push(resumeAudio);
                newMap[config.user.memberId] = newResume;
            }

            dispatch(setUserResumeBundles(newMap));
        },
        syncResumeAudios: (resumeAudiosData: AudioVideoResume[]) => {
            dispatch(
                setUserResumeAudios({
                    [config.user.memberId]: resumeAudiosData,
                }),
            );
        },
        syncResumeBundles: (resumeBundlesData: AudioVideoResume[]) => {
            dispatch(
                setUserResumeBundles({
                    [config.user.memberId]: resumeBundlesData,
                }),
            );
        },
        syncPlayedAudios: (playedAudios: PlayedAudiosVideos[]) => {
            dispatch(
                setGlobalPlayedAudios({
                    [config.user.memberId]: playedAudios,
                }),
            );
        },
    };
};

export const useSyncOfflineAudios = () => {
    const { refetch: refetchOfflineAudios } = useAudioIndexFromDb(true);
    const { bulkUpdateAudios } = useBulkUpdateAudiosDB();

    const syncOfflineAudios = useCallback(
        async (audios: AudiosAndGifts[]) => {
            const offlineAudios = await refetchOfflineAudios();

            if (offlineAudios && offlineAudios.length) {
                const allOfflineIds = offlineAudios.map((d) => d.id);

                //get the details of the audios which are downloaded
                const filteredAudios = audios.filter((x) => {
                    const offlineId = createOfflineIDBID(
                        config.user.memberId,
                        x.nid,
                    );

                    if (x.media?.length > 1) {
                        //for bundles id will be like userid-nid-position
                        //include this bundle even if one audio in that is downloaded
                        return allOfflineIds.some((id) =>
                            id.includes(offlineId),
                        );
                    }

                    return allOfflineIds.includes(offlineId);
                });

                const newOfflineAudios = createUpdatedOfflineAudios(
                    offlineAudios,
                    filteredAudios,
                );

                await bulkUpdateAudios(newOfflineAudios);
            }
        },
        [bulkUpdateAudios, refetchOfflineAudios],
    );

    const createUpdatedOfflineAudios = (
        offlineAudios: AudioDexie[],
        filteredAudios: AudiosAndGifts[],
    ) => {
        const offlineAudMap = keyBy(offlineAudios, (x) => x.id);
        const newOfflineAudios: AudioDexie[] = [];

        filteredAudios.forEach((v) => {
            const offlineId = createOfflineIDBID(config.user.memberId, v.nid);
            const media = v.media;
            if (media?.length > 1) {
                media.forEach((m) => {
                    const partNo = m.position;
                    const currAud = offlineAudMap[`${offlineId}-${partNo}`];
                    if (currAud) {
                        newOfflineAudios.push({
                            ...currAud,
                            description: v.description,
                            media_title: m.media_title,
                            name: v.title,
                            release_date: v.release_date,
                            position: m.position,
                            no_of_files: media.length,
                        });
                    }
                });
            } else {
                const currAud = offlineAudMap[offlineId];
                newOfflineAudios.push({
                    ...currAud,
                    description: v.description,
                    name: v.title,
                    release_date: v.release_date,
                });
            }
        });

        return newOfflineAudios;
    };

    return {
        syncOfflineAudios,
    };
};
