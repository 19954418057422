import { FBCourseStats, FBStepStats } from "JS/Models/Firebase/Courses";
import { courseFirebaseService } from "./CourseService";

export const observeStepStats = (
    courseSkuId: string,
    stepSkuId: string,
    onObserve: (stats: FBStepStats) => void,
) => {
    courseFirebaseService.observeStepStats(courseSkuId, stepSkuId, onObserve);
};

export const observeCourseStats = (
    sku: string,
    onObserve: (stats: FBCourseStats) => void,
) => {
    courseFirebaseService.observeCourseStats(sku, onObserve);
};
