import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BlackRecommendationsState {
    firstStart: boolean;
}

const initialState: BlackRecommendationsState = {
    firstStart: true,
};

export const blackRecommendationsStateSlice = createSlice({
    name: "blackRecommendationsState",
    initialState,
    reducers: {
        setGlobalBlackRecommendations: (
            state,
            data: PayloadAction<Partial<BlackRecommendationsState>>,
        ) => {
            state = {
                ...state,
                ...data.payload,
            };
            return state;
        },
        resetBlackRecommendationsState: () => {
            return initialState;
        },
    },
});
export const { setGlobalBlackRecommendations, resetBlackRecommendationsState } =
    blackRecommendationsStateSlice.actions;
