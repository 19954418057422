import { config } from "JS/Config";
import { VideoContent } from "JS/Models";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";

export class VideoService extends BaseService {
    async getVideosIndex() {
        const formData = new FormData();

        formData.append("access_token", `${config?.accessToken}`);

        return (
            await this.doXHR<AppResponse<VideoContent[]>>({
                url: this.routes.server.api.videos.getVideosIndex(),
                method: "POST",
                data: formData,
            })
        ).data;
    }
}
