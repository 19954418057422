import AWS from "aws-sdk";
import { config } from "JS/Config";
import { GetObjectRequest } from "aws-sdk/clients/s3";

export class EventsHelper {
    public folder = config.s3.events.folder;
    public imagePath = config.s3.events.imagePath;
    public bucket = config.s3.events.bucketName;

    public static ONE_DAY = 24 * 60 * 60 * 1000;

    public getS3(): AWS.S3 {
        return new AWS.S3();
    }

    public getParamsS3(params): AWS.S3 {
        return new AWS.S3(params);
    }

    public initConfig() {
        AWS.config.update({
            credentials: {
                accessKeyId: config.s3.accessKey,
                secretAccessKey: config.s3.secretAccessKey,
            },
            region: config.s3.region,
        });
    }

    public primaryTicketPromise(
        folder: string,
    ): Promise<AWS.S3.GetObjectOutput> {
        return new Promise((res, _) => {
            this.getS3().getObject(
                this.primaryTicketParams(folder),
                (err, data) => {
                    if (err) res(null);
                    res(data);
                },
            );
        });
    }

    public secondaryTicketPromise(
        folder: string,
    ): Promise<AWS.S3.GetObjectOutput> {
        return new Promise((res, _) => {
            this.getS3().getObject(
                this.secondaryTicketParams(folder),
                (err, data) => {
                    if (err) res(null);
                    res(data);
                },
            );
        });
    }

    private primaryTicketParams(folderName: string): GetObjectRequest {
        return {
            Bucket: this.bucket,
            Key: `${folderName}/${config.user.memberId}.json`,
            ResponseCacheControl: 'no-cache',
        };
    }

    private secondaryTicketParams(folderName: string): GetObjectRequest {
        return {
            Bucket: this.bucket,
            Key: `${folderName}/${config.user.memberId}2.json`,
            ResponseCacheControl: 'no-cache',
        };
    }
}
