import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DownloadCountState {
    downloadClickCount: number;
    showDialog: boolean;
}

export const initialState: DownloadCountState = {
    downloadClickCount: 0,
    showDialog: true,
};

export const downloadCountStateSlice = createSlice({
    name: "downloadCountState",
    initialState,
    reducers: {
        setGlobalDownloadCount: (
            state,
            data: PayloadAction<DownloadCountState>,
        ) => {
            state.downloadClickCount = data.payload.downloadClickCount;
            state.showDialog = data.payload.showDialog;

            return state;
        },
        resetGlobalDownloadClickCount: (state) => {
            state.downloadClickCount = initialState.downloadClickCount;
            return state;
        },
        resetGlobalDownloadCount: () => {
            return initialState;
        },
    },
});
export const {
    setGlobalDownloadCount,
    resetGlobalDownloadCount,
    resetGlobalDownloadClickCount,
} = downloadCountStateSlice.actions;
