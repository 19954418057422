import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Theme,
    InternalStandardProps,
    Grid,
    Box,
    ListItem,
    ListItemButton,
    CircularProgress,
    Typography,
    Dialog,
    DialogContent,
    DialogProps,
    IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppTypography } from "../AppTypography";
import { AppSearchBar } from "../AppSearchBar";
import { AppCheckboxList } from "../AppCheckboxList";
import { Members } from "JS/Models";
import { Icon, Icons } from "../Icon";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            boxShadow: `0px -8px 10px ${theme.palette.grey[200]}`,
            minWidth: "100%",
            minHeight: `calc(95vh - (${theme.footer.height} + ${theme.header.height} + 30px))`,
            border: `1px solid ${theme.palette.grey[200]}`,
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        icon: {
            cursor: "pointer",
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        mainContent: {
            padding: "0 !important",
        },
        topContent: {
            padding: theme.spacing(2),
        },
        contentHeaderItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        membersWrapper: {
            background: theme.palette.grey[900],
            color: theme.palette.grey[200],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        hideVisibility: {
            visibility: "hidden",
        },
        btnOkWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        btnOkay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `1px solid ${theme.palette.grey[200]}`,
            color: theme.palette.common.black,
        },
        warpperChkBoxList: {
            overflow: "auto",
            height: `calc(100vh - (${theme.footer.height} + ${theme.header.height} + 200px))`,
            backgroundColor: theme.palette.background.default,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        singleSelectText: {
            marginLeft: "10px",
            fontSize: "14px",
        },
        dialogContentWarpper: {
            backgroundColor: theme.palette.background.default,
        },
    }),
);

export interface SelectMembersDialogProps
    extends InternalStandardProps<DialogProps> {
    onClose: () => void;
    onSend?: () => void;
    selectedMembers: Members[];
    checkboxList?: Members[];
    searchable: boolean;
    searchQuery: string;
    setSearchQuery: (value: string) => void;
    onToggleItemSelect: (value: string) => void;
    searchPlaceholder: string;
    giftSendLoading?: boolean;
    availableQuantity?: number;
    singleSelect?: boolean;
}

export const SelectMembersDialog = (props: SelectMembersDialogProps) => {
    const classes = useStyles(props);
    const {
        className,
        searchable,
        setSearchQuery,
        searchPlaceholder,
        searchQuery,
        onClose,
        checkboxList,
        selectedMembers,
        onToggleItemSelect,
        giftSendLoading,
        availableQuantity,
        singleSelect,
        onSend,
        ...rest
    } = props;

    return (
        <Dialog fullWidth className={clsx(className, classes.root)} {...rest}>
            <Box className={classes.dialogContentWarpper}>
                <DialogContent className={classes.mainContent}>
                    <Grid container className={classes.topContent}>
                        <Grid
                            item
                            xs={12}
                            className={classes.contentHeaderItem}
                        >
                            {singleSelect ? (
                                <Box
                                    display={"flex"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                >
                                    <Icon icon={Icons.alertIcon} />
                                    <AppTypography
                                        className={clsx(
                                            classes.grey600,
                                            classes.singleSelectText,
                                        )}
                                        variant="body1"
                                    >
                                        Select One Member Only
                                    </AppTypography>
                                </Box>
                            ) : (
                                <Box
                                    display={"flex"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                >
                                    {availableQuantity ||
                                    availableQuantity === 0 ? (
                                        <span
                                            className={classes.membersWrapper}
                                        >
                                            {availableQuantity > 99
                                                ? "99+"
                                                : availableQuantity}
                                        </span>
                                    ) : (
                                        <span
                                            className={classes.membersWrapper}
                                        >
                                            {selectedMembers &&
                                                selectedMembers.length}
                                        </span>
                                    )}
                                    <AppTypography
                                        justifySelf={"center"}
                                        className={clsx(classes.grey600)}
                                        variant="body1"
                                    >
                                        Available to Send
                                    </AppTypography>
                                </Box>
                            )}
                            <IconButton aria-label="Close" onClick={onClose}>
                                <FontAwesomeIcon
                                    className={classes.icon}
                                    icon={["fas", "xmark"]}
                                />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <AppSearchBar
                                className={clsx(
                                    !searchable && classes.hideVisibility,
                                )}
                                value={searchQuery}
                                onChange={(e) => {
                                    if (setSearchQuery) {
                                        setSearchQuery(e.target.value);
                                    }
                                }}
                                placeholder={searchPlaceholder}
                                elementId="select-member-search"
                                onCancel={() => {
                                    if (setSearchQuery) {
                                        setSearchQuery("");
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={classes.warpperChkBoxList}
                        >
                            <AppCheckboxList
                                checkboxList={checkboxList}
                                selectedMembers={selectedMembers}
                                onToggleItemSelect={onToggleItemSelect}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} className={classes.btnOkWrapper}>
                            <ListItem key="btnOk" disablePadding>
                                <ListItemButton
                                    disabled={giftSendLoading}
                                    className={classes.btnOkay}
                                    onClick={onSend}
                                >
                                    {!giftSendLoading ? (
                                        <Typography variant="body1">
                                            SEND
                                        </Typography>
                                    ) : (
                                        <CircularProgress
                                            className={classes.spinner}
                                        />
                                    )}
                                </ListItemButton>
                            </ListItem>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Box>
        </Dialog>
    );
};
