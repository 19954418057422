import { BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { messaging } from "JS/Helpers/UserMessaging";
import { PaginationInfo } from "JS/Types/Pagination";
import { useCallback, useRef } from "react";
import { AppTypography } from "../Components/AppTypography";

export interface InfiniteScrollManagerProps
    extends InternalStandardProps<BoxProps> {
    children: React.ReactNode;
    paginationInfo?: PaginationInfo;
    onLastContentHit?: () => void;
}

export const InfiniteScrollManager = (props: InfiniteScrollManagerProps) => {
    const classes = useStyles(props);

    const {
        className,
        children,
        paginationInfo,
        onLastContentHit = () => {},
        ...rest
    } = props;

    const observer = useRef<IntersectionObserver>();

    const lastElementRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    onLastContentHit();
                }
            });
            if (node) observer.current.observe(node);
        },
        [onLastContentHit],
    );

    return (
        <>
            {children}

            {paginationInfo && paginationInfo.perPage < paginationInfo.total && (
                <div className={classes.navLoadMore} ref={lastElementRef}>
                    <AppTypography variant="body1">
                        {messaging.pagination.loadMore}
                    </AppTypography>
                </div>
            )}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        navLoadMore: {
            backgroundColor: theme.palette.primary.main,
            color: theme.colors.white,
            height: `calc(${theme.footer.height} - 40px)`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    }),
);
