import {
    ValidityState,
    replaceError,
    getInitialValidityState,
    addErrorInValidity,
} from "JS/Types";
import { isNumber } from "./Number";
import { toNumber } from "./Utilities";

export enum ValidationTypes {
    REQUIRED = "required",
    EMAIL = "email",
    REGEX = "regex",
    MAX_VALUE = "max_value",
    MIN_VALUE = "min_value",
    NUMBER = "number",
}

export interface InputValidationRule {
    rule: ValidationTypes;
    message?: string;
    options?: any;
}

export type ValidationRules<T extends string> = Partial<
    Record<T, InputValidationRule[]>
>;

export type ErrorsObj<T extends string> = Partial<Record<T, boolean>>;

const validators: { [index in ValidationTypes]?: RuleDefinition } = {
    [ValidationTypes.EMAIL]: {
        validate: (str: string) => {
            const mailFormat = new RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
            if (str && mailFormat.exec(str)) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message) =>
            message ? message : `Entered email is invalid`,
    },

    [ValidationTypes.REQUIRED]: {
        validate: (value) =>
            !!(
                value !== undefined &&
                value !== null &&
                value.toString().trim().length > 0
            ),
        formatMessage: (message, field: string) => {
            return message ? message : `${field} is required`;
        },
    },

    [ValidationTypes.REGEX]: {
        validate: (str: string, options: any) => {
            const exp: RegExp = options.regex;
            if (exp.test(str)) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, field: string, value, { value: len }) =>
            message ? message : `${field} does not match required input syntax`,
    },
    [ValidationTypes.MIN_VALUE]: {
        validate: (value: any, options) => {
            const required = validators[ValidationTypes.REQUIRED].validate(
                value,
                options,
            );
            const { value: maxLimit = 0 } = options;
            if (!required || (isNumber(value) && toNumber(value) >= maxLimit)) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message, field: string, value, { limit = 0 } = {}) =>
            message ? message : `${field} can't be less than ${limit}`,
    },
    [ValidationTypes.MAX_VALUE]: {
        validate: (value: any, options) => {
            const requiredPass = validators[ValidationTypes.REQUIRED].validate(
                value,
                options,
            );
            const { value: limit = 0 } = options;
            if (
                !requiredPass ||
                (isNumber(value) && toNumber(value) <= limit)
            ) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (
            message,
            field: string,
            value,
            { limit = Infinity } = {},
        ) => (message ? message : `${field} can't be greater than ${limit}`),
    },
    [ValidationTypes.NUMBER]: {
        validate: (str: any, options: any) => {
            const requiredPass = validators[ValidationTypes.REQUIRED].validate(
                str,
                options,
            );
            if (!requiredPass || isNumber(str)) {
                return true;
            } else {
                return false;
            }
        },
        formatMessage: (message) => (message ? message : `Not a number`),
    },
};

export interface RuleDefinition {
    validate: (value: any, options: any) => boolean;
    formatMessage: (
        message: string,
        fieldName: string,
        value: any,
        options: any,
    ) => string;
}

export function applyValidationRules(
    state: ValidityState,
    rules: InputValidationRule[],
    value: any,
    field: string,
): ValidityState {
    let isValid = true;
    rules.forEach((rule) => {
        const options = rule.options || {};
        const result = !validators[rule.rule].validate(value, options);

        if (result) {
            isValid = false;
            state = addErrorInValidity(state, field, {
                code: rule.rule,
                message: rule.message
                    ? rule.message
                    : validators[rule.rule].formatMessage(
                          rule.message,
                          field,
                          value,
                          options,
                      ),
            });
        }
    });
    if (isValid) {
        state = replaceError(state, field, null);
    }
    return state;
}

export function getInitialValidityFromRules(
    rules: ValidationRules<string>,
    values: { [index: string]: any },
    state = getInitialValidityState([], []),
) {
    for (const k of Object.keys(rules)) {
        state = applyValidationRules(state, rules[k], values[k], k);
    }
    return state;
}
