import { useCallback, useEffect, useState } from "react";
import * as videoIndexDb from "JS/Database/Video";
import { VideoDexie } from "JS/Database/Dexie";
import { config } from "JS/Config";

export const useVideoIndexFromDb = (skip: boolean = false) => {
    const [loading, setLoading] = useState(false);
    const [memberVideos, setMemberVideos] = useState<VideoDexie[]>([]);

    const refetch = useCallback(async () => {
        setLoading(true);
        try {
            const videos = await videoIndexDb.getVideosIndex();
            const memberVid = videos.filter((d) =>
                d.id.startsWith(config.user.memberId),
            );
            setMemberVideos(memberVid);
            return videos;
        } catch (err) {
            return null;
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!skip) refetch();
    }, [skip]);

    return {
        memberVideos,
        loading,
        refetch,
    };
};

export const useBulkUpdateVideosDB = () => {
    const bulkUpdateVideos = useCallback(async (videos: VideoDexie[]) => {
        try {
            const id = await videoIndexDb.bulkUpdateVideos(videos);
            return id;
        } catch (err) {
            return null;
        }
    }, []);

    return {
        bulkUpdateVideos,
    };
};
