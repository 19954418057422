import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Gifts, ResumeVideoContent, VideoContent } from "JS/Models";
import {
    AudioVideoResume,
    PlayedAudiosVideos,
} from "JS/Models/Firebase/Resume";

export interface VideoState {
    catagoriesList?: VideoContent[];
    videosList?: VideoContent[];
    resumeVideos?: ResumeVideosMap;
    playedVideos: PlayedVideosMap;
    isUpdateRequired?: boolean;
}

export type PlayedVideosMap = {
    [key: string]: (VideoContent | Gifts | PlayedAudiosVideos)[];
};

export type ResumeVideosMap = {
    [key: string]: (ResumeVideoContent | AudioVideoResume)[];
};

const initialState: VideoState = {
    catagoriesList: [],
    videosList: [],
    playedVideos: {},
    resumeVideos: {},
    isUpdateRequired: false,
};

export const videoStateSlice = createSlice({
    name: "videoState",
    initialState,
    reducers: {
        setGlobalVideos: (state, data: PayloadAction<VideoState>) => {
            state.catagoriesList = data.payload.catagoriesList;
            state.videosList = data.payload.videosList;
            state.playedVideos = data.payload.playedVideos;
            state.isUpdateRequired = false;
            return state;
        },
        setGlobalUserResumeVideos: (
            state,
            data: PayloadAction<ResumeVideosMap>,
        ) => {
            state = { ...state, resumeVideos: data.payload };
            return state;
        },
        setGlobalPlayedVideos: (
            state,
            data: PayloadAction<PlayedVideosMap>,
        ) => {
            state = { ...state, playedVideos: { ...data.payload } };
            return state;
        },
        resetVideoState: (state) => {
            return initialState;
        },
        setVideoUpdateRequired: (state) => {
            return {
                ...state,
                isUpdateRequired: true,
            };
        },
    },
});

export const {
    setGlobalVideos,
    setGlobalPlayedVideos,
    resetVideoState,
    setVideoUpdateRequired,
    setGlobalUserResumeVideos,
} = videoStateSlice.actions;
