import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AudioContent, VideoContent } from "JS/Models";

export interface FavoritesState {
    favoritesAudios?: AudioContent[];
    favoritesVideos?: VideoContent[];
}

const initialState: FavoritesState = {
    favoritesAudios: [],
    favoritesVideos: [],
}

export const favoritesStateSlice = createSlice({
    name: "favoritesState",
    initialState,
    reducers: {
        setGlobalFavorites: (state, data: PayloadAction<FavoritesState>) => {
            state = {
                ...state, ...data.payload
            }
            return state;
        },
        resetFavoritesState: () => {
            return initialState;
        },
    },
});
export const { setGlobalFavorites, resetFavoritesState } = favoritesStateSlice.actions;