import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Event } from "JS/Models/Events";

export interface EventState {
    eventsList?: Event[];
}

const initialState: EventState = {
    eventsList: [],
};

export const eventStateSlice = createSlice({
    name: "eventState",
    initialState,
    reducers: {
        setGlobalEvents: (state, data: PayloadAction<EventState>) => {
            state.eventsList = data.payload.eventsList;
            return state;
        },
        resetEventsState: () => {
            return initialState;
        },
    },
});
export const { setGlobalEvents, resetEventsState } = eventStateSlice.actions;
