import { config } from "JS/Config";
import { S3Helper } from "JS/Helpers";
import { RecommendationBatch, Recommendations } from "JS/Models";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";
import { messaging } from "JS/Helpers/UserMessaging";

export class RecommendationService extends BaseService {
    async getRecommendedAudios() {
        if (!config)
            return Promise.reject(
                new Error(messaging.apiError.somethingWentWrong),
            );

        return (
            await this.doXHR<AppResponse<Recommendations[]>>({
                url: this.routes.server.api.recommendations.getRecommendations(
                    config?.user?.memberId,
                    "MP3",
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async getS3AudioRecommendationBatch(): Promise<RecommendationBatch> {
        const s3Sdk = new S3Helper().sdk;
        return new Promise((res, rej) => {
            if (!config?.s3) {
                rej(new Error(messaging.apiError.somethingWentWrong));
            }
            const params = {
                Bucket: config?.s3?.recommendations?.bucketName,
                Key: config?.s3?.recommendations?.key,
                ResponseCacheControl: "no-cache",
            };

            s3Sdk.getObject(params, (err, data) => {
                if (err) {
                    rej(err);
                } else {
                    const parsed = JSON.parse(data?.Body?.toString());
                    res(parsed);
                }
            });
        });
    }
}
