import * as Sentry from "@sentry/react";
import { config } from "JS/Config";
import { BrowserTracing } from "@sentry/tracing";
import { isBetaEnvironment } from "./Helpers";

export const sentryInit = () => {
    Sentry.init({
        dsn: config.sentry.dsn,
        integrations: [new BrowserTracing()],
        environment: config.sentry.environment,
        tracesSampleRate: config.sentry.traceSampleRate,
        release:
            isBetaEnvironment()
                ? config.betaAppVersion
                : config.appVersion,
        attachStacktrace: true,
    });
};

export const initializeSentryUser = () => {
    Sentry.setUser({
        id: config.user.memberId,
    });
};

export const captureSentryError = (
    error: Error,
    tags?: { [key: string]: string },
) => {
    Sentry.captureException(error, {
        tags: tags,
    });
};
