import {
    checkHoursPassed,
    checkIfBatchAllowsNotify,
    checkIfBatchIncludesIbos,
} from "JS/Helpers";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import {
    useGlobalBlackRecommendations,
    useGlobalRecommendations,
    useOpenAutoPopup,
    useRecommendations,
    useRecommendationsBatch,
} from "JS/React/Hooks/Recommendations";
import { useCallback, useEffect } from "react";

const HOURS_TO_CHECK = 2;

export interface RecommendationsBatchManagerProps {
    children: React.ReactNode;
}

export const RecommendationsBatchManager = (
    props: RecommendationsBatchManagerProps,
) => {
    const { children } = props;
    const { loading, loadingMessage } = useHandlers();

    return (
        <>
            {!loading ? (
                <>{children}</>
            ) : (
                <AppBackdropProgress open backdropText={loadingMessage} />
            )}
        </>
    );
};

const useHandlers = () => {
    const {
        refetch: refetchBatch,
        batch,
        loading: loadingBatch,
    } = useRecommendationsBatch();
    const { refetch: refetchRecommendations, loading: loadingRecommendations } =
        useRecommendations(true);

    const { recommendationState, setGlobalRecommendations } =
        useGlobalRecommendations();

    const { openPopup } = useOpenAutoPopup();

    const onRefresh = async () => {
        const timePassed = recommendationState?.lastFetched
            ? checkHoursPassed(recommendationState?.lastFetched, HOURS_TO_CHECK)
            : true;

        if (!recommendationState.batch && timePassed) {
            const apiBatch = await refetchBatch();
            const includesIbo = checkIfBatchIncludesIbos(apiBatch);
            const allowsNotify = checkIfBatchAllowsNotify(apiBatch);

            if (
                !recommendationState.recommendations &&
                includesIbo &&
                allowsNotify
            ) {
                await refetchRecommendations();
            }
        }
    };

    const onRefetchBatch = () => {
        refetchBatch().then(async (apiBatch) => {
            const localBatch = recommendationState?.batch;

            if (localBatch) {
                const batchChanged = localBatch?.batchId !== apiBatch?.batchId;
                const includesIbo = checkIfBatchIncludesIbos(apiBatch);
                const allowsNotify = checkIfBatchAllowsNotify(apiBatch);

                if (batchChanged && includesIbo && allowsNotify) {
                    refetchRecommendations().then(() => {
                        setGlobalRecommendations({
                            noOfPopups: 0,
                        });

                        openPopup(true);
                    });
                }
            }
        });
    };

    useVisibilityChange(onRefetchBatch);
    useFirstStart(onRefetchBatch);

    useEffect(() => {
        onRefresh();
    }, []);

    return {
        batch,
        loading: loadingRecommendations || loadingBatch,
        loadingMessage: loadingBatch
            ? messaging.loader.data
            : messaging.loader.recommendations,
    };
};

const useVisibilityChange = (refetchBatch: () => void) => {
    const { recommendationState } = useGlobalRecommendations();

    const onToggleVisibility = useCallback(() => {
        const state = document.visibilityState;

        if (state === "visible") {
            if (
                recommendationState?.batch &&
                checkHoursPassed(
                    recommendationState?.lastFetched,
                    HOURS_TO_CHECK,
                )
            ) {
                refetchBatch();
            }
        }
    }, [recommendationState?.batch, recommendationState?.lastFetched]);

    useEffect(() => {
        document.addEventListener("visibilitychange", onToggleVisibility);

        return () =>
            document.removeEventListener(
                "visibilitychange",
                onToggleVisibility,
            );
    }, [onToggleVisibility]);
};

const useFirstStart = (refetchBatch: () => void) => {
    const { blackRecommendationState } = useGlobalBlackRecommendations();
    const { recommendationState } = useGlobalRecommendations();

    useEffect(() => {
        if (blackRecommendationState?.firstStart) {
            /**Anything here will run on application restart */
            if (
                recommendationState?.batch &&
                checkHoursPassed(
                    recommendationState?.lastFetched,
                    HOURS_TO_CHECK,
                )
            ) {
                refetchBatch();
            }
        }
    }, [blackRecommendationState?.firstStart]);
};
