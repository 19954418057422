import { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { setGlobalEvents } from "JS/Redux/Event";
import { TicketError, TicketsService } from "JS/Services/Events";
import { Event } from "JS/Models/Events";
import { messaging } from "JS/Helpers/UserMessaging";
import { sortArrayByKey } from "JS/Helpers";

export const service = new TicketsService();

export const useGetEvents = (skip?: boolean) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<Event[]>([]);
    const [isErrorNoEvents, setErrorNoEvents] = useState(false);
    const [isErrorNoRegistered, setErrorNoRegistered] = useState(false);

    const dispatch = useAppDispatch();

    const refetch = useCallback(() => {
        setLoading(true);
        return service
            .getEvents()
            .then(async (res) => {
                const sortedEvents = sortArrayByKey(res, "EventName", "ASC");
                dispatch(
                    setGlobalEvents({
                        eventsList: sortedEvents,
                    }),
                );
                setResponse(sortedEvents);
                return sortedEvents;
            })
            .catch((err) => {
                setErrorNoEvents(err === TicketError.NoEvents);
                setErrorNoRegistered(err === TicketError.NoneRegistered);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        response,
        loading,
        isErrorNoEvents,
        isErrorNoRegistered,
    };
};

export const useGetBarCode = (userId: string, skip?: boolean) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<string>("");

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback((id: string) => {
        setLoading(true);

        return service
            .getBarCode(id)
            .then(async (res) => {
                if (!res?.status) {
                    enqueueSnackbar(res.message, {
                        variant: "warning",
                    });
                    setResponse(res.data);
                } else {
                    setResponse(res.data);
                }
                return res;
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });

                return {
                    data: "",
                    status: err.toJSON().status,
                    message: err.message,
                };
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip && userId) {
            refetch(userId);
        }
    }, [skip, userId]);

    return {
        refetch,
        response,
        loading,
    };
};

export const useGetPdfBlob = (url: string, skip?: boolean) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<Blob>(null);

    const refetch = useCallback((path: string) => {
        setLoading(true);

        return service
            .getPdfBlob(path)
            .then(async (res) => {
                setResponse(res);
                return res;
            })
            .catch((err) => {
                return new Blob([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip && url) {
            refetch(url);
        }
    }, [skip, url]);

    return {
        refetch,
        response,
        loading,
    };
};

export const useGetProfileImage = (userId: string, skip?: boolean) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<boolean>(false);

    const refetch = useCallback((id: string) => {
        setLoading(true);
        return service
            .getProfImageByS3(id)
            .then(async (res: boolean) => {
                if (res) {
                    setResponse(res);
                    return res;
                } else {
                    setResponse(res);
                }
            })
            .catch((err) => {
                setResponse(false);
                return false;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip && userId) {
            refetch(userId);
        }
    }, [skip, userId]);

    return {
        refetch,
        response,
        loading,
    };
};

export const useSetProfileImage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    const refetch = useCallback(
        async (file: any, fileName: string, isPrimaryUser: number) => {
            setLoading(true);
            return service
                .getS3PostFileUrl(fileName, isPrimaryUser)
                .then(async (res) => {
                    const apiResponse = res.response;
                    if (!apiResponse.status) {
                        enqueueSnackbar(apiResponse.message, {
                            variant: "warning",
                        });
                        setResponse(apiResponse.status);
                        return false;
                    } else {
                        if (apiResponse.data) {
                            return service
                                .setProfilePhotoInS3(file, apiResponse.data)
                                .then((result) => {
                                    if (result.status === 200) {
                                        enqueueSnackbar(
                                            messaging?.events
                                                ?.uploadedsuccesfully,
                                            {
                                                variant: "success",
                                            },
                                        );
                                        setResponse(apiResponse.status);
                                        return true;
                                    } else {
                                        setResponse(false);
                                        return false;
                                    }
                                })
                                .catch((err) => {
                                    setResponse(false);
                                    enqueueSnackbar(err.message, {
                                        variant: "error",
                                    });
                                    return false;
                                });
                        } else {
                            setResponse(false);
                            return false;
                        }
                    }
                })
                .catch((err) => {
                    setResponse(false);
                    enqueueSnackbar(err.message, {
                        variant: "error",
                    });
                    return false;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        refetch,
        response,
        loading,
    };
};

export const useGlobalEvents = () => {
    const dispatch = useAppDispatch();

    const allData = useAppSelector((state) => state.events);

    return {
        events: useAppSelector((state) => state.events)?.eventsList,
        setGlobalEvents: (events: Event[]) =>
            dispatch(
                setGlobalEvents({
                    ...allData,
                    eventsList: events,
                }),
            ),
    };
};
