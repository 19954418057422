import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    BoxProps,
    IconButton,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { QuizFooter } from "../Components/QuizFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppCompletionProgress } from "JS/React/Components/AppCompletionProgress";
import { QuizQuestion } from "../Components/QuizQuestion";
import {
    CourseDetail,
    CourseQuizOption,
    CourseStep,
    EventActions,
    EventCategories,
    EventNames,
} from "JS/Models";
import { FBFunctionStepStats, FBStepStats, StepActions } from "JS/Models/Firebase/Courses";
import { QuizResult } from "../Components/QuizResult";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import { AppButton } from "JS/React/Components/AppButton";
import { CourseLogFirebaseEventSettings } from "./CourseStep";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
        },
        footer: {
            background: theme.palette.grey[200],
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "90vh",
        },
    }),
);

export interface QuizStepProps extends InternalStandardProps<BoxProps> {
    step: CourseStep;
    courseSku: string;
    course: CourseDetail;
    stepDetail: Partial<FBStepStats>;
    goBackToIndex: () => void;
    onQuizStepCompleted: () => Promise<void>;
    onQuizStepFinished: () => void;
    updateStepStats: (stats: Partial<FBFunctionStepStats>, action: StepActions, refetch?: boolean) => Promise<void>;
    onStepOpen: () => Promise<void>;
    handleLogFirebaseEvents: (
        type: "course" | "step" | "question",
        settings: CourseLogFirebaseEventSettings,
    ) => void;
}

export const QuizStep = (props: QuizStepProps) => {
    const classes = useStyles(props);
    const {
        className,
        goBackToIndex,
        step,
        stepDetail,
        courseSku,
        course,
        updateStepStats,
        onQuizStepFinished,
        onQuizStepCompleted,
        onStepOpen,
        handleLogFirebaseEvents,
        ...rest
    } = props;
    const initialAnswer = {
        position: -1,
        description: "",
        is_correct: null,
    };
    const initialTexts = {
        title: null,
        subTitle: null,
    };

    const [position, setPosition] = useState(0);
    const [answer, setAnswer] = useState<CourseQuizOption>(initialAnswer);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [showScore, setShowScore] = useState<boolean>(null);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [shouldFinish, setShouldFinish] = useState(false);
    const [submitAction, setSubmitAction] = useState<
        "Submit" | "Next Question" | "View Result"
    >("Submit");
    const [texts, setTexts] = useState<{
        title: string;
        subTitle: string;
    }>(initialTexts);

    const firstTime = useRef(true);

    const activeQuestion = step.quiz.find((x) => x.position === position);
    const correctOption = activeQuestion?.options.find(
        (x) => x.is_correct === true,
    );

    useEffect(() => {
        if (stepDetail && firstTime.current) {
            setShowScore(false);
            if (stepDetail && stepDetail?.quiz?.result) {
                const nextPos =
                    stepDetail?.quiz?.result.correct_answers +
                    stepDetail?.quiz?.result.wrong_answers;
                const nextQuestion = step.quiz.find(
                    (x) => x.position === nextPos,
                );

                if (nextQuestion) {
                    setPosition(nextPos);
                    setShowScore(false);
                } else {
                    setShowScore(true);
                    setShowResult(true);
                }
            }
            firstTime.current = false;
        }
        if (!stepDetail) {
            setShowScore(false);
        }
    }, [stepDetail]);

    const onSubmitClick = async () => {
        if (submitAction === "Submit") {
            if (course.content_item_type_name === "Course") {
                handleLogFirebaseEvents("question", {
                    eventNames: EventNames.COURSE_STEP_QUESTION_COMPLETED,
                    action: EventActions.QUESTION_COMPLETED,
                    category: EventCategories.COURSE_STEP,
                    questionId: +activeQuestion.id,
                    questionPos: activeQuestion.position,
                });
            }
            if (course.content_item_type_name === "GiftCourse") {
                handleLogFirebaseEvents("question", {
                    eventNames: EventNames.GIFT_COURSE_STEP_QUESTION_COMPLETED,
                    action: EventActions.QUESTION_COMPLETED,
                    category: EventCategories.GIFT_COURSE_STEP,
                    questionId: +activeQuestion.id,
                    questionPos: activeQuestion.position,
                });
            }
            setSubmitted(true);
            const options = {};
            activeQuestion.options.forEach((d) => {
                options[d.position] = d.description;
            });



            const toSendStats: Partial<FBFunctionStepStats> = {
                ...stepDetail,
                quiz: {
                    ...stepDetail.quiz,
                    questions: {
                        ...stepDetail.quiz.questions,
                        [`q${activeQuestion.id}`]: {
                            correct_answer: correctOption.position,
                            user_answer: answer.position,
                            options: options,
                            position: activeQuestion.position,
                            question: activeQuestion.question,
                        },
                    },
                },
            };

            const oldRes = toSendStats.quiz.result;

            if (answer.position === correctOption.position) {
                toSendStats.quiz.result = {
                    ...oldRes,
                    correct_answers: oldRes.correct_answers + 1,
                };
                setTexts({
                    ...texts,
                    title: "Correct!",
                });
            } else {
                toSendStats.quiz.result = {
                    ...oldRes,
                    wrong_answers: oldRes.wrong_answers + 1,
                };
                setTexts({
                    title: "Correct Answer:",
                    subTitle: correctOption.description,
                });
            }
            delete toSendStats.quiz.content_type;
            await updateStepStats(toSendStats, StepActions.QuizInProgress);
            const nextPosition = position + 1;

            const nextQuestion = step?.quiz?.find(
                (x) => x.position === nextPosition,
            );

            if (!!nextQuestion) {
                setSubmitAction("Next Question");
            } else {
                onQuizStepCompleted();
                setSubmitAction("View Result");
            }
        }

        if (submitAction === "Next Question") {
            setSubmitAction("Submit");
            setSubmitted(false);
            setPosition(position + 1);
            setAnswer(initialAnswer);
            setTexts(initialTexts);
        }

        if (submitAction === "View Result") {
            setShowScore(true);
            setShouldFinish(true);
        }
    };

    useEffect(() => {
        onStepOpen();
        if (course.content_item_type_name === "Course") {
            handleLogFirebaseEvents("step", {
                action: EventActions.STARTED,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_STARTED,
            });
            handleLogFirebaseEvents("step", {
                action: EventActions.QUIZ_OPENED,
                category: EventCategories.COURSE_STEP,
                eventNames: EventNames.COURSE_STEP_QUIZ_OPENED,
            });
        }
        if (course.content_item_type_name === "GiftCourse") {
            handleLogFirebaseEvents("step", {
                action: EventActions.STARTED,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_STARTED,
            });
            handleLogFirebaseEvents("step", {
                action: EventActions.QUIZ_OPENED,
                category: EventCategories.GIFT_COURSE_STEP,
                eventNames: EventNames.GIFT_COURSE_STEP_QUIZ_OPENED,
            });
        }
    }, []);

    const toggleShowResult = () => {
        setShowResult(!showResult);
    };

    return (
        <>
            {!!stepDetail && (
                <>
                    <Box {...rest} className={clsx(className, classes.root)}>
                        <Box display="flex" justifyContent={"space-between"}>
                            {showResult && (
                                <AppButton
                                    onClick={() => setShowResult(false)}
                                    buttonVariant="grey-outlined"
                                    rounded
                                >
                                    Score
                                </AppButton>
                            )}
                            {!showResult && <Box />}

                            {!shouldFinish && (
                                <IconButton onClick={goBackToIndex}>
                                    <FontAwesomeIcon
                                        style={{
                                            fontSize: "30px",
                                        }}
                                        className={classes.grey600}
                                        icon={["fas", "xmark"]}
                                    />
                                </IconButton>
                            )}
                        </Box>

                        {showScore != null && !showScore && (
                            <>
                                <AppCompletionProgress
                                    title={`${activeQuestion.position + 1} of ${step.quiz.length
                                        } ${step.quiz.length > 1
                                            ? `Questions`
                                            : `Question`
                                        }`}
                                    value={
                                        ((activeQuestion.position + 1) /
                                            step.quiz.length) *
                                        100
                                    }
                                />

                                {activeQuestion && (
                                    <QuizQuestion
                                        question={activeQuestion}
                                        answer={answer}
                                        setAnswer={setAnswer}
                                        disableOptions={submitted}
                                        handleLogFirebaseEvents={
                                            handleLogFirebaseEvents
                                        }
                                        readOnly={false}
                                        course={course}
                                    />
                                )}
                            </>
                        )}

                        {showScore != null && showScore && (
                            <QuizResult
                                toggleShowResult={toggleShowResult}
                                stats={stepDetail}
                                showResult={showResult}
                                quiz={step.quiz}
                                onFinish={onQuizStepFinished}
                                hideFinishBtn={!shouldFinish}
                            />
                        )}
                    </Box>

                    {showScore != null && !showScore && (
                        <QuizFooter
                            title={texts.title}
                            subTitle={texts.subTitle}
                            btnText={submitAction}
                            disableBtn={answer.position === -1}
                            onBtnClick={onSubmitClick}
                            increaseHeight={texts.title === "Correct Answer:"}
                        />
                    )}
                </>
            )}

            {!stepDetail && (
                <Box className={clsx(classes.wrapperLoader)}>
                    <AppCircularProgress />
                </Box>
            )}
        </>
    );
};
