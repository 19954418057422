import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    CircularProgress,
    Grid,
    GridProps,
    IconButton,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { Layout } from "../Layout";
import { AppHeader } from "JS/React/Components/AppHeader";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
    useReceivedProspectAudioGifts,
    useReceivedMemberVideoGifts,
    useReceivedProspectVideoGifts,
} from "JS/React/Hooks/Gifts";
import { useGlobalAudios, useAudios } from "JS/React/Hooks/Audio";
import { AppButton } from "JS/React/Components/AppButton";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPublicUrl, getSizeOfContent } from "JS/Helpers/S3Helper";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { GraphicEq as GraphicEqIcon } from "@mui/icons-material";
import moment from "moment";
import {
    EventNames,
    EventCategories,
    EventActions,
    FirebaseEventAction,
    Gifts,
    AudioContent,
    VideoContent,
    AllTabs,
} from "JS/Models";
import { GiftCategories } from ".";
import { Icon, Icons } from "JS/React/Components/Icon";
import { useRouting } from "JS/React/Hooks/Routes";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import {
    useCheckContentDownloaded,
    useVerifyAwsKeys,
} from "JS/React/Hooks/Media";
import { messaging } from "JS/Helpers/UserMessaging";
import { useSnackbar } from "notistack";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { usePlaylistCommonHandlers } from "JS/React/Hooks/Playlist";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { InputDialog } from "JS/React/Components/InputDialog";
import { ListDialog } from "JS/React/Components/ListDialog";
import {
    getDownloadedPartsNids,
    isBundleInProgress,
    invalidContentSize,
    sortPlaylists,
} from "JS/Helpers";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import { config } from "JS/Config";
import {
    useGetFavorites,
    useGlobalFavorites,
    useMarkFavorites,
    useUnMarkFavorites,
} from "JS/React/Hooks/Favorites";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import {
    DownloadStatus,
    addToDownloadQueue,
    getDownloadQueueLength,
    isInDownloadQueue,
} from "JS/Helpers/ContentDownloadHelper";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { setDownloadedContentNids } from "JS/Redux/download";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { PLAYER_PROGRESS_INTERVAL, appConstants } from "JS/Helpers/Contants";
import { AddToDownloadOptions, MiniPlayerOptions } from "JS/Models/Common";
import { usePlayerHandlers } from "JS/React/Hooks/MediaPlayer";
import { useDownloadingStatus } from "JS/React/Hooks/Downloading";
import { DownloadDisabled } from "JS/React/Components/DownloadDisabled";
import { DownloadWarningDialog } from "JS/React/Components/DownloadWarningDialog";
import { useMiniPlayerHandlers } from "JS/React/Hooks/MiniPlayer";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import {
    useResumeRecentlyAudio,
    useResumeRecentlyVideo,
} from "JS/React/Hooks/ResumeRecently";
import { getCurrentUrl } from "JS/Helpers/MiniPlayerHelper";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";
import {
    getPlayerPlayedTimeToLog,
    toSentGift,
    toSentGiftType,
} from "JS/Models/Firebase/GiftDashboard";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import { AppTypography } from "JS/React/Components/AppTypography";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";
import qs from "qs";
export interface ReceivedGiftDetailProps
    extends InternalStandardProps<GridProps> {}

export const ReceivedGiftsDetail = (props: ReceivedGiftDetailProps) => {
    const classes = useStyles(props);
    const { className } = props;

    const history = useHistory();
    const params: any = useParams();
    const { homeStack } = useGlobalNavStack();
    const { contentId, categoryId, selectedTab, isAutoPlay } = params;

    const [mediaURL, setMediaURL] = useState("");
    const [queueLimitReached, setQueueLimitReached] = useState(false);
    const [downloadedUrl, setDownloadedUrl] = useState<string>("");
    const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
    const [contentURL, setContentUrl] = useState("");
    const [sizeOfContent, setSizeOfContent] = useState(0);
    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const { audios, isAudioUpdateRequired } = useGlobalAudios();

    const { enqueueSnackbar } = useSnackbar();
    const { loading } = useAudios(
        !!audios && audios.length > 0,
        isAudioUpdateRequired,
    );

    const downloadingQueueLimit =
        appConstants.downloadingQueue.downloadingQueueLimit;
    const {
        favoritesAudios,
        favoritesVideos,
        setGlobalFavoritesAudios,
        setGlobalFavoritesVideos,
    } = useGlobalFavorites();

    const { loading: favoritesLoading, refetch: fetchFavorites } =
        useGetFavorites(!!favoritesAudios && favoritesAudios.length > 0);

    const { markFavoriteByNid, loading: markFavLoading } = useMarkFavorites();
    const { unMarkFavoriteByNid, loading: unMarkFavLoading } =
        useUnMarkFavorites();

    const { logFirebaseEvent } = useFirebaseLogger();

    const {
        memberAudiosLoading,
        memberVideosLoading,
        prospectAudiosLoading,
        prospectVideosLoading,
        giftDetail,
        giftAnalyticsData,
        giftDashboardData,
        isAudioGift,
        isVideoGift,
    } = useReceivedGifts(categoryId, selectedTab, contentId);

    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );

    useCheckContentDownloaded(
        giftDetail?.nid,
        inProgressNid,
        giftDetail?.content_item_type_name === "GiftVideo" || "GiftProsVideo"
            ? "video"
            : "audio",
        setIsDownloaded,
        setDownloadedUrl,
    );

    const {
        addToPlaylistLoading,
        addToPlaylistSuccess,
        createLoading,
        playlistsLoading,
        isCreatePlaylist,
        openPlaylistSelection,
        playlistsInStore,
        createClick,
        handlePlaylistClick,
        addAudio,
        setAnchorPlaylist,
        setCreatePlaylist,
    } = usePlaylistCommonHandlers(giftDetail);
    const { miniPlayer, onClickMiniPlayer } = useMiniPlayerHandlers();

    const fetchMediaUrl = (
        options: {
            media_url_prefix: string;
            media_url_postfix: string;
            media_file_name: string;
        },
        skipThumb: boolean = false,
    ) => {
        const { media_file_name, media_url_postfix, media_url_prefix } =
            options;

        verifyAwsKeys().then(async () => {
            getPublicUrl(media_url_prefix, media_url_postfix, media_file_name)
                .then((signedURL) => setMediaURL(signedURL))
                .catch((err) => console.log(err));
        });
    };

    const resumeRecentlyAud = useResumeRecentlyAudio(giftDetail, contentId);
    const resumeRecentlyVid = useResumeRecentlyVideo(giftDetail, contentId);

    const {
        playerRef,
        handleContentCompleted,
        handleRecentlyPlayed,
        onPlayerProgress,
        onMediaPlayerReady,
        setIsReady,
        isReadyToPlay,
        setIsReadyToPlay,
    } = selectedTab === AllTabs.AUDIOS ? resumeRecentlyAud : resumeRecentlyVid;

    const { handleChangeSeek } = usePlayerHandlers(playerRef);

    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (!!isDownloaded) setIsReady(false);
    }, [isDownloaded]);

    const isBundle = giftDetail?.media.length > 1;

    const isFavoriteContent = () => {
        if (
            categoryId === GiftCategories.RECEIVED_AS_PROSPECT ||
            categoryId === GiftCategories.RECEIVED_AS_MEMBER
        ) {
            if (selectedTab === AllTabs.AUDIOS) {
                if (
                    favoritesAudios &&
                    favoritesAudios.find((x) => x.nid === giftDetail?.nid)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (
                    favoritesVideos &&
                    favoritesVideos.find((x) => x.nid === giftDetail?.nid)
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    };
    const { linkProvider } = useRouting();
    const playClick = () => {
        history.push(
            linkProvider.react.bundle().audios(contentId, categoryId, "true"),
        );
    };

    const getDashboardActionDetails = () => {
        return getPlayerPlayedTimeToLog(playerRef);
    };

    const isReceivedMemberGift =
        isAudioGift(contentId) || isVideoGift(contentId);

    const { logGiftOpen, logGiftCompleted } = useGiftDashboard(
        giftDashboardData,
        toSentGiftType(giftDetail?.content_item_type_name),
        giftDetail?.sender?.member_id,
        isReceivedMemberGift && isPlaying && !miniPlayer?.url,
        getDashboardActionDetails,
    );

    useEffect(() => {
        if (giftDetail) {
            const {
                media_url_prefix,
                media_url_postfix,
                media_file_name,
                title,
            } = giftDetail;

            getSizeOfContent(
                `${media_url_postfix}/${media_file_name}`,
                media_url_prefix,
            )
                .then((res) => {
                    setSizeOfContent(res);
                })
                .catch((err) => {
                    setSizeOfContent(0);
                });

            fetchMediaUrl({
                media_url_prefix: media_url_prefix,
                media_url_postfix: media_url_postfix,
                media_file_name: media_file_name,
            });

            logFirebaseEvent(
                selectedTab === AllTabs.AUDIOS
                    ? EventNames.GIFT_AUDIO_OPENED
                    : EventNames.GIFT_VIDEO_OPENED,
                giftAnalyticsData(EventActions.OPEN),
            );

            if (isReceivedMemberGift) logGiftOpen();
        }
    }, [giftDetail?.nid, selectedTab, isReceivedMemberGift]);

    useEffect(() => {
        if (giftDetail) {
            const { nid } = giftDetail;
            if (giftDetail?.media.length > 1) {
                getDownloadedPartsNids(giftDetail.nid)
                    .then((res) => {
                        if (res.length === giftDetail?.media?.length) {
                            setIsReady(false);
                            setIsDownloaded(true);
                            setAddedToDownloadQueue(false);
                        }
                    })
                    .catch((err) => {
                        setIsReady(true);
                        setIsDownloaded(false);
                    });
            } else {
                const db = getDexieConnectionRefresh();
                const activeDb =
                    giftDetail.content_item_type_name === "GiftVideo" ||
                    giftDetail.content_item_type_name === "GiftProsVideo"
                        ? db.videos
                        : db.audios;

                activeDb
                    .get(`${config.user.memberId}-${nid}`)
                    ?.then((res) => {
                        if (res) {
                            setIsDownloaded(true);
                            setDownloadedUrl(URL.createObjectURL(res.blob));
                        } else {
                            setIsDownloaded(false);
                        }
                    })
                    .catch((err) => {
                        setIsDownloaded(false);
                    });
            }
        }
    }, [giftDetail?.nid]);

    const handleAudioStartedPlaying = () => {
        if (giftDetail) {
            if (selectedTab === AllTabs.AUDIOS) {
                const { nid, sku_id, title } = giftDetail;
                if (!isDownloaded) {
                    const action: FirebaseEventAction = {
                        action: EventActions.LIVE_STREAM,
                        nId: nid,
                        contentTitle: title,
                        skuId: sku_id,
                        category: EventCategories.GIFT_AUDIOS,
                    };
                    logFirebaseEvent(EventNames.GIFT_AUDIO_LIVESTREAM, action);
                } else {
                    const action: FirebaseEventAction = {
                        action: EventActions.PLAY,
                        nId: nid,
                        contentTitle: title,
                        skuId: sku_id,
                        category: EventCategories.GIFT_AUDIOS,
                    };

                    logFirebaseEvent(EventNames.GIFT_AUDIO_PLAYED, action);
                }
                handleRecentlyPlayed();
            } else if (selectedTab === AllTabs.VIDEOS) {
                const { nid, sku_id, title } = giftDetail;

                if (!isDownloaded) {
                    const action: FirebaseEventAction = {
                        action: EventActions.LIVE_STREAM,
                        nId: nid,
                        contentTitle: title,
                        skuId: sku_id,
                        category: EventCategories.GIFT_VIDEOS,
                    };

                    logFirebaseEvent(EventNames.GIFT_VIDEO_LIVESTREAM, action);
                } else {
                    const action: FirebaseEventAction = {
                        action: EventActions.PLAY,
                        nId: nid,
                        contentTitle: title,
                        skuId: sku_id,
                        category: EventCategories.GIFT_VIDEOS,
                    };

                    logFirebaseEvent(EventNames.GIFT_VIDEO_PLAYED, action);
                }
                handleRecentlyPlayed();
            }
        }
    };

    //== is used instead of === because for some gifts publish_free is the boolean value true
    const isMediaPlayable =
        giftDetail?.is_purchased || giftDetail?.publish_free == "1";
    const isAudio = giftDetail?.content_item_type_name.includes("MP3");

    useEffect(() => {
        if (giftDetail) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [giftDetail]);

    const fetchThumbnails = async () => {
        if (!thumbnails || (thumbnails && !thumbnails[giftDetail.nid])) {
            const thumb = await getPublicUrl(
                giftDetail.image_url_prefix,
                giftDetail.image_url_postfix,
                giftDetail.image_file_name,
            );

            setThumbnails({
                [giftDetail.nid]: thumb ? thumb : "a",
            });
        }
    };

    const handleFavouriteClick = (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
        const firebaseEvent: Partial<FirebaseEventAction> = {
            nId: giftDetail?.nid,
            skuId: giftDetail?.sku_id,
            contentTitle: giftDetail?.title,
            category:
                selectedTab === AllTabs.AUDIOS
                    ? EventCategories.GIFT_AUDIOS
                    : EventCategories.GIFT_VIDEOS,
        };

        if (isFavoriteContent()) {
            unMarkFavoriteByNid(giftDetail?.nid, firebaseEvent)
                .then((res) => {
                    if (res.status && res.key === 0) {
                        if (
                            categoryId ===
                                GiftCategories.RECEIVED_AS_PROSPECT ||
                            categoryId === GiftCategories.RECEIVED_AS_MEMBER
                        ) {
                            if (selectedTab === AllTabs.AUDIOS) {
                                let tempArray = [...favoritesAudios];
                                tempArray?.splice(
                                    tempArray?.findIndex(
                                        (x) => x.nid === giftDetail?.nid,
                                    ),
                                    1,
                                );
                                setGlobalFavoritesAudios(tempArray);
                                enqueueSnackbar(messaging.favorites.success, {
                                    variant: "success",
                                });
                            } else {
                                let tempArray = [...favoritesVideos];
                                tempArray?.splice(
                                    tempArray?.findIndex(
                                        (x) => x.nid === giftDetail?.nid,
                                    ),
                                    1,
                                );
                                setGlobalFavoritesVideos(tempArray);
                                enqueueSnackbar(messaging.favorites.success, {
                                    variant: "success",
                                });
                            }
                        }
                    } else if (res.status && res.key === 85) {
                        fetchFavorites(true, true);
                    } else {
                        enqueueSnackbar(messaging.favorites.error, {
                            variant: "error",
                        });
                    }
                })
                .catch((ex) => {
                    enqueueSnackbar(messaging.favorites.error, {
                        variant: "error",
                    });
                });
        } else {
            markFavoriteByNid(giftDetail?.nid, firebaseEvent)
                .then((res) => {
                    if (res.status && res.key === 0) {
                        if (
                            categoryId ===
                                GiftCategories.RECEIVED_AS_PROSPECT ||
                            categoryId === GiftCategories.RECEIVED_AS_MEMBER
                        ) {
                            if (selectedTab === AllTabs.AUDIOS) {
                                let tempArray = [...favoritesAudios];
                                tempArray.push(giftDetail as AudioContent);
                                setGlobalFavoritesAudios(tempArray);
                                enqueueSnackbar(messaging.favorites.success, {
                                    variant: "success",
                                });
                            } else {
                                let tempArray = [...favoritesVideos];
                                tempArray.push(giftDetail as VideoContent);
                                setGlobalFavoritesVideos(tempArray);

                                enqueueSnackbar(messaging.favorites.success, {
                                    variant: "success",
                                });
                            }
                        }
                    } else if (res.status && res.key === 85) {
                        fetchFavorites(true, true);
                    } else {
                        enqueueSnackbar(messaging.favorites.error, {
                            variant: "error",
                        });
                    }
                })
                .catch((ex) => {
                    enqueueSnackbar(messaging.favorites.error, {
                        variant: "error",
                    });
                });
        }
    };

    useEffect(() => {
        if (addToPlaylistSuccess) {
            const params: FirebaseEventAction = {
                ...giftAnalyticsData(EventActions.ADDED_TO_PLAYLIST),
                playlistId: addToPlaylistSuccess.playlistId,
            };
            logFirebaseEvent(EventNames.GIFT_AUDIO_ADDED_TO_PLAYLIST, params);
            enqueueSnackbar(addToPlaylistSuccess.success, {
                variant: "success",
            });
        }
    }, [addToPlaylistSuccess, enqueueSnackbar]);

    const handlePlaylistSelected = (id: string) => {
        setAnchorPlaylist(null);
        addAudio(id, giftDetail?.nid, giftDetail?.sku_id);
    };

    const favIconLoading =
        markFavLoading || unMarkFavLoading || favoritesLoading;

    const [isLoop, setLoop] = useState(false);

    const dispatch = useAppDispatch();
    const [addedToDownloadQueue, setAddedToDownloadQueue] =
        useState<boolean>(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [isOpenConfirmDailog, setIsOpenConfirmDailog] = useState(false);

    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );

    useEffect(() => {
        let interval;
        if (
            giftDetail &&
            (inProgressNid.includes(giftDetail?.nid) || addedToDownloadQueue)
        ) {
            const { nid } = giftDetail;
            interval = setInterval(() => {
                if (!isInDownloadQueue(nid)) {
                    setAddedToDownloadQueue(false);
                }
                if (!isBundle) {
                    const db = getDexieConnectionRefresh();
                    const activeDb =
                        giftDetail?.content_item_type_name === "GiftVideo" ||
                        giftDetail?.content_item_type_name === "GiftProsVideo"
                            ? db.videos
                            : db.audios;
                    activeDb
                        .get(`${config.user.memberId}-${nid}`)
                        ?.then((res) => {
                            if (res) {
                                setIsReady(false);
                                setIsDownloaded(true);
                                setAddedToDownloadQueue(false);
                                setDownloadedUrl(URL.createObjectURL(res.blob));
                            }
                        })
                        .catch((err) => {
                            setIsReady(true);
                            setIsDownloaded(false);
                        });
                } else {
                    getDownloadedPartsNids(giftDetail.nid)
                        .then((res) => {
                            if (res.length === giftDetail?.media?.length) {
                                setIsReady(false);
                                setIsDownloaded(true);
                                setAddedToDownloadQueue(false);
                            }
                        })
                        .catch((err) => {
                            setIsReady(true);
                            setIsDownloaded(false);
                        });
                }

                if (isDownloaded) {
                    clearInterval(interval);
                }
            }, 5000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [giftDetail, addedToDownloadQueue, isDownloaded]);

    const handleDelete = async (nid: string) => {
        setDeleteLoader(true);
        if (!isBundle) {
            const db = getDexieConnectionRefresh();
            const activeDb =
                giftDetail?.content_item_type_name === "GiftVideo" ||
                giftDetail?.content_item_type_name === "GiftProsVideo"
                    ? db.videos
                    : db.audios;
            activeDb
                .delete(`${config.user.memberId}-${nid}`)
                .then((res) => {
                    setIsReady(false);
                    setIsDownloaded(false);
                    setAddedToDownloadQueue(false);
                    enqueueSnackbar(messaging.download.deleteSuccess, {
                        variant: "success",
                    });
                    if (downloadedContentNids && downloadedContentNids.length) {
                        const downloadedIds = [...downloadedContentNids];
                        const updatedDownloadedIds = downloadedIds?.filter(
                            (downloadedNid) =>
                                downloadedNid !==
                                `${config.user.memberId}-${nid}`,
                        );
                        dispatch(
                            setDownloadedContentNids({
                                downloadedContentNids: updatedDownloadedIds,
                            }),
                        );
                    }
                })
                .catch(() => {
                    enqueueSnackbar(messaging.download.deleteError, {
                        variant: "error",
                    });
                })
                .finally(() => setDeleteLoader(false));
        } else {
            setDeleteLoader(true);
            let isDeleted = false;
            await getDownloadedPartsNids(nid).then(async (res) => {
                if (res.length) {
                    for (let i = 0; i < res.length; i++) {
                        await getDexieConnectionRefresh()
                            .audios.delete(res[i])
                            // eslint-disable-next-line no-loop-func
                            .then((res) => {
                                setIsReady(false);
                                setIsDownloaded(false);
                                setAddedToDownloadQueue(false);
                                isDeleted = true;
                                if (
                                    downloadedContentNids &&
                                    downloadedContentNids.length
                                ) {
                                    const downloadedNids = [
                                        ...downloadedContentNids,
                                    ];
                                }
                            })
                            .catch((err) => {});

                        if (i === res.length - 1) {
                            if (isDeleted) {
                                enqueueSnackbar(
                                    messaging.download.deleteSuccess,
                                    {
                                        variant: "success",
                                    },
                                );
                            } else {
                                enqueueSnackbar(
                                    messaging.download.deleteError,
                                    {
                                        variant: "error",
                                    },
                                );
                            }
                            setDeleteLoader(false);
                        }
                    }
                }
            });
        }

        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const handleBundleDownload = (audio: Gifts | AudioContent) => async () => {
        setAddedToDownloadQueue(true);
        const queueLength = getDownloadQueueLength();
        if (queueLength < downloadingQueueLimit) {
            const downloadedContentNids = await getDownloadedPartsNids(
                audio.nid,
            );
            if (
                queueLength +
                    (+audio.no_of_files - downloadedContentNids.length) >
                downloadingQueueLimit
            ) {
                setQueueLimitReached(true);
            }
            const options: AddToDownloadOptions = {
                media_url_prefix: audio.media_url_prefix,
                media_url_postfix: audio.media_url_postfix,
                media_file_name: audio.media_file_name,
                name: audio.title,
                type: "giftAudioBundle",
                nid: audio.nid,
                skuId: audio.sku_id,
                description: audio.description,
                release_date: audio.release_date,
                media: audio.media,
            };

            addToDownloadQueue(options);
        } else {
            setQueueLimitReached(true);
            setAddedToDownloadQueue(false);
        }
    };

    const handleToggleConfirmDialog = () => {
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const handleGiftDownload = (audio: Gifts) => () => {
        showWarningSnackbarIfRequired();
        setAddedToDownloadQueue(true);
        const queueLength = getDownloadQueueLength();
        if (queueLength < downloadingQueueLimit) {
            const options: AddToDownloadOptions = {
                media_url_prefix: audio.media_url_prefix,
                media_url_postfix: audio.media_url_postfix,
                media_file_name: audio.media_file_name,
                name: audio.title,
                type: audio.content_item_type_name.includes("MP3")
                    ? "giftAudio"
                    : "giftVideo",
                nid: audio.nid,
                skuId: audio.sku_id,
                description: audio.description,
                release_date: audio.release_date,
            };

            addToDownloadQueue(options);
        } else {
            setQueueLimitReached(true);
            setAddedToDownloadQueue(false);
        }
    };

    useEffect(() => {
        if (!contentURL || !isDownloaded) {
            setContentUrl(isDownloaded ? downloadedUrl : mediaURL);
        }
    }, [isDownloaded, mediaURL]);

    const handleNavigateToQueue = () =>
        history.push(linkProvider.react.downloadingQueue().index("gift"));

    const toggleQueueLimitReached = () => {
        setQueueLimitReached(!queueLimitReached);
    };

    const isDownloading =
        (inProgressNid?.includes(giftDetail?.nid) ||
            (isBundle && isBundleInProgress(contentId))) &&
        !isDownloaded;

    const { downloadStatus, showWarningSnackbarIfRequired } =
        useDownloadingStatus(
            `${giftDetail?.media_url_postfix}/${giftDetail?.media_file_name}`,
            giftDetail?.media_url_prefix,
        );

    const miniplayerOptions = useRef<MiniPlayerOptions>(null);

    useMemo(() => {
        if (giftDetail) {
            miniplayerOptions.current = {
                mediaUrl: contentURL,
                thumbnail: thumbnails && thumbnails[giftDetail.nid],
                title: giftDetail?.title,
                content: giftDetail,
                singleLoop: isLoop,
                isPlaying,
                parentLink: getCurrentUrl(),
            };
        }
    }, [contentURL, isPlaying, thumbnails, giftDetail, isLoop]);

    const miniPlayerClick = () => {
        const options = miniplayerOptions.current;
        if (options)
            onClickMiniPlayer({
                isAudio: selectedTab === AllTabs.AUDIOS,
                mediaUrl: options.mediaUrl,
                thumbnail: options.thumbnail,
                title: options.title,
                content: options.content,
                singleLoop: options.singleLoop,
                parentLink: options.parentLink,
            })();
    };

    // this useEffect is for component unmounting
    useEffect(() => {
        return () => {
            if (
                miniplayerOptions.current &&
                miniplayerOptions.current.isPlaying
            )
                miniPlayerClick();
        };
    }, []);

    const giftsLoading =
        memberAudiosLoading ||
        memberVideosLoading ||
        prospectAudiosLoading ||
        prospectVideosLoading ||
        loading;

    const playerEnded = () => {
        handleContentCompleted();
        if (isReceivedMemberGift) logGiftCompleted();
    };

    const goToReceivedGifts = () =>
        history.push(
            linkProvider.react
                .gifts()
                .listing(categoryId, selectedTab, queryParams),
        );

    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { scrollTo = 0 } = parsedQueryString;

        return {
            scrollTo: scrollTo.toString(),
        };
    }, [location?.search]);

    return (
        <Layout>
            <AppHeader
                title={giftDetail?.title}
                canGoBack
                searchable={false}
                onBackClick={() => {
                    if (!homeStack?.isHomeTrack) goToReceivedGifts();
                    else history.goBack();
                }}
            />
            <Grid container className={clsx(classes.root, className)}>
                {giftDetail && (
                    <>
                        <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Typography
                                className={classes.greyA100}
                                fontWeight={"bold"}
                                variant="h6"
                            >
                                {giftDetail.title}
                            </Typography>
                            <div className={classes.favoritesWrapper}>
                                <IconButton>
                                    {favIconLoading ? (
                                        <AppCircularProgress loaderSize="small" />
                                    ) : (
                                        <FontAwesomeIcon
                                            onClick={handleFavouriteClick}
                                            style={{ fontSize: "22px" }}
                                            icon={[
                                                isFavoriteContent()
                                                    ? "fas"
                                                    : "far",
                                                "star",
                                            ]}
                                            className={classes.icon}
                                        />
                                    )}
                                </IconButton>
                                {isMediaPlayable &&
                                    !isDownloaded &&
                                    !addedToDownloadQueue &&
                                    !isInDownloadQueue(giftDetail?.nid) &&
                                    !isDownloading && (
                                        <div>
                                            {isBundle ? (
                                                <DownloadWarningDialog
                                                    onConfirm={handleBundleDownload(
                                                        giftDetail,
                                                    )}
                                                >
                                                    {(renderProps) => (
                                                        <IconButton>
                                                            <AppDownloadingIcons
                                                                onClick={
                                                                    renderProps.askConfirmation
                                                                }
                                                                iconType="download"
                                                            />
                                                        </IconButton>
                                                    )}
                                                </DownloadWarningDialog>
                                            ) : (
                                                <IconButton>
                                                    {invalidContentSize(
                                                        sizeOfContent,
                                                    ) ||
                                                    downloadStatus ===
                                                        DownloadStatus.BLOCK ? (
                                                        <DownloadDisabled />
                                                    ) : (
                                                        <DownloadWarningDialog
                                                            onConfirm={handleGiftDownload(
                                                                giftDetail,
                                                            )}
                                                        >
                                                            {(renderProps) => (
                                                                <IconButton>
                                                                    <AppDownloadingIcons
                                                                        onClick={
                                                                            renderProps.askConfirmation
                                                                        }
                                                                        iconType="download"
                                                                    />
                                                                </IconButton>
                                                            )}
                                                        </DownloadWarningDialog>
                                                    )}
                                                </IconButton>
                                            )}
                                        </div>
                                    )}
                                {isDownloaded && (
                                    <IconButton>
                                        <AppDownloadingIcons
                                            iconType="trash"
                                            onClick={handleToggleConfirmDialog}
                                        />
                                    </IconButton>
                                )}
                                {!isDownloaded &&
                                    (isInDownloadQueue(giftDetail?.nid) ||
                                        addedToDownloadQueue) &&
                                    !isDownloading &&
                                    !inProgressNid.includes(
                                        giftDetail?.nid,
                                    ) && (
                                        <IconButton>
                                            <AppDownloadingIcons
                                                iconType="queued"
                                                onClick={handleNavigateToQueue}
                                            />
                                        </IconButton>
                                    )}
                                {isDownloading && (
                                    <IconButton>
                                        <AppDownloadingIcons
                                            iconType="in-progress"
                                            onClick={handleNavigateToQueue}
                                        />
                                    </IconButton>
                                )}
                                {isOpenConfirmDailog && (
                                    <AppConfimationDialog
                                        open={isOpenConfirmDailog}
                                        onConfirm={() =>
                                            handleDelete(giftDetail.nid)
                                        }
                                        btnConfirmLoader={deleteLoader}
                                        onClose={handleToggleConfirmDialog}
                                        popupMainText="Are you sure you want to delete Audio?"
                                        confirmText="Delete"
                                        cancelButtonText="Cancel"
                                        showConfirmFirst={true}
                                    />
                                )}
                            </div>
                        </Grid>
                        <Grid
                            item
                            className={classes.grey500}
                            xs={12}
                            display="flex"
                        >
                            <Typography
                                className={clsx(classes.metaData)}
                                variant="body1"
                            >
                                {giftDetail.sku_id}
                            </Typography>
                            &nbsp;|&nbsp;
                            <Typography
                                className={clsx(classes.metaData)}
                                variant="body1"
                            >
                                From: {giftDetail.sender?.first_name || ""}{" "}
                                {giftDetail.sender?.last_name || ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.playerContainer}>
                            {isMediaPlayable &&
                            mediaURL?.length > 0 &&
                            thumbnails &&
                            thumbnails[giftDetail.nid] &&
                            !isBundle ? (
                                <MediaPlayer
                                    title={giftDetail.title}
                                    playerRef={playerRef}
                                    mediaURL={contentURL}
                                    imageURL={
                                        thumbnails && thumbnails[giftDetail.nid]
                                    }
                                    onPlay={() => setIsPlaying(true)}
                                    onPause={() => setIsPlaying(false)}
                                    onReady={() => onMediaPlayerReady()}
                                    onStart={handleAudioStartedPlaying}
                                    progressInterval={PLAYER_PROGRESS_INTERVAL}
                                    onProgress={onPlayerProgress}
                                    onEnded={playerEnded}
                                    loop={isLoop}
                                    isAudio={selectedTab === AllTabs.AUDIOS}
                                    playing={
                                        (isAutoPlay === "true" || isPlaying) &&
                                        !miniPlayer.url
                                    }
                                    loading={!isReadyToPlay}
                                    onUpdateExpiredUrl={() => {
                                        setIsReady(false);
                                        setIsReadyToPlay(true);
                                    }}
                                    onError={() => {
                                        setIsReadyToPlay(true);
                                    }}
                                />
                            ) : thumbnails && thumbnails[giftDetail.nid] ? (
                                <img
                                    alt={giftDetail?.title}
                                    src={
                                        thumbnails && thumbnails[giftDetail.nid]
                                    }
                                    className={classes.mediaImage}
                                />
                            ) : (
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"flex-end"}
                                    className={classes.dummyImageBox}
                                >
                                    <Box
                                        className={clsx(
                                            classes.giftIconPositioner,
                                        )}
                                    >
                                        <Icon icon={Icons.giftIcon} />
                                    </Box>
                                    <span className={classes.graphicWrapper}>
                                        <GraphicEqIcon
                                            className={classes.graphicIcon}
                                            sx={{
                                                fontSize: "60px",
                                            }}
                                        />
                                    </span>
                                </Box>
                            )}
                        </Grid>

                        <Grid
                            item
                            mt={1}
                            xs={12}
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                        >
                            <RenderIf
                                isTrue={isMediaPlayable && isAudio}
                                fallback={<Box></Box>}
                            >
                                <Box className={classes.controlIcons}>
                                    <IconButton onClick={handlePlaylistClick}>
                                        <PlaylistAddIcon
                                            sx={{ fontSize: "30px" }}
                                            className={
                                                classes.addToPlaylistIcon
                                            }
                                        />
                                    </IconButton>
                                </Box>
                            </RenderIf>

                            {isBundle && isMediaPlayable && (
                                <>
                                    <AppButton
                                        onClick={() => playClick()}
                                        buttonVariant="grey-outlined"
                                        rounded
                                    >
                                        PLAY
                                    </AppButton>
                                    <Box className={classes.controlIcons}></Box>
                                </>
                            )}
                            {!isBundle && isMediaPlayable && (
                                <>
                                    <PlayerActionBtns
                                        handleChangeSeek={handleChangeSeek}
                                        isLoop={isLoop}
                                        onClickLoop={() => setLoop(!isLoop)}
                                        onClickMiniPlayer={miniPlayerClick}
                                    />
                                    <Box></Box>
                                </>
                            )}
                        </Grid>
                        {giftDetail?.sender?.sending_date && (
                            <Typography
                                className={clsx(classes.grey500, classes.date)}
                                variant="body2"
                            >
                                {moment(
                                    giftDetail?.sender?.sending_date,
                                ).format("LL")}
                            </Typography>
                        )}
                        <Grid item xs={12}>
                            <Typography
                                className={classes.grey800}
                                variant="body2"
                            >
                                {giftDetail.description}
                            </Typography>
                        </Grid>
                        {queueLimitReached && (
                            <AppConfimationDialog
                                titleText={
                                    messaging?.downloadingQueue?.QueueLimitation
                                }
                                showTitle={true}
                                open={queueLimitReached}
                                popupMainText={
                                    messaging?.downloadingQueue?.limitReached
                                }
                                cancelButtonText="OK"
                                onClose={toggleQueueLimitReached}
                            />
                        )}
                    </>
                )}
                {!giftDetail && (
                    <Grid xs={12} className={classes.errorLoaderWrapper}>
                        {giftsLoading ? (
                            <CircularProgress className={classes.spinner} />
                        ) : (
                            <Box>
                                <AppTypography className={classes.greyA100}>
                                    Unable to display this gift.
                                </AppTypography>
                                <AppButton onClick={goToReceivedGifts}>
                                    Go to Received Gifts
                                </AppButton>
                            </Box>
                        )}
                    </Grid>
                )}
                <ListDialog
                    open={openPlaylistSelection}
                    items={sortPlaylists(playlistsInStore).map((p) => {
                        return {
                            id: p.playlist_id,
                            title: p.playlist_name,
                        };
                    })}
                    onSelection={handlePlaylistSelected}
                    onClose={() => setAnchorPlaylist(null)}
                />
                {playlistsLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.playlist}
                    />
                )}
                {addToPlaylistLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.add_to_playlist}
                    />
                )}
                {isCreatePlaylist && (
                    <InputDialog
                        open={true}
                        title={"Create Playlist"}
                        hint={"Please enter playlist name."}
                        cancelText={"Cancel"}
                        okText={"Create"}
                        cancelClicked={() => setCreatePlaylist(false)}
                        okClicked={createClick}
                    />
                )}
                {createLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.create_playlist}
                    />
                )}
                {favIconLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.processing}
                    />
                )}
            </Grid>
        </Layout>
    );
};

const useReceivedGifts = (
    categoryId: GiftCategories,
    selectedTab: AllTabs,
    contentId: string,
) => {
    const {
        receivedMemberAudioGifts,
        receivedMemberVideoGifts,
        receivedProspectVideoGifts,
        receivedProspectAudioGifts,
    } = useGlobalGifts();

    const giftDetail = useMemo(() => {
        if (categoryId === GiftCategories.RECEIVED_AS_MEMBER) {
            if (selectedTab === AllTabs.AUDIOS)
                return receivedMemberAudioGifts?.find(
                    (audio) => audio.nid === contentId,
                );
            return receivedMemberVideoGifts?.find(
                (audio) => audio.nid === contentId,
            );
        } else {
            if (selectedTab === AllTabs.AUDIOS)
                return receivedProspectAudioGifts?.find(
                    (audio) => audio.nid === contentId,
                );
            return receivedProspectVideoGifts?.find(
                (audio) => audio.nid === contentId,
            );
        }
    }, [
        receivedMemberAudioGifts,
        contentId,
        receivedMemberVideoGifts,
        categoryId,
        selectedTab,
        receivedProspectAudioGifts,
        receivedProspectVideoGifts,
    ]);

    const skipRMAudGifts =
        !!giftDetail ||
        categoryId !== GiftCategories.RECEIVED_AS_MEMBER ||
        selectedTab !== AllTabs.AUDIOS;
    const skipRMVidGifts =
        !!giftDetail ||
        categoryId !== GiftCategories.RECEIVED_AS_MEMBER ||
        selectedTab !== AllTabs.VIDEOS;
    const skipRPAudGifts =
        !!giftDetail ||
        categoryId !== GiftCategories.RECEIVED_AS_PROSPECT ||
        selectedTab !== AllTabs.AUDIOS;
    const skipRPVidGifts =
        !!giftDetail ||
        categoryId !== GiftCategories.RECEIVED_AS_PROSPECT ||
        selectedTab !== AllTabs.VIDEOS;

    const { loading: memberAudiosLoading, isGift: isAudioGift } =
        useReceivedMemberAudioGifts(skipRMAudGifts);
    const { loading: memberVideosLoading, isGift: isVideoGift } =
        useReceivedMemberVideoGifts(skipRMVidGifts);
    const { loading: prospectAudiosLoading } =
        useReceivedProspectAudioGifts(skipRPAudGifts);
    const { loading: prospectVideosLoading } =
        useReceivedProspectVideoGifts(skipRPVidGifts);

    const giftAnalyticsData = (action: EventActions) => {
        const { nid: nId, sku_id: skuId, title: contentTitle } = giftDetail;
        return {
            nId,
            skuId,
            contentTitle,
            action,
        };
    };

    const giftDashboardData = useMemo(() => {
        return toSentGift(giftDetail);
    }, [giftDetail]);

    return {
        memberAudiosLoading,
        memberVideosLoading,
        prospectAudiosLoading,
        prospectVideosLoading,
        giftDetail,
        giftAnalyticsData,
        giftDashboardData,
        isAudioGift,
        isVideoGift,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            position: "relative",
            background: theme.palette.grey[900],
            height: "100%",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        giftSectionWarpper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        giftIconButtonDisabled: {
            opacity: "50%",
            cursor: "not-allowed !important",
        },
        giftIconPositioner: {
            position: "absolute",
            bottom: 10,
            right: 10,
        },
        metaData: {
            fontSize: "14px",
        },
        controlIcons: {
            minWidth: "40px",
            display: "flex",
        },
        playerIcon: {
            height: "35px",
        },
        favoritesWrapper: {
            display: "flex",
            alignItems: "center",
        },
        addToPlaylistIcon: {
            color: theme.palette.grey[600],
        },
        errorLoaderWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
        },
    }),
);
