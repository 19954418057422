export interface Subscription {
    subscriptionId: string;
    subscriptionName: string;
}
export interface UserTeamInfo {
    teamName: string;
    isTestUser: boolean;
    activeSubscriptions: Subscription[];
}

export enum HomeTabs {
    HOME = "Home",
    DASHBOARD = "Dashboard",
}

export enum GiftDashboardFilters {
    BYDATE = "By Date",
    BYTYPE = "By Type",
    BYMEMBER = "By Member",
    NOFILTER = "No Filter",
}
