import { createSlice } from "@reduxjs/toolkit";
import { AcquiredDate, Gifts, GiftsGiven } from "JS/Models";

export interface GiftState {
    audioGiftsList: Gifts[];
    videoGiftsList: Gifts[];
    sentAudioGiftsList: Gifts[];
    sentVideoGiftsList: Gifts[];
    receivedMemberAudioGifts: Gifts[];
    receivedMemberVideoGifts: Gifts[];
    receivedProspectAudioGifts: Gifts[];
    receivedProspectVideoGifts: any;
    audioGiftsGiven: GiftsGiven[];
    videoGiftsGiven: GiftsGiven[];
    acquiredDates: AcquiredDate;
    videoAcquiredDates: AcquiredDate;
    audioAcquiredDates: AcquiredDate;
    courseAcquiredDates: AcquiredDate;
    prosAudioAcquiredDates: AcquiredDate;
    prosVideoAcquiredDates: AcquiredDate;
}

const initialState: GiftState = {
    audioGiftsList: [],
    videoGiftsList: [],
    sentAudioGiftsList: [],
    sentVideoGiftsList: [],
    receivedMemberAudioGifts: [],
    receivedMemberVideoGifts: [],
    receivedProspectAudioGifts: [],
    receivedProspectVideoGifts: [],
    audioGiftsGiven: [],
    videoGiftsGiven: [],
    acquiredDates: {},
    audioAcquiredDates: {},
    videoAcquiredDates: {},
    courseAcquiredDates: {},
    prosAudioAcquiredDates: {},
    prosVideoAcquiredDates: {},
};

export const giftStateSlice = createSlice({
    name: "giftState",
    initialState,
    reducers: {
        setGlobalGifts: (state, data) => {
            if (data.payload.audioGiftsList)
                state.audioGiftsList = data.payload.audioGiftsList;
            if (data.payload.videoGiftsList)
                state.videoGiftsList = data.payload.videoGiftsList;
            if (data.payload.sentAudioGiftsList)
                state.sentAudioGiftsList = data.payload.sentAudioGiftsList;
            if (data.payload.sentVideoGiftsList)
                state.sentVideoGiftsList = data.payload.sentVideoGiftsList;
            if (data.payload.receivedMemberAudioGifts)
                state.receivedMemberAudioGifts =
                    data.payload.receivedMemberAudioGifts;
            if (data.payload.receivedMemberVideoGifts)
                state.receivedMemberVideoGifts =
                    data.payload.receivedMemberVideoGifts;
            if (data.payload.receivedProspectAudioGifts)
                state.receivedProspectAudioGifts =
                    data.payload.receivedProspectAudioGifts;
            if (data.payload.receivedProspectVideoGifts)
                state.receivedProspectVideoGifts =
                    data.payload.receivedProspectVideoGifts;
            if (data.payload.audioGiftsGiven)
                state.audioGiftsGiven = data.payload.audioGiftsGiven;
            if (data.payload.videoGiftsGiven)
                state.videoGiftsGiven = data.payload.videoGiftsGiven;
            if (data.payload.acquiredDates)
                state.acquiredDates = {
                    ...data.payload.acquiredDates,
                    ...state.acquiredDates,
                };
            if (data.payload.audioAcquiredDates)
                state.audioAcquiredDates = {
                    ...state.audioAcquiredDates,
                    ...data.payload.audioAcquiredDates,
                };
            if (data.payload.videoAcquiredDates)
                state.videoAcquiredDates = {
                    ...state.videoAcquiredDates,
                    ...data.payload.videoAcquiredDates,
                };
            if (data.payload.courseAcquiredDates)
                state.courseAcquiredDates = {
                    ...state.courseAcquiredDates,
                    ...data.payload.courseAcquiredDates,
                };
            if (data.payload.prosAudioAcquiredDates)
                state.prosAudioAcquiredDates = {
                    ...state.prosAudioAcquiredDates,
                    ...data.payload.prosAudioAcquiredDates,
                };
            if (data.payload.prosVideoAcquiredDates)
                state.prosVideoAcquiredDates = {
                    ...state.prosVideoAcquiredDates,
                    ...data.payload.prosVideoAcquiredDates,
                };
            return state;
        },
        softResetGiftState: (state) => {
            return {
                ...initialState,
                receivedMemberAudioGifts: state.receivedMemberAudioGifts,
                receivedMemberVideoGifts: state.receivedMemberVideoGifts,
                receivedProspectAudioGifts: state.receivedProspectAudioGifts,
                receivedProspectVideoGifts: state.receivedProspectVideoGifts,
            };
        },
        resetGiftState: () => {
            return initialState;
        },
    },
});
export const { setGlobalGifts, resetGiftState, softResetGiftState } =
    giftStateSlice.actions;
