import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Theme, Typography, Box, InternalStandardProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

import { DocumentDetails } from "JS/Models";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";
import { useDownloadedDocumentsList } from "JS/React/Hooks/Database/Document";
import { useGlobalDocuments } from "JS/React/Hooks/Documents";
import { useRouting } from "JS/React/Hooks/Routes";
import { DocumentDexie } from "JS/Database/Dexie";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { OfflineDocumentItem } from "./OfflineDocumentItem";

export interface DocumentsListProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const OfflineDocumentsList = (props: DocumentsListProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const history = useHistory();
    const { linkProvider } = useRouting();

    const { downloadedDocuments, isLoading } = useDownloadedDocumentsList();
    const { allDocuments } = useGlobalDocuments();

    const viewOnlyDocuments = useMemo(() => {
        const isDownloadedDocument = (document: DocumentDetails) =>
            downloadedDocuments?.some((downloadedDoc) => {
                const userIdEndIndex = downloadedDoc?.id?.indexOf("-");
                const downloadedDocName = downloadedDoc?.id?.slice(
                    userIdEndIndex + 1,
                );

                return downloadedDocName === document?.Name;
            });

        if (allDocuments)
            return allDocuments.filter(
                (document: DocumentDetails) => !isDownloadedDocument(document),
            );

        return [];
    }, [allDocuments, downloadedDocuments]);

    const onDocumentClick = (pdfName: string, description?: string) => {
        history.push(
            linkProvider.react
                .offline()
                .documents()
                .detail(pdfName, description),
        );
    };

    const isDownloadedDoc = (document: DocumentDetails | DocumentDexie) =>
        !!(document as DocumentDexie)?.id;

    const allDocs = [...downloadedDocuments, ...viewOnlyDocuments];

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            <RenderIf
                isTrue={!isLoading}
                fallback={
                    <Box display={"flex"} justifyContent="center">
                        <AppCircularProgress />
                    </Box>
                }
            >
                {allDocs?.map((document, index) => (
                    <OfflineDocumentItem
                        key={(document as DocumentDexie).id}
                        document={document}
                        isDownloadedDoc={isDownloadedDoc(document)}
                        index={index}
                        onDocumentClick={onDocumentClick}
                    />
                ))}
            </RenderIf>

            <RenderIf
                isTrue={
                    (!downloadedDocuments ||
                        downloadedDocuments?.length <= 0) &&
                    (!viewOnlyDocuments || viewOnlyDocuments?.length <= 0)
                }
            >
                <Typography
                    align="center"
                    variant="h6"
                    className={classes.content}
                >
                    {messaging.common.noItemsFound}
                </Typography>
            </RenderIf>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            color: `${theme.palette.common.black}`,
        },
    }),
);
