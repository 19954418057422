import { useEffect } from "react";
import { useMiniPlayerHandlers } from "../MiniPlayer";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";

export const useMiniPlayerOpen = () => {
    const { miniPlayer } = useMiniPlayerHandlers();
    const { enqueueSnackbar } = useSnackbar();

    const showSnackbar = () =>
        enqueueSnackbar(messaging.miniPlayer.coursePrompt, {
            variant: "info",
        });

    useEffect(() => {
        if (!!miniPlayer && miniPlayer.url) showSnackbar();
    }, []);

    return {
        isOpen: !!miniPlayer.url,
        showSnackbar,
    };
};
