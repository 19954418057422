import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Layout } from "../Layout";
import {
    useGlobalGifts,
    useReceivedMemberVideoGifts,
    useReceivedProspectVideoGifts,
} from "JS/React/Hooks/Gifts";
import { useGlobalVideos, useVideos } from "JS/React/Hooks/Video";
import { AppHeader } from "JS/React/Components/AppHeader";
import { VideoCategoryList } from "./Components/VideoCategoryList";
import { uniqBy } from "lodash-es";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useSeamlessUpdate } from "JS/React/Hooks/Users";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useGetFavorites, useGlobalFavorites } from "JS/React/Hooks/Favorites";
import { useAttributes, useGlobalAttributes } from "JS/React/Hooks/Attributes";
import { AttributeType, ContentSpeakerName } from "JS/Redux/Attributes";
import { Gifts, VideoContent } from "JS/Models";
import { MultiAutocompleteProps } from "JS/React/Components/AppMultiTagSelect";
import { useScrollWithDelay } from "JS/React/Hooks/Media";
import { useGlobalMediaEssentials } from "JS/React/Hooks/MediaEssentials";
import { ListingComponent } from "../Audios/ListingComponent";
import { useVideoResumeHelper } from "./VideoHelpers";
import { useVideosHandlers } from "./VideoHooks";
import { useDownloadedContentStatus } from "./useDownloadedContentStatus";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },

        wrapperLoader: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: `calc(100vh - ${theme.footer.height})`,
        },
    }),
);

export interface VideosProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > { }

export enum VideoCategoryTitle {
    ALL_VIDEOS = "all-videos",
    MY_VIDEOS = "my-videos",
    SAVED = "saved-videos",
    RECENTLY_PLAYED = "recently_played",
    INDEX = "index",
}

export const Videos = (props: VideosProps) => {
    const classes = useStyles(props);

    const { className, ...rest } = props;

    useSeamlessUpdate();

    const { videos, categorizedVideos, isVideoUpdateRequired } =
        useGlobalVideos();

    const { favoritesVideos } = useGlobalFavorites();

    useGetFavorites(!!favoritesVideos && favoritesVideos.length > 0);
    const { isResumable } = useVideoResumeHelper();
    const { receivedMemberVideoGifts, receivedProspectVideoGifts } =
        useGlobalGifts();
    const { loading: videosLoading } = useVideos(
        !!categorizedVideos && categorizedVideos.length > 0,
        isVideoUpdateRequired,
    );

    useReceivedMemberVideoGifts();
    useReceivedProspectVideoGifts();
    const { setGlobalVideosStack, resetGlobalVideosStack, videosStack } =
        useGlobalNavStack();

    const [searchQuery, setSearchQuery] = useState(videosStack?.searchQuery);
    const {
        mediaEssentials,
        videoUniqueSpeakers,
        videoSelectedSpeakers,
        attributesLoading,
        onToggleSpeakerSelect,
        videoContentSpeakers,
        handleClearFilters,
        handleResetVideoAttributes,
    } = useVideoAttributesHandlers();

    const [showList, setShowList] = useState(false);

    const filteredVideos = useMemo(() => {
        return uniqBy(
            [
                ...videos,
                ...receivedMemberVideoGifts,
                ...receivedProspectVideoGifts,
            ],
            (x) => x.nid,
        );
    }, [videos, receivedMemberVideoGifts, receivedProspectVideoGifts]);
    const { handleListItemClick } = useVideosHandlers(searchQuery, VideoCategoryTitle.INDEX);
    const { searchResults } = useFilterVideoHandlers(
        filteredVideos,
        searchQuery,
        videoSelectedSpeakers,
        videoContentSpeakers,
    );

    useEffect(() => {
        setGlobalVideosStack({
            searchQuery: searchQuery,
        });
    }, [searchResults, videoSelectedSpeakers]);
    const loaded = !videosLoading && !attributesLoading;
    const useVideosDownloadHelperData = (data: (VideoContent | Gifts)[]) => {
        const { getDownloadedStatusById } = useDownloadedContentStatus(data, 'video');
        return getDownloadedStatusById;
    }
    const videoListVisible =
        showList ||
        searchQuery?.length > 0 ||
        videoSelectedSpeakers?.length > 0;

    useScrollWithDelay();

    return (
        <Layout>
            <div className={clsx(className, classes.root)} {...rest}>
                <AppHeader
                    title="Videos"
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchPlaceholder={"Search Videos by title/description"}
                    onSearchFieldClick={() => {
                        setShowList(true);
                    }}
                    onBackClick={() => {
                        setSearchQuery("");
                        resetGlobalVideosStack();
                        handleResetVideoAttributes();
                        setShowList(false);
                    }}
                    canGoBack={videoListVisible}
                    filterable={mediaEssentials?.allow_speaker_feature}
                    onToggleSpeakerSelect={onToggleSpeakerSelect}
                    uniqueSpeakers={videoUniqueSpeakers}
                    selectedSpeakers={videoSelectedSpeakers}
                    handleClearFilters={handleClearFilters}
                />

                {loaded ? (
                    <>
                        {videoListVisible ? (
                            <ListingComponent
                                listContent={searchResults}
                                searchQuery={searchQuery}
                                categoryId={VideoCategoryTitle.INDEX}
                                showSortHeader={true}
                                setGlobalStack={setGlobalVideosStack}
                                globalStack={videosStack}
                                isResumeable={isResumable}
                                favoritesContent={favoritesVideos}
                                handleListItemClick={handleListItemClick}
                                getDownloadHelperData={useVideosDownloadHelperData}
                                controlType="video"
                            />

                        ) : (
                            <VideoCategoryList
                                videos={videos}
                                categorizedVideos={categorizedVideos}
                            />
                        )}
                    </>
                ) : (
                    <AppBackdropProgress
                        open={true}
                        backdropText={
                            !attributesLoading
                                ? messaging.loader.videos
                                : messaging.loader.fectchingSpeakers
                        }
                    />
                )}
            </div>
        </Layout>
    );
};

export const useVideoAttributesHandlers = () => {
    const {
        videoFetchStatus,
        videoUniqueSpeakers,
        setGlobalVidAttributes,
        videoSelectedSpeakers,
        videoContentSpeakers,
        resetVideoAttributes,
    } = useGlobalAttributes();

    const { mediaEssentials } = useGlobalMediaEssentials();

    const [selectedSpeakers, setSelectedSpeakers] = useState<string[]>(
        videoSelectedSpeakers,
    );

    const { loading: attributesLoading } = useAttributes(
        AttributeType.VIDEO,
        videoFetchStatus || !mediaEssentials?.allow_speaker_feature,
    );

    const onToggleSpeakerSelect: MultiAutocompleteProps["onChange"] = (
        event,
        value,
    ) => {
        setSelectedSpeakers(value);
        setGlobalVidAttributes({ selectedSpeakers: value });
    };

    const handleClearFilters = () => {
        setSelectedSpeakers([]);
        setGlobalVidAttributes({ selectedSpeakers: [] });
    };
    const handleResetVideoAttributes = () => {
        setSelectedSpeakers([]);
        resetVideoAttributes();
    };

    return {
        mediaEssentials,
        videoUniqueSpeakers,
        setGlobalVidAttributes,
        videoSelectedSpeakers,
        attributesLoading,
        onToggleSpeakerSelect,
        videoContentSpeakers,
        handleClearFilters,
        handleResetVideoAttributes,
    };
};

export const useFilterVideoHandlers = (
    filteredVideos: (VideoContent | Gifts)[],
    searchQuery: string,
    videoSelectedSpeakers: string[],
    videoContentSpeakers: ContentSpeakerName[],
) => {
    const filterNids = useMemo(() => {
        return videoContentSpeakers
            ? videoContentSpeakers.filter((x) =>
                videoSelectedSpeakers.some((z) => z === x.speakerName),
            )
            : [];
    }, [videoSelectedSpeakers, videoContentSpeakers]);

    const filteredResults = useMemo(() => {
        return filterNids.length > 0
            ? filteredVideos?.filter((d) =>
                filterNids.some((z) => z.nid.toString() === d.nid),
            )
            : filteredVideos;
    }, [filteredVideos, filterNids]);

    const searchResults = useMemo(() => {
        return filteredResults?.filter(
            (d) =>
                d.title
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()) ||
                d.description
                    .toLocaleLowerCase()
                    .includes(searchQuery.toLocaleLowerCase().trim()),
        );
    }, [filteredResults, searchQuery]);

    return { searchResults };
};
