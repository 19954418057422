import { config } from "JS/Config";
import {
    Course,
    CourseDetail,
    GiftsGivenCourse,
    PurchasedGfitCourse,
    ReceivedCourse,
} from "JS/Models";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";

export class CoursesService extends BaseService {
    async getCoursesIndex() {
        return (
            await this.doXHR<AppResponse<{ courses: Course[] }>>({
                url: this.routes.server.api.courses.getCoursesIndex(
                    config?.user?.memberId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async getPurchasableGiftsForMembers() {
        return (
            await this.doXHR<AppResponse<{ courses: Course[] }>>({
                url: this.routes.server.api.courses.getPurchasableCoursesForMembers(
                    config?.user?.memberId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async getPurchasedGiftCourses() {
        return (
            await this.doXHR<AppResponse<{ courses: PurchasedGfitCourse[] }>>({
                url: this.routes.server.api.courses.getPurchasedGiftCourses(
                    config?.user?.memberId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async getReceivedCourses() {
        return (
            await this.doXHR<AppResponse<ReceivedCourse[]>>({
                url: this.routes.server.api.courses.getReceivedCourses(
                    config?.user?.memberId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async getCoursesGiven() {
        return (
            await this.doXHR<AppResponse<GiftsGivenCourse[]>>({
                url: this.routes.server.api.courses.getCoursesGiven(
                    config?.user?.memberId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async getCourseDetailWithMedia(skuId: string) {
        return (
            await this.doXHR<AppResponse<CourseDetail>>({
                url: this.routes.server.api.courses.getCourseDetailWithMedia(
                    skuId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async getCourseDetailWithOutMedia(skuId: string) {
        return (
            await this.doXHR<AppResponse<CourseDetail>>({
                url: this.routes.server.api.courses.getCourseDetailWithOutMedia(
                    skuId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }
}
