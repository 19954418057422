import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import { config } from "JS/Config";
import {
    getFirestore,
    Firestore,
    doc,
    setDoc,
    arrayUnion,
    getDoc,
    onSnapshot,
    FirestoreError,
    arrayRemove,
    runTransaction,
} from "firebase/firestore";
import {
    ActionDetails,
    DashboardActivity,
    DashboardLogs,
    PushLog,
    PushLogs,
    PushStatus,
    SentGift,
    SentGiftAction,
    SentGiftToLog,
    SentGiftType,
} from "JS/Models/Firebase/GiftDashboard";
import moment from "moment";
import { isStageEnvironment } from "JS/Helpers";
import { BaseService } from "../BaseService";
import { captureSentryError } from "JS/Helpers/SentryHelper";

export class GiftDashboardService extends BaseService {
    private app: FirebaseApp;
    private store: Firestore;
    private baseCollection = "GiftDashboard";
    private activeCollection = "Active";
    private currentDocument = "Current";
    private pushLogDocument = "PushLog";
    private activity = "Activity";
    private mergeOption = { merge: true };

    constructor() {
        super();

        const apps = getApps();
        const alreadyExist = apps?.find((x) => x.name === "[DEFAULT]");
        if (!alreadyExist) {
            this.app = initializeApp(config?.firebase?.courses);
        } else {
            this.app = alreadyExist;
        }
        this.store = getFirestore(this.app);
    }

    public logGiftSent(data: SentGift, type: SentGiftType) {
        if (this.shouldLog()) {
            setDoc(
                this.getDoc(config?.user?.memberId),
                this.getSentArrayObject(type, data),
                this.mergeOption,
            ).then(() => this.updateUpdatedAt(config?.user?.memberId));
        }
    }

    public logGiftOpen(
        gift: SentGiftToLog,
        type: SentGiftType,
        memberId: string,
        actionDetails?: ActionDetails,
    ) {
        if (this.shouldLog() && !!gift.receiverFirstName)
            return this.logActivity(
                gift,
                type,
                memberId,
                SentGiftAction.OPEN,
                actionDetails,
            );
    }

    public async logGiftStream(
        gift: SentGiftToLog,
        type: SentGiftType,
        memberId: string,
        actionDetails: ActionDetails,
    ) {
        if (this.shouldLog() && !!gift.receiverFirstName) {
            return runTransaction(this.store, async (transaction) => {
                const docData = await transaction.get(this.getDoc(memberId));
                const allLogs: DashboardLogs = docData.data() as DashboardLogs;
                const activity = allLogs?.Activity;
                if (!!activity) {
                    const playedAndCompletedEvents = activity
                        .filter(
                            (a) =>
                                a.nid === gift.nid &&
                                a.receiverId === config?.user?.memberId &&
                                a.activity.actionDetails?.index ===
                                    actionDetails.index &&
                                (a.activity.action === SentGiftAction.PLAYING ||
                                    a.activity.action ===
                                        SentGiftAction.COMPLETED),
                        )
                        .sort((a, b) => a.createdAt - b.createdAt);
                    const last =
                        playedAndCompletedEvents[
                            playedAndCompletedEvents.length - 1
                        ];
                    if (
                        !!last &&
                        last.activity.action === SentGiftAction.PLAYING
                    ) {
                        const lastPlayedIndex = activity.findIndex(
                            (a) => a === last,
                        );
                        const lastPlayed = activity[lastPlayedIndex];
                        const removeItem = arrayRemove(lastPlayed);
                        transaction.update(this.getDoc(memberId), {
                            [this.activity]: removeItem,
                        });
                    }
                }
                this.logOnConsole(
                    gift,
                    type,
                    memberId,
                    SentGiftAction.PLAYING,
                    actionDetails,
                );
                transaction.set(
                    this.getDoc(memberId),
                    this.getActivityDataToLog(
                        gift,
                        type,
                        SentGiftAction.PLAYING,
                        actionDetails,
                    ),
                    this.mergeOption,
                );
            });
        }
    }

    public logGiftCompleted(
        gift: SentGiftToLog,
        type: SentGiftType,
        memberId: string,
        actionDetails?: ActionDetails,
    ) {
        if (this.shouldLog() && !!gift.receiverFirstName)
            this.logActivity(
                gift,
                type,
                memberId,
                SentGiftAction.COMPLETED,
                actionDetails,
            );
    }

    private shouldLog() {
        if (!this.store) this.store = getFirestore(this.app);
        const storeInitialized = !!this.store;

        if (!storeInitialized) {
            const err = new Error("Store not initilized for logs.");
            captureSentryError(err, {
                location: "Gift-Dashboard",
            });
        }

        return storeInitialized;
    }

    private getSentArrayObject(type: SentGiftType, data: SentGift) {
        const timestamp = moment.now();
        let unionData: any;
        const union = arrayUnion({
            ...data,
            createAt: timestamp,
            sentAt: timestamp,
        });
        if (type === SentGiftType.AUDIO)
            unionData = {
                Audio: union,
            };
        else if (type === SentGiftType.VIDEO)
            unionData = {
                Video: union,
            };
        else if (type === SentGiftType.COURSE)
            unionData = {
                Course: union,
            };
        return {
            Sent: unionData,
        };
    }

    private updateUpdatedAt(iboId: string) {
        if (!!this.store)
            setDoc(doc(this.store, this.baseCollection, iboId), {
                updatedAt: moment.now(),
            });
    }

    private getDoc(memberId: string) {
        return doc(
            this.store,
            this.baseCollection,
            memberId,
            this.activeCollection,
            this.currentDocument,
        );
    }

    private logActivity(
        gift: SentGiftToLog,
        type: SentGiftType,
        memberId: string,
        action: SentGiftAction,
        actionDetails?: ActionDetails,
    ) {
        setDoc(
            this.getDoc(memberId),
            this.getActivityDataToLog(gift, type, action, actionDetails),
            this.mergeOption,
        ).then(() => this.updateUpdatedAt(memberId));
        this.logOnConsole(gift, type, memberId, action, actionDetails);
    }

    private getActivityData(
        gift: SentGiftToLog,
        type: SentGiftType,
        action: SentGiftAction,
        actionDetails?: ActionDetails,
    ) {
        const data: any = {
            ...gift,
            contentType: type,
            receiverId: config?.user?.memberId,
            activity: { action: action },
        };
        if (!!actionDetails) data.activity.actionDetails = actionDetails;
        return data;
    }

    private getActivityDataToLog(
        gift: SentGiftToLog,
        type: SentGiftType,
        action: SentGiftAction,
        actionDetails?: ActionDetails,
    ) {
        const union = arrayUnion(
            this.getActivityData(gift, type, action, actionDetails),
        );
        const data = {
            [this.activity]: union,
        };

        return data;
    }

    public getDataSnapshot = (
        callback: (activity: DashboardActivity[]) => void,
        onError: (error: FirestoreError) => void,
    ) => {
        return onSnapshot(
            this.getDoc(config?.user?.memberId),
            (snapshot) => {
                const data = snapshot.data();
                const allLogs: DashboardActivity[] = data
                    ? JSON.parse(JSON.stringify(data))?.Activity
                    : [];
                callback(allLogs);
            },
            (error) => onError(error),
        );
    };

    private logOnConsole(
        gift: SentGiftToLog,
        type: SentGiftType,
        memberId: string,
        action: SentGiftAction,
        actionDetails?: ActionDetails,
    ) {
        if (isStageEnvironment)
            console.log(
                "GiftDash",
                memberId,
                type,
                gift,
                action,
                actionDetails,
            );
    }

    public async sendDashboardPush(
        gift: SentGiftToLog,
        action: SentGiftAction,
        memberId: string,
        actionDetails: ActionDetails,
    ) {
        const shouldSendForThisActivity = await this.shouldSendPush(
            memberId,
            gift,
            action,
            actionDetails,
        );
        const shouldSend =
            shouldSendForThisActivity &&
            !!gift.receiverFirstName &&
            (await this.getUserPushStatus(memberId));
        if (shouldSend) {
            const formData = new FormData();
            formData.append("access_token", `${config?.accessToken}`);
            formData.append(
                "message",
                this.preparePushMessage(gift, action, actionDetails),
            );
            formData.append("alias", memberId);
            this.doXHR({
                url: this.routes.server.api.dashboard.push(),
                method: "POST",
                data: formData,
            }).then(() => {
                if (shouldSendForThisActivity)
                    this.setPushSent(memberId, gift, action, actionDetails);
            });
        }
    }

    public setPushStatus(memberId: string, status: boolean) {
        return setDoc(
            this.getPushStatusDoc(memberId),
            {
                shouldSendPush: status,
            },
            this.mergeOption,
        );
    }

    public observePushStatus(
        memberId: string,
        onData: (status: boolean) => void,
        onError: (e: any) => void,
    ) {
        return onSnapshot(
            this.getPushStatusDoc(memberId),
            (snap) => {
                const data = snap.data() as PushStatus;
                onData(this.getPushStatus(data));
            },
            (e) => onError(e),
        );
    }

    private async getUserPushStatus(memberId: string) {
        if (this.shouldLog()) {
            try {
                const userData = (
                    await getDoc(this.getPushStatusDoc(memberId))
                ).data() as PushStatus;
                return this.getPushStatus(userData);
            } catch (e) {
                return false;
            }
        } else return false;
    }

    private getPushStatusDoc(memberId: string) {
        return doc(this.store, this.baseCollection, memberId);
    }

    private preparePushMessage(
        gift: SentGiftToLog,
        action: SentGiftAction,
        actionDetails: ActionDetails,
    ) {
        let actionForMsg: string;
        if (action === SentGiftAction.OPEN) actionForMsg = "opened";
        else if (action === SentGiftAction.PLAYING) actionForMsg = "is playing";
        else if (action === SentGiftAction.COMPLETED)
            actionForMsg = "completed";
        let userName = gift.receiverFirstName;
        if (!!gift.receiverLastName) userName += ` ${gift.receiverLastName}`;
        let predicate = gift.contentTitle;
        if (!!actionDetails?.stepName)
            predicate = `${actionDetails.stepName} from ${gift.contentTitle}`;
        return `${userName} ${actionForMsg} ${predicate}.`;
    }

    private getPushStatus(status: PushStatus) {
        return !status
            ? true
            : status.shouldSendPush === undefined
            ? true
            : status.shouldSendPush;
    }

    private setPushSent(
        memberId: string,
        gift: SentGift,
        action: SentGiftAction,
        actionDetails: ActionDetails,
    ) {
        const data: PushLog = {
            sku: gift.sku,
            action,
        };
        if (!!actionDetails) {
            if (!!actionDetails.stepSku) data.sku = actionDetails.stepSku;
            if (!!actionDetails.index) data.index = actionDetails.index;
        }
        setDoc(
            this.getPushLogDocument(memberId),
            {
                [this.pushLogDocument]: {
                    [config?.user?.memberId]: arrayUnion(data),
                },
            },
            this.mergeOption,
        );
    }

    private async shouldSendPush(
        memberId: string,
        gift: SentGift,
        action: SentGiftAction,
        actionDetails: ActionDetails,
    ) {
        if (this.shouldLog()) {
            try {
                const userData = (
                    await getDoc(this.getPushLogDocument(memberId))
                ).data() as PushLogs;
                if (!userData) return true;
                const skuToMatch = actionDetails?.stepSku
                    ? actionDetails.stepSku
                    : gift.sku;
                return !userData.PushLog[config?.user?.memberId]?.find(
                    (p: PushLog) =>
                        p.sku === skuToMatch &&
                        p.action === action &&
                        p.index === actionDetails?.index,
                );
            } catch (e: any) {
                return false;
            }
        } else return false;
    }

    private getPushLogDocument(memberId: string) {
        return doc(
            this.store,
            this.baseCollection,
            memberId,
            this.activeCollection,
            this.pushLogDocument,
        );
    }
}
