import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContentAttribute } from "JS/Models/Attributes";
import omit from "lodash-es/omit";

export enum AttributeType {
    "AUDIO" = "audio",
    "VIDEO" = "video",
}

export interface AttributesState {
    audio?: ContentTypeAttributes;
    video?: Omit<ContentTypeAttributes, "playlistSnackMsgCount">;
}

export interface ContentSpeakerName {
    nid: number;
    speakerName: string;
}

export interface ContentTypeAttributes {
    attributesList?: ContentAttribute[];
    isAttributeFetched?: boolean;
    uniqueSpeakers?: ContentSpeakerName[];
    contentSpeakers?: ContentSpeakerName[];
    selectedSpeakers?: string[];
    playlistSnackMsgCount?: number;
}

const initialStateData: ContentTypeAttributes = {
    attributesList: [],
    isAttributeFetched: false,
    uniqueSpeakers: [],
    contentSpeakers: [],
    selectedSpeakers: [],
    playlistSnackMsgCount: 0,
};

const initialState: AttributesState = {
    audio: initialStateData,
    video: omit(initialStateData, "playlistSnackMsgCount"),
};

export const attributesStateSlice = createSlice({
    name: "AttributesState",
    initialState,
    reducers: {
        setGlobalAudioAttributes: (
            state: AttributesState,
            data: PayloadAction<ContentTypeAttributes>,
        ) => {
            state = {
                ...state,
                audio: {
                    ...state?.audio,
                    ...data.payload,
                },
            };
            return state;
        },

        setGlobalVideoAttributes: (
            state: AttributesState,
            data: PayloadAction<
                Omit<ContentTypeAttributes, "playlistSnackMsgCount">
            >,
        ) => {
            state = {
                ...state,
                video: {
                    ...state?.video,
                    ...data.payload,
                },
            };
            return state;
        },
        resetAudAttributesState: (state: AttributesState) => {
            state = {
                ...state,
                audio: { ...state?.audio, selectedSpeakers: [] },
            };
            return state;
        },
        resetVidAttributesState: (state: AttributesState) => {
            state = {
                ...state,
                video: { ...state?.video, selectedSpeakers: [] },
            };
            return state;
        },
        resetOnlyAttributesState: (state: AttributesState) => {
            state = {
                ...initialState,
                audio: {
                    ...initialState?.audio,
                    selectedSpeakers: state?.audio?.selectedSpeakers,
                    playlistSnackMsgCount: state?.audio?.playlistSnackMsgCount,
                },
                video: {
                    ...initialState?.video,
                    selectedSpeakers: state?.video?.selectedSpeakers,
                },
            };
            return state;
        },
        resetAllAttributesState: () => {
            return initialState;
        },
    },
});
export const {
    setGlobalAudioAttributes,
    setGlobalVideoAttributes,
    resetOnlyAttributesState,
    resetAllAttributesState,
    resetAudAttributesState,
    resetVidAttributesState,
} = attributesStateSlice.actions;
