import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import {
    DownloadCountState,
    setGlobalDownloadCount,
} from "JS/Redux/DownloadCount";

export const useGlobalDownloadCount = () => {
    const dispatch = useAppDispatch();

    return {
        downloadCount: useAppSelector((state) => state.downloadCount),
        setGlobalDownloadCount: (countState: DownloadCountState) =>
            dispatch(setGlobalDownloadCount(countState)),
    };
};
