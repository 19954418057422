import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

export const DashboardListItemIcon = (props: DashboardListItemIconProps) => {
    const classes = useStyles(props);

    const { className, contentType } = props;
    return (
        <Box className={classes.ListIconsWrapper}>
            {contentType?.toLowerCase() === "video" && (
                <FontAwesomeIcon
                    className={clsx(
                        className,
                        classes.IconVideo,
                        classes.primaryColor,
                    )}
                    icon={["fas", "video"]}
                />
            )}
            {contentType?.toLowerCase() === "course" && (
                <FontAwesomeIcon
                    className={clsx(
                        className,
                        classes.IconCourse,
                        classes.primaryColor,
                    )}
                    icon={["fas", "award"]}
                />
            )}
            {contentType?.toLowerCase() === "audio" && (
                <FontAwesomeIcon
                    className={clsx(
                        className,
                        classes.IconAudio,
                        classes.primaryColor,
                    )}
                    icon={["fas", "music"]}
                />
            )}
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ListIconsWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "-20px",
        },
        IconVideo: {
            fontSize: "25px",
        },
        IconCourse: {
            fontSize: "30px",
        },
        IconAudio: {
            fontSize: "27px",
        },
        primaryColor: {
            color: theme.palette.primary.main,
        },
    }),
);

interface DashboardListItemIconProps extends InternalStandardProps<BoxProps> {
    contentType: string;
}
