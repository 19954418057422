import {
    CourseDetail,
    CourseStatsBody,
    CourseStep,
    DownloadedCourseDetail,
} from "JS/Models";
import {
    FBCourseResponse,
    FBCourseStats,
    FBFunctionStepStats,
    FBStepStats,
    StepActions,
    StepContentType,
} from "JS/Models/Firebase/Courses";
import {
    CourseFirebaseService,
    IAttendanceDetails,
    courseFirebaseService,
} from "JS/Services/Firebase";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import {
    useWriteOfflineCourseStats,
    useWriteOfflineStepStats,
} from "../DownloadCourses";
import { useFirebaseAuth } from "./Auth";
import { appConstants } from "JS/Helpers/Contants";
import { messaging } from "JS/Helpers/UserMessaging";

const useRetryConnection = () => {
    const { coursesFirebaseSignIn } = useFirebaseAuth();

    const retryConnection = <T>(
        func: (...args) => Promise<T>,
    ): ((...args) => Promise<T>) => {
        return (...args) => {
            return new Promise(async (res, rej) => {
                try {
                    const result = await func(...args);
                    res(result);
                } catch (err) {
                    if (err.message === appConstants.firebaseError.permDenied) {
                        try {
                            await coursesFirebaseSignIn();
                            const result = await func(...args);
                            res(result);
                        } catch (err) {
                            rej(err);
                        }
                    } else {
                        rej(err);
                    }
                }
            });
        };
    };

    return { retryConnection };
};

export const useCourseStats = (
    skuId: string = null,
    detail: CourseDetail | DownloadedCourseDetail = null,
    isPurchased?: boolean,
) => {
    const [loading, setLoading] = useState(false);
    const [courseStats, setCourseStats] = useState<FBCourseStats>(null);

    const { enqueueSnackbar } = useSnackbar();
    const { writeOfflineFBCourseStats } = useWriteOfflineCourseStats();

    const { retryConnection } = useRetryConnection();

    const getStats = async (
        skuId: string,
        detail: CourseDetail,
        skipInitialize?: boolean,
    ) => {
        return courseFirebaseService
            .getCourseStats(skuId)
            .then(async (stats) => {
                if (stats === null && !skipInitialize) {
                    const initialSteps =
                        await courseFirebaseService.initializeCourseStats(
                            skuId,
                            {
                                type: detail.content_item_type_name,
                                title: detail.title,
                                purchased_state: isPurchased ? 2 : 1,
                                state: isPurchased ? 2 : 1,
                                analytics: {
                                    total_steps: detail.steps
                                        ? detail.steps.length
                                        : 0,
                                },
                            },
                        );
                    stats = initialSteps;
                    writeOfflineFBCourseStats(skuId, initialSteps);
                    setCourseStats(initialSteps);
                } else {
                    setCourseStats(stats);
                }
                return stats;
            });
    };

    const refetch = useCallback(
        async (
            skuId: string,
            detail: CourseDetail,
            skipInitialize?: boolean,
        ) => {
            setLoading(true);

            const fetch = retryConnection<FBCourseStats>(getStats);
            return fetch(skuId, detail, skipInitialize)
                .catch((err) => {
                    enqueueSnackbar(messaging.common.error, {
                        variant: "error",
                    });
                    return null as FBCourseStats;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [enqueueSnackbar, isPurchased],
    );

    useEffect(() => {
        if (skuId != null && detail != null) {
            refetch(skuId, detail);
        }
    }, [skuId, detail, refetch]);

    return {
        courseStats,
        loading,
        refetch,
    };
};

export const useWriteCourseStatsFBFunc = () => {
    const [loading, setLoading] = useState(false);
    const [courseStats, setCourseStats] = useState<FBCourseResponse>(null);
    const { writeOfflineFBCourseStats } = useWriteOfflineCourseStats();

    const { enqueueSnackbar } = useSnackbar();
    const service = new CourseFirebaseService();

    const writeFBCourseStats = useCallback(
        (courseStats: CourseStatsBody, skipOfflineStats?: boolean) => {
            setLoading(true);

            return service
                .writeCourseStatsOnFB(courseStats)
                .then((updatedStats) => {
                    setCourseStats(updatedStats);
                    !skipOfflineStats &&
                        writeOfflineFBCourseStats(
                            courseStats.skuId,
                            updatedStats.stats,
                        );
                    return updatedStats;
                })
                .catch((err) => {
                    enqueueSnackbar(messaging.common.error, {
                        variant: "error",
                    });

                    return null as FBCourseResponse;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        writeFBCourseStats,
        courseStats,
        loading,
    };
};

export const useWriteOfflineCourseStatsFBFunc = () => {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const service = new CourseFirebaseService();

    const writeOffineCourseStatsFB = useCallback(
        (courseSku: string, stats: FBCourseStats) => {
            setLoading(true);

            return service
                .writeOfflineCourseStatsOnFB(courseSku, stats)
                .then((updatedStats) => updatedStats)
                .catch((err) => {
                    enqueueSnackbar(messaging.common.error, {
                        variant: "error",
                    });

                    return null as FBCourseStats;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        writeOffineCourseStatsFB,
        loading,
    };
};

export const useWriteStepStats = () => {
    const [loading, setLoading] = useState(false);
    const [stepStats, setStepStats] = useState<FBStepStats>(null);
    const { writeOfflineFBStepStats } = useWriteOfflineStepStats();
    const { enqueueSnackbar } = useSnackbar();
    const service = new CourseFirebaseService();

    const writeFBStepStats = useCallback(
        (
            courseSkuId: string,
            stepSkuId: string,
            action: StepActions,
            stats: Partial<FBFunctionStepStats>,
        ) => {
            setLoading(true);

            return service
                .writeStepStats(courseSkuId, stepSkuId, action, stats)
                .then((statsData) => {
                    writeOfflineFBStepStats(
                        courseSkuId,
                        stepSkuId,
                        statsData.data.stats,
                    );
                    setStepStats(statsData.data.stats);
                    return statsData.data.stats;
                })
                .catch((err) => {
                    enqueueSnackbar(err.message, {
                        variant: "error",
                    });
                    return null as FBStepStats;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [enqueueSnackbar],
    );

    return {
        writeFBStepStats,
        stepStats,
        loading,
    };
};

export const useWriteEventAttendance = () => {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const writeEventAttendance = useCallback(
        (actionDetails: IAttendanceDetails) => {
            setLoading(true);
            return courseFirebaseService
                .markAttendance(actionDetails)
                .then((res) => {
                    if (res) {
                        enqueueSnackbar(actionDetails?.successMessage, {
                            variant: "success",
                        });
                    } else {
                        enqueueSnackbar(actionDetails?.failureMessage, {
                            variant: "error",
                        });
                    }
                    return res;
                })
                .catch(() => {
                    enqueueSnackbar(actionDetails?.failureMessage, {
                        variant: "error",
                    });
                    return null;
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [],
    );

    return {
        writeEventAttendance,
        loading,
    };
};
