import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    BoxProps,
    Fab,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
} from "@mui/material";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "notistack";
import qs from "qs";

import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useRouting } from "JS/React/Hooks/Routes";
import { useAppSelector } from "JS/Redux/Store";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { getDownloadingSpeed, getRemainingTime } from "JS/Helpers";
import { AppDivider } from "JS/React/Components/AppDivider";
import { QueueItems } from "./Components/QueueItems";
import { AppTypography } from "../../Components/AppTypography";
import { Queue } from "./Components/Queue";
import { Layout } from "../Layout";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: `${theme.palette.common.black}`,
        },
        content: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            color: `${theme.palette.common.black}`,
        },
        list: {
            overflowY: "auto",
            width: "100%",
            height: `calc(100vh - ${theme.footer.height} - 40px)`,
            paddingBottom: `calc(3*${theme.footer.height})`,
        },
        editIconWrapper: {
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            width: "100%",
        },
        editIcon: {
            fontSize: "20px",
            cursor: "pointer",
            color: theme.palette.grey[600],
        },
        fabWarpper: {
            position: "fixed",
            right: "10px",
            bottom: `calc(${theme.footer.height} + 10px)`,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
        },
        ProgressItemWrapper: {
            backgroundColor:
                theme.palette.mode === "light"
                    ? `${theme.palette.grey[200]}`
                    : `${theme.palette.background.paper}`,
        },
    }),
);

export interface SortableContainerQueueProps
    extends InternalStandardProps<GridProps> {
    items: any;
    isEdit: boolean;
    handleDeleteItem: (nid: string) => void;
    isDisableControls: boolean;
}

const SortableContainerQueue = SortableContainer(
    (props: SortableContainerQueueProps) => {
        const { isEdit, items, isDisableControls, handleDeleteItem, ...rest } =
            props;
        return (
            <Grid {...rest}>
                {items?.map(
                    (item, index) =>
                        index !== 0 && (
                            <>
                                <DownloadingQueueItem
                                    isDisableControls={isDisableControls}
                                    isDownloading={false}
                                    isEdit={isEdit}
                                    value={item}
                                    key={item.nid}
                                    index={index}
                                    isSortable={items.length > 2}
                                    handleDeleteItem={handleDeleteItem}
                                />
                                <AppDivider />
                            </>
                        ),
                )}
            </Grid>
        );
    },
);

export interface DownloadingQueueItemProps {
    key: string;
    value: any;
    isEdit: boolean;
    isDownloading: boolean;
    isSortable: boolean;
    isDisableControls: boolean;
    handleDeleteItem: (nid: string) => void;
}

const DownloadingQueueItem = SortableElement(
    (props: DownloadingQueueItemProps) => {
        const {
            key,
            isEdit,
            value,
            isDownloading,
            isSortable,
            isDisableControls,
            handleDeleteItem,
        } = props;
        return (
            <Queue
                key={key}
                isEdit={isEdit}
                isDisableControls={isDisableControls}
                type={value?.type}
                nid={value?.nid}
                name={
                    value?.type === "audioBundle" ||
                    value?.type === "giftAudioBundle"
                        ? value?.media_title
                        : value?.name
                }
                isSortable={isSortable}
                isShowProgress={false}
                isDownloading={isDownloading}
                handleDeleteItem={handleDeleteItem}
            />
        );
    },
);

export interface DownloadingQueueProps
    extends InternalStandardProps<BoxProps> {}

export const DownloadingQueue = (props: DownloadingQueueProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const history = useHistory();
    const { linkProvider } = useRouting();
    const { enqueueSnackbar } = useSnackbar();
    const [downloadingQueue, setDownloadingQueue] = useState([]);
    const [isEdit, setEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [showDeleteConfirm, setShowDeleteConfim] = useState<boolean>(false);
    const [deleteItemNid, setDeleteItemNid] = useState<string>("");
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );
    const location = useLocation();

    const { homeStack, audiosStack, videosStack, coursesStack, moreStack } =
        useGlobalNavStack();

    const downloadState = useAppSelector((state) => state.download);
    const {
        inProgressNid,
        loadedContent,
        startTime,
        contentLength,
        downloadContentPercentage,
        downloadContentName,
    } = downloadState;

    const getDownloadQueue = () => {
        return localStorage.getItem("downloadQueue")
            ? JSON.parse(localStorage.getItem("downloadQueue"))
            : [];
    };
    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { contentType = "" } = parsedQueryString;

        return {
            contentType: contentType.toString(),
        };
    }, [location?.search]);

    const contentType = queryParams?.contentType;

    useEffect(() => {
        const downloadQueue = getDownloadQueue();

        if (
            downloadQueue?.length !== downloadingQueue?.length ||
            downloadQueue?.length === 0
        ) {
            setDownloadingQueue(downloadQueue);
        }

        if (
            !inProgressNid &&
            downloadQueue?.length > 1 &&
            downloadQueue[0]?.tries === 4
        ) {
            let tempQueue = [...downloadingQueue];
            tempQueue?.splice(0, 1);
            setDownloadingQueue(tempQueue);
        }

        if (
            !inProgressNid &&
            downloadQueue?.length === 1 &&
            (downloadedContentNids?.find(
                (x) =>
                    (x?.split("-").length === 3
                        ? `${x?.split("-")[1]}-${x?.split("-")[2]}`
                        : x?.split("-")[1]) === downloadQueue[0]?.nid,
            ) ||
                downloadQueue[0]?.tries === 4)
        ) {
            setDownloadingQueue([]);
        }
        setLoading(false);
    }, [inProgressNid, downloadContentName, downloadedContentNids]);

    const inProgress = (nid: string) => nid === inProgressNid;

    const handleEditAndSaveClick = () => {
        setEdit(!isEdit);
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let sortedArray = arrayMoveImmutable(
            downloadingQueue,
            oldIndex,
            newIndex,
        );

        setDownloadingQueue(sortedArray);
        localStorage.setItem("downloadQueue", JSON.stringify(sortedArray));
    };

    const handleDeleteConfirm = (nid: string) => {
        setDeleteItemNid(nid);
        setShowDeleteConfim(true);
    };

    const handleDeleteItem = () => {
        if (
            inProgressNid &&
            deleteItemNid !== inProgressNid &&
            downloadingQueue.findIndex((x) => x.nid === deleteItemNid) !== -1
        ) {
            let tempQueue = [...downloadingQueue];
            tempQueue.splice(
                tempQueue.findIndex((x) => x.nid === deleteItemNid),
                1,
            );
            setDownloadingQueue(tempQueue);
            setDeleteItemNid("");
            localStorage.setItem("downloadQueue", JSON.stringify(tempQueue));
            enqueueSnackbar(`${messaging.downloadingQueue.success}`, {
                variant: "success",
            });
        } else {
            if (
                !(
                    downloadingQueue.length > 2 &&
                    downloadingQueue.findIndex((x) => x.nid === deleteItemNid) >
                        1
                )
            ) {
                enqueueSnackbar(`${messaging.downloadingQueue.deleteError}`, {
                    variant: "warning",
                });
                setDeleteItemNid("");
            } else {
                let tempQueue = [...downloadingQueue];
                tempQueue.splice(
                    tempQueue.findIndex((x) => x.nid === deleteItemNid),
                    1,
                );
                setDownloadingQueue(tempQueue);
                setDeleteItemNid("");
                localStorage.setItem(
                    "downloadQueue",
                    JSON.stringify(tempQueue),
                );
                enqueueSnackbar(`${messaging.downloadingQueue.success}`, {
                    variant: "success",
                });
            }
        }
        handleCloseDeleteConfirm();
    };

    const handleCloseDeleteConfirm = () => {
        setDeleteItemNid("");
        setShowDeleteConfim(false);
    };

    const handleBackNavigation = () => {
        if (!homeStack?.isHomeTrack) {
            if (contentType === "Audio") {
                history.push(audiosStack?.lastVisited);
            } else if (contentType === "Video") {
                history.push(videosStack?.lastVisited);
            } else if (contentType === "gift") {
                history.goBack();
            } else if (contentType === "Document") {
                history.push(moreStack?.lastVisited);
            } else if (contentType === "Course") {
                history.push(coursesStack?.lastVisited);
            } else {
                history.push(linkProvider.react.more().index());
            }
        } else {
            history.push(linkProvider.react.home());
        }
    };

    return (
        <Layout>
            <Box {...rest} className={clsx(className, classes.root)}>
                <AppHeader
                    title="Downloading Queue"
                    canGoBack
                    onBackClick={handleBackNavigation}
                    searchable={false}
                />
                {!loading && downloadingQueue && downloadingQueue.length > 0 && (
                    <>
                        <Grid className={clsx(classes, classes.list)}>
                            <Box className={classes.ProgressItemWrapper}>
                                <QueueItems
                                    contentType={downloadingQueue[0]?.type}
                                    isShowProgress={true}
                                    contentTitle={
                                        downloadingQueue[0]?.type ===
                                            "audioBundle" ||
                                        downloadingQueue[0]?.type ===
                                            "giftAudioBundle"
                                            ? downloadingQueue[0]?.media_title
                                            : downloadingQueue[0]?.name
                                    }
                                    isDownloading={inProgress(
                                        downloadingQueue[0]?.nid as string,
                                    )}
                                    downloadPercentage={
                                        inProgress(
                                            downloadingQueue[0]?.nid as string,
                                        )
                                            ? downloadContentPercentage
                                            : 0
                                    }
                                    status={downloadingQueue[0]?.status}
                                    timeRemaining={getRemainingTime(
                                        downloadingQueue[0]?.nid,
                                        inProgressNid,
                                        loadedContent,
                                        contentLength,
                                        startTime,
                                    )}
                                    downloadingSpeed={getDownloadingSpeed(
                                        downloadingQueue[0]?.nid,
                                        inProgressNid,
                                        loadedContent,
                                        contentLength,
                                        startTime,
                                    )}
                                />
                            </Box>
                            <SortableContainerQueue
                                key="sortableContainer"
                                items={downloadingQueue}
                                handleDeleteItem={handleDeleteConfirm}
                                isEdit={isEdit}
                                isDisableControls={inProgressNid === ""}
                                onSortEnd={onSortEnd}
                                useDragHandle={true}
                            />
                        </Grid>
                        <Box className={clsx(className, classes.fabWarpper)}>
                            {downloadingQueue?.length > 1 && (
                                <Fab
                                    color="secondary"
                                    aria-label="edit"
                                    onClick={handleEditAndSaveClick}
                                >
                                    <FontAwesomeIcon
                                        icon={[
                                            "fas",
                                            isEdit
                                                ? "circle-check"
                                                : "pen-to-square",
                                        ]}
                                    />
                                </Fab>
                            )}
                        </Box>
                        <AppConfimationDialog
                            open={showDeleteConfirm}
                            confirmText="Yes"
                            popupMainText={`${messaging.downloadingQueue.deleteConfirmation}`}
                            cancelButtonText="No"
                            onConfirm={handleDeleteItem}
                            onClose={handleCloseDeleteConfirm}
                            showConfirmFirst={true}
                        />
                    </>
                )}
                {!loading && downloadingQueue && downloadingQueue.length === 0 && (
                    <AppTypography
                        align="center"
                        variant="h6"
                        className={classes.content}
                    >
                        {messaging.common.noItemsFound}
                    </AppTypography>
                )}
            </Box>
        </Layout>
    );
};
