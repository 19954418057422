import { Ticket, Webcast } from "JS/Models/Events";
import { EventsHelper } from "./EventsHelper";

export class WebcastService {
    private helper = new EventsHelper();
    private allWebcastParams = {
        Bucket: this.helper.bucket,
        Key: `${this.helper.folder}/all_webcasts.json`,
        ResponseCacheControl: 'no-cache',
    };

    public async getWebcasts(): Promise<Webcast[]> {
        this.helper.initConfig();
        const s3 = this.helper.getS3();
        return new Promise<Webcast[]>((res, rej) => {
            s3.getObject(this.allWebcastParams, (err, data) => {
                if (err) rej();
                else {
                    const webcasts: Webcast[] = JSON.parse(
                        data.Body.toString(),
                    );
                    const activeWebcasts = webcasts.filter((w) =>
                        this.isWebcastActive(w),
                    );
                    Promise.all(
                        activeWebcasts.map(
                            (w) =>
                                new Promise<Webcast>((wres, _) => {
                                    Promise.all([
                                        this.helper.primaryTicketPromise(
                                            `${this.helper.folder}/tix/${w.FolderName}`,
                                        ),
                                        this.helper.secondaryTicketPromise(
                                            `${this.helper.folder}/tix/${w.FolderName}`,
                                        ),
                                    ]).then((values) => {
                                        let primaryTicket: Ticket = null;
                                        let secondaryTicket: Ticket = null;
                                        if (values[0] != null)
                                            primaryTicket = JSON.parse(
                                                values[0].Body.toString(),
                                            );
                                        if (values[1] != null)
                                            secondaryTicket = JSON.parse(
                                                values[1].Body.toString(),
                                            );
                                        if (primaryTicket != null)
                                            w.isAuthorized =
                                                primaryTicket.WebcastAccess ===
                                                1;
                                        if (
                                            !w.isAuthorized &&
                                            secondaryTicket != null
                                        )
                                            w.isAuthorized =
                                                secondaryTicket.WebcastAccess ===
                                                1;
                                        wres(w);
                                    });
                                }),
                        ),
                    ).then((values) => {
                        const notNull = values.filter((value) => value != null);
                        if (notNull.length === 0) rej();
                        else
                            res(
                                notNull.sort((w1, w2) =>
                                    w1.Description.localeCompare(
                                        w2.Description,
                                    ),
                                ),
                            );
                    });
                }
            });
        });
    }

    private isWebcastActive(webcast: Webcast): boolean {
        const webcastEnd = new Date(webcast.EndDate);
        return (
            webcast.IsActive === "true" &&
            Date.now() - webcastEnd.getTime() <= 0
        );
    }
}
