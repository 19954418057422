import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { Layout, NavigationTypes } from "../Layout";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { getPublicUrl } from "JS/Helpers/S3Helper";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { GraphicEq as GraphicEqIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContentMedia, MiniPlayerOptions } from "JS/Models/Common";
import {
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
} from "JS/Models";
import { useGlobalAudios } from "JS/React/Hooks/Audio";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
} from "JS/React/Hooks/Gifts";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import qs from "qs";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRouting } from "JS/React/Hooks/Routes";
import { GiftCategories } from "../Gifts";
import { FavoritesTypes } from "../Favorites";
import { config } from "JS/Config";
import { getDexieConnectionRefresh } from "JS/Database/Dexie";
import {
    useContentPlayingHandlers,
    usePlayerHandlers,
} from "JS/React/Hooks/MediaPlayer";
import { useMiniPlayerHandlers } from "JS/React/Hooks/MiniPlayer";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import { useResumeRecentlyBundles } from "JS/React/Hooks/ResumeRecently";
import { getCurrentUrl } from "JS/Helpers/MiniPlayerHelper";
import { PLAYER_PROGRESS_INTERVAL } from "JS/Helpers/Contants";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import {
    SentGiftType,
    getPlayerPlayedTimeForBundle,
    toSentGift,
} from "JS/Models/Firebase/GiftDashboard";

export interface BundlePlayerProps extends InternalStandardProps<GridProps> {}

export const BundlePlayer = (props: BundlePlayerProps) => {
    const classes = useStyles(props);
    const { className } = props;
    const params: any = useParams();
    const { contentId } = params;
    const [mediaURL, setMediaURL] = useState("");
    const { linkProvider } = useRouting();
    const location = useLocation();
    const { homeStack } = useGlobalNavStack();

    const [downloadedAudioUrl, setDownloadedAudioUrl] = useState<string>("");
    const [isAudioDownloaded, setIsAudioDownloaded] = useState<boolean>(false);

    const [currentPlayingState, setCurrentPlayingState] = useState(true);
    const [playingState, setPlayingState] = useState(false);
    const { handlePlayerPause, handlePlayerPlay } = useContentPlayingHandlers(
        (val) => {
            setCurrentPlayingState(val);
            setPlayingState(val);
        },
    );
    const { miniPlayer, onClickMiniPlayer } = useMiniPlayerHandlers();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const {
            isGift = false,
            index = 0,
            categoryId = 0,
            isMixedContent = false,
            scrollTo = 0,
        } = parsedQueryString;

        return {
            isGift: isGift.toString(),
            index: index.toString(),
            categoryId: categoryId.toString(),
            isMixedContent: !!isMixedContent ? true : false,
            scrollTo: scrollTo,
        };
    }, [location?.search]);

    const mediaIndex = +queryParams.index;
    const history = useHistory();
    const setQueryParams = (params: Partial<typeof queryParams>) => {
        history.replace({
            pathname: location.pathname,
            search: qs.stringify({ ...queryParams, ...params }),
        });
    };

    const { audios } = useGlobalAudios();
    const { isGift } = useReceivedMemberAudioGifts(true);
    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();

    const isReceived = () => {
        return queryParams.isGift === "true";
    };

    const audio = useMemo(() => {
        if (isReceived()) {
            const receivedMemberGift = receivedMemberAudioGifts?.find(
                (g) => g?.nid === contentId,
            );
            if (receivedMemberGift) return receivedMemberGift;
            return receivedProspectAudioGifts?.find(
                (g) => g?.nid === contentId,
            );
        } else return audios?.find((audio) => audio?.nid === contentId);
    }, [
        audios,
        contentId,
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
    ]);

    const {
        playerRef,
        setIsReady,
        handleContentCompleted,
        handleRecentlyPlayed,
        onMediaPlayerReady,
        onPlayerProgress,
        isReadyToPlay,
        setIsReadyToPlay,
    } = useResumeRecentlyBundles(audio, mediaIndex);

    const { handleChangeSeek } = usePlayerHandlers(playerRef);

    const isMediaPlayable =
        audio?.isReceived || audio?.is_purchased || audio?.publish_free === "1";
    const media: ContentMedia[] = audio?.media;
    const isBundle = media && media?.length > 1;
    const next = () => {
        setIsReady(false);
        setIsReadyToPlay(false);
        setCurrentPlayingState(true);
        setQueryParams({
            isGift: queryParams.isGift,
            index: ((mediaIndex + 1) % media?.length).toString(),
        });
    };
    const previous = () => {
        setIsReady(false);
        setIsReadyToPlay(false);
        setCurrentPlayingState(true);
        setQueryParams({
            isGift: queryParams.isGift,
            index: (mediaIndex === 0
                ? media?.length - 1
                : mediaIndex - 1
            ).toString(),
        });
    };
    const onComplete = () => {
        if (audio) {
            handleContentCompleted();
            if (isReceivedMemberAudio) {
                const actionsDetails = getDashboardActionDetails();
                logGiftCompleted({ index: actionsDetails.index });
            }
            if (mediaIndex < media?.length - 1) next();
        }
    };
    const { logFirebaseEvent } = useFirebaseLogger();
    const [thumbnail, setThumbnail] = useState<string>(null);
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const fetchThumbnails = async () => {
        if (!thumbnail) {
            const thumb = await getPublicUrl(
                audio.image_url_prefix,
                audio.image_url_postfix,
                audio.image_file_name,
            );

            setThumbnail(thumb);
        }
    };

    useEffect(() => {
        if (media) {
            const { media_url_prefix, media_url_postfix, media_file_name } =
                media[mediaIndex];

            if (audio) {
                getDexieConnectionRefresh()
                    .audios.get(
                        `${config.user.memberId}-${audio?.nid}-${
                            mediaIndex + 1
                        }`,
                    )
                    .then((res) => {
                        if (res) {
                            if (res.blob) {
                                setIsAudioDownloaded(true);
                                setDownloadedAudioUrl(
                                    URL.createObjectURL(res?.blob),
                                );
                            }
                        } else {
                            setIsAudioDownloaded(false);
                        }
                    })
                    .catch((err) => {
                        setIsAudioDownloaded(false);
                    });

                verifyAwsKeys().then(() => {
                    fetchThumbnails();
                    getPublicUrl(
                        media_url_prefix,
                        media_url_postfix,
                        media_file_name,
                    ).then((signedURL) => setMediaURL(signedURL));
                });
            }
        }
    }, [audio?.nid, media, mediaIndex]);

    const handleAudioStartedPlaying = () => {
        if (audio) {
            const { nid, media_file_name: sku } = audio;
            if (!isAudioDownloaded) {
                const action: FirebaseEventAction = {
                    action: EventActions.LIVE_STREAM,
                    nId: nid,
                    contentTitle: media[mediaIndex].media_title,
                    skuId: sku,
                    category: EventCategories.AUDIOS,
                };

                logFirebaseEvent(EventNames.AUDIO_LIVE_STREAM, action);
            } else {
                const action: FirebaseEventAction = {
                    action: EventActions.PLAY,
                    nId: nid,
                    contentTitle: media[mediaIndex].media_title,
                    skuId: sku,
                    category: EventCategories.AUDIOS,
                };
                logFirebaseEvent(EventNames.AUDIO_PLAYED, action);
            }
            handleRecentlyPlayed();
        }
    };

    const navId =
        isReceived() &&
        (queryParams?.categoryId === GiftCategories.RECEIVED_AS_MEMBER ||
            queryParams?.categoryId === GiftCategories.RECEIVED_AS_PROSPECT)
            ? NavigationTypes.MORE
            : queryParams?.categoryId === FavoritesTypes.FAVAUDIOS
            ? NavigationTypes.MORE
            : NavigationTypes.AUDIOS;

    const { isMixedContent } = queryParams;

    const handleBackNavigation = () => {
        if (!isMixedContent) {
            if (!homeStack.isHomeTrack) {
                history.push(
                    linkProvider.react
                        .bundle()
                        .audios(
                            contentId,
                            queryParams?.categoryId,
                            queryParams?.isGift,
                            {
                                scrollTo: queryParams?.scrollTo
                                    ? +queryParams?.scrollTo
                                    : 0,
                            },
                        ),
                );
            } else {
                history.goBack();
            }
        } else {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .bundleDetail(
                        contentId,
                        queryParams?.categoryId,
                        audio?.isReceived.toString(),
                        {
                            isMixedContent: true,
                            scrollTo: queryParams?.scrollTo?.toString(),
                        },
                    ),
            );
        }
    };

    const url = isAudioDownloaded ? downloadedAudioUrl : mediaURL;

    const [isLoop, setLoop] = useState(false);

    const miniplayerOptions = useRef<MiniPlayerOptions>(null);
    const alreadyMini = useRef<boolean>(null);

    useEffect(() => {
        if (miniPlayer.url) alreadyMini.current = true;
        else alreadyMini.current = false;
    }, [miniPlayer]);

    useMemo(() => {
        miniplayerOptions.current = {
            mediaUrl: url,
            thumbnail: thumbnail,
            title: media ? media[mediaIndex]?.media_title : "",
            singleLoop: isLoop,
            isPlaying: playingState,
            parentLink: getCurrentUrl(),
            content: audio,
            bundleAudio: {
                type: "online",
                data: audio,
                mediaIndex: mediaIndex,
            },
        };
    }, [url, playingState, thumbnail, isLoop, mediaIndex, media, audio]);

    const miniPlayerClick = () => {
        const options = miniplayerOptions.current;
        if (options)
            onClickMiniPlayer({
                isAudio: true,
                mediaUrl: options.mediaUrl,
                thumbnail: options.thumbnail,
                title: options.title,
                singleLoop: options.singleLoop,
                parentLink: options.parentLink,
                bundleAudio: options.bundleAudio,
                content: options.content,
            })();
    };

    // this useEffect is for component unmounting
    useEffect(() => {
        return () => {
            if (
                miniplayerOptions.current &&
                miniplayerOptions.current.isPlaying &&
                !alreadyMini.current
            ) {
                miniPlayerClick();
            }
        };
    }, []);

    const giftDashboardData = useMemo(() => {
        return toSentGift(audio, mediaIndex);
    }, [audio, mediaIndex]);

    const getDashboardActionDetails = () => {
        return getPlayerPlayedTimeForBundle(playerRef, mediaIndex + 1);
    };
    const isReceivedMemberAudio = isGift(contentId);
    const { logGiftCompleted } = useGiftDashboard(
        giftDashboardData,
        SentGiftType.AUDIO,
        audio?.sender?.member_id,
        isReceivedMemberAudio && playingState && !miniPlayer?.url,
        getDashboardActionDetails,
    );

    return (
        <Layout navId={navId} navPath={useLocation().pathname}>
            <AppHeader
                title={media ? media[mediaIndex]?.media_title : ""}
                canGoBack
                searchable={false}
                onBackClick={handleBackNavigation}
            />
            <Grid container className={clsx(classes.root, className)}>
                {audio && (
                    <>
                        <Grid
                            container
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            {isMediaPlayable && isBundle ? (
                                <>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent={"space-between"}
                                            >
                                                <Box
                                                    display={"flex"}
                                                    alignItems="center"
                                                    className={
                                                        classes.forwardBackItem
                                                    }
                                                    onClick={previous}
                                                >
                                                    <FontAwesomeIcon
                                                        className={clsx(
                                                            classes.fwdBackIcon,
                                                            classes.backIcon,
                                                        )}
                                                        icon={[
                                                            "fas",
                                                            "chevron-left",
                                                        ]}
                                                    />
                                                    <Typography
                                                        className={
                                                            classes.fwdBackTypo
                                                        }
                                                        fontWeight={"bold"}
                                                        variant="h5"
                                                    >
                                                        Previous
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    alignItems="center"
                                                    className={
                                                        classes.forwardBackItem
                                                    }
                                                    onClick={next}
                                                >
                                                    <Typography
                                                        className={
                                                            classes.fwdBackTypo
                                                        }
                                                        fontWeight={"bold"}
                                                        variant="h5"
                                                    >
                                                        Next
                                                    </Typography>
                                                    <FontAwesomeIcon
                                                        className={clsx(
                                                            classes.fwdBackIcon,
                                                            classes.fwdIcon,
                                                        )}
                                                        icon={[
                                                            "fas",
                                                            "chevron-right",
                                                        ]}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.playerContainer}
                                    >
                                        <MediaPlayer
                                            title={
                                                media
                                                    ? media[mediaIndex]
                                                          ?.media_title
                                                    : ""
                                            }
                                            playerRef={playerRef}
                                            progressInterval={
                                                PLAYER_PROGRESS_INTERVAL
                                            }
                                            onProgress={onPlayerProgress}
                                            onStart={handleAudioStartedPlaying}
                                            onReady={() => onMediaPlayerReady()}
                                            onUpdateExpiredUrl={() => {
                                                setIsReadyToPlay(true);
                                                setIsReady(false);
                                            }}
                                            mediaURL={url}
                                            imageURL={thumbnail}
                                            onEnded={onComplete}
                                            loop={isLoop}
                                            isAudio={true}
                                            playing={
                                                currentPlayingState &&
                                                !miniPlayer.url
                                            }
                                            onNext={next}
                                            onPrevious={previous}
                                            onPlay={handlePlayerPlay}
                                            onPause={handlePlayerPause}
                                            loading={!isReadyToPlay}
                                            onError={() =>
                                                setIsReadyToPlay(true)
                                            }
                                        />
                                    </Grid>

                                    <Box
                                        display={"flex"}
                                        width={"100%"}
                                        justifyContent={"center"}
                                    >
                                        <PlayerActionBtns
                                            handleChangeSeek={handleChangeSeek}
                                            onClickLoop={() => setLoop(!isLoop)}
                                            isLoop={isLoop}
                                            onClickMiniPlayer={miniPlayerClick}
                                        />
                                    </Box>
                                </>
                            ) : thumbnail ? (
                                <img
                                    alt={audio?.title}
                                    src={thumbnail}
                                    className={classes.mediaImage}
                                />
                            ) : (
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"flex-end"}
                                    className={classes.dummyImageBox}
                                >
                                    <span className={classes.graphicWrapper}>
                                        <GraphicEqIcon
                                            className={classes.graphicIcon}
                                            sx={{
                                                fontSize: "60px",
                                            }}
                                        />
                                    </span>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                className={classes.grey800}
                                variant="body2"
                            >
                                {audio.description}
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>
        </Layout>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },

        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            position: "relative",
            background: theme.palette.grey[900],
            height: "100%",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        giftSectionWarpper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        giftIconButtonDisabled: {
            opacity: "50%",
            cursor: "not-allowed !important",
        },
        giftIconPositioner: {
            position: "absolute",
            bottom: 10,
            right: 10,
        },
        metaData: {
            fontSize: "14px",
        },

        forwardBackItem: {
            padding: theme.spacing(2),
            color: theme.palette.grey.A100,
            cursor: "pointer",
        },
        fwdBackTypo: {
            fontSize: "18px",
        },
        fwdBackIcon: {
            fontSize: "18px",
        },
        backIcon: {
            marginRight: theme.spacing(2),
        },
        fwdIcon: {
            marginLeft: theme.spacing(2),
        },
        controlIcons: {
            minWidth: "40px",
            display: "flex",
        },
        playerIcon: {
            height: "35px",
        },
    }),
);
