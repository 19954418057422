import { Theme, InternalStandardProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useHistory } from "react-router-dom";
import { useRouting } from "JS/React/Hooks/Routes";
import { OfflineDocumentsList } from "./OfflineDocumentsList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
    }),
);

export interface DocumentsProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const OfflineDocuments = (props: DocumentsProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const history = useHistory();
    const { linkProvider } = useRouting();

    const onBackClick = () => {
        history.push(linkProvider.react.offline().index());
    };

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            <AppHeader
                title={"Documents"}
                canGoBack
                searchable={false}
                onBackClick={onBackClick}
            />
            <OfflineDocumentsList />
        </div>
    );
};
