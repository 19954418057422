import { useMemo } from "react";
import clsx from "clsx";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { GiftDashboardListItem } from "./GiftDashboardListItem";
import { Dictionary } from "lodash";
import { DashboardActivity } from "JS/Models/Firebase/GiftDashboard";
import { GiftDashboardFilters } from "JS/Models";
import { sortArrayByDate } from "JS/Helpers";
import { AppTypography } from "JS/React/Components/AppTypography";
import { messaging } from "JS/Helpers/UserMessaging";

export const GiftDashboardListing = (props: GiftDashboadListingProps) => {
    const classes = useStyles(props);

    const { className, giftDashboardLogs, activeFilter, apiError, ...rest } =
        props;

    const noContent =
        giftDashboardLogs && Object.keys(giftDashboardLogs)?.length === 0;

    const sortedGiftDashboardlogs = useMemo(() => {
        if (activeFilter === GiftDashboardFilters.BYDATE) {
            return (
                giftDashboardLogs &&
                Object.keys(giftDashboardLogs)?.sort((a, b) => {
                    return Date.parse(b) - Date.parse(a);
                })
            );
        } else if (
            [
                GiftDashboardFilters.BYTYPE,
                GiftDashboardFilters.BYMEMBER,
            ].includes(activeFilter)
        ) {
            return giftDashboardLogs && Object.keys(giftDashboardLogs).sort();
        } else {
            return giftDashboardLogs && Object.keys(giftDashboardLogs);
        }
    }, [giftDashboardLogs, activeFilter]);

    return (
        <Box className={clsx(className, classes.root)} {...rest}>
            <Box className={classes.listWrapper}>
                {noContent && !apiError && (
                    <AppTypography
                        className={classes.emptyList}
                        align="center"
                        variant="h6"
                    >
                        {messaging.common.noItemsFound}
                    </AppTypography>
                )}
                {noContent && apiError && (
                    <AppTypography
                        className={classes.emptyList}
                        align="center"
                        variant="h6"
                    >
                        {messaging.apiError.somethingWentWrong}
                    </AppTypography>
                )}
                {sortedGiftDashboardlogs?.map((item, idx) => {
                    const data = giftDashboardLogs[item];
                    return (
                        <ListItemWithDateHeader
                            key={`${idx}-${item}`}
                            dashboradLogs={data}
                            createdDate={item}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

const ListItemWithDateHeader = (props: ListItemWithDateHeaderProps) => {
    const classes = useStyles(props);

    const { className, dashboradLogs, createdDate, ...rest } = props;

    const sortedDashbardLogs = useMemo(() => {
        return sortArrayByDate(dashboradLogs, "createdAt", "DESC");
    }, [dashboradLogs]);

    return (
        <>
            <Box className={classes.dateHeader}>{createdDate}</Box>
            {sortedDashbardLogs?.map((x, idx) => (
                <GiftDashboardListItem
                    key={`${x.nid}-${x.createdAt}`}
                    giftActivity={x}
                />
            ))}
        </>
    );
};

export interface DashboradLogs extends DashboardActivity {
    createdDate: string;
    receiverFullName: string;
}

export interface GiftDashboadListingProps
    extends InternalStandardProps<BoxProps> {
    giftDashboardLogs: Dictionary<DashboradLogs[]>;
    activeFilter: GiftDashboardFilters;
    apiError: boolean;
}

export interface ListItemWithDateHeaderProps
    extends InternalStandardProps<BoxProps> {
    dashboradLogs: DashboradLogs[];
    createdDate: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.default,
        },
        listWrapper: {
            backgroundColor: theme.palette.background.default,
        },
        emptyList: {
            marginTop: theme.spacing(3),
            color: theme.palette.grey[500],
        },
        dateHeader: {
            color: theme.colors.white,
            backgroundColor: theme.palette.grey[900],
            display: "flex",
            alignItems: "center",
            height: "40px",
            paddingLeft: "10px",
        },
    }),
);
