import { DeleteTokenData, RegisterTokenData, UpdateTokenData } from "JS/Models";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";
import { isIOS } from "react-device-detect";
import { config } from "JS/Config";

export class PushNotificationsService extends BaseService {
    async registerDeviceToken(
        memberId: string,
        token: RegisterTokenData["token"],
        deviceId: RegisterTokenData["deviceId"],
    ) {
        const toSend: RegisterTokenData = {
            appName: "LTD Media",
            language: "EN",
            platform: isIOS ? "ios" : "android",
            token: token,
            appType: 1,
            deviceId: deviceId,
        };

        const formData = new FormData();

        formData.append("appName", `${toSend.appName}`);
        formData.append("language", `${toSend.language}`);
        formData.append("platform", `${toSend.platform}`);
        formData.append("token", `${toSend.token}`);
        formData.append("appType", `${toSend.appType}`);
        formData.append("deviceId", `${toSend.deviceId}`);

        return (
            await this.doXHR<AppResponse<{}>>({
                url: this.routes.server.api.pushNotifications.registerDeviceToken(
                    memberId,
                ),
                method: "POST",
                data: formData,
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }

    async updateDeviceToken(
        memberId: string,
        oldToken: UpdateTokenData["oldToken"],
        newToken: UpdateTokenData["newToken"],
        deviceId: UpdateTokenData["deviceId"],
    ) {
        const toSend: UpdateTokenData = {
            oldToken,
            newToken,
            appName: "LTD Media",
            language: "EN",
            platform: isIOS ? "ios" : "android",
            appType: 1,
            deviceId: deviceId,
        };

        const formData = new FormData();

        formData.append("oldToken", `${toSend.oldToken}`);
        formData.append("newToken", `${toSend.newToken}`);
        formData.append("appName", `${toSend.appName}`);
        formData.append("language", `${toSend.language}`);
        formData.append("platform", `${toSend.platform}`);
        formData.append("appType", `${toSend.appType}`);
        formData.append("deviceId", `${toSend.deviceId}`);

        return (
            await this.doXHR<AppResponse<{}>>({
                url: this.routes.server.api.pushNotifications.updateDeviceToken(
                    memberId,
                ),
                method: "POST",
                data: formData,
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }
}
