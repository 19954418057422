import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GiftDashboardFilters } from "JS/Models";
import { DashboardCache } from "JS/Models/Firebase/GiftDashboard";

export interface GiftDashboardState {
    giftStream?: DashboardCache[];
    giftOpen?: DashboardCache[];
    giftCompleted?: DashboardCache[];
    dashboadActiveFilter?: GiftDashboardFilters;
}

const initialState: GiftDashboardState = {
    giftStream: [],
    giftOpen: [],
    giftCompleted: [],
    dashboadActiveFilter: GiftDashboardFilters.BYDATE,
};

export const giftDashboardStateSlice = createSlice({
    name: "giftDashboardState",
    initialState,
    reducers: {
        setGlobalGiftDashboard: (
            state,
            data: PayloadAction<GiftDashboardState>,
        ) => {
            return {
                ...state,
                ...data.payload,
            };
        },
        resetGiftDashboardState: () => {
            return initialState;
        },
    },
});
export const { resetGiftDashboardState, setGlobalGiftDashboard } =
    giftDashboardStateSlice.actions;
