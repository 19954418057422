import { FeedbackService } from "JS/Services/Feedback";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { useRouting } from "JS/React/Hooks/Routes";

const service = new FeedbackService();

export default service;

export const useFeedback = () => {
    const [loading, setLoading] = useState(false);
    const [feedbackResponse, setFeedbackResponse] = useState<any>(null);
    const { linkProvider } = useRouting();
    const { homeStack } = useGlobalNavStack();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const feed = useCallback((field: string) => {
        setLoading(true);
        setFeedbackResponse(null);
        return service
            .sendFeedback(field)
            .then((val) => {
                if (val) {
                    setFeedbackResponse(val);
                    enqueueSnackbar(`Feedback successfully submitted.`, {
                        variant: "success",
                    });
                    if (!homeStack?.isHomeTrack) {
                        history.push(linkProvider.react.more().index());
                    } else {
                        history.push(linkProvider.react.home());
                    }
                }
                return val;
            })
            .catch((err) => {
                enqueueSnackbar(`Feedback not submitted.`, {
                    variant: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return {
        feed,
        feedbackResponse,
        loading,
    };
};
