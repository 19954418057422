import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PlaylistControls {
    shuffle: boolean;
    loop: boolean;
}

const initialState: PlaylistControls = {
    shuffle: false,
    loop: false,
};

export const playlistControlsSlice = createSlice({
    name: "playlistControlState",
    initialState,
    reducers: {
        setGlobalPlaylistControls: (
            state,
            data: PayloadAction<PlaylistControls>,
        ) => {
            state.shuffle = data.payload.shuffle;
            state.loop = data.payload.loop;
            return state;
        },
        resetPlaylistControls: () => {
            return initialState;
        },
    },
});

export const { setGlobalPlaylistControls, resetPlaylistControls } =
    playlistControlsSlice.actions;
