import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentDetails } from "JS/Models";

export type DocumentsFavorites = { [key: string]: DocumentDetails[] };

export interface DocumentsState {
    documentFavorites: DocumentsFavorites;
    documentsList: DocumentDetails[];
}

const initialState: DocumentsState = {
    documentFavorites: {},
    documentsList: [],
};

export const documentsStateSlice = createSlice({
    name: "documentsState",
    initialState,
    reducers: {
        setGlobalDocuments: (state, data: PayloadAction<DocumentDetails[]>) => {
            state.documentsList = data.payload;
            return state;
        },
        setGlobalDocumentsFavorites: (
            state,
            data: PayloadAction<DocumentsFavorites>,
        ) => {
            state.documentFavorites = data?.payload;
            return state;
        },
        resetDocumentsState: () => {
            return initialState;
        },
        resetDocumentsListState: (state) => {
            state.documentsList = [];
            return state;
        },
    },
});
export const {
    setGlobalDocumentsFavorites,
    setGlobalDocuments,
    resetDocumentsState,
    resetDocumentsListState,
} = documentsStateSlice.actions;
