import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "JS/Config";
import { Playlist } from "JS/Models";

export interface PlaylistState {
    playlists?: Playlist[];
    playlistsMap: PlaylistMap;
    playlistResumeMap: ResumePlaylistMap;
}

export type ResumePlaylist = {
    id: string;
    resumeAudioNid: string;
    resumeBundleIndex: number;
};

type PlaylistMap = { [key: string]: Playlist[] };

type ResumePlaylistMap = { [key: string]: ResumePlaylist[] };

const initialState: PlaylistState = {
    playlists: [],
    playlistsMap: {},
    playlistResumeMap: {},
};

export const playlistStateSlice = createSlice({
    name: "playlistState",
    initialState,
    reducers: {
        setGlobalPlaylist: (state, data: PayloadAction<PlaylistState>) => {
            state.playlistsMap = data.payload.playlistsMap;
            state.playlistResumeMap = data.payload.playlistResumeMap;
            return state;
        },
        migratePlaylistData: (state) => {
            const migratedData = {};
            migratedData[config.user.memberId] = state.playlists
                ? state.playlists
                : [];
            return {
                ...state,
                playlists: [],
                playlistsMap: migratedData,
            };
        },
        updatePlaylistResume: (
            state,
            data: PayloadAction<ResumePlaylistMap>,
        ) => {
            state = { ...state, playlistResumeMap: { ...data.payload } };
            return state;
        },
        resetPlaylistResumeMap: (state) => {
            return { ...state, playlistResumeMap: {} };
        },
    },
});
export const {
    setGlobalPlaylist,
    migratePlaylistData,
    updatePlaylistResume,
    resetPlaylistResumeMap,
} = playlistStateSlice.actions;
